import { GeocodingApi } from "../api/geocoding-api";
import { ILocation, ILocationAddress } from "../dtos/location.dto";
import { MapboxLocationFeature } from "../dtos/mapbox-location.dto";

export class LocationRepository {
  private api = new GeocodingApi();

  async getLocationFromText(text: string): Promise<ILocation[]> {
    const location = await this.api.getLocation(text);
    if (location.features.length === 0) {
      throw new Error();
    }

    return location.features.map((feature) =>
      this._getAddressFromMapbox(feature)
    );
  }

  async getLocationFromLatLng(lat: number, lon: number): Promise<ILocation> {
    const location = await this.api.reverse(lat, lon);
    if (location.features.length === 0) {
      throw new Error();
    }
    const mostRelevantAddress = location.features[0];
    return this._getAddressFromMapbox(mostRelevantAddress, lat, lon);
  }

  async getLocationFromCurrentPosition(): Promise<ILocation> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (geolocation) => {
          const { latitude, longitude } = geolocation.coords;
          resolve(this.getLocationFromLatLng(latitude, longitude));
        },
        (error) => {
          reject("geocoder_gps_error");
        }
      );
    });
  }

  _getAddressFromMapbox(
    mapboxLocationFeature: MapboxLocationFeature,
    lat?: number,
    lon?: number
  ): ILocation {
    const latitude = lat || mapboxLocationFeature?.center[1];
    const longitude = lon || mapboxLocationFeature?.center[0];
    return {
      place_id: mapboxLocationFeature?.id,
      display_address: mapboxLocationFeature?.place_name,
      display_name: mapboxLocationFeature?.place_name,
      lat: latitude?.toString(),
      lon: longitude?.toString(),
      position: [latitude, longitude],
      address: this._getAddressDetailFromMapbox(mapboxLocationFeature),
    };
  }

  _getAddressDetailFromMapbox(
    mapboxLocationFeature: MapboxLocationFeature
  ): ILocationAddress {
    return {
      city: mapboxLocationFeature.context.find((element) =>
        element?.id?.startsWith("place")
      ).text,
      country: mapboxLocationFeature.context.find((element) =>
        element?.id?.startsWith("country")
      ).text,
      postcode: mapboxLocationFeature.context.find((element) =>
        element?.id?.startsWith("postcode")
      ).text,
      region: mapboxLocationFeature.context.find((element) =>
        element?.id?.startsWith("region")
      ).text,
    };
  }
}
