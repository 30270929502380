import { DimensionneurApi } from "../api/dimensionneur-api";
import { DimensionneurDto } from "../api/dtos/dimensionneur-dto";
import { SimulationEntity } from "./entities/simulation-entity";

class DimensionneurService {
  api = new DimensionneurApi();

  async getSimulation(params: {
    budget: number;
    equipements: string[];
    lat: number;
    lng: number;
    angle: number;
    orientation: number;
    pays: string;
  }): Promise<SimulationEntity | undefined> {
    const [dimension, dimensionOptimized] = await Promise.all([
      this.getDimension(params),
      this.getDimension({
        ...params,
        orientation: undefined,
        angle: undefined,
      }),
    ]);

    // Solar potential
    const power = dimension.maxPower[dimension.power_sélection.toString()];
    const powerOptimized =
      dimensionOptimized.maxPower[dimension.power_sélection.toString()];

    const productionAnnuelle = power["3"];
    const productionAnnuelleOptimized = powerOptimized["3"];

    const puissance = power["2"];
    const pourcentageOptimisation =
      (productionAnnuelle * 100) / productionAnnuelleOptimized;

    // Estimation
    // const savings = power[15];
    // const surplus = power[8];

    // Esimation V2
    const gain = power[0];
    const surplus = power[8];
    const savings = gain - surplus;

    // Rentabilite
    const powerKw = power["2"] / 1000;
    const price = power["9"];
    return {
      all: dimension,
      potentielSolaire: {
        heureEnsoleillement: (productionAnnuelle / puissance) * 1000,
        pourcentageOptimisation: pourcentageOptimisation,
        productionAnnuelle: productionAnnuelle,
        lostRendementPercent: 100 - pourcentageOptimisation,
      },
      rentabilite: {
        savings: savings,
        surplus: surplus,
        gain: gain,
      },
      estimation: {
        kitPowerKw: powerKw,
        kitPanels: dimension.power_sélection,
        kitPrice: price,
      },
    };
  }

  private async getDimension(params: {
    budget: number;
    equipements: string[];
    lat: number;
    lng: number;
    angle: number;
    orientation: number;
    pays: string;
  }): Promise<DimensionneurDto | undefined> {
    try {
      return await this.api.getReqTab(params);
    } catch (_) {
      return undefined;
    }
  }
}

export const dimensionneurRepository = new DimensionneurService();
