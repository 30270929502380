import { ComponentStatus } from "../../../components/shared/status/component-status";
import { configurateurService } from "../../../data/configurateur/services/configurateur-service";
import { ConfigurateurFixationsState } from "./configurateur-fixations-state";

const initialState: ConfigurateurFixationsState = {
  fixationsData: {
    status: ComponentStatus.idle,
  },
};

enum ActionType {
  loadFixationsToiture = "loadFixationsToiture",
  loadFixationHorsToiture = "loadFixationHorsToiture",
}

export const configurationFixationsReducer = (
  state = initialState,
  action
): ConfigurateurFixationsState => {
  const { data, status, error } = action;

  switch (action.type) {
    case ActionType.loadFixationsToiture:
    case ActionType.loadFixationHorsToiture:
      return {
        ...state,
        fixationsData: {
          panneauFixation: data || state?.fixationsData?.panneauFixation,
          status: status,
          error: error,
        },
      };
    default:
      return { ...state };
  }
};

export const configurateurFixationsToitureLoad =
  (
    matrice: number[][],
    disposition: number,
    emplacementId: number,
    panelId: string
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ActionType.loadFixationsToiture,
        status: ComponentStatus.loading,
      });
      const panneauFixations = await configurateurService.getPanneauFixations(
        matrice,
        disposition,
        emplacementId,
        panelId
      );

      dispatch({
        type: ActionType.loadFixationsToiture,
        status: ComponentStatus.loaded,
        data: panneauFixations,
      });
    } catch (_) {
      dispatch({
        type: ActionType.loadFixationsToiture,
        status: ComponentStatus.error,
      });
    }
  };

export const configurateurFixationsHorsToitureLoad =
  (fixationIdAttribute: string) => async (dispatch) => {
    try {
      dispatch({
        type: ActionType.loadFixationHorsToiture,
        status: ComponentStatus.loading,
      });
      const panneauFixation = await configurateurService.getPanneauFixationById(
        fixationIdAttribute
      );

      dispatch({
        type: ActionType.loadFixationHorsToiture,
        status: ComponentStatus.loaded,
        data: {
          fixationsNb: 1,
          data: [panneauFixation],
        },
      });
    } catch (_) {
      dispatch({
        type: ActionType.loadFixationHorsToiture,
        status: ComponentStatus.error,
      });
    }
  };
