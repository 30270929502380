import styled from "styled-components";
import { theme } from "../../Styled/GlobalStyle";

export const OMaterialButton = styled.span<{
    icon?: String;
    size?: String;
    color?: String;
  }>`
    cursor: pointer;

    ${(props) => {
        switch (props.icon) {
        case "+":
            return `
                &:before {
                    content: "add_circle";
                }
            `;
        case "-":
            return `
                &:before {
                    content: "do_not_disturb_on";
                }
            `;
        case "!":
            return `
                &:before {
                    content: "error";
                }
            `;
        case "x":
            return `
                &:before {
                    content: "close";
                }
            `;
        default:
            return props.icon ? `
                &:before { 
                    content: ${props.icon};
                }
            ;` : ``;
        }
    }}    

    font-size: ${(props) => { 
        return props.size ? props.size.toString() : "18";
    }}px;

    ${(props) => {
        switch (props.color) {
        case "primary":
            return `
                color: ${theme.colors.primary.bluePower700};
                &:hover {
                    color: ${props.theme.colors.primary.bluePower600};
                }
            `;
        case "red":
            return `
                color: ${theme.colors.error.cherryPower700};
            `;
        case "yellow":
            return `
                color: ${theme.colors.secondary.yellowPower600};
                &:hover {
                    color: ${props.theme.colors.secondary.yellowPower700};
                }
            `;
        case "grey-light":
            return `
                color: ${theme.colors.clay.clayPower700};
                &:hover {
                    color: ${props.theme.colors.onyx.onyxPower500};
                }
            `;
        case "grey-blue":
            return `
                color: ${theme.colors.onyx.onyxPower400};
                &:hover {
                    color: ${props.theme.colors.onyx.onyxPower500};
                }
            `;
        case "grey":
            return `
                color: ${theme.colors.onyx.onyxPower500};
            `;
        default:
            return `color: ${theme.colors.onyx.onyxPower700}`;
        }
    }}

`;

// ${(props) => {
//     switch (props.size) {
//     case "28":
//         return `
//             font-size: 28px;
//         `;
//     default:
//         return `font-size: 18px;`;
//     }
// }}

// ${(props) => {
//     switch (props.theme) {
//     case "primary":
//         return `
//             color: ${theme.colors.primary.bluePower600};
//         `;
//     default:
//         return `font-size: 18px;`;
//     }
// }}

// font-size: ${(props)=>{return props.taille ? props.taille : "black"}};

// ${(props) => {return props.size ? `font-size: ${props.size}px` : `font-size: 18px`}}