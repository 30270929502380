// Compute mapbox globalGrid into usable matrix
// Copy/Paste from calepinage
export const buildMatrix = (globalGrid: any): number[][] => {
  const matrix = [];
  for (const rectangle of globalGrid.features) {
    const { selected, row, column } = rectangle.properties;
    if (!matrix[row]) {
      matrix[row] = [];
    }
    if (matrix[row].length <= column) {
      matrix[row].push(...Array(column - matrix[row].length + 1).fill(0));
    }
    matrix[row][column] = selected ? 1 : 0;
  }

  matrix.reverse();

  const filteredMatrix = matrix.filter((row) =>
    row.some((value) => value !== 0)
  );

  let maxLineLength = getLargerLine(filteredMatrix);

  let final_matrix = [];
  filteredMatrix.forEach(function (line, index) {
    let new_line = fillArrayWithZeros(line, maxLineLength);
    final_matrix.push(new_line);
  });
  return final_matrix;
};

// Copy/Paste from calepinage
const filterMatriceForAPI = (matrice) => {
  let minIndice = -1,
    maxIndice = -1;
  for (let i = 0; i < matrice.length; i++) {
    // Parcours de lignes
    for (let j = 0; j < matrice[i].length; j++) {
      // Parcours des colonnes
      if (matrice[i][j] === 1) {
        if (minIndice === -1 || minIndice > j) {
          minIndice = j;
        }
        if (maxIndice < j || maxIndice === -1) {
          maxIndice = j;
        }
      }
    }
  }
  let result = [];
  // Reconstruction de la matrice filtrée
  for (let i = 0; i < matrice.length; i++) {
    result[i] = [];
    for (let j = minIndice; j <= maxIndice; j++) {
      result[i].push(matrice[i][j]);
    }
  }
  return result;
};

export const getUsableMatriceFromMapbox = (globalGrid) => {
  return filterMatriceForAPI(buildMatrix(globalGrid));
};

// Copy/Paste from calepinage
const getLargerLine = (matrice) => {
  let maxLength = 0;
  matrice.forEach(function (line, index) {
    if (line.length > maxLength) {
      maxLength = line.length;
    }
  });
  return maxLength;
};

// Copy/Paste from calepinage
const fillArrayWithZeros = (line, desiredLength) => {
  const currentLength = line.length;
  if (currentLength >= desiredLength) {
    return line;
  }
  const zerosToAdd = desiredLength - currentLength;
  for (let i = 0; i < zerosToAdd; i++) {
    line.push(0);
  }
  return line;
};
