import { AzimutId, PenteId } from "../Steps/Recommandation/phases/phase1/constants";

export const GLOBAL_SHOW_HEADER = false;
export const GLOBAL_GO_TO_END = true;
export const GLOBAL_LAUNCH_SIMULATION = false;
export const GLOBAL_SIMULATION_DATAS = {
    budget: 80,
    equipements: [],
    lat: 45.71038967282056,
    lng: 5.762410028335218,
    angle: PenteId["PENTE_1524"],
    orientation: AzimutId["SUD"],
    pays: "fr",
}
export const GLOBAL_USE_NEW_FORMULA_ECONOMY = true;
export const GLOBAL_SHOW_CRC = true;
export const GLOBAL_SHOW_VALIDATE_CALEPINAGE = false;
export const GLOBAL_PERCENT_AUTOCONSO = 0.5;
export const GLOBAL_PRICE_SELL = 0.04;
export const GLOBAL_EDF_PRICE = 0.23;
export const GLOBAL_NBRE_HEURE_PRODUCTION = 1200;