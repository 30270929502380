import styled from "styled-components";

export const HeaderContainer = styled.div`
  * {
    user-select: none;
  }

  img {
    padding-left: 40px;
    width: 200px;
    height: 20px;
  }
  img:hover {
    cursor: pointer;
  }

  display: flex;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.clay.clayPower200};
  box-sizing: border-box;
  border-bottom: 1px solid #dbdada;
  backdrop-filter: blur(4px);
  fixed: top;
  opacity: 0.72;
  z-index: 99;
  top: 0;
  left: 0;

  .active-border {
    position: absolute;
    bottom: 0;
    height: 4px;
    background-color: ${({ theme }) => theme.colors.primary.bluePower700};
    transition: all 0.3s ease-in-out;
    margin: 0 0px;
    border-radius: 2px;
    z-index: 1000;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}px) {
    .active-border {
      display: none;
    }

    img {
      padding-left: 24px;
      width: 150px;
    }

    * .is-active p {
      color: ${({ theme }) => theme.colors.primary.bluePower700};
    }
  }
`;
export const Nav = styled.nav<{ open: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 40px;
  width: 100%;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}px) {
    justify-content: flex-start; 
    overflow-x: scroll;
    width: auto; 
    padding-left: 1rem;
    padding-right: 1rem;
    overflow-y: hidden;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
`;

export const NavLinkCustom = styled.button<{ disabled: boolean }>`
  color: ${(props) => props.theme.colors.onyx.onyxPower600};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  transition: border-color 0.3s ease-in-out;
  text-decoration: none !important;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
  padding-left: 10px;
  padding-right: 10px;
  border: 0;
  background: transparent;

  text-align: center;

  &:hover {
    // color: ${(props) => props.theme.colors.onyx.onyxPower700};
    // transform: scale(0.95);
  }
`;
