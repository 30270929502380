import { useTranslation } from "react-i18next";
import styled from "styled-components";
import store from "../../../../../../store";
import { validateMatriceCalepinage } from "../../../../../../store/calepinage/calepinageHorsMapReducer";
import { STEP_DESIGN } from "../../../../../../store/reducer";
import { OMainButton } from "../../../../../Styled/Buttons";
import { theme } from "../../../../../Styled/GlobalStyle";
import { OBody10, OBody11 } from "../../../../../Styled/Texts";

export const CalepinageHmConfirmOverlay = () => {
  const { t } = useTranslation(STEP_DESIGN);
  const validate = () => {
    store.dispatch(validateMatriceCalepinage());
  };
  return (
    <Container>
      <Body>
        <Card>
          <OBody10>{t("phase_2.calepinage_hm_to_validate.title")}</OBody10>
          <OBody11>
            {t("phase_2.calepinage_hm_to_validate.description")}
          </OBody11>
        </Card>
        <OMainButton primary onClick={validate}>
          {t("phase_2.calepinage_hm_to_validate.action")}
        </OMainButton>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  z-index: 100;

  display: flex;
  align-items: start;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30vh;
`;

const Card = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
  flex-direction: column;
  gap: 4px;
  background-color: ${theme.colors.secondary.yellowPower400};
  border-radius: 12px;
  padding: 12px;
  margin: 24px;
  text-align: center;
`;
