import { Variants } from "framer-motion";

export const slide = (
  direction = "right",
  delay = 0,
  duration = 0.5,
  ease = "easeOut",
  gap = 20
): Variants => {
  const initial: any = { opacity: 0, x: 0, y: 0 };
  const animate: any = { opacity: 1, x: 0, y: 0 };
  const transition = { duration, ease, delay } as any;
  switch (direction) {
    case "right":
      initial.x = -gap;
      animate.x = 0;
      break;
    case "left":
      initial.x = gap;
      animate.x = 0;
      break;
    case "up":
      initial.y = gap;
      animate.y = 0;
      break;
    case "down":
      initial.y = -gap;
      animate.y = 0;
      break;
  }
  return { initial, animate, transition };
};

export const fade = (
  delay = 0.1,
  duration = 1,
  direction = "center",
  ease = "easeOut",
  gap = 20
): Variants => {
  const initial: any = { opacity: 0, x: 0, y: 0 };
  const animate: any = { opacity: 1, x: 0, y: 0 };
  const transition = { duration, ease, delay } as any;
  switch (direction) {
    case "center":
      initial.x = 0;
      animate.x = 0;
      break;

    case "right":
      initial.x = -gap;
      animate.x = 0;
      break;
    case "left":
      initial.x = gap;
      animate.x = 0;
      break;
    case "up":
      initial.y = gap;
      animate.y = 0;
      break;
    case "down":
      initial.y = -gap;
      animate.y = 0;
      break;
  }
  
  return { initial, animate, transition };
};
