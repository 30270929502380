import { useEffect, useState } from 'react';


export default function useDimension() {

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension(){
    return {
        width: window.innerWidth,
        height: window.innerHeight
    }
  }

  useEffect(() => {

    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
  
    window.addEventListener('resize', updateDimension);


    return(() => {
      window.removeEventListener('resize', updateDimension);
  })
  }, [screenSize]);

  return screenSize;
};
