import styled from "styled-components";
import { motion } from "framer-motion";

const OBaseSecondary = styled(motion.button)`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;

  border-radius: 8px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;

  cursor: pointer;
  border: none;
  outline: none;

  position: relative;

  transition: all 0.3s ease-in-out;
  color: ${(props) => props.theme.colors.primary.bluePower700};
  background: ${(props) => props.theme.colors.primary.bluePower300};
  &:hover {
    background: ${(props) => props.theme.colors.primary.bluePower200};
  }

  &:active {
    background: ${(props) => props.theme.colors.primary.bluePower300};
  }
`;

export const OSecondaryButton = styled(OBaseSecondary)<{}>``;
