import mapboxgl from "mapbox-gl";
import { setIsPressingOnRotate } from "../../../../store/calepinage/mapBoxReducer";
import { svgsPath } from "../../../../utils/constants";

export class RotateControl {
  private _map: mapboxgl.Map;
  private _container: HTMLDivElement;
  private _dispatch: Function;

  constructor(dispatch: Function) {
    this._dispatch = dispatch;
  }

  onAdd(map: mapboxgl.Map) {
    this._map = map;
    this._container = document.createElement("div");
    this._container.style.position = "absolute";
    this._container.style.display = "flex";
    this._container.style.bottom = "20px";
    this._container.style.right = "37px";
    this._container.style.gap = "0rem";

    // this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    // this._container.style.display = 'flex';
    // this._container.style.justifyContent = 'space-between';
    // this._container.style.position = 'relative';
    // this._container.style.bottom = '-136px';
    // this._container.style.left = '-38px';

    //   this._container.style.position = 'absolute';
    //   this._container.style.top = '15%';  // Ajuster ces valeurs selon vos besoins
    //   this._container.style.left = '50px';
    //   this._container.style.width = '48px';
    //   this._container.style.height = '48px';
    //   this._container.style.borderRadius = '8px';
    //   this._container.style.position = 'absolute';
    //   this._container.style.top = '516px';
    //   this._container.style.left = '966px';

    let intervalId: number;

    const containerForButton = document.createElement("div");
    containerForButton.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
    containerForButton.style.display = "flex";
    // containerForButton.style.justifyContent = 'space-between';
    containerForButton.style.position = "relative";
    // containerForButton.style.bottom = '-136px';
    // containerForButton.style.left = '-38px';

    const containerForButtonRight = document.createElement("div");
    containerForButtonRight.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
    containerForButtonRight.style.display = "flex";
    // containerForButtonRight.style.justifyContent = 'space-between';
    containerForButtonRight.style.position = "relative";
    // containerForButtonRight.style.bottom = '-136px';
    // containerForButtonRight.style.left = '-72px';

    const button = document.createElement("button");
    button.style.backgroundImage =
      "url(" + svgsPath + "arrow_right_calepinage.svg)";
    button.style.backgroundSize = "42%";
    button.style.backgroundPosition = "center";
    button.style.backgroundRepeat = "no-repeat";
    button.style.width = "29px";
    button.style.height = "29px";
    button.style.borderRadius = "8px";
    button.className = "mapboxgl-ctrl-icon";

    const buttonRight = document.createElement("button");
    buttonRight.style.backgroundSize = "45%";
    buttonRight.style.backgroundPosition = "center";
    buttonRight.style.backgroundRepeat = "no-repeat";
    buttonRight.style.width = "29px";
    buttonRight.style.height = "29px";
    buttonRight.style.borderRadius = "8px";
    buttonRight.style.backgroundImage =
      "url(" + svgsPath + "arrow_left_calepinage.svg)";
    buttonRight.className = "mapboxgl-ctrl-icon";

    //   button.onmouseover = () => {
    //     button.style.backgroundColor = "darkgray";
    //   }

    //   button.onmouseout = () => {
    //     button.style.backgroundColor = "";
    //   }

    //   buttonRight.onmouseover = () => {
    //     buttonRight.style.backgroundColor = "darkgray";
    //   }

    //   buttonRight.onmouseout = () => {
    //     buttonRight.style.backgroundColor = "";
    //   }

    button.onclick = () => {
      const currentBearing = this._map.getBearing();
      this._map.setBearing(currentBearing - 0.25);
    };

    button.onmousedown = () => {
      this._dispatch(setIsPressingOnRotate(true));
      intervalId = window.setInterval(() => {
        const currentBearing = this._map.getBearing();
        this._map.setBearing(currentBearing - 1);
      }, 20);
    };

    button.onmouseup = () => {
      if (intervalId) {
        this._dispatch(setIsPressingOnRotate(false));
        window.clearInterval(intervalId);
      }
    };

    button.onmouseleave = () => {
      if (intervalId) {
        this._dispatch(setIsPressingOnRotate(false));
        window.clearInterval(intervalId);
      }
    };

    buttonRight.onmousedown = () => {
      this._dispatch(setIsPressingOnRotate(true));
      intervalId = window.setInterval(() => {
        const currentBearing = this._map.getBearing();
        this._map.setBearing(currentBearing + 1);
      }, 20);
    };

    buttonRight.onmouseup = () => {
      if (intervalId) {
        this._dispatch(setIsPressingOnRotate(false));
        window.clearInterval(intervalId);
      }
    };

    buttonRight.onmouseleave = () => {
      if (intervalId) {
        this._dispatch(setIsPressingOnRotate(false));
        window.clearInterval(intervalId);
      }
    };

    buttonRight.onclick = () => {
      const currentBearing = this._map.getBearing();
      this._map.setBearing(currentBearing + 0.25);
    };

    containerForButton.appendChild(button);
    containerForButtonRight.appendChild(buttonRight);
    this._container.appendChild(containerForButton);
    this._container.appendChild(containerForButtonRight);
    // this._container.appendChild(button);
    // this._container.appendChild(buttonRight);

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}

export class ZoomControl {
  private _map: mapboxgl.Map;
  private _container: HTMLDivElement;
  private _dispatch: Function;
  private _resetAll: Function;

  constructor(dispatch: Function, resetAll: Function) {
    this._dispatch = dispatch;
    this._resetAll = resetAll;
  }

  onAdd(map: mapboxgl.Map) {
    this._map = map;
    this._container = document.createElement("div");
    this._container.style.position = "absolute";
    // this._container.style.display = 'flex';
    this._container.style.bottom = "20px";
    this._container.style.right = "0px";
    this._container.style.gap = "0rem";

    const containerForButton = document.createElement("div");
    containerForButton.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
    containerForButton.style.display = "flex";
    containerForButton.style.position = "relative";

    const containerForButtonZoomOut = document.createElement("div");
    containerForButtonZoomOut.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
    containerForButtonZoomOut.style.display = "flex";
    containerForButtonZoomOut.style.position = "relative";

    const containerForTrash = document.createElement("div");
    containerForTrash.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
    containerForTrash.style.display = "flex";
    containerForTrash.style.position = "relative";

    const button = document.createElement("button");
    button.style.width = "29px";
    button.style.height = "29px";
    button.style.borderRadius = "8px";
    button.className = "mapboxgl-ctrl-zoom-in";
    button.ariaLabel = "Zoom in";
    button.ariaDisabled = "true";

    const span = document.createElement("span");
    span.className = "mapboxgl-ctrl-icon";
    span.ariaHidden = "true";
    span.title = "Zoom in";

    button.appendChild(span);

    const buttonZoomOut = document.createElement("button");
    buttonZoomOut.style.width = "29px";
    buttonZoomOut.style.height = "29px";
    buttonZoomOut.style.borderRadius = "8px";
    buttonZoomOut.className = "mapboxgl-ctrl-zoom-out";
    buttonZoomOut.ariaLabel = "Zoom in";
    buttonZoomOut.ariaDisabled = "true";

    const spanZoomOut = document.createElement("span");
    spanZoomOut.className = "mapboxgl-ctrl-icon";
    spanZoomOut.ariaHidden = "true";
    spanZoomOut.title = "Zoom out";

    buttonZoomOut.appendChild(spanZoomOut);

    const buttonTrash = document.createElement("button");
    buttonTrash.style.width = "29px";
    buttonTrash.style.height = "29px";
    buttonTrash.style.borderRadius = "8px";
    buttonTrash.className = "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash";
    buttonTrash.title = "Delete";

    const spanTrash = document.createElement("span");
    spanTrash.className = "mapboxgl-ctrl-icon";
    spanTrash.ariaHidden = "true";
    spanTrash.title = "Zoom out";

    buttonTrash.appendChild(spanTrash);

    button.onclick = () => {
      const currentZoom = this._map.getZoom();
      this._map.flyTo({ zoom: currentZoom + 0.5 });
    };

    buttonZoomOut.onclick = () => {
      const currentZoom = this._map.getZoom();
      this._map.flyTo({ zoom: currentZoom - 0.5 });
    };

    buttonTrash.onclick = () => {
      this._resetAll(true);
    };

    containerForButton.appendChild(button);
    containerForButtonZoomOut.appendChild(buttonZoomOut);
    containerForTrash.appendChild(buttonTrash);
    this._container.appendChild(containerForButton);
    this._container.appendChild(containerForButtonZoomOut);
    this._container.appendChild(containerForTrash);

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}

// export default RotateControl;
