import { KitsApi } from "../api/kits.api";
import { KitDetailDto } from "../dtos/kit-detail.dto";

export class KitsService {
  api = new KitsApi();

  async getKitDetails(id: string): Promise<KitDetailDto> {
    return this.api.getKitDetails(id);
  }

  async createKit(
    products: {
      id_product: string;
      id_variant: string;
      quantity: number;
    }[],
    panelsMatrix?: number[][]
  ): Promise<string> {
    const kit = await this.api.createKit(products, panelsMatrix);
    return kit.variants.find((v) => v.id).id;
  }
}

export const kitsService = new KitsService();
