import React, { useEffect, useState } from "react";
import { LocalizationContainer } from "./styled";
import { OContainer } from "../../Styled/CustomGrid";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useDispatch } from "react-redux";
import { StepProps } from "../../interface";
import { STEP_LOCALIZATION, setGlobalCurrentPhase, setStepAccessible } from "../../../store/reducer";
import "leaflet/dist/leaflet.css";
import LocalizationPhase1 from "./phases/localization_phase_1";
import { RedirectModal } from "../../shared/redirect-modal";
import { ConfigurateurPopups } from "../Design/phases/phase2/configurateur-modals-context";

const Localization: React.FC<StepProps> = ({ onContinue }: any) => {
  const currentStepName = STEP_LOCALIZATION;
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(true);

  const stepData = useSelector(
    (state: RootState) => state.step.steps[currentStepName].data
  );

  useEffect(() => {
      dispatch(setStepAccessible({ step: currentStepName, accessible: true }));
  }, []);

  useEffect(() => {
    dispatch(setGlobalCurrentPhase(stepData.phase));
  }, [stepData.phase]);

  return (

    <LocalizationContainer>
      {/* <RedirectModal show={showModal} onCloseButtonClick={ ()=> setShowModal(false) }/> */}
      <OContainer fluid>
        {stepData.phase === 1 && (
          <LocalizationPhase1 onContinue={onContinue} />
        )}
      </OContainer>
    </LocalizationContainer>
  );
};

export default Localization;
