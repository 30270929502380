import styled from "styled-components";

const OBaseSocial = styled.button`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 8px;
  padding: 12px 20px;
  margin: 10px;
  text-align: center;
  min-height: ${(props) => props.theme.heights.input};

  cursor: pointer;
  outline: none;

  position: relative;
  border: 1px solid ${(props) => props.theme.colors.onyx.onyxPower300};

  transition: all 0.3s ease-in-out;
  color: ${(props) => props.theme.colors.primary.bluePower700};
  background: transparent;
  &:hover {
    background: transparent;
  }

  &:active {
    background: transparent;
  }
`;

export const OSocialButton = styled(OBaseSocial)<{
  us?: boolean;
  google?: boolean;
  apple?: boolean;
}>`
  ${(props) =>
    props.us &&
    `
        background: ${props.theme.colors.primary.bluePower700};
        color: ${props.theme.colors.clay.clayPower200};
        border: none;
        &:hover {
            background: ${props.theme.colors.primary.bluePower600};
        }
        &:active {
            background: ${props.theme.colors.primary.bluePower700};
        }

        &:before {
            content: '';
            display: inline-block;
            margin-right: 8px;
            background-image: url('/icon/logo-us.svg');
            width: 24px;
            height: 24px;
            background-size: contain;
            background-repeat: no-repeat;
        }
    `}
  ${(props) =>
    props.google &&
    `
        &:before {
            content: '';
            display: inline-block;
            margin-right: 8px;
            background-image: url(svgsPath + 'google.svg');
            width: 24px;
            height: 24px;
            background-size: contain;
            background-repeat: no-repeat;
        }
    `}
    ${(props) =>
    props.apple &&
    `
        &:before {
            content: '';
            display: inline-block;
            margin-right: 8px;
            background-image: url(svgsPath + 'apple.svg');
            width: 24px;
            height: 24px;
            background-size: contain;
            background-repeat: no-repeat;
        }
    `}
`;
