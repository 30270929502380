import { createGlobalStyle } from "styled-components";
import { theme } from "./GlobalStyle";

export const CustomScrollbar = createGlobalStyle`

@media (min-width: ${theme.breakpoints.md}px) {
::-webkit-scrollbar {
  position: absolute;
  width: 12px;
  height: 12px;
  z-index: 10;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: ${theme.colors.clay.clayPower600};
  border-radius: 10px;
  border: 3px solid #f0f0f0;
  box-shadow: ${theme.colors.clay.clayPower600} 0px 0px 0px 2px inset;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(0, 55, 159);
}

}

`;
