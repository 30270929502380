import { svgsPath } from "../../../utils/constants";
import useIsMobile from "../../../utils/useIsMobile";
import { PanelsSizeRatio, bigNumberPanelsSizeRatio } from "./constant";
import { PanelFadedContainer, PanelPictureContainer } from "./styled";

export interface MatrixViewerrProps {
  matrix: any;
  orientation: any;
}

const MatrixViewer: React.FC<MatrixViewerrProps> = ({
  matrix = [[]],
  orientation = 0,
}) => {
  const isMobile = useIsMobile();

  // orientation = 0; // 0 portrait -  1 paysage
  // orientation = 1; // 0 portrait -  1 paysage

  // 1x4
  // matrix = [[1, 1, 1, 1]];

  // 3x3
  // matrix = [
  //     [1, 1, 1],
  //     [1, 1, 1],
  //     [1, 1, 1]
  // ];

  // 4x4
  // matrix = [
  //     [1, 1, 1, 1],
  //     [1, 1, 1, 1],
  //     [1, 1, 1, 1],
  //     [1, 1, 1, 1],
  // ];

  // 5x5
  // matrix = [
  //     [1, 1, 1, 1, 1],
  //     [1, 1, 1, 1, 1],
  //     [1, 1, 1, 1, 1],
  //     [1, 1, 1, 1, 1],
  //     [1, 1, 1, 1, 1],
  // ];

  // 6x4
  // matrix = [
  //     [1, 1, 1, 1, 1, 1],
  //     [1, 1, 1, 1, 1, 1],
  //     [1, 1, 1, 1, 1, 1],
  //     [1, 1, 1, 1, 1, 1],
  // ];

  // Matrix 1x8
  // matrix = [
  //     [1, 1, 1, 1, 1, 1, 1, 1],
  // ];

  // Matrix 1x10
  // matrix = [
  //     [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  // ];

  // Matrix 1x12
  // matrix = [
  //     [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  // ];

  // Matrix 1x14
  // matrix = [
  //     [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  // ];

  // Matrix 1x16
  // matrix = [
  //     [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  // ];

  // Matrix 1x18
  // matrix = [
  //     [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  // ];

  // Matrix 1x20
  // matrix = [
  //     [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  // ];

  // Matrix 1x22
  // matrix = [
  //     [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  // ];

  // Matrix 1x24
  // matrix = [
  //     [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  // ];

  // Matrix 1x25
  // matrix = [
  //     [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  // ];

  // Matrix 2x4
  // matrix = [
  //     [1, 1, 1, 1],
  //     [1, 1, 1, 1],
  // ];

  if (matrix?.length <= 0) return;
  const matrixFullSize = matrix.length * matrix[0].length;

  let ratioBySize =
    matrixFullSize > 25
      ? bigNumberPanelsSizeRatio(matrixFullSize)
      : PanelsSizeRatio[matrixFullSize];

  const ratio = ratioBySize * (orientation ? 2 : 1); // scale 2 for landscape
  const maxCol = 6;

  return (
    // flex-shrink:1
    <div className="d-flex flex-column align-content-around flex-grow flex-shrink-1">
      {matrix.map((row, i) => (
        // flex:auto
        <div
          key={i}
          className={`d-flex flex-auto ${orientation ? "" : "py-1"}`}
        >
          {isMobile && row.length > maxCol ? (
            <>
              <div className="d-flew flew-row">
                {row.slice(0, maxCol).map((col, j) => (
                  <PanelPictureContainer
                    key={col + "-" + j}
                    src={
                      svgsPath +
                      (orientation
                        ? "matrice-panneau-paysage.svg"
                        : "matrice-panneau-portrait.svg")
                    }
                    alt="Panneau"
                    className={`shadow-none ${orientation ? "px-1" : ""} ${
                      col ? "" : "opacity-25"
                    }`}
                    ratio={ratio}
                  ></PanelPictureContainer>
                ))}
                <PanelFadedContainer>
                  <PanelPictureContainer
                    src={
                      svgsPath +
                      (orientation
                        ? "matrice-panneau-paysage.svg"
                        : "matrice-panneau-portrait.svg")
                    }
                    alt="Panneau"
                    className={`shadow-none ${orientation ? "px-1" : ""} `}
                    ratio={ratio}
                  ></PanelPictureContainer>
                  <span id="remaining">+ {row.length - maxCol}</span>
                </PanelFadedContainer>
              </div>
            </>
          ) : (
            <>
              {row.map((col, j) => (
                <PanelPictureContainer
                  key={col + "-" + j}
                  src={
                    svgsPath +
                    (orientation
                      ? "matrice-panneau-paysage.svg"
                      : "matrice-panneau-portrait.svg")
                  }
                  alt="Panneau"
                  className={`shadow-none ${
                    orientation ? "px-1 max-h-15" : ""
                  } ${col ? "" : "opacity-25"}`}
                  ratio={ratio}
                ></PanelPictureContainer>
              ))}
            </>
          )}
        </div>
      ))}
    </div>
  );

  // }
};

export default MatrixViewer;
