import axios from "axios";
import {
  API_PRODUCTS,
  API_SETTINGS,
  API_OSCAROPOWER,
  API_DEALT_OFFERS,
  API_CART,
  API_CART_DEALT,
} from "../../../utils/constants";
import { DealtOpOffersDto, ProductsDto } from "./dtos/product-dto";
import { SettingsDto } from "./dtos/settings-dto";
import { PrestashopCartItemDto } from "./dtos/prestashop-cart-item-dto";
import { convertJsonToXml } from "../../../utils";

// >> REMOVED FOR DEVELOPMENT <<
// /**
//  * get headers for api authorization token for dev/preprod environment
//  * @returns {
//     * headers: {
//     *   Authorization: `TOKEN`
//     * }
//  * }
//  */
// export const getDevApiHeaders = (): any => {
//     if(isProd) return undefined;
//     return {
//         headers: {
//             "Authorization": `Basic ${API_DEV_TOKEN}`
//         }
//     };
// };

// API to fetch Products from Prestashop
export const getProductsById = async (
  productIds: string[] = []
): Promise<ProductsDto> => {
  if (productIds.length === 0) {
    return { products: [] };
  }

  const productsList = productIds.join("|");
  const urlParams = `&display=full&filter[id]=[${productsList}]`;
  const url = API_PRODUCTS + urlParams;

  return axios
    .get(url)
    .then((response) => {
      // console.log("response data: ", response.data);
      return response.data.products ? response.data : { products: [] };
    })
    .catch((error) => {
      return { products: [] };
    });
};

// API to fetch Products via "OscaroPower" custom model from Prestashop
export const getOscaroPowerProductsById = async (
  productIds: string[] = []
): Promise<ProductsDto> => {
  if (productIds.length === 0) {
    return { products: [] };
  }

  const productsList = productIds.join("|");
  const urlParams = `&display=full&filter[id_product]=[${productsList}]`;
  const url = API_OSCAROPOWER + urlParams;

  return axios
    .get(url)
    .then((response) => {
      // console.log("response data: ", response.data);
      return response.data.products ? response.data : { products: [] };
    })
    .catch((error) => {
      return { products: [] };
    });
};

// API to fetch Products via "OscaroPower" custom model from Prestashop
export const getDealtOffers = async (): Promise<DealtOpOffersDto> => {
  const urlParams = `&display=[id,dealt_id_offer,title,description,picture,thumbnail,price,panels_min,panels_max]&filter[active]=1`;
  const url = API_DEALT_OFFERS + urlParams;

  return axios
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { products: [] };
    });
};

// API to fetch Settings from Prestashop
export const getDefaultSettings = async (): Promise<SettingsDto> => {
  const url = API_SETTINGS;

  return axios
    .get(url)
    .then((response) => {
      // console.log("response data: ", response.data);
      return response.data.settings;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
};

// API to add Prestashop Products to Cart
export const addProductsToCart = async (
  cartId: number,
  items: PrestashopCartItemDto[]
) => {
  const data = {
    cart: {
      id: cartId,
      id_currency: "1",
      id_lang: "1",
      id_shop: "1",
      id_shop_group: "1",
      associations: {
        cart_rows: {
          cart_row: items,
        },
      },
    },
  };
  const url = API_CART(cartId, JSON.stringify(data));
  return (await axios.get(url)).data;
};

// API to add Prestashop Dealt product to Cart
export const addDealtOfferToCart = async (
  cartId: number,
  dealtOffer
): Promise<void> => {
  // Extracted from V1
  const dealtOfferData = {
    prestashop: {
      "dealt-offer": {
        id_cart: cartId,
        dealt_id_offer: dealtOffer.dealt_id_offer,
        id_offer: dealtOffer.id,
        title_offer: dealtOffer.title,
        description_offer: dealtOffer.description,
        picture_offer: dealtOffer.picture,
        price_offer: dealtOffer.price,
      },
    },
  };

  // Prestashop accepts only xml for this endpoint
  const data = convertJsonToXml(dealtOfferData);
  return await axios.post(API_CART_DEALT, data);
};
