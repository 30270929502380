import { ComponentStatus } from "../../../components/shared/status/component-status";
import { configurateurService } from "../../../data/configurateur/services/configurateur-service";
import { getTotalDiscountedPrice } from "../../../utils/calculate-item-price";
import {
  ConfigurateurAccessoriesCombination,
  ConfigurateurAccessoriesState,
} from "./configurateur-accessories-state";

const initialState: ConfigurateurAccessoriesState = {
  accessoriesData: {
    status: ComponentStatus.idle,
    combinations: [],
  },
};

enum ActionType {
  loadAccessories = "loadAccessories",
  selectAccesoriesCombination = "selectAccesoriesCombination",
}

const cableCombinations = [
  {
    entryA: 10,
    entryB: 5,
    withParafoudre: false,
  },
  {
    entryA: 25,
    entryB: 5,
    withParafoudre: false,
  },
  {
    entryA: 10,
    entryB: 25,
    withParafoudre: false,
  },
  {
    entryA: 25,
    entryB: 25,
    withParafoudre: false,
  },
  {
    entryA: 10,
    entryB: 5,
    withParafoudre: true,
  },
  {
    entryA: 25,
    entryB: 5,
    withParafoudre: true,
  },
  {
    entryA: 10,
    entryB: 25,
    withParafoudre: true,
  },
  {
    entryA: 25,
    entryB: 25,
    withParafoudre: true,
  },
];

export const configurationAccessoriesReducer = (
  state = initialState,
  action
): ConfigurateurAccessoriesState => {
  const { combinations, status, error, selected, parafoudreRequired } = action;

  switch (action.type) {
    case ActionType.loadAccessories:
      return {
        ...state,
        accessoriesData: {
          combinations: combinations || state?.accessoriesData?.combinations,
          status: status,
          error: error,
          selectedCombination:
            selected || state?.accessoriesData?.selectedCombination,
          parafoudreRequired: parafoudreRequired,
        },
      };
    case ActionType.selectAccesoriesCombination:
      return {
        ...state,
        accessoriesData: {
          ...state.accessoriesData,
          combinations: combinations || state?.accessoriesData?.combinations,
          status: status,
          error: error,
          selectedCombination: selected,
        },
      };
    default:
      return { ...state };
  }
};

export const configurateurAccessoriesLoad =
  (
    panelId: string,
    panelAttribute: string,
    panelsNumber: number,
    onduleurId: string,
    onduleurAttribute: string,
    onduleursNumber: number,
    fixationId: string,
    fixationAttribute: string,
    supervisionId: string,
    supervisionAttribute: string,
    phaseNumber: number,
    tracker: number,
    fixationNumber?: number,
    postcode?: string
  ) =>
  async (dispatch, getStore) => {
    try {
      dispatch({
        type: ActionType.loadAccessories,
        status: ComponentStatus.loading,
      });
      const accessoriesCombinations =
        await Promise.all<ConfigurateurAccessoriesCombination>(
          cableCombinations.map(async (combination) => {
            const accessoriesCombination =
              await configurateurService.getAccessories(
                panelId,
                panelAttribute,
                panelsNumber,
                onduleurId,
                onduleurAttribute,
                onduleursNumber,
                fixationId,
                fixationAttribute,
                fixationNumber || 1,
                combination.withParafoudre,
                combination.entryA,
                combination.entryB,
                supervisionId,
                supervisionAttribute,
                phaseNumber,
                tracker,
                postcode
              );
            return {
              entryA: combination.entryA,
              entryB: combination.entryB,
              withParafoudre: combination.withParafoudre,
              priceDelta: 0,
              parafoudreRequired: accessoriesCombination.parafoudreRequired,
              totalPrice: accessoriesCombination.data
                .map((combination) => getTotalDiscountedPrice(combination))
                .reduce((prev, value) => prev + value, 0),
              data: accessoriesCombination.data,
            };
          })
        );

      const prevSelected =
        getStore().configurateurAccessories?.accessoriesData
          ?.selectedCombination;
      const parafoudreRequired = accessoriesCombinations.some(
        (combination) => combination.parafoudreRequired
      );
      let selected =
        accessoriesCombinations.find(
          (combination) =>
            combination.entryA === prevSelected?.entryA &&
            combination.entryB === prevSelected?.entryB &&
            combination.withParafoudre === prevSelected?.withParafoudre
        ) ||
        (parafoudreRequired
          ? accessoriesCombinations.find(
              (combination) => combination.withParafoudre
            )
          : accessoriesCombinations[0]);

      const baseCombinationPrice = selected.data
        ?.map((product) => getTotalDiscountedPrice(product))
        .reduce((prevPrice, currentPrice) => prevPrice + currentPrice, 0);

      accessoriesCombinations.forEach((combination) => {
        const combinationPrice = combination?.data
          ?.map((product) => getTotalDiscountedPrice(product))
          .reduce((prevPrice, currentPrice) => prevPrice + currentPrice, 0);
        combination.priceDelta = combinationPrice - baseCombinationPrice;
      });

      dispatch({
        type: ActionType.loadAccessories,
        status: ComponentStatus.loaded,
        combinations: accessoriesCombinations,
        selected: selected,
        parafoudreRequired: accessoriesCombinations.some(
          (combination) => combination.parafoudreRequired
        ),
      });
    } catch (_) {
      dispatch({
        type: ActionType.loadAccessories,
        status: ComponentStatus.error,
      });
    }
  };

export const configurateurAccessoriesOnCombinationChanged =
  (selectedCombination: {
    entyrA?: number;
    entryB?: number;
    withParafoudre?: boolean;
  }) =>
  (dispatch, getState) => {
    const accessoriesData =
      getState().configurateurAccessories?.accessoriesData;
    const combinations =
      accessoriesData?.combinations as ConfigurateurAccessoriesCombination[];

    const previousCombination =
      accessoriesData?.selectedCombination as ConfigurateurAccessoriesCombination;

    const entryA = selectedCombination.entyrA || previousCombination?.entryA;
    const entryB = selectedCombination.entryB || previousCombination?.entryB;
    const withParafoudre =
      selectedCombination.withParafoudre !== undefined
        ? selectedCombination.withParafoudre
        : previousCombination?.withParafoudre;

    const newSelectedCombination =
      combinations?.find(
        (combination) =>
          combination.entryA === entryA &&
          combination.entryB === entryB &&
          combination.withParafoudre === withParafoudre
      ) || previousCombination;
    dispatch({
      type: ActionType.selectAccesoriesCombination,
      selected: newSelectedCombination,
    });
  };
