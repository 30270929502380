import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  STEP_NEEDS,
  setGlobalCurrentPhase,
  setStepAccessible,
  setStepData,
} from "../../../store/reducer";
import { StepProps } from "../../interface";
import { OContainer } from "../../Styled/CustomGrid";
import CustomTransition from "../../Styled/CustomTransitions";
import { NeedPhase1ReduxStepData } from "./phases/phase1/constants";
import NeedPhase1 from "./phases/phase1/need_phase_1";
import { NeedPhase2ReduxStepData } from "./phases/phase2/constants";
import { NeedsContainer } from "./styled";

const Needs: React.FC<StepProps> = ({ onContinue }: any) => {
  const currentStepName = STEP_NEEDS;
  const stepData = useSelector(
    (state: RootState) => state.step.steps[currentStepName].data
  );

  const dispatch = useDispatch();

  const [dataPhase1, setDataPhase1] = useState<NeedPhase1ReduxStepData>(
    stepData?.phase1 || { budget: null }
  );
  const [dataPhase2] = useState<NeedPhase2ReduxStepData>(
    stepData?.phase2 || null
  );

  const [phase, setPhase] = useState(stepData.phase || 1);

  useEffect(() => {
    // Handle changes on this phase to refresh simulation if needed
    // store.subscribe(simulationHandleChange);
    dispatch(setStepAccessible({ step: currentStepName, accessible: true }));
  }, []);

  useEffect(() => {
    dispatch(setGlobalCurrentPhase(phase));
  }, [phase]);

  const handleContinue = (
    dataKey: string,
    dataValue: any,
    nextPhase: number
  ) => {
    dispatch(
      setStepData({
        step: currentStepName,
        data: { [dataKey]: dataValue, phase: nextPhase },
      })
    );
    if (nextPhase === 0) {
      onContinue();
      return;
    }

    setPhase(nextPhase);
  };

  return (
    <NeedsContainer>
      <OContainer fluid>
        <CustomTransition mode="fade" key={phase}>
          {phase === 1 ? (
            <NeedPhase1
              data={dataPhase1}
              setData={setDataPhase1}
              onContinue={() => handleContinue("phase1", dataPhase1, phase - 1)}
            />
          ) : null}

          {/* {phase === 2 ? (
            <NeedPhase2
              onContinue={onContinue}
              onReturn={() => handleContinue("phase2", dataPhase2, phase - 1)}
            />
          ) : null} */}
        </CustomTransition>
      </OContainer>
    </NeedsContainer>
  );
};

export default Needs;
