import { ComponentStatus } from "../../../components/shared/status/component-status";
import { configurateurService } from "../../../data/configurateur/services/configurateur-service";
import { ConfigurateurBatteryEntity } from "../../../data/configurateur/services/entities/batteries/configurateur-battery.entity";
import { getConfigurationPresetItem } from "../../../utils/preset/configuration-preset.utils";
import { ConfigurateurBatteriesState } from "./configurateur-batteries-state";

const initialState: ConfigurateurBatteriesState = {
  batteriesData: {
    status: ComponentStatus.idle,
  },
};

enum ActionType {
  loadBatteries = "loadBatteries",
  selectBattery = "selectBattery",
}

export const configurationBatteriesReducer = (
  state = initialState,
  action
): ConfigurateurBatteriesState => {
  const { data, status, error, selected } = action;

  switch (action.type) {
    case ActionType.loadBatteries:
      return {
        ...state,
        batteriesData: {
          data: data || state?.batteriesData?.data,
          status: status,
          error: error,
          selected: selected || state?.batteriesData?.selected,
        },
      };
    case ActionType.selectBattery:
      return {
        ...state,
        batteriesData: {
          ...state.batteriesData,
          data: data || state?.batteriesData?.data,
          selected: selected,
        },
      };
    default:
      return { ...state };
  }
};

export const configurateurBatteriesLoad =
  (onduleurId: string, onduleurAttribute: string, phaseNumber: number) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ActionType.loadBatteries,
        status: ComponentStatus.loading,
      });
      const batteries = await configurateurService.getBatteries(
        onduleurId,
        onduleurAttribute,
        phaseNumber
      );
      const preset =
        getConfigurationPresetItem<ConfigurateurBatteryEntity>(batteries);

      dispatch({
        type: ActionType.loadBatteries,
        status: ComponentStatus.loaded,
        data: batteries,
      });

      dispatch({
        type: ActionType.selectBattery,
        selected: preset.selected
          ? {
              ...preset.selected,
              configurator_quantity: preset.quantity,
            }
          : undefined,
      });
    } catch (_) {
      dispatch({
        type: ActionType.loadBatteries,
        status: ComponentStatus.error,
      });
    }
  };

export const configurateurBatterySelect =
  (batteryIdAttribute?: string) => (dispatch, getState) => {
    const batteriesState = getState()
      .configurateurBatteries as ConfigurateurBatteriesState;
    const selectedBattery = batteriesState?.batteriesData?.data?.find(
      (battery) =>
        battery?.legacy_presta_product_attribute_id === batteryIdAttribute
    );

    dispatch({
      type: ActionType.selectBattery,
      selected: selectedBattery,
    });
  };
