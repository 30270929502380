import React, { useRef } from "react";
import { CalepinageToitureProps } from "./constant";
import MapMapboxComponent from "./mapbox";

const CalepinageToiture: React.FC<CalepinageToitureProps> = ({
  position = [51.505, -0.09],
  mapMode = true,
  canvasMode = true,
  orientation = "portrait",
  mainButtonClicked = 0,
  deleteAll = false,
  setDeleteAll = () => {},
}) => {
    const mapContainer = useRef(null);
    return (
        <div ref={mapContainer} style={{ width: '100%', height: '100%' }}> 
          <MapMapboxComponent 
            orientation={orientation}
            deleteAll={deleteAll}
            setDeleteAll={setDeleteAll}
        ></MapMapboxComponent> </div>
    );
};

export default CalepinageToiture;
