import styled from "styled-components";
import { StepWrapperContainer } from "../../Styled/StepWrapperContainer";

export const RecommandationContainer = styled(StepWrapperContainer)`
.rotateSUD {
    transform: rotate(0deg) !important;
    transition: transform 1s ease-in-out;
  }

  .rotateSUD_EST {
    transform: rotate(-45deg) !important;
    transition: transform 1s ease-in-out;
  }

  .rotateEST {
    transform: rotate(-90deg) !important;
    transition: transform 1s ease-in-out;
  }

  .rotateNORD_EST {
    transform: rotate(-135deg) !important;
    transition: transform 1s ease-in-out;
  }

  .rotateNORD {
    transform: rotate(180deg) !important;
    transition: transform 1s ease-in-out;
  }

  .rotateNORD_OUEST {
    transform: rotate(135deg) !important;
    transition: transform 1s ease-in-out;
  }

  .rotateOUEST {
    transform: rotate(90deg) !important;
    transition: transform 1s ease-in-out;
  }

  .rotateSUD_OUEST {
    transform: rotate(45deg) !important;
    transition: transform 1s ease-in-out;
  }
`;
