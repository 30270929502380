import { useTranslation } from "react-i18next";
import { VirtualCartCategoryItem } from "../../../../store/configurateur/virtual-cart/use-virtual-cart-items-hook";
import { OBadge } from "../../../Styled/badges/OBadge";
import { theme } from "../../../Styled/GlobalStyle";
import { OBody4, OBody8 } from "../../../Styled/Texts";
import { VirtualCartItemContainer } from "./styled";

export const VirtualCartItem = (props: {
  item: VirtualCartCategoryItem;
  itemColor?: {
    category?: string;
    link?: string;
    price?: string;
  };
}) => {
  const { t } = useTranslation("global");
  const item = props.item;
  const discount = props.item.details?.discount;
  const categoryColor =
    props.itemColor?.category || theme.colors.clay.clayPower200;
  const linkColor = props.itemColor?.link || theme.colors.clay.clayPower200;

  return (
    <VirtualCartItemContainer>
      <img src={item?.img} alt={item?.label} />
      <div className="virtualCartItemLeading">
        <div className="virtualCartItemLabel">
          {discount > 0 && (
            <OBadge>{t("global:discount", { discount: discount })}</OBadge>
          )}
          <OBody4 color={categoryColor}>{item?.label}</OBody4>
        </div>
        {item?.link && (
          <a
            href={item?.link}
            rel="noreferrer"
            target="_blank"
            color={linkColor}
          >
            {item?.linkLabel}
          </a>
        )}
      </div>
      <div className="virtualCartItemTrailing">
        {item?.trailingLabel ||
          (true && (
            <OBody8 color={theme.colors.clay.clayPower600}>
              {item.trailingLabel}
            </OBody8>
          ))}
      </div>
    </VirtualCartItemContainer>
  );
};
