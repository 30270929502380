import {
  STEP_RECOMMANDATION,
  setStepData,
} from "../../../../../../../store/reducer";
import {
  InputSelect,
  SelectOption,
} from "../../../../../../shared/inputs/selects/input-select";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FixationRepository } from "../../../../../../../data/fixations/repositories/fixation-repository";
import { FixationTypeDto } from "../../../../../../../data/fixations/dtos/fixation-type-dto";
import { ImageUtils } from "../../../../../../../utils/images/image-utils";
import { useEffect, useState } from "react";
import { RootState } from "../../../../../../../store";

const Container = styled.div`
  width: 100%;
`;

export const FixationSelect = (props: { emplacementId: string }) => {
  const tRecommandation = useTranslation([
    STEP_RECOMMANDATION + "phase1",
    "global",
  ]).t;
  const dispatch = useDispatch();

  const fixationRecommandation = useSelector(
    (state: RootState) =>
      state.step.steps[STEP_RECOMMANDATION].data?.phase1?.fixation
  );
  const [fixations, setFixations] = useState([]);
  const [fixationType, setFixationType] = useState<FixationTypeDto>();

  useEffect(() => {
    const fixations = new FixationRepository().getFixationsByEmplacement(
      props.emplacementId
    );
    setFixations(fixations);
    const fixation =
      fixations.find(
        (fixation) => fixation.id === fixationRecommandation?.id
      ) || fixations[0];
    setFixationType(fixation);
  }, [dispatch, props.emplacementId]);

  useEffect(() => {
    if (fixationType) {
      dispatch(
        setStepData({
          step: STEP_RECOMMANDATION,
          data: {
            phase1: {
              fixation: fixationType,
            },
          },
        })
      );
    }
  }, [dispatch, fixationType]);

  const optionsFixations: SelectOption<FixationTypeDto>[] = fixations.map(
    (fixationType) => {
      return {
        value: fixationType,
        label: fixationType.name,
        icon: ImageUtils.buildFixationImage(fixationType.img),
      };
    }
  );

  return (
    <Container>
      <InputSelect
        className="configurateur-input-installation-fixation"
        label={tRecommandation(`fixationLabel`)}
        options={optionsFixations}
        selection={fixationType}
        compare={(fixation, selection) => fixation.id === selection.id}
        onSelect={(selection) => {
          setFixationType(selection.value);
        }}
      />
    </Container>
  );
};
