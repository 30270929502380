import { useTranslation } from "react-i18next";
import { OBody5 } from "../../../../../Styled/Texts";
import { Modal } from "../../../../../Styled/modals";
import { STEP_DESIGN } from "../../../../../../store/reducer";
import {
  ConfigurateurPopupContext,
  ConfigurateurPopups,
} from "../configurateur-modals-context";
import { useContext } from "react";

export const InstallHelpModal = (props: { show: boolean }) => {
  const { t } = useTranslation([STEP_DESIGN, "global"]);
  const { setPopup } = useContext(ConfigurateurPopupContext);
  return (
    <Modal
      show={props.show}
      title={t("phase_2.sections.installation_help.modal.title")}
      onClose={() => setPopup(ConfigurateurPopups.none)}
    >
      <div className="modal-content-list">
        <OBody5>
          {t("phase_2.sections.installation_help.modal.section1.title")}
        </OBody5>
        <ul>
          <li>
            <OBody5>
              {t("phase_2.sections.installation_help.modal.section1.item1")}
            </OBody5>
          </li>
          <li>
            <OBody5>
              {t("phase_2.sections.installation_help.modal.section1.item2")}
            </OBody5>
          </li>
          <li>
            <OBody5>
              {t("phase_2.sections.installation_help.modal.section1.item3")}
            </OBody5>
          </li>
          <li>
            <OBody5>
              {t("phase_2.sections.installation_help.modal.section1.item4")}
            </OBody5>
          </li>
          <li>
            <OBody5>
              {t("phase_2.sections.installation_help.modal.section1.item5")}
            </OBody5>
          </li>
          <li>
            <OBody5>
              {t("phase_2.sections.installation_help.modal.section1.item6")}
            </OBody5>
          </li>
        </ul>
      </div>
    </Modal>
  );
};
