import {
  LayersKeys,
  Position,
  localizationConfig,
} from "../../Steps/Localization/constants";

export enum Orientation2D {
  PORTRAIT = 0,
  LANDSCAPE = 1,
}

export interface PanelsGridContainerProps {
  position?: Position;
  mapMode?: boolean;
  canvasMode?: boolean;
}

export interface IPanelsGrid {
  orientation?: Orientation2D;
  rows?: number;
  cols?: number;
  array?: number[][];
  count?: number;
}

export enum PanelsGridReadOnlyState {
  SELECTED = 1,
  UNSELECTED = 0,
}

export const panelsGridConfig = {
  layersControl: {
    layers: [
      {
        key: LayersKeys.LAYER_SATELLITE,
        name: "layer_satellite",
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      },
    ],
  },
  layerPosition: "topright",
  layerSelected: localizationConfig.layerSelected,
  defaultValues: {
    orientation: Orientation2D.PORTRAIT,
    rows: -1,
    cols: -1,
    array: [],
    count: -1,
  },
};

// Call when no grid is provided, so we create a default grid
export const setGridByPanelNumber = (
  inputPanelNumber: number,
  actualGrid: IPanelsGrid
): IPanelsGrid => {
  let newGrid = { ...actualGrid };
  // TODO : Fill with API data
  const size = 7;

  // Initialize a new grid array with all zeroes
  const gridEmpty = Array.from({ length: size }, () => Array(size).fill(0));

  // Fill the grid with 1 until we reach the inputPanelNumber
  for (let i = 0; i < inputPanelNumber; i++) {
    const row = Math.floor(i / 10); // Calculate the row index
    const col = i % 10; // Calculate the column index
    gridEmpty[row][col] = 1; // Set the value at the corresponding position
  }

  // Update the newGrid object with the modified grid
  newGrid.array = gridEmpty;
  newGrid.count = newGrid.array.flat().filter((x) => x === 1).length;

  // Count rows and cols
  newGrid.rows = gridEmpty.length;
  newGrid.cols = gridEmpty[0].length;

  // Return the modified grid
  return newGrid;
};
