import styled from "styled-components";

export const StepWrapperContainer = styled.div`
  min-height: ${(props) => props.theme.sizes.content};
  max-height: ${(props) => props.theme.sizes.content};
  height: 100%;
  
  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    max-height: ${(props) => props.theme.sizes.mobileContent};
    min-height: ${(props) => props.theme.sizes.mobileContent};
    height: 100%;
  }

`;
