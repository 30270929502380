import React, { useEffect } from "react";
import { OContainer } from "../../Styled/CustomGrid";
import { STEP_INSTALLATION, setGlobalCurrentPhase, setStepAccessible } from "../../../store/reducer";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useDispatch } from "react-redux";
import { StepProps } from "../../interface";
import InstallationPhase1 from "./phases/installation_phase_1";
import { InstallationContainer } from "./styled";

const Installation: React.FC<StepProps> = ({ onContinue }: any) => {
  const currentStepName = STEP_INSTALLATION;
  const dispatch = useDispatch();
  const stepData = useSelector(
    (state: RootState) => state.step.steps[currentStepName].data
  );

  useEffect(() => {
      dispatch(setStepAccessible({ step: currentStepName, accessible: true }));
  
  }, []);

  useEffect(() => {
    dispatch(setGlobalCurrentPhase(stepData.phase));
  }, [stepData.phase]);

  return (
    <InstallationContainer>
      <OContainer fluid>
        {stepData.phase === 1 && <InstallationPhase1 onContinue={onContinue} />}
      </OContainer>
    </InstallationContainer>
  );
};

export default Installation;
