export const useVirtualCartKitProfitability = (kitPrice, economy, newVersion) => {
  if(newVersion) {
    return calculProfitability(kitPrice, economy);
  } else {
    if (economy) {
      return convertMonthlySavingsToYears(kitPrice, economy);
    }
  }
};

const convertMonthlySavingsToYears = (kitPrice, monthlySavings) => {
  let cumulatedSavings = 0;
  let month = 0;
  while (cumulatedSavings < kitPrice) {
    cumulatedSavings += monthlySavings;
    month += 1;
  }
  // Convert months to years and round to the next year
  return Math.round(month / 12);
};

const calculProfitability = (kitPrice, economy) => {
  const annualProfitability = economy * 12;
  const rentability =  annualProfitability / kitPrice * 100;
  return Math.round(rentability);
};  
