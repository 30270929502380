export function transformNumberWithLocale(
  number: number,
  options?: Intl.NumberFormatOptions
): string {
  const language =
    window.navigator.languages && window.navigator.languages.length > 0
      ? window.navigator.languages[0]
      : window.navigator.language || "fr-FR";
  return new Intl.NumberFormat(language, {
    ...options,
  }).format(number);
}

export const formatNumber = (
  number: number,
  round: number = 2,
  displaySign: boolean = true,
  options?: Intl.NumberFormatOptions,
  arrondir: boolean = false,
  hideZero: boolean = false
) => {
  let fractionDigits = Number.isInteger(number) ? 0 : round;
  if (arrondir) {
    number = Math.ceil(number);
  }

  let result = transformNumberWithLocale(number, {
    ...(displaySign
      ? {
          style: "currency",
          currency: "EUR",
        }
      : {}),
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
    ...options,
  });

  if (hideZero) {
    const parts = new Intl.NumberFormat('en', { maximumFractionDigits: 20 }).formatToParts(number);
    const fractionPart = parts.find(part => part.type === 'fraction');
    if (fractionPart && fractionPart.value.split('').every(digit => digit === '0')) {
      fractionDigits = 0;
      result = transformNumberWithLocale(number, {
        ...(displaySign
          ? {
              style: "currency",
              currency: "EUR",
            }
          : {}),
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits,
        ...options,
      });
    }
  }

  return result;
};
