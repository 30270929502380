import styled from "styled-components";
import { theme } from "../../Styled/GlobalStyle";

const color = theme.colors.primary.bluePower700;

export const HorizontalDimensionElements = styled.div`

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;

    & .arrow{
        position: relative;
        width: 100%;
        height: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    & .body{
        width: fit-content;
        height: 20px;
        padding: 0 5px;
        margin-top: -10px;
        background: ${color};
        text-align: center;
        color: white;
        font-size: 14px;
        font-weight: 100;
        font-family: "Poppins", sans-serif;
    }

    & .line{
        width: 100%;
        height: 2px;
        background: ${color};
    }

    & .arrow .back,
    .arrow .forward {
        color: ${color};
        font-size: 18px;
        position: absolute;
    }

    & .arrow .forward {
        transform: translate(40%, 0%);
        right: 0px;
    }

    & .arrow .back {
        left: 0px;
        transform: translate(-37%, 0%);
    }

`;