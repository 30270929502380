import { useEffect } from "react";
import {
  InstallationType,
  isRoofType,
} from "../../components/Steps/Installation/constants";
import store, { RootState } from "../../store";

import { useSelector } from "react-redux";
import {
  configurateurFixationsHorsToitureLoad,
  configurateurFixationsToitureLoad,
} from "../../store/configurateur/fixations/configurateur-fixations-reducer";

export const useEffectPanneauFixations = (
  installationType: InstallationType,
  matrice: number[][],
  emplacementId: number,
  disposition: number,
  panelId: string
) => {
  // console.log(matrice);
  // console.log(panelId);
  // Case toiture
  useEffect(() => {
    if (emplacementId && panelId && isRoofType(installationType)) {
      store.dispatch(
        configurateurFixationsToitureLoad(
          matrice,
          disposition,
          emplacementId,
          panelId
        )
      );
    }
  }, [disposition, emplacementId, installationType, matrice, panelId]);

  // Case hors toiture
  const fixationIdAttribute = useSelector(
    (state: RootState) => state.calepinageReducer?.fixationSelected?.id_product
  );
  useEffect(() => {
    if (!isRoofType(installationType) && fixationIdAttribute) {
      store.dispatch(
        configurateurFixationsHorsToitureLoad(fixationIdAttribute)
      );
    }
  }, [fixationIdAttribute, installationType]);
};

export const useFixationIds = (): {
  id_legacy?: string;
  id_attribute_legacy?: string;
} => {
  const fixationsState = useSelector(
    (state: RootState) => state.configurateurFixations?.fixationsData
  );
  if (fixationsState?.panneauFixation?.data?.length > 0) {
    return fixationsState?.panneauFixation?.data?.map((fixation) => {
      return {
        id_legacy: fixation?.legacy_presta_product_id,
        id_attribute_legacy: fixation?.legacy_presta_product_attribute_id,
      };
    })[0];
  }
  return {};
};
