import { ILocation } from "../../data/location/dtos/location.dto";
import { ZOOM_DEFAULT } from "../../utils/constants";

export interface ILocalizationState {
loading: boolean;
text: string;
location: ILocation | null,
suggestions: ILocation[],
error?: string,
needToCenterAndZoom: boolean,
zoom: number;
}

export const initialLocalizationStateState : ILocalizationState = {
    text: '',
    location: null,
    suggestions: [],
    error: null,
    loading: false,
    needToCenterAndZoom: false,
    zoom: ZOOM_DEFAULT
}
