import { Icon } from "leaflet";
import { theme } from "../../Styled/GlobalStyle";
import { StepData } from "../../../store/reducer";
import { imgsPath, svgsPath } from "../../../utils/constants";

export type Position = [number, number];

export interface LocalizationReduxStepData extends StepData {
  position?: Position;
  search?: string;
  zoom?: number;
}

export interface GeocoderInputProps {
  texts: Record<string, string>;
  inputPosition?: Position;
  className?: string;
  searchInput: string;
  onChangePosition: (newPosition?: Position) => void;
  onSearchBoxChange: (searchBoxText: string) => void;
  onSuggestionsUpdate: (suggestions: any[]) => void;
  onSuggestionHover: (suggestion: any) => void;
}

export enum LayersKeys {
  LAYER_SATELLITE = "satellite",
  LAYER_STANDARD = "standard",
}

export const localizationConfig = {
  defaultValues: {
    position: undefined,
    search: "",
    zoom: 5,
  },
  mapConfig: {
    position: [46.227638, 2.213749],
    locationZoom: 18,
    zoomDuration: 1,
    satelliteStyle: "mapbox://styles/mapbox/satellite-v9",
    planStyle: "mapbox://styles/mapbox/streets-v12",
  },
  gridConfig: {
    left: [12, 5, 6],
    right: [12, 7, 6],
  },
  mapHeight: "100%",
  geocoderConfig: {
    gpsIcon: svgsPath + "geocoder_gps_icon.svg",
    spinnerIcon: svgsPath + "geocoder_spinner_icon.svg",
    apiConfig: {
      apiUrl: "https://api.locationiq.com/v1",
      apiKey: "pk.8a35fe281bf9afee31b1b821d921bfff",
      limit: 10,
      country: "fr,be",
      language: "fr",
    },
    containerVariants: {
      hidden: { opacity: 0, scale: 0.9 },
      visible: { opacity: 1, scale: 1 },
    },
  },
  circleOptions: {
    color: theme.colors.primary.bluePower700,
    fillColor: theme.colors.primary.bluePower700,
    fillOpacity: 0.2,
    circleRadius: 50,
  },
  displayCirle: true,
  displayLayersControl: true,
  layerSelected: LayersKeys.LAYER_SATELLITE,
  layerPosition: "topright",
  zoomControlPosition: "topleft",
  ZOOM_THRESHOLD: 15,
  MIN_ZOOM: 5,
  oscpIcon: new Icon({
    iconUrl: imgsPath + "map/marker.svg",
    shadowUrl: undefined,
    iconSize: [62, 82],
    shadowSize: [62, 82],
    iconAnchor: [31, 82],
    shadowAnchor: [26, 76],
    popupAnchor: [0, -82],
  }),
  othersMarkerIcon: new Icon({
    iconUrl: imgsPath + "map/marker_others.png",
    shadowUrl: imgsPath + "map/marker-shadow_others.png",
    iconSize: [25, 25],
    shadowSize: [20, 20],
    iconAnchor: [10, 10],
    shadowAnchor: [10, 10],
    popupAnchor: [10, -10],
  }),
  othersHoveredMarkerIcon: new Icon({
    iconUrl: imgsPath + "map/marker_others_hover.png",
    shadowUrl: imgsPath + "map/marker-shadow_others_hover.png",
    iconSize: [25, 25],
    shadowSize: [20, 20],
    iconAnchor: [15, 10],
    shadowAnchor: [10, 10],
    popupAnchor: [10, -10],
  }),
  layersControl: {
    layers: [
      {
        key: LayersKeys.LAYER_SATELLITE,
        name: "layer_satellite",
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      },
      {
        key: LayersKeys.LAYER_STANDARD,
        name: "layer_standard",
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      },
    ],
  },
};
