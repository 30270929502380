import React from "react";
import { useTranslation } from "react-i18next";
import { Azimut, AzimutImagesLinks } from "./constants";
import { STEP_RECOMMANDATION } from "../../../../../store/reducer";
import {
  OFormGroup,
  OFormLabel,
  OSelect,
  OSelectOptionIcon,
} from "../../../../Styled/Forms";

const AzimutSelect: React.FC<{
  azimut: Azimut;
  setAzimut: Function;
  supportMode?: boolean;
}> = ({ azimut, setAzimut, supportMode }) => {
  const { t } = useTranslation([STEP_RECOMMANDATION + "phase1", "global"]);

  const AzimutArray = Object.keys(Azimut)
    .filter((value) => isNaN(Number(value)))
    .map((key) => ({
      value: Azimut[key as keyof typeof Azimut],
      label: t(`azimut.${Azimut[key as keyof typeof Azimut]}`),
      icon: AzimutImagesLinks[Azimut[key as keyof typeof Azimut]],
    }));

  return (
    <OFormGroup>
      <OSelectOptionIcon
        src={AzimutImagesLinks[Azimut[azimut]]}
        alt="icon"
        className={"rotate" + azimut}
      />
      <OFormLabel>
        {supportMode ? t("azimutLabelSupp") : t("azimutLabel")}
      </OFormLabel>
      <OSelect
        styles={{
          indicatorsContainer: (provided) => ({
            ...provided,
            "& svg": {
              fill: "#ABB0DB",
            },
          }),
        }}
        options={AzimutArray}
        value={AzimutArray.find(
          (option: any) => option.value === Azimut[azimut].toString()
        )}
        onChange={(selectedOption: any) =>
          setAzimut(Azimut[selectedOption.value])
        }
        className="react-select-container with-icon"
        classNamePrefix="react-select"
      />
    </OFormGroup>
  );
};

export default AzimutSelect;
