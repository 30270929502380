import { useTranslation } from "react-i18next";
import { OBody5 } from "../../../../../Styled/Texts";
import { Modal } from "../../../../../Styled/modals";
import { STEP_DESIGN } from "../../../../../../store/reducer";
import {
  ConfigurateurPopupContext,
  ConfigurateurPopups,
} from "../configurateur-modals-context";
import { useContext } from "react";
import { theme } from "../../../../../Styled/GlobalStyle";

export const AlimentationModal = (props: { show: boolean }) => {
  const { t } = useTranslation([STEP_DESIGN, "global"]);
  const { setPopup } = useContext(ConfigurateurPopupContext);
  return (
    <Modal
      show={props.show}
      title={t("phase_2.sections.alimentation_type.modal.title")}
      onClose={() => setPopup(ConfigurateurPopups.none)}
    >
      <OBody5>{t("phase_2.sections.alimentation_type.modal.item1")}</OBody5>
      <br />
      <OBody5 color={theme.colors.primary.bluePower700}>
        {t("phase_2.sections.alimentation_type.modal.item2")}
      </OBody5>
      <br />
      <OBody5>{t("phase_2.sections.alimentation_type.modal.item3")}</OBody5>
    </Modal>
  );
};
