import React from "react";
import { useTranslation } from "react-i18next";
import { Pente, PenteImagesLinks } from "./constants";
import { STEP_RECOMMANDATION } from "../../../../../store/reducer";
import {
  OFormGroup,
  OFormLabel,
  OSelect,
  OSelectOption,
  OSelectOptionIcon,
} from "../../../../Styled/Forms";

const PenteSelect: React.FC<{
  pente: Pente;
  setPente: Function;
}> = ({ pente, setPente }) => {
  const { t } = useTranslation([STEP_RECOMMANDATION + "phase1", "global"]);

  const PenteArray = Object.values(Pente)
    .filter((value) => isNaN(Number(value)))
    .map((value) => ({
      value,
      label: t(`pente.${value}`),
      icon: PenteImagesLinks[value],
    }));

  const Option = (props) => {
    return (
      <OSelectOption {...props}>
        <img src={props.data.icon} alt="icon" height="20px" />
        {props.data.label}
      </OSelectOption>
    );
  };

  return (
    <OFormGroup>
      <OSelectOptionIcon src={PenteImagesLinks[Pente[pente]]} alt="icon" />
      <OFormLabel>{t("penteLabel")}</OFormLabel>
      <OSelect
        styles={{
          indicatorsContainer: (provided) => ({
            ...provided,
            "& svg": {
              fill: "#ABB0DB",
            },
          }),
        }}
        options={PenteArray}
        value={PenteArray.find((option) => option.value === Pente[pente])}
        onChange={(selectedOption: any) =>
          setPente(Pente[selectedOption.value])
        }
        components={{ Option }}
        className="react-select-container with-icon"
        classNamePrefix="react-select"
      />
    </OFormGroup>
  );
};

export default PenteSelect;
