import { imgsPath } from "../../../../../../utils/constants";

export enum AlimType {
  MONOPHASE = "monophase",
  THREE_PHASE = "three_phase",
}

export const getPhaseNumberFromAlimType = (type: AlimType) =>
  type === AlimType.THREE_PHASE ? 3 : 1;

export enum PanelType {
  CLASSIC = "classic",
  PREMIUM = "premium",
  ECO = "eco",
  RED = "red",
}

export const PanelTypeImagesLinks = {
  CLASSIC : imgsPath + "panels/classic.png",
  PREMIUM : imgsPath + "panels/premium.png",
  ECO : imgsPath + "panels/eco.png",
  RED : imgsPath + "panels/red.png",
}

export enum AdminHelpType {
  ALL = "all",
  ASK = "ask",
}

export enum InstallationHelpType {
  ELECTRICAL_CONNECTION_HELP = "electrical_connection_help",
}

export interface SectionData {
  display: boolean;
}

export interface SectionCalepinageHorsToitureTypeData extends SectionData {
  
}

export interface SectionCalepinageToitureMobileTypeData extends SectionData {
  
}

export interface SectionAlimentationTypeData extends SectionData {
  alimType?: AlimType;
}

export interface SectionSolarKitData extends SectionData {
  panelType?: PanelType;
  inverters?: any[];
  selectedInverter?: string;
}

export interface SectionSupervisingData extends SectionData {
  included?: boolean;
}

export interface SectionWiringData extends SectionData {
  panel_box?: SectionWiringDataLink;
  box_grid?: SectionWiringDataLink;
}

export interface SectionConfirmData extends SectionData {
  checked?: boolean;
}

export interface SectionWiringDataLink {
  value: number[];
  sup?: number[]; // -1 = 'inclus
  selectedIndex?: number;
}

export interface SectionLightningArresterData extends SectionData {
  with?: boolean;
  withSup?: number;
}

export interface SectionWarrantyData extends SectionData {
  with?: boolean;
  withSup?: number;
}

export interface SectionBatteryData extends SectionData {
  with?: boolean;
  withSup?: number;
}

export interface SectionAdministrationHelpData extends SectionData {
  selecteds?: AdminHelpType[];
  details?: string[];
}

export interface SectionInstallationHelpData extends SectionData {
  selecteds?: InstallationHelpType[];
  details?: string[];
}
