import { ConfigurateurProductVariantDto } from "../../api/dtos/configurateur-product-variant.dto";

export class ConfigurateurProductEntity {
  id: string;
  title: string;
  price: number;
  price_discounted: number;
  // total_price: number;
  // total_price_discounted: number;
  marketing_fields: ConfigurateurProductMetafieldsEntity;
  class_fields: ConfigurateurProductMetafieldsEntity;
  parent: ConfigurateurProductParentEntity;
  marketing_name?: string;
  dimension: ConfigurateurProductVariantDimensionEntity;
  configurator_quantity?: number;
  legacy_presta_product_id: string;
  legacy_presta_product_attribute_id?: string;

  is_oscaro_choice: boolean;
  tag?: string;
  configurator_name?: string;
  price_delta?: number;
  guarantee?: number;
  guranteeYear?: number;
  discount: number;
  default?: boolean;

  static fromDto(
    dto: ConfigurateurProductVariantDto
  ): ConfigurateurProductEntity {
    const cfgQty = dto.qty || 1;

    const marketingName = dto.marketing_fields.fields.find(
      (f) => f.key === "name"
    )?.value;
    const oscaroChoice =
      dto.configurator_fields.fields.find((f) => f.key === "op_choice")
        ?.value === "true";
    const tag = dto.configurator_fields.fields.find(
      (f) => f.key === "tag"
    )?.value;
    const cfgName = dto.configurator_fields.fields.find(
      (f) => f.key === "name"
    )?.value;

    const discount = parseFloat(
      dto.configurator_fields.fields.find((f) => f.key === "discount")?.value ||
        "0"
    );

    const discountPercent = discount > 0 ? 1 - discount / 100 : 1;
    const priceDiscounted = dto.price * discountPercent;

    return {
      ...dto,
      price_discounted: priceDiscounted,
      parent: {
        ...dto.parent,
        store_url:
          dto.parent?.store_url && `${dto.parent?.store_url}?variant=${dto.id}`,
      },
      configurator_quantity: cfgQty,
      marketing_name: marketingName,
      is_oscaro_choice: oscaroChoice,
      tag: tag,
      configurator_name: cfgName,
      discount: discount,
      guranteeYear: dto.guarantee ? Math.floor(dto.guarantee / 12) : undefined,
    };
  }
}

export interface ConfigurateurProductMetafieldsEntity {
  fields: ConfigurateurProductMetafieldEntity[];
}

export interface ConfigurateurProductMetafieldEntity {
  key: string;
  value: string;
}

export interface ConfigurateurProductParentEntity {
  id: string;
  title: string;
  category?: string;
  default_image: ConfigurateurProductImageEntity;
  store_url: string;
  vendor: string;
}

export interface ConfigurateurProductImageEntity {
  url: string;
}

export interface ConfigurateurProductVariantDimensionEntity {
  height?: number;
  width?: number;
  length?: number;
}
