import { AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStep } from "../store/reducer";
import { useTrackingPageView } from "../utils/tracking/tracking-hook";
import Home from "./Home";
import Design from "./Steps/Design";
import Installation from "./Steps/Installation";
import Localization from "./Steps/Localization";
import Needs from "./Steps/Needs";
import { BuilderRouterContainer } from "./Styled/BuilderRouterContainer";
import CustomTransition from "./Styled/CustomTransitions";
import { IStepComponents } from "./interface";
import Header from "./shared/Headers";
import Recommandation from "./Steps/Recommandation";
import { skipToConfiguration } from "../utils/navigation/navigation-utils";
import { GLOBAL_SHOW_HEADER } from "./shared/constant";

/**
 * `BuilderRouter` is responsible for managing and rendering a series of steps in a multi-step process.
 * Each step is represented by a separate React component, and the flow between steps is controlled by Redux.
 *
 * The state of the current step is maintained in a Redux store. When the user completes a step, the `handleContinue`
 * function is called, updating the `currentStep` in the Redux store to the next step, triggering a re-render of
 * `BuilderRouter` with the next step.
 */
function BuilderRouter() {
  // Get current step from redux
  let currentStep = useSelector((state: any) => state.step.currentStep);
  const dispatch = useDispatch();

  // Handle continue button to change current step
  const handleContinue = (nextStep: string) => {
    dispatch(setCurrentStep(nextStep));
  };
  useTrackingPageView();
  // Object with all steps components
  const stepComponents: IStepComponents = {
    home: <Home onContinue={() => handleContinue("localization")} />,
    needs: <Needs onContinue={() => handleContinue("installation")} />,
    installation: (
      <Installation onContinue={() => handleContinue("localization")} />
    ),
    localization: (
      <Localization onContinue={() => handleContinue("recommandation")} />
    ),
    recommandation: (
      <Recommandation onContinue={() => handleContinue("design")} />
    ),
    design: <Design onContinue={() => handleContinue("end")} />,
  };

  const renderCurrentStep = () => (
    <AnimatePresence mode="wait">
      <CustomTransition mode="fade" key={currentStep}>
        {stepComponents[currentStep]}
      </CustomTransition>
    </AnimatePresence>
  );

  return (
    <BuilderRouterContainer>
      {GLOBAL_SHOW_HEADER ? (
      <Header currentStep={currentStep} handleContinue={handleContinue} />
    ) : null}
      <div></div>
      <div className="h-100 overflow-hidden">{renderCurrentStep()}</div>
    </BuilderRouterContainer>
  );
}

export default BuilderRouter;
