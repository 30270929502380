import { GLOBAL_EDF_PRICE, GLOBAL_NBRE_HEURE_PRODUCTION, GLOBAL_PERCENT_AUTOCONSO, GLOBAL_PRICE_SELL, GLOBAL_USE_NEW_FORMULA_ECONOMY } from "../../../components/shared/constant";
import { useSimulationFormula } from "../../../hooks/simulation/use-simulation-power";

export const useVirtualCartEconomy = (kitPower: number): {min: number, max:number} => {
  let min, max;
  let nombreHeureRevente = kitPower * GLOBAL_PERCENT_AUTOCONSO * GLOBAL_NBRE_HEURE_PRODUCTION;
  let nombreHeureAutoconso = kitPower * GLOBAL_NBRE_HEURE_PRODUCTION - nombreHeureRevente;
  let valueRevente = nombreHeureRevente * GLOBAL_PRICE_SELL;
  let valueAutoconso = nombreHeureAutoconso * GLOBAL_EDF_PRICE;
  max = valueRevente + valueAutoconso;
  max = max / 12;
  min = max
  return {
    min,
    max
  }
  if(kitPower <= 1) {
    min = 20;
  }
  if(kitPower > 1 && kitPower <= 2) {
    min = 35;
  }
  if(kitPower > 2 && kitPower <= 3) {
    min = 50;
  }
  if(kitPower > 3 && kitPower <= 4) {
    min = 65;
  }
  if(kitPower > 4 && kitPower <= 5) {
    min = 80;
  }
  if(kitPower > 5 && kitPower <= 6) {
    min = 95;
  }
  if(kitPower > 6 && kitPower <= 7) {
    min = 110;
  }
  if(kitPower > 7 && kitPower <= 8) {
    min = 125;
  }
  if(kitPower > 8 && kitPower <= 9) {
    min = 140;
  }
  if(kitPower > 9) {
    min = 155;
  }
  return {
    min,
    max: min + 15
  }
  // if(GLOBAL_USE_NEW_FORMULA_ECONOMY) {
  //   return 15
  // } else {
  //   // const economyIndex = 15;
  //   // const revenuIndex = 8;
  //   const smartEconomyIndex = 0;
  //   const simulationPower = useSimulationFormula();
  
  //   if (simulationPower) {
  //     // const annualEconomy = simulationPower[economyIndex];
  //     // const annualRevenue = simulationPower[revenuIndex];
  //     // return (annualEconomy + annualRevenue) / 12;
  //     const annualSmartRevenue = simulationPower[smartEconomyIndex];
  //     return annualSmartRevenue / 12;
  //   }
  // }
};