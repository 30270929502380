import store from "..";
import {
  NeedType,
  NeedTypeApiKeys,
} from "../../components/Steps/Needs/phases/phase2/constants";
import { ComponentStatus } from "../../components/shared/status/component-status";
import { dimensionneurRepository } from "../../data/dimensionneur/services/dimensionneur-service";
import { STEP_LOCALIZATION, STEP_NEEDS, STEP_RECOMMANDATION } from "../reducer";
import { SimulationState } from "./simulation-state";

import _ from "lodash";
import {
  AzimutId,
  PenteId,
} from "../../components/Steps/Recommandation/phases/phase1/constants";

let currentParameters: SimulationParameters;

const initialState = {
  status: ComponentStatus.idle,
  data: undefined,
  error: undefined,
};

enum ActionType {
  load,
  reset,
}

interface SimulationParameters {
  budget: number;
  equipements: string[];
  lat: number;
  lng: number;
  angle: number;
  orientation: number;
  pays: string;
}

export default function simulationReducer(state, action): SimulationState {
  const { data, status, error } = action;

  switch (action.type) {
    case ActionType.load:
      return {
        ...state,
        simulation: {
          data: data || state.data,
          status: status,
          error: error,
        },
      };

    case ActionType.reset:
      return { ...state, simulation: initialState };
    default:
      return { ...state };
  }
}

export const simulationLoad =
  (params: SimulationParameters) => async (dispatch) => {
    currentParameters = params;
    try {
      dispatch({ type: ActionType.load, status: ComponentStatus.loading });
      const simulation = await dimensionneurRepository.getSimulation({
        budget: params.budget,
        equipements: params.equipements.map(
          (equipement: NeedType) => NeedTypeApiKeys[equipement]
        ),
        lat: params.lat,
        lng: params.lng,
        angle: params.angle,
        orientation: params.orientation,
        pays: params.pays,
      });
      dispatch({
        type: ActionType.load,
        status: ComponentStatus.loaded,
        data: simulation,
      });
    } catch (_) {
      dispatch({ type: ActionType.load, status: ComponentStatus.error });
    }
  };

const simulationReset = (dispatch) => {
  dispatch({ type: ActionType.reset });
};

export const simulationHandleChange = () => {
  const currentState = store.getState();

  const parameters: SimulationParameters = {
    budget: currentState.step.steps[STEP_NEEDS].data?.phase1?.budget,
    equipements: currentState.step.steps[STEP_NEEDS].data?.phase2?.equipements,
    lat: currentState.step.steps[STEP_LOCALIZATION].data?.position[0],
    lng: currentState.step.steps[STEP_LOCALIZATION].data?.position[1],
    orientation:
      PenteId[currentState.step.steps[STEP_RECOMMANDATION].data?.phase1?.pente],
    angle:
      AzimutId[
        currentState.step.steps[STEP_RECOMMANDATION].data?.phase1?.azimut
      ],
    pays: "fr",
  };

  if (
    !_.isEqual(parameters, currentParameters) &&
    !_.isEqual(currentState.simulationReducer, initialState)
  ) {
    currentParameters = parameters;
    store.dispatch(simulationReset);
  }
};

export const simulationShouldLoad = (state) =>
  initialState === state.simulation;
