import { useTranslation } from "react-i18next";

import { OActionButton } from "../../../../../Styled/Buttons";
import { OBody5, OBody8 } from "../../../../../Styled/Texts";
import { STEP_DESIGN } from "../../../../../../store/reducer";
import styled from "styled-components";
import { ConfigurateurProductEntity } from "../../../../../../data/configurateur/services/entities/configurateur-product.entity";

export const SupervisionItem = (props: {
  supervision?: ConfigurateurProductEntity;
}) => {
  const { t } = useTranslation([STEP_DESIGN, "global"]);
  return (
    <>
      {props.supervision ? (
        <SupervisionItemContainer>
          <img
            src={props.supervision?.parent?.default_image?.url}
            alt={props.supervision?.parent?.title}
          />
          <OBody8 className="supervision-item-name">
            {props.supervision?.configurator_name ||
              props.supervision?.parent?.title}
          </OBody8>
          <OBody5 className="supervision-item-trailing">{t("include")}</OBody5>
        </SupervisionItemContainer>
      ) : (
        <OActionButton disabled={true}>
          {t("supervision.unavailableForCurrentConfiguration")}
        </OActionButton>
      )}
    </>
  );
};

const SupervisionItemContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.colors.clay.clayPower200};
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.primary.bluePower700};

  img {
    height: 54px;
    width: 54px;
  }

  .supervision-item-name {
    padding-left: 8px;
    padding-right: 16px;
  }

  .supervision-item-trailing {
    min-width: fit-content;
  }
`;
