import styled, { css } from "styled-components";
import { OBody5 } from "../Texts";

export const OActionButton = styled(OBody5)<{
  isactive?: string;
  sublabel?: string;
  disabled?: boolean;
}>`
  background: ${(props) =>
    !props.disabled
      ? props.theme.colors.clay.clayPower200
      : props.theme.colors.clay.clayPower500};

  color: ${(props) =>
    !props.disabled ? "inherit" : props.theme.colors.clay.clayPower700};
  font-size: ${(props) => (!props.disabled ? "inherit" : "12px")};

  user-select: none;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  min-height: ${(props) => props.theme.heights.input};
  width: 100%;
  text-align: center;
  height: ${(props) => props.theme.heights.input};
  border: solid 1px
    ${(props) =>
      props.isactive === "true"
        ? props.theme.colors.primary.bluePower700
        : props.theme.colors.clay.clayPower600};

  border-radius: 8px;

  transition: all 0.2s ease-in-out;

  :hover:not([disabled]) {
    cursor: pointer;
    border: solid 1px ${(props) => props.theme.colors.primary.bluePower700};
  }

  &.design {
    flex-direction:column;
  }

  ${(props) =>
    props.isactive === "true" &&
    css`
      border: solid 1px ${props.theme.colors.primary.bluePower700};
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.005);
    `}

  &:after {
    content: "${(props) => props.sublabel}";
    position: absolute;
    right: 0;
    top: 1;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    color: ${(props) => props.theme.colors.onyx.onyxPower700};
  }

  &.design:after {
    position:inherit;
    line-height:1;
  }
`;
