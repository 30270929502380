import { ComponentStatus } from "../../../components/shared/status/component-status";
import { configurateurService } from "../../../data/configurateur/services/configurateur-service";
import { ConfigurateurInverterEntity } from "../../../data/configurateur/services/entities/inverters/configurateur-inverter.entity";
import {
  getTotalDiscountedPrice,
  getTotalPrice,
} from "../../../utils/calculate-item-price";
import { getConfigurationPresetItem } from "../../../utils/preset/configuration-preset.utils";

import { ConfigurateurOnduleursState } from "./configurateur-onduleurs-state";

const initialState: ConfigurateurOnduleursState = {
  onduleursData: {
    status: ComponentStatus.idle,
    hasSelectedOnce: false,
  },
};

enum ActionType {
  loadOnduleurs = "loadOnduleurs",
  selectOnduleur = "selectOnduleur",
}

export const configurationOnduleursReducer = (
  state = initialState,
  action
): ConfigurateurOnduleursState => {
  const { data, status, error, selected } = action;

  switch (action.type) {
    case ActionType.loadOnduleurs:
      return {
        ...state,
        onduleursData: {
          data: data || state?.onduleursData?.data,
          status: status,
          error: error,
          selected: selected || state?.onduleursData?.selected,
          hasSelectedOnce: state?.onduleursData?.hasSelectedOnce,
        },
      };
    case ActionType.selectOnduleur:
      return {
        ...state,
        onduleursData: {
          ...state.onduleursData,
          data: data || state?.onduleursData?.data,
          selected: selected,
          hasSelectedOnce: true,
        },
      };
    default:
      return { ...state };
  }
};

export const configurateurOnduleursLoad =
  (
    emplacementId: number,
    fixationId: string,
    panelId: string,
    panelIdAttribute: string,
    panelsNumber: number,
    phaseNumber: number
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ActionType.loadOnduleurs,
        status: ComponentStatus.loading,
      });

      const onduleurs = await configurateurService.getOnduleurs(
        emplacementId,
        fixationId,
        panelId,
        panelIdAttribute,
        panelsNumber,
        phaseNumber
      );

      const selected = getDefaultSelection(
        panelsNumber,
        onduleurs,
        getState().configurateurOnduleurs?.onduleursData?.hasSelectedOnce,
        getState().configurateurOnduleurs?.onduleursData?.selected
      );

      const onduleursWithPriceDelta = onduleurs.map((inverter) => {
        return {
          ...inverter,
          price_delta:
            getTotalDiscountedPrice(inverter) -
            getTotalDiscountedPrice(selected),
        };
      });
      // console.log(onduleurs);
      dispatch({
        type: ActionType.loadOnduleurs,
        status: ComponentStatus.loaded,
        data: onduleursWithPriceDelta,
        selected: selected,
      });
    } catch (_) {
      dispatch({
        type: ActionType.loadOnduleurs,
        status: ComponentStatus.error,
      });
    }
  };

export const configurateurOnOnduleurSelected =
  (onduleurId: string) => (dispatch, getState) => {
    const onduleursData = getState().configurateurOnduleurs?.onduleursData;
    const newSelectedOnduleur = onduleursData?.data?.find(
      (onduleur) => onduleur.id === onduleurId
    );
    const onduleursWithPriceDelta = onduleursData.data.map((onduleur) => {
      return {
        ...onduleur,
        price_delta:
          getTotalPrice(onduleur) - getTotalPrice(newSelectedOnduleur),
      };
    });

    if (
      newSelectedOnduleur &&
      onduleursData?.selected?.id !== newSelectedOnduleur?.id
    ) {
      dispatch({
        type: ActionType.selectOnduleur,
        selected: newSelectedOnduleur,
        data: onduleursWithPriceDelta,
      });
    }
  };

export const isMicroOnduleur = (
  onduleur: ConfigurateurInverterEntity
): boolean => {
  return onduleur.type === "Micro-onduleur";
};

const getDefaultSelection = (
  panelsNumber: number,
  onduleurs: ConfigurateurInverterEntity[],
  hasSelectedOnce: boolean,
  lastOnduleurSelected?: ConfigurateurInverterEntity
): ConfigurateurInverterEntity => {
  // Return oscaro choice if no onduleur previously selected
  if (!hasSelectedOnce) {
    const preset =
      getConfigurationPresetItem<ConfigurateurInverterEntity>(onduleurs);

    return (
      preset?.selected ||
      onduleurs.find((onduleur) => onduleur.is_oscaro_choice) ||
      onduleurs[0]
    );
  }

  if (lastOnduleurSelected) {
    const previous = onduleurs.find(
      (onduleur) => lastOnduleurSelected.id === onduleur.id
    );
    // Return previous selection if exists into new [onduleurs] list
    if (previous) return previous;

    // Return a product with the same manufacturer name if exists
    const sameManufacturer = onduleurs.find(
      (onduleur) =>
        onduleur?.parent.vendor === lastOnduleurSelected?.parent.vendor
    );
    if (sameManufacturer) return sameManufacturer;
  }
  return onduleurs[0];
};
