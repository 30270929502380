import _ from "lodash";
import {
  OFormGroup,
  OFormLabel,
  OSelect,
  OSelectOption,
  OSelectOptionIcon,
} from "../../../Styled/Forms";

export interface SelectOption<T> {
  value: T;
  label: string;
  icon?: string;
}
export const InputSelect = <T extends unknown>(props: {
  className?: string;
  label: string;
  options: SelectOption<T>[];
  selection: T;
  onSelect: (selection: SelectOption<T>) => void;
  compare?: (option: T, selection: T) => boolean;
}) => {
  const Option = (props) => {
    return (
      <OSelectOption {...props}>
        <img src={props.data?.icon} alt="icon" height="20px" />
        {props.data.label}
      </OSelectOption>
    );
  };

  const selectedOption = props.options.find((option) => {
    if (props.compare !== undefined) {
      return props.compare(option.value, props.selection);
    }
    return option.value === props.selection;
  });
  return (
    <OFormGroup className={props.className ? props.className : ""}>
      {selectedOption?.icon && (
        <OSelectOptionIcon src={selectedOption?.icon} alt="icon" />
      )}
      <OFormLabel>{props.label}</OFormLabel>
      <OSelect
        styles={{
          indicatorsContainer: (provided) => ({
            ...provided,
            "& svg": {
              fill: "#ABB0DB",
            },
          }),
        }}
        options={props.options}
        value={selectedOption}
        onChange={props.onSelect}
        components={{ Option }}
        className="react-select-container with-icon"
        classNamePrefix="react-select"
      />
    </OFormGroup>
  );
};
