export const SET_CURRENT_POLYGON = 'SET_CURRENT_POLYGON';
export const SET_SELECTED_PANELS = 'SET_SELECTED_PANELS';
export const SET_GLOBAL_GRID = 'SET_GLOBAL_GRID';
export const SET_LINE_LAYER_ID = 'SET_LINE_LAYER_ID';
export const SET_BEARING = 'SET_BEARING';
export const SET_CENTROID = 'SET_CENTROID';
export const SET_RECTANGLES_LINES = 'SET_RECTANGLES_LINES';
export const SET_SELECTED_PANEL_IN_GLOBAL_GRID = 'SET_SELECTED_PANEL_IN_GLOBAL_GRID'
export const RESET_MAPBOX_REDUCER = 'RESET_MAPBOX_REDUCER';
export const SET_CURRENT_PRESS_ON_ROTATE = 'SET_CURRENT_PRESS_ON_ROTATE';
export const SET_ZOOM_AND_POSITION = "SET_ZOOM_AND_POSITION";


interface setRectanglesLinesAction {
    type: typeof SET_RECTANGLES_LINES;
    payload: any;
}
interface SetCurrentPressOnRotateAction {
    type: typeof SET_CURRENT_PRESS_ON_ROTATE;
    payload: any;
}
interface ResetMapBoxReducerAction {
    type: typeof RESET_MAPBOX_REDUCER;
}

interface SetCurrentPolygonAction {
    type: typeof SET_CURRENT_POLYGON;
    payload: any;
}

interface SetSelectedPanelsAction {
    type: typeof SET_SELECTED_PANELS;
    payload: any;
}

interface SetSelectedPanelsActionInGlobalGrid {
    type: typeof SET_SELECTED_PANEL_IN_GLOBAL_GRID;
    payload: any;
}

interface SetGlobalGridAction {
    type: typeof SET_GLOBAL_GRID;
    payload: any;
}

interface SetLineLayerIdAction {
    type: typeof SET_LINE_LAYER_ID;
    payload: any;
}

interface SetBearingAction {
    type: typeof SET_BEARING;
    payload: any;
}

interface SetCentroidAction {
    type: typeof SET_CENTROID;
    payload: any;
}

interface SetZoomAndPositionAction {
    type: typeof SET_ZOOM_AND_POSITION;
    payload: any;
  }


export type MapBoxActionTypes = SetCurrentPolygonAction | SetSelectedPanelsAction | SetGlobalGridAction | SetLineLayerIdAction 
| SetBearingAction | SetCentroidAction | SetSelectedPanelsActionInGlobalGrid | ResetMapBoxReducerAction | SetCurrentPressOnRotateAction | setRectanglesLinesAction
| SetZoomAndPositionAction;
export interface MapBoxState {
    currentPolygon: any;
    selectedPanels: any;
    globalGrid: any;
    lineLayerId: any;
    bearing: any;
    centroid: any;
    isPressingOnRotate: boolean;
    rectanglesLines: any;
    zoom: number;
    position: any;
}

const initialState: MapBoxState = {
    currentPolygon: null,
    selectedPanels: [],
    globalGrid: null,
    lineLayerId: null,
    bearing: null,
    centroid: null,
    isPressingOnRotate: false,
    rectanglesLines: [],
    zoom: 20,
    position: null,
};

export function mapBoxReducer(
  state = initialState,
  action: MapBoxActionTypes
): MapBoxState {
  switch (action.type) {
    case SET_CURRENT_POLYGON:
      return {
        ...state,
        currentPolygon: action.payload,
      };
    case SET_SELECTED_PANELS:
        const newSelectedPanels = [...state.selectedPanels]; // Make a copy of the array
        return {
            ...state,
            selectedPanels: newSelectedPanels,
        };
    case SET_GLOBAL_GRID:
        return {
            ...state,
            globalGrid: action.payload,
        };
    case SET_LINE_LAYER_ID:
        return {
            ...state,
            lineLayerId: action.payload,
        };
    case SET_BEARING:
        return {
            ...state,
            bearing: action.payload,
        };
    case SET_CENTROID:
        return {
            ...state,
            centroid: action.payload,
        };
    case SET_SELECTED_PANEL_IN_GLOBAL_GRID:
        return {
            ...state,
            globalGrid: action.payload,
        };
    case RESET_MAPBOX_REDUCER:
        return {
            ...state,
            currentPolygon: null,
            selectedPanels: [],
            globalGrid: null,
            lineLayerId: null,
            bearing: null,
            centroid: null,
        };
    case SET_CURRENT_PRESS_ON_ROTATE:
        return {
            ...state,
            isPressingOnRotate: action.payload,
        };
    case SET_RECTANGLES_LINES:
        return {
            ...state,
            rectanglesLines: action.payload,
        };
    case SET_ZOOM_AND_POSITION:
        return {
            ...state,
            zoom: action.payload.zoom,
            position: action.payload.position,
        };
    default:
      return state;
  }
}

export function setCurrentPolygonInReducer(polygon: any): MapBoxActionTypes {
    return {
        type: SET_CURRENT_POLYGON,
        payload: polygon,
    };
}

export function setSelectedPanelsInReducer(panels: any): MapBoxActionTypes {
    return {
        type: SET_SELECTED_PANELS,
        payload: panels,
    };
}

export function setBearingInReducer(bearing: any): MapBoxActionTypes {
    return {
        type: SET_BEARING,
        payload: bearing,
    };
}

export function setCentroidInReducer(centroid: any): MapBoxActionTypes {
    return {
        type: SET_CENTROID,
        payload: centroid,
    };
}

export function setGlobalGridInReducer(grid: any): MapBoxActionTypes {
    return {
        type: SET_GLOBAL_GRID,
        payload: grid,
    };
}

export function setLineLayerIdInReducer(id: any): MapBoxActionTypes {
    return {
        type: SET_LINE_LAYER_ID,
        payload: id,
    };
}

export function setSelectedPanelInGlobalGrid(panelId: number): MapBoxActionTypes {
    return {
        type: SET_SELECTED_PANEL_IN_GLOBAL_GRID,
        payload: panelId,
    };
}

export function resetMapBoxReducer(): MapBoxActionTypes {
    return {
        type: RESET_MAPBOX_REDUCER,
    };
}

export function setIsPressingOnRotate(isPressing: boolean): MapBoxActionTypes {
    return {
        type: SET_CURRENT_PRESS_ON_ROTATE,
        payload: isPressing,
    };
}

export function setRectanglesLines(lines: any[]): MapBoxActionTypes {
    return {
        type: SET_RECTANGLES_LINES,
        payload: lines,
    };
}

export function SetZoomAndPosition(
    zoom: number,
    position: any
  ): MapBoxActionTypes {
    return {
      type: SET_ZOOM_AND_POSITION,
      payload: { zoom, position },
    };
  }