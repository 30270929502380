export const dealtDepartmentsAvailable = [
  "01",
  "04",
  "06",
  "07",
  "13",
  "21",
  "22",
  "26",
  "27",
  "31",
  "33",
  "34",
  "38",
  "42",
  "59",
  "60",
  "63",
  "69",
  "73",
  "74",
  "75",
  "77",
  "78",
  "83",
  "84",
  "91",
  "92",
  "93",
  "94",
  "95",
];
