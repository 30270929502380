import React from 'react';
import { VerticalDimensionElements } from './styled-vertical-dimension';

interface VerticalDimensionProps {
    value: string;
    // id?: string;
    // index: number;
    // disposition: number;
    // className?: string;
}

// const VerticalDimension: React.FC<VerticalDimensionProps> = ({ index, disposition }) => {
const VerticalDimension: React.FC<VerticalDimensionProps> = ({value}) => {

    // const addPanelInRowEvent = () => {
    //     store.dispatch(addPanelInRow({ rowIndex: index }));
    // }

    return(
        <VerticalDimensionElements>

            <span className="body">
                {value}
            </span>

            <div className="arrow">
                <span className="material-symbols-rounded top">keyboard_arrow_up</span>
                <span id="line" className="line"></span>
                <span className="material-symbols-rounded bottom">keyboard_arrow_down</span>
            </div>

        </VerticalDimensionElements>
    );
};

export default VerticalDimension;