import { css } from "styled-components";

export const OSpinner = css`
  &:before {
    content: "";
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 50%;
    aspect-ratio: 1/1;
    border: 2px solid ${(props) => props.theme.colors.clay.clayPower200};
    border-top-color: transparent;
    animation: spin 0.6s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
