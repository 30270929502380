import { useEffect } from "react";
import { theme } from "../../../../Styled/GlobalStyle";

export const useJumpToLocation = (map, location) => {
  useEffect(() => {
    if (!location) return;
    map.current.flyTo({
      center: [location?.lon, location?.lat],
      zoom: 18,
      speed: 3,
    });

    const circleLayer = map.current.getLayer("mapbox-circle-layer");
    if (circleLayer) {
      map.current
        .getSource("mapbox-circle-layer")
        .setData(getMarkerCircleLayer(location).source.data);
      return;
    }
  }, [location]);
};

export const addMarkerLayerToMap = (map, location) => {
  map.addLayer(getMarkerCircleLayer(location));
};

export const getMarkerCircleLayer = (location) => {
  return {
    id: "mapbox-circle-layer",
    type: "circle",
    paint: {
      "circle-radius": 100,
      "circle-color": theme.colors.primary.bluePower700,
      "circle-opacity": 0.2,
      "circle-stroke-color": theme.colors.primary.bluePower700,
      "circle-stroke-width": 2,
    },
    source: {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [location?.lon, location?.lat],
            },
          },
        ],
      },
    },
  };
};
