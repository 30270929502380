import styled from "styled-components";
import { StepWrapperContainer } from "../../Styled/StepWrapperContainer";
import { MapContainer } from "react-leaflet";
import { motion } from "framer-motion";
import { localizationConfig } from "./constants";
import { imgsPath } from "../../../utils/constants";

export const LocalizationContainer = styled(StepWrapperContainer)``;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 65%;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    width: 100%;
    & button {
      margin-bottom: 30px;
    }
  }
`;

export const LeafletContainer = styled(motion.div)`
  width: 100%;
  height: 100%;

  .leaflet-control-layers {
    color: ${(props) => props.theme.colors.onyx.onyxPower500};
    border: 3px solid ${(props) => props.theme.colors.primary.bluePower400};
    background-color: ${(props) => props.theme.colors.neutralBackground};

    .leaflet-control-layers-expanded {
      .leaflet-control {
        .leaflet-control-layers-selector {
          &:checked + span {
            background-color: ${(props) =>
              props.theme.colors.primary.bluePower400};
            border-color: ${(props) => props.theme.colors.primary.bluePower400};

            &:after {
              background-color: ${(props) =>
                props.theme.colors.primary.bluePower400};

              &:hover {
                background-color: ${(props) =>
                  props.theme.colors.primary.bluePower400};
              }
            }
          }
        }
      }
    }
  }

  .leaflet-control-zoom {
    outline: none;
    border: none;

    .leaflet-control-zoom-in {
      background-color: ${(props) => props.theme.colors.neutralBackground};
      border-radius: 50%;
      border: 1px solid ${(props) => props.theme.colors.primary.bluePower400};
      transform: rotate(180deg);
    }

    .leaflet-control-zoom-out {
      background-color: ${(props) => props.theme.colors.neutralBackground};
      transform: rotate(180deg);
      border-radius: 50%;
      border: 1px solid ${(props) => props.theme.colors.primary.bluePower400};
    }
  }
`;

export const Map = styled.div`
  height: ${localizationConfig.mapHeight};
  width: 100%;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    height: 100%;
  }

  .mapbox-marker-icon {
    background-image: url(${(_) => imgsPath + "map/marker.svg"});
    background-size: contain;
    background-repeat: no-repeat;
    width: 60px;
    height: 60px;
    display: block;
    margin: auto;
  }
`;

export const MapboxLocationContainer = styled.div`
  width: 100%;
  height: 100%;
`;
