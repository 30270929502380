import { useTranslation } from "react-i18next";
import { OTinyButton, OActionButton } from "../Buttons";
import { OH3 } from "../Texts";
import { OModal } from "./styled";
import { AnimatePresence, MotionConfig, motion } from "framer-motion";

export const Modal = (props: {
  show: boolean;
  maxContentWidth?: string;
  maxContentHeight?: string;
  padding?: string;
  title?: string;
  onClose?: any;
  children?: any;
  actions?;
}) => {
  const { t } = useTranslation("global");
  return (
    <MotionConfig transition={{ duration: 0.15 }}>
      <AnimatePresence>
        {props.show && (
          <OModal
            key="o-modal"
            show={props.show}
            maxContentWidth={props.maxContentWidth}
            maxContentHeight={props.maxContentHeight}
            padding={props.padding}
          >
            <motion.div
              className={`modal-background ${props.show ? "modal-shown" : ""}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
            <motion.div
              className={`modal-content overflow-auto ${props.show ? "modal-shown" : ""}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <OH3>{props.title}</OH3>
              <OTinyButton
                className="modal-header-close-btn"
                onClick={props.onClose}
              />
              {props.children && (
                <div className="modal-content-body">{props.children}</div>
              )}
              {!props.actions ? (
                <OActionButton
                  className="modal-close-btn"
                  onClick={props.onClose}
                >
                  {t("close")}
                </OActionButton>
              ) : (
                props.actions
              )}
            </motion.div>
          </OModal>
        )}
      </AnimatePresence>
    </MotionConfig>
  );
};
