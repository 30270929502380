import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledRecommandationCard = styled(motion.div)`
  background: ${(props) => props.theme.gradients.yellow};
  border-radius: 12px;
  height: fit-content;
  z-index: 1;
  padding: 1rem;
  padding-bottom: 0.4rem;
  position: absolute;
  
  margin-right: 1.5rem;
  margin-top: 1.5rem;
  right: 0;
  top: 0;

  width: 90%;
  
  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    margin-right: 0px;
    left:50%;
    transform: translateX(-50%) !important;
    margin-top: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}px) {
    max-width: 80%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}px) {
    // width: 60%;
    max-width: 60%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    // width: 90%;
    // background-color: blue;
    // margin: 0 auto;
    // left: 0;
    // right: 0;
    // bottom: 0;
  }

  #star {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 24px;
    height: 24px;
  }

  span {
    * {
      margin: 0;
      padding: 0;
    }
  }
`;

export const AlertCard = styled(motion.div)`
    width: 100%;
    height: fit-content;
    padding: 1rem;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.error.cherryPower200};
    
    * {
        text-align: center;
        width: 90%;
        margin: auto;
        color: ${(props) => props.theme.colors.error.cherryPower700};
    `;
