import { AlimType } from "../../../components/Steps/Design/phases/phase2/sections_content";
import { ConfigurateurAlimentationState } from "./configurateur-alimentation-state";

const initialState: ConfigurateurAlimentationState = {
  alimentationData: {
    type: AlimType.MONOPHASE,
  },
};

enum ActionType {
  selectAlimentation = "selectAlimentation",
}

export const configurationAlimentationReducer = (
  state = initialState,
  action
): ConfigurateurAlimentationState => {
  const { selection } = action;

  switch (action.type) {
    case ActionType.selectAlimentation:
      return {
        ...state,
        alimentationData: {
          type: selection,
        },
      };

    default:
      return { ...state };
  }
};

export const configurateurAlimentationSelect =
  (alimentation: AlimType) => (dispatch) => {
    dispatch({
      type: ActionType.selectAlimentation,
      selection: alimentation,
    });
  };
