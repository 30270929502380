import _ from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

export const useIsCalepinageHmValidated = () => {
  return useSelector((state: RootState) => {
    if (!state.step.skip) return true;
    return _.isEqual(
      state.calepinageHMReducer.matriceCalepinageApiValidated,
      state.calepinageHMReducer.matriceCalepinageApi
    );
  });
};
