import styled from "styled-components";
import { motion } from "framer-motion";

export const ContainerCard = styled(motion.div)`
    width: 40%;
    top: 1.5rem;
    left: 1.5rem;
    position: absolute; 
    zIndex: 1000;
    display:flex;
    flex-direction:column;
    gap:1rem;
    user-select: none;


    .mapboxgl-popup {
        background-color: transparent;
        border: none;
        box-shadow: none; /* Supprime l'ombre autour de la popup */
    }
    
    .mapboxgl-popup-content {
        color: #000; /* Assurez-vous que cette couleur est visible sur votre carte */
    }

    .mapboxgl-popup-tip {
        display: none;
    }
`;

export const ContainerCardForArea = styled(motion.div)`
    padding: 1rem;
    width: auto;
    height: 2rem;
    bottom: 1.5rem;
    left: 1.5rem;
    position: absolute; 
    zIndex: 1000;
    display:flex;
    border-radius: 8px;
    background-color: #4256FF;
    color: white;
    align-items:center;
`;


export const StyledMapBox = styled.div`
    .mapboxgl-popup {
        background-color:#4256FF;
        border: none;
        box-shadow: none;
        pointer-events: none;
    }
    
    .mapboxgl-popup-content {
        color: white;
        font-weight: normal;
        pointer-events: none;
        background-color:#4256FF;
        padding: 5px;
        line-height: 100%;
    }

    .mapboxgl-popup-tip {
        display: none;
    }
`;

