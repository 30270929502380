import { useSelector } from "react-redux";
import { RootState } from "../..";
import { usePanelCount } from "../../../hooks/configurateur/panels/use-panel-count.hook";
import {
  getTotalDiscountedPrice,
  getTotalPrice,
} from "../../../utils/calculate-item-price";

export const useVirtualCartTotal = () => {
  const panelCount = usePanelCount();
  const panelPriceDiscounted = useSelector((state: RootState) =>
    getTotalDiscountedPrice(
      state.configurateurPanels?.panelsData?.selected,
      panelCount
    )
  );

  const onduleurPriceDiscounted = useSelector((state: RootState) =>
    getTotalDiscountedPrice(
      state.configurateurOnduleurs?.onduleursData?.selected
    )
  );

  const fixationPriceDiscounted = useSelector((state: RootState) =>
    state.configurateurFixations?.fixationsData?.panneauFixation?.data
      ?.map((fixation) => getTotalDiscountedPrice(fixation))
      ?.reduce((a, b) => a + b, 0)
  );
  const supervisionPriceDiscounted = useSelector(
    (state: RootState) =>
      state.configurateurSupervisions?.supervisionData?.selected
        ?.price_discounted || 0
  );
  const garantiePriceDiscounted = useSelector(
    (state: RootState) =>
      state.configurateurGaranties?.garantiesData?.selected?.price_discounted ||
      0
  );
  const batteryPriceDiscounted = useSelector(
    (state: RootState) =>
      state.configurateurBatteries?.batteriesData?.selected?.price_discounted ||
      0
  );
  const helpPriceDiscounted = useSelector(
    (state: RootState) =>
      state.configurateurHelps?.helpsData?.selected?.price_discounted || 0
  );

  const accessoiresPriceDiscounted = useSelector(
    (state: RootState) =>
      state.configurateurAccessories?.accessoriesData?.selectedCombination
        ?.totalPrice || 0
  );

  const panelPrice = useSelector((state: RootState) =>
    getTotalPrice(state.configurateurPanels?.panelsData?.selected, panelCount)
  );

  const onduleurPrice = useSelector((state: RootState) =>
    getTotalPrice(state.configurateurOnduleurs?.onduleursData?.selected)
  );

  const fixationPrice = useSelector((state: RootState) =>
    state.configurateurFixations?.fixationsData?.panneauFixation?.data
      ?.map((fixation) => getTotalPrice(fixation))
      ?.reduce((a, b) => a + b, 0)
  );
  const supervisionPrice = useSelector(
    (state: RootState) =>
      state.configurateurSupervisions?.supervisionData?.selected?.price || 0
  );
  const garantiePrice = useSelector(
    (state: RootState) =>
      state.configurateurGaranties?.garantiesData?.selected?.price || 0
  );
  const batteryPrice = useSelector(
    (state: RootState) =>
      state.configurateurBatteries?.batteriesData?.selected?.price || 0
  );
  const helpPrice = useSelector(
    (state: RootState) =>
      state.configurateurHelps?.helpsData?.selected?.price || 0
  );

  const accessoiresPrice = useSelector(
    (state: RootState) =>
      state.configurateurAccessories?.accessoriesData?.selectedCombination
        ?.totalPrice || 0
  );

  const priceDiscounted =
    panelPriceDiscounted +
    onduleurPriceDiscounted +
    fixationPriceDiscounted +
    supervisionPriceDiscounted +
    garantiePriceDiscounted +
    batteryPriceDiscounted +
    helpPriceDiscounted +
    accessoiresPriceDiscounted;

  const price =
    panelPrice +
    onduleurPrice +
    fixationPrice +
    supervisionPrice +
    garantiePrice +
    batteryPrice +
    helpPrice +
    accessoiresPrice;

  return {
    price: price,
    discountedPrice: priceDiscounted,
  };
};
