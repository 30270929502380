import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useBudgetParamHook } from "../../../hooks/navigation/use-budget-param.hook";
import {
  setCurrentStep,
  setStepData,
  STEP_NEEDS,
} from "../../../store/reducer";

export const BudgetHandler = (props) => {
  const budget = useBudgetParamHook();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!budget) return;
    dispatch(
      setStepData({
        step: STEP_NEEDS,
        data: {
          phase1: {
            budget: budget,
          },
        },
      })
    );
    dispatch(setCurrentStep("installation"));
  }, [budget]);
  return props.children;
};
