import React, { useEffect, useRef, useState, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { RecommandationReduxStepData } from "../../../Steps/Recommandation/constants";
import {
    STEP_LOCALIZATION,
    STEP_RECOMMANDATION,
} from "../../../../store/reducer";
import { PenteId } from "../../../Steps/Recommandation/phases/phase1/constants";
import { Card } from 'react-bootstrap'; // Assurez-vous d'avoir installé react-bootstrap ou utilisez votre propre composant Card
import {
    setMatriceCalepinage,
    setNeedComputeMatriceCalepinage,
    setPanelCountInCalepinage,
} from "../../../../store/calepinage/calepinageReducer";
import { useDispatch } from "react-redux";
import {
    SetZoomAndPosition,
    setCentroidInReducer,
    setCurrentPolygonInReducer,
    setBearingInReducer,
    setGlobalGridInReducer,
    setLineLayerIdInReducer,
    setRectanglesLines
} from "../../../../store/calepinage/mapBoxReducer";
import { MAX_AREA, MAX_PANEL_COUNT, PANEL_HEIGHT_PAYSAGE, PANEL_HEIGHT_PORTRAIT, PANEL_WIDTH_PAYSAGE, PANEL_WIDTH_PORTRAIT, ZOOM_MAX_OUT } from '../constant';
import { useTranslation } from 'react-i18next';
import { RotateControl, ZoomControl } from './mapControl';
import { ContainerCard, ContainerCardForArea, StyledMapBox } from './styled';
import { svgsPath, imgsPath } from '../../../../utils/constants';
import { PUBLIC_KEY_MAPBOX } from '../../../../utils/constants';
import { formatNumber } from '../../../../utils/convert-number-locale';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const MapMapboxComponent = ({ orientation, deleteAll = false, setDeleteAll = (deleteAll) => { } }) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const draw = useRef(null);
    const dispatch = useDispatch();
    const { t } = useTranslation("mapbox");


    const currentPolygonInReducer = useSelector(
        (state: RootState) => state.mapBoxReducer.currentPolygon);

    /*const selectedPanels = useSelector(
        (state: RootState) => state.mapBoxReducer.selectedPanels);*/

    const globalGrid = useSelector(
        (state: RootState) => state.mapBoxReducer.globalGrid);

    const lineLayerId = useSelector(
        (state: RootState) => state.mapBoxReducer.lineLayerId);

    const bearing = useSelector(
        (state: RootState) => state.mapBoxReducer.bearing);

    const centroid = useSelector(
        (state: RootState) => state.mapBoxReducer.centroid);

    const isPressingOnRotate = useSelector(
        (state: RootState) => state.mapBoxReducer.isPressingOnRotate);

    const rectanglesLines = useSelector(
        (state: RootState) => state.mapBoxReducer.rectanglesLines);

    const zoomInReducer = useSelector(
        (state: RootState) => state.mapBoxReducer.zoom);

    const [rectangleWidth, setRectangleWidth] = useState(PANEL_WIDTH_PORTRAIT);
    const [rectangleHeight, setRectangleHeight] = useState(PANEL_HEIGHT_PORTRAIT);
    const [espacement] = useState(turf.convertLength(0.05, 'meters', 'degrees')); // Espacement entre les panneaux
    const [espacement_toit] = useState(turf.convertLength(5, 'centimeters', 'degrees') * 100); // Espacement entre les limites du toit et les panneaux
    const [zoomOutMax] = useState(ZOOM_MAX_OUT);
    const [panelIdSelected, setPanelIdSelected] = useState(null); // Id du panneau sélectionné
    const [triggerSelectPanel, setTriggerSelectPanel] = useState(false); // Trigger pour sélectionner un panneau
    const [needRedrawRectangles, setNeedRedrawRectangles] = useState(false);
    const [canShowCard, setCanShowCard] = useState(true);
    const [canShowCarTooSmall, setCanShowCardTooSmall] = useState(true);
    const [canShowCarTooBig, setCanShowCardTooBig] = useState(true);
    const [isTooSmall, setIsTooSmall] = useState(false);
    const [isTooBig, setIsTooBig] = useState(false);
    const [canShowCardTooMuch, setcanShowCardTooMuch] = useState(true);
    const [tooMuch, setIsTooMuch] = useState(false);
    const [waitDeleteAll, setWaitDeleteAll] = useState(false);
    const [needInitOnClick, setNeedInitOnClick] = useState(false);
    const [needBoundMap, setNeedBoundMap] = useState(false);
    const [needChangeModeDraw, setNeedChangeModeDraw] = useState(false);
    const globalGridRef = useRef(globalGrid);
    const polygonRef = useRef(currentPolygonInReducer);
    const [isDrawingStarted, setIsDrawingStarted] = useState(false);


useEffect(() => {
    globalGridRef.current = globalGrid;
}, [globalGrid]);

useEffect(() => {
    polygonRef.current = currentPolygonInReducer;
}, [currentPolygonInReducer]);

    const setBearing = (bearing) => {
        dispatch(setBearingInReducer(bearing));
    };

    const setCentroid = (centroid) => {
        dispatch(setCentroidInReducer(centroid));
    };

    const setLineLayerId = (lineLayerId) => {
        dispatch(setLineLayerIdInReducer(lineLayerId));
    };

    const setGlobalGrid = (globalGrid) => {
        dispatch(setGlobalGridInReducer(globalGrid));
    };

    // Données nécessaires pour le calepinage
    // Nombre de panneaux recommandés, puis choisis par l'utilisateur
    // Angle du toit
    // Poisition portrait ou paysage
    // Géolocation


    const recommandationCount = useSelector(
        (state: RootState) => state.calepinageReducer.recommandationCount);
    const panelCountInCalepinage = useSelector(
        (state: RootState) => state.calepinageReducer.panelCountInCalepinage);
    const needRecomputeMatrice = useSelector(
        (state: RootState) => state.calepinageReducer.needComputeMatriceCalepinage);

    const localisation = useSelector(
        (state: RootState) => state.step.steps[STEP_LOCALIZATION].data?.position);

    const recommandationData: RecommandationReduxStepData =
        useSelector(
            (state: RootState) => state.step.steps[STEP_RECOMMANDATION].data
        ) || {};

    const [panelCount] = useState(recommandationCount);
    const [angle] = useState(
        PenteId[recommandationData?.phase1?.pente]
    );

    const popups = useRef([]);

    const dispatchPanelCountInCalepinage = (count) =>
        dispatch(setPanelCountInCalepinage(count));

    const setLines = (lines) => {
        dispatch(setRectanglesLines(lines));
    }

    // 1 - Créer la map
    // 2 - Créer le draw
    // 3 - Créer le polygon (source + layer ET dans draw-> ) dans update Polygon
    // 4 - Afficher la grid ou afficher rectangle si globalGrid est null

    useEffect(() => {
        // Init map when component mounts
        if (map.current) return; // If map is already initialized, return
        mapboxgl.accessToken = PUBLIC_KEY_MAPBOX;
        const newMap = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/satellite-streets-v12',
            center: [localisation[1], localisation[0]],
            zoom: zoomInReducer || 20,
            pitch: 0,
            pitchWithRotate: false,
            bearing: bearing,
            bearingSnap: 0
        });

        const newDraw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: false,
                trash: false
            },
            defaultMode: 'draw_polygon',
            styles: [
                // ACTIVE (being drawn)
                // line stroke
                {
                    "id": "gl-draw-line",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": "#4256FF",
                        "line-dasharray": [0.2, 2],
                        "line-width": 2
                    }
                },
                // polygon fill
                {
                    "id": "gl-draw-polygon-fill",
                    "type": "fill",
                    "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                    "paint": {
                        "fill-color": "#4256FF",
                        "fill-outline-color": "#4256FF",
                        "fill-opacity": 0.1
                    }
                },

                {
                    "id": "gl-draw-polygon-stroke-selected",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "direct_select"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": "#FFBA00", // Par exemple, une couleur rouge pour le contour du polygone sélectionné
                        "line-dasharray": [0.2, 2],
                        "line-width": 2
                    }
                },

                // polygon mid points
                {
                    'id': 'gl-draw-polygon-midpoint',
                    'type': 'circle',
                    'filter': ['all',
                        ['==', '$type', 'Point'],
                        ['==', 'meta', 'midpoint']],
                    'paint': {
                        'circle-radius': 3,
                        'circle-color': '#fbb03b'
                    }
                },
                // polygon outline stroke
                // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
                {
                    "id": "gl-draw-polygon-stroke-active",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"],  ["!=", "mode", "direct_select"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": "#4256FF",
                        "line-dasharray": [0.2, 2],
                        "line-width": 2
                    }
                },
                // vertex point halos
                {
                    "id": "gl-draw-polygon-and-line-vertex-halo-active",
                    "type": "circle",
                    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                    "paint": {
                        "circle-radius": 5,
                        "circle-color": "#FFF"
                    }
                },
                // vertex points
                {
                    "id": "gl-draw-polygon-and-line-vertex-active",
                    "type": "circle",
                    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                    "paint": {
                        "circle-radius": 3,
                        "circle-color": "#4256FF",
                    }
                },

                // INACTIVE (static, already drawn)
                // line stroke
                {
                    "id": "gl-draw-line-static",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": "#000",
                        "line-width": 3
                    }
                },
                // polygon fill
                {
                    "id": "gl-draw-polygon-fill-static",
                    "type": "fill",
                    "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
                    "paint": {
                        "fill-color": "#000",
                        "fill-outline-color": "#000",
                        "fill-opacity": 0.1
                    }
                },
                // polygon outline
                {
                    "id": "gl-draw-polygon-stroke-static",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": "#000",
                        "line-width": 3
                    }
                }
            ]
        });

        newMap.addControl(newDraw, 'bottom-right');
        newMap.addControl(new RotateControl(dispatch), 'bottom-right');
        newMap.addControl(new ZoomControl(dispatch, setDeleteAll), 'bottom-right');
        draw.current = newDraw;
        newMap.on('draw.create', createPolygon);
        newMap.on('draw.delete', updateArea);
        newMap.on('draw.update', updateArea);
        newMap.on('rotateend', () => {
            setBearing(newMap.getBearing());
        });
        // newMap.current.on('bearing', function(event) {
        //     setBearing(event.bearing);
        // });
        // newMap.on('draw.modechange', (event) => {
        //     console.log('Mode ' , event.mode);
        //     if(event.mode === 'direct_select') {
        //         // RAS

        //     }
        //     if(event.mode === 'simple_select') {
        //         // RAS
        //     }
        //     if(event.mode === 'draw_polygon') {
        //         // RAS
        //     }
        // //     } else {
        // //     if(event.mode === 'draw_polygon') {
        // //         if(currentPolygonInReducer !== null) {
        // //             draw.current.changeMode('simple_select');
        // //         }
        // //     } else {
        // //         if(currentPolygonInReducer == null) {
        // //             console.log('PASSAGE EN DRAW POLYGON');
        // //             // force mode to draw_polygon
        // //             draw.current.changeMode('draw_polygon');
        // //         }
        // //     }
        // // }
        // });
        newMap.scrollZoom.disable();
        newMap.on('zoom', () => {
            const currentZoom = newMap.getZoom();
            if (currentZoom < zoomOutMax) {
                newMap.setZoom(zoomOutMax);
            }
            const currentZoomUpdated = newMap.getZoom();
            dispatch(SetZoomAndPosition(currentZoomUpdated, null));
        });

        newMap.loadImage(imgsPath + '/add.png', function (error, image) {
            if (error) throw error;
            newMap.addImage('add-icon', image);
        });

        newMap.loadImage(imgsPath + '/suppr.png', function (error, image) {
            if (error) throw error;
            newMap.addImage('suppr-icon', image);
        });

        newMap.on('mousedown', (event) => {
            if (newDraw.getMode() === 'draw_polygon' && !isDrawingStarted) {
                console.log('Début du dessin du polygone!');
                setIsDrawingStarted(true);
            }
        });


        newMap.on('load', () => {
            map.current = newMap;
            if (currentPolygonInReducer !== null) {
                redrawPolygon();
            }
            if (bearing !== null) {
                // map.current.setBearing(bearing);
            }
        });

        return () => {
            newMap.off('mousedown');
        };

    }, []);


    useEffect(() => {
        if (orientation === 0) {
            setRectangleWidth(PANEL_WIDTH_PORTRAIT);
            setRectangleHeight(PANEL_HEIGHT_PORTRAIT);
        } else if (orientation === 1) {
            setRectangleWidth(PANEL_WIDTH_PAYSAGE);
            setRectangleHeight(PANEL_HEIGHT_PAYSAGE);
        }
    }, [orientation]);

    useEffect(() => {
        if (map.current !== null && globalGrid !== null && currentPolygonInReducer !== null) {
            setGlobalGrid(null);
            setNeedRedrawRectangles(true);
        }
    }, [rectangleWidth, rectangleHeight]);


    useEffect(() => {
        if (globalGrid !== null && map.current !== null) {
            printGlobalGridOnPolygon();
        }

    }, [globalGrid])

    useEffect(() => {
        if (deleteAll) {
            deleteAllFromMap();
            setDeleteAll(false);
            draw.current.changeMode('draw_polygon');
        }
    }, [deleteAll, setDeleteAll])

    const deleteAllFromMap = async () => {
        setIsDrawingStarted(false);
        supprimerRectangles();
        if (draw && draw.current) {
            draw.current.deleteAll();
        }
        setWaitDeleteAll(true);
        dispatchPanelCountInCalepinage(0);
        setLineLayerId(null);
        setBearing(null);
        setCentroid(null);
        setIsTooSmall(false);
        dispatch(setCurrentPolygonInReducer(null));
        await setGlobalGrid(null);
        popups.current.forEach(popup => popup.remove());
        popups.current = [];
    };

    function redrawPolygon() {
        if (currentPolygonInReducer) {
            draw.current.set({
                type: 'FeatureCollection',
                features: [currentPolygonInReducer]
            });
            draw.current.changeMode('simple_select')
            validatePolygon();
            setNeedInitOnClick(true);
        }
    }

    function createPolygon(e) {
        setIsDrawingStarted(false); 
        const data = draw.current.getAll();
        if (data.features.length > 0) { // SI polygone est dessiné
            const area = turf.area(data);
            if (area > MAX_AREA) {
                setIsTooBig(true);
                draw.current.delete(data.features[0].id);
                setNeedChangeModeDraw(true);
                // if (currentPolygonInReducer) {
                //     console.log('MAX WITH REDRAW');
                //     draw.current.add(currentPolygonInReducer);
                //     redrawPolygon();
                //     // validatePolygon();
                // } else {
                //     console.log('WITH NO POLYGON IN REDUCER');
                //     // setDeleteAll(true);
                //     // deleteAllFromMap();
                //     // setNeedChangeModeDraw(true);
                //     // draw.current.changeMode('draw_polygon');
                // }
            } else {
                setIsTooBig(false);
                if (currentPolygonInReducer) {
                    // DELTE CURRENT FEATURES
                    for (var i = 1; i < data.features.length; i++) {
                        var feature = data.features[i];
                        draw.current.delete(feature.id);
                    }
                }
                setNeedBoundMap(true);
                dispatch(setCurrentPolygonInReducer(data.features[0]));
            }
        } 
    }

    function updateArea(e) {
        const data = draw.current.getAll();
        if (data.features.length > 0) { // SI polygone est dessiné
            const area = turf.area(data);
            if (area > MAX_AREA) {
                draw.current.set({
                type: 'FeatureCollection',
                features: [polygonRef.current]
            });
                // console.log('TOO BIG AREA');
                setIsTooBig(true);
                // // draw.current.delete(data.features[0].id);
                // if (currentPolygonInReducer) {
                //     console.log('MAX WITH REDRAW');
                //     // draw.current.add(currentPolygonInReducer);
                //     // setGlobalGrid(null);
                //     for (var i = 1; i < data.features.length; i++) {
                //         var feature = data.features[i];
                //         draw.current.delete(feature.id);
                //     }
                //     draw.current.set({
                //         type: 'FeatureCollection',
                //         features: [currentPolygonInReducer]
                //     });
                //     console.log('COUNT ', globalGridRef.current.features.filter(feature => feature.properties.selected === true).length);
                //     revalidatePolygon();
                //     validatePolygon(map.current.getSource('grille')); 
                // } else {
                //     console.log('WITH NO POLYGON IN REDUCER');
                //     // setDeleteAll(true);
                //     // deleteAllFromMap();
                //     // setNeedChangeModeDraw(true);
                //     // draw.current.changeMode('draw_polygon');
                // }
            } else {
                setGlobalGrid(null);
                setIsTooBig(false);
                if (currentPolygonInReducer) {
                    // DELTE CURRENT FEATURES -> KEEP ONLY POLYGON 0 , cas du multi polygon
                    // for (var i = 1; i < data.features.length; i++) {
                    //     var feature = data.features[i];
                    //     draw.current.delete(feature.id);
                    // }
                }
                setNeedBoundMap(true);
                dispatch(setCurrentPolygonInReducer(data.features[0]));
            }
        } else {
            // SI polygone est supprimé
            draw.current.changeMode('draw_polygon');
            supprimerRectangles();
            setIsTooSmall(false);
            dispatch(setCurrentPolygonInReducer(null));
            setBearing(null);
            setCentroid(null);
            dispatchPanelCountInCalepinage(0);
            if (e.type !== 'draw.delete')
                alert('Click the map to draw a polygon.');
        }
    }

    useEffect(() => {
        if (needChangeModeDraw) {
            draw.current.changeMode('draw_polygon');
            setNeedChangeModeDraw(false);
        }
    }, [needChangeModeDraw]);


    useEffect(() => {
        if (needRecomputeMatrice === true) {
            dispatch(setNeedComputeMatriceCalepinage(false));
            let matrice = buildMatrix(globalGrid.features);
            let matriceFiltered = filterMatriceForAPI(matrice);
            dispatch(setMatriceCalepinage(matriceFiltered));
        }
    }, [needRecomputeMatrice]);

    useEffect(() => {
        // Update du polygone, on le valide (dessine les rectangles)
        if (currentPolygonInReducer !== null && map.current !== null) {
            validatePolygon();
        }
        if (currentPolygonInReducer === null) {
            draw.current.changeMode('draw_polygon');
        }
    }, [currentPolygonInReducer]
    );

    useEffect(() => {
        if (centroid !== null && bearing !== null && map.current !== null) {
            setGlobalGrid(null);
            if (!isPressingOnRotate) {
                setNeedRedrawRectangles(true);
            }
        }
    }, [bearing]
    );

    useEffect(() => {
        if (needBoundMap && map.current && currentPolygonInReducer) {
            setNeedBoundMap(false);
            const boundingBox = turf.bbox(currentPolygonInReducer);
            const bounds = [
                [boundingBox[0], boundingBox[1]],  // Sud-Ouest
                [boundingBox[2], boundingBox[3]]   // Nord-Est
            ];

            map.current.fitBounds(bounds, {
                padding: 20,
                bearing: map.current.getBearing(), // TODO tester
            });
        }
    }, [needBoundMap, currentPolygonInReducer]);

    useEffect(() => {
        if (needRedrawRectangles && globalGrid == null) {
            setNeedRedrawRectangles(false);
            afficherRectangles(centroid, bearing);
        }
    }, [needRedrawRectangles, globalGrid]);


    function filterMatriceForAPI(matrice) {
        let minIndice = -1, maxIndice = -1;
        for (let i = 0; i < matrice.length; i++) {
            // Parcours de lignes
            for (let j = 0; j < matrice[i].length; j++) {
                // Parcours des colonnes
                if (matrice[i][j] === 1) {
                    if (minIndice === -1 || minIndice > j) {
                        minIndice = j;
                    }
                    if (maxIndice < j || maxIndice === -1) {
                        maxIndice = j;
                    }
                }
            }
        }
        let result = [];
        // Reconstruction de la matrice filtrée
        for (let i = 0; i < matrice.length; i++) {
            result[i] = [];
            for (let j = minIndice; j <= maxIndice; j++) {
                result[i].push(matrice[i][j]);
            }
        }
        return result;
    }


    // Construit la matrice à partir de l'ensemble de rectangle et ses propriétés
    function buildMatrix(rectangles) {
        const matrix = [];
        for (const rectangle of rectangles) {
            const { selected, row, column } = rectangle.properties;
            if (!matrix[row]) {
                matrix[row] = [];
            }
            if (matrix[row].length <= column) {
                matrix[row].push(...Array(column - matrix[row].length + 1).fill(0));
            }
            matrix[row][column] = selected ? 1 : 0;
        }

        matrix.reverse();

        const filteredMatrix = matrix.filter(row => row.some(value => value !== 0));

        let maxLineLength = getLargerLine(filteredMatrix);

        let final_matrix = []
        filteredMatrix.forEach(function (line, index) {
            let new_line = fillArrayWithZeros(line, maxLineLength);
            final_matrix.push(new_line);
        });
        return final_matrix;
    }

    function getLargerLine(matrice) {
        let maxLength = 0;
        matrice.forEach(function (line, index) {
            if (line.length > maxLength) {
                maxLength = line.length
            }
        });
        return maxLength
    }

    function fillArrayWithZeros(line, desiredLength) {
        const currentLength = line.length;
        if (currentLength >= desiredLength) {
            return line;
        }
        const zerosToAdd = desiredLength - currentLength;
        for (let i = 0; i < zerosToAdd; i++) {
            line.push(0);
        }
        return line;
    }

    function supprimerRectangles() {
        // console.log('Supprimer rectangles' , map.current)
        // answerRef.current.innerHTML = ``;
        // answerMatrixRef.current.innerHTML = ``;
        if (map.current !== null) {

            if (map.current.getLayer('rectangle')) {
                // map.current.off('click', 'rectangle', rectangleSelection);
                map.current.off('mouseenter', 'rectangle');
                map.current.off('mouseleave', 'rectangle');
                map.current.removeLayer('rectangle');
                map.current.removeLayer('lines');
            }

            if (map.current.getLayer('rectangle-icon-layer-add')) {
                map.current.removeLayer('rectangle-icon-layer-add');
            }

            if (map.current.getSource('grille')) {
                map.current.removeSource('grille');
            }

            if (map.current.getSource('lines')) {
                map.current.removeSource('lines');
            }

            // Supprime la ligne si elle existe
            if (lineLayerId) {
                map.current.removeLayer(lineLayerId);
                setLineLayerId(null);
            }
        }
    }

    // Gère la sélection de panneaux
    // function rectangleSelection(e) {
    //     console.log(globalGrid.features.length)
    //     const selectedFeature = e.features[0];
    //     if (selectedFeature) {
    //         // console.log('Selected panels ', selectedPanels  )
    //         const panelId = selectedFeature.properties.panelId;
    //         setPanelIdSelected(panelId)
    //         setTriggerSelectPanel(true);
    //     }
    // }


    // const rectangleSelection = ((e) => {
    //     const selectedFeature = e.features[0];
    //     if (selectedFeature) {
    //         const panelId = selectedFeature.properties.panelId;
    //         setPanelIdSelected(panelId)
    //         setTriggerSelectPanel(true);
    //     }
    // });

    const rectangleSelection = useCallback((e) => {
        if (e && e.features && e.features.length > 0) {
            const countSelectedPanels = globalGrid?.features?.filter(feature => feature.properties.selected === true).length;
            const selectedFeature = e.features[0];
            if (selectedFeature) {
                // Déselectionner un panneau => Too Much a false
                if (tooMuch && selectedFeature.properties.selected) {
                    setIsTooMuch(false);
                }
                if (!selectedFeature.properties.selected && countSelectedPanels < MAX_PANEL_COUNT || selectedFeature.properties.selected) {
                    const panelId = selectedFeature.properties.panelId;
                    setPanelIdSelected(panelId)
                    setTriggerSelectPanel(true);
                } else {
                    setIsTooMuch(true);
                }

            }
        }
    }, [setPanelIdSelected, setTriggerSelectPanel, globalGrid?.features?.length, globalGrid]);

    useEffect(() => {
        if (map.current || needInitOnClick) {
            setNeedInitOnClick(false);
            map.current.on('click', 'rectangle', rectangleSelection);
            return () => {
                map.current.off('click', 'rectangle', rectangleSelection);
            };
        }
    }, [map.current, rectangleSelection, needInitOnClick]);

    useEffect(() => {
        if (panelIdSelected !== null && triggerSelectPanel === true) {
            setTriggerSelectPanel(false);
            const newGlobalGrid = {
                ...globalGrid,
                features: globalGrid.features.map(feature => {
                    if (feature.properties != null && feature.properties.panelId === panelIdSelected) {
                        return { ...feature, properties: { ...feature.properties, selected: !feature.properties.selected } }
                    } else {
                        return feature;
                    }
                }),
            };
            const selectedCount = newGlobalGrid.features.filter(feature => feature.properties.selected === true).length;
            dispatchPanelCountInCalepinage(selectedCount);
            setPanelIdSelected(null);
            globalGridRef.current = newGlobalGrid;
            setGlobalGrid(newGlobalGrid);   
        }
    }, [panelIdSelected, triggerSelectPanel]);

    function validatePolygon() {
        if (currentPolygonInReducer !== null) {
            popups.current.forEach(popup => popup.remove());
            popups.current = [];
            let polygon = turf.polygon(currentPolygonInReducer.geometry.coordinates);
            let centroid = turf.centroid(polygon);
            let bearing = map.current.getBearing();

            map.current.flyTo({ center: centroid.geometry.coordinates });
            afficherPopups();
            setIsTooSmall(false);
            setBearing(bearing);
            setCentroid(centroid);
            afficherRectangles(centroid, bearing);
            // Call afficher rectangle avec bonne valeur du centroid et du bearing
        } else {
            alert("Veuillez sélectionner une toiture");
        }
    }

    function revalidatePolygon() {
        popups.current.forEach(popup => popup.remove());
        popups.current = [];
        let polygon = turf.polygon(currentPolygonInReducer.geometry.coordinates);
        let centroid = turf.centroid(polygon);
        let bearing = map.current.getBearing();

        map.current.flyTo({ center: centroid.geometry.coordinates });
        afficherPopups();
        // setIsTooSmall(false);
        // afficherRectangles(centroid, bearing);
        setNeedRedrawRectangles(true);
    }

    function MetersToDecimalDegrees(meters, latitude)
{
    return meters / (111.32 * 1000 * Math.cos(latitude * (Math.PI / 180)));
}


    function afficherPopups() {
        let polygon = currentPolygonInReducer.geometry.coordinates[0];
        for (let i = 0; i < polygon.length - 1; i++) {
            let from = turf.point(polygon[i]);
            let to = turf.point(polygon[i + 1]);
            let distance = turf.distance(from, to, { units: 'meters' });
            let distanceDisplay = formatNumber(distance, 2, false,{ style :"unit" , unit: 'meter' });

            // Créer un Popup et le placer au milieu du segment
            let midPoint = turf.midpoint(from, to);
            const popup = new mapboxgl.Popup(
                {
                    closeOnClick: false,
                    closeButton: false,
                })
                .setLngLat(midPoint.geometry.coordinates)
                .setHTML(distanceDisplay) // ajustez selon l'unité choisie
                .addTo(map.current);
            popups.current.push(popup);
        }
    }

    function afficherRectangles(centroid, bearing) {
        supprimerRectangles();

        if (currentPolygonInReducer !== null) {
            if (globalGrid == null) {
                // Convertir les valeurs en m en degrés
                // const rectWidth = turf.convertLength(rectangleWidth, 'meters', 'degrees');
                const rectWidth = MetersToDecimalDegrees(rectangleWidth, centroid.geometry.coordinates[1]);
                const rectHeight = turf.convertLength(calculRealPanelDimension(), 'meters', 'degrees'); // Prise en compte de l'angle du toit

                // Contiendra l'ensemble des panneaux
                const rectangles = [];

                // On simule la rotation du polygone pour avoir une bbox qui aura le bon bearing
                //const rotatedPoly = turf.transformRotate(currentPolygonInReducer, bearing, { pivot: centroid });
                //const bbox = turf.bbox(rotatedPoly);
                var bbox = turf.bbox(currentPolygonInReducer);
                var from = turf.point([bbox[0], bbox[1]]);
                var to = turf.point([bbox[2], bbox[3]]);

                const radius = turf.distance(from, to, { units: 'meters' });
                const circlePol = turf.circle(centroid, radius, { steps: 20, units: 'meters' });

                bbox = turf.bbox(circlePol);

                // On construit la grille de panneaux en prennant en compte un espacement de 2cm entre les panneaux
                // Les panneaux remplissent la bbox
                // Par défaut les panneaux ne sont pas sélectionnés
                let panelId = 1;
                let col = 0;
                let row = 0;
                let x= bbox[0] //+ offsetX;
                let y = bbox[1] //+ offsetY;
                while( x <= bbox[2] ) {
                    y = bbox[1] + 0;
                    var xDestination = turf.destination(turf.point([x, y]), rectangleWidth, 90, {units: 'meters'}).geometry.coordinates[0];
                    row = 0;
                    while(y <= bbox[3]) {
                        var yDestination = turf.destination(turf.point([x, y]), calculRealPanelDimension(), 0, {units: 'meters'}).geometry.coordinates[1];
                        const rectangle = turf.bboxPolygon([
                            x + espacement, y + espacement, xDestination, yDestination
                        ]);
                        y= yDestination;

                        const currentPanelId = panelId++;
                        rectangle.properties = {
                            panelId: currentPanelId,
                            selected: false,
                            column: col,
                            row: row
                        };

                        row++;
                        /*if (row * (rectHeight + espacement) > bbox[3] - bbox[1]) {
                            row = 0;
                            col++;
                        }*/

                        rectangles.push(rectangle);
                    }
                    col++
                    x=xDestination;
                }
                const acclines = [];
                let nbLinesHauteur = orientation === 0 ? 2 : 3;
                let nbLinesLargeur = orientation === 0 ? 3 : 2;

                // On crée la grille de rectangle qu'on met en place par rapport au bearing actuel
                const grid = turf.featureCollection(rectangles);
                const rotatedGrid = turf.transformRotate(grid, bearing, { pivot: centroid });

                let filteredRectangles = [];
                // On vérifie que les panneaux sont dans le polygone de base et respecte l'espacement de la limite du toit de 30cm
                for (let i = 0; i < rotatedGrid.features.length; i++) {
                    const rectangle = rotatedGrid.features[i];
                    if (turf.booleanWithin(rectangle, currentPolygonInReducer)) {

                        // Le but est de déterminer la distance le rectangle et le polygone
                        // On calcule la distance entre chaque point du rectangle et le polygone
                        let distancesToLines = [];

                        distancesToLines = (rectangle.geometry.coordinates[0] as []).map((corner, index) => {
                            const closestLinePoint = findClosestPointOnPolygonLine(currentPolygonInReducer, corner);
                            return turf.distance(turf.point(corner), turf.point(closestLinePoint));
                        });

                        // // On vérifie pour chaque point du rectangle qu'il est au moins à 30cm du polygone
                        const allDistancesAboveThreshold = distancesToLines.every((distance) => distance >= espacement_toit);
                        if (allDistancesAboveThreshold) {
                            filteredRectangles.push(rectangle);
                        }
                    }
                }

                for (let i = 0; i < filteredRectangles.length; i++) {
                    const rectangleLines = createLinesInsideRectangle(filteredRectangles[i], nbLinesHauteur, nbLinesLargeur, bearing, centroid);
                    acclines.push(...rectangleLines);
                }
                setLines(acclines);

                // On range les panneaux suivant leur distance par rapport au centre 
                // pour pouvoir sélectionner ceux le plus au centre du polygone
                filteredRectangles.sort((a, b) => {
                    const distanceA = turf.distance(turf.centroid(a), centroid);
                    const distanceB = turf.distance(turf.centroid(b), centroid);
                    return distanceA - distanceB;
                });

                // On prend les qty premiers panneaux et on les mets comme sélectionnés par défaut
                for (let i = 0; i < panelCount; i++) {
                    const rectangle = filteredRectangles[i];
                    if (rectangle) {
                        rectangle.properties.selected = true;
                        // const newSelectedPanels = [...selectedPanels, rectangle.properties.panelId];
                        // setSelectedPanels(newSelectedPanels);
                        // selectedPanels.push(rectangle.properties.panelId);
                        filteredRectangles[i] = rectangle;
                    } else {
                        //TODO message erreur toiture trop petite
                        setIsTooSmall(true);
                    }
                }

                // Si l'utilissateur souhaite mettre + de panneaux que l'espace disponible
                if (panelCount > filteredRectangles.length) {
                    // TODO voir avec Camille
                    // alert("Espace sur le toit insuffisant")
                }

                // On convertit en grille l'ensemble des rectangles sélectionnés
                const filteredGrid = turf.featureCollection(filteredRectangles);
                const selectedCount = filteredGrid.features.filter(feature => feature.properties.selected === true).length;
                dispatchPanelCountInCalepinage(selectedCount);
                setGlobalGrid(filteredGrid);

            } else {
                // Afficher la Global grid
                printGlobalGridOnPolygon();
            }
        }
    }

    function printGlobalGridOnPolygon () {
        if (map && map.current && map.current.getSource('grille')) {
            map.current.getSource('grille').setData(globalGridRef.current);
        } else {
            map.current.addSource('grille', {
                type: 'geojson',
                data: globalGrid
            });
            map.current.addLayer({
                id: 'rectangle',
                type: 'fill',
                source: 'grille',
                paint: {
                    'fill-color': {
                        property: 'selected',
                        type: 'categorical',
                        stops: [
                            [true, '#DBDFFF'],
                            [false, '#CECECE']
                        ],
                        default: '#CECECE'
                    },
                    'fill-opacity': {
                        property: 'selected',
                        type: 'categorical',
                        stops: [
                            [true, 0.8],
                            [false, 0.3]
                        ],
                        default: 0.3
                    },
                    'fill-outline-color': {
                        property: 'selected',
                        type: 'categorical',
                        stops: [
                            [true, '#4256FF'],
                            [false, '#3F415C']
                        ],
                        default: '#3F415C'
                    },
                }
            });

            // Gestion des clicks sur les rectangles
            // map.current.on('click', 'rectangle', rectangleSelection);

            map.current.addLayer({
                'id': 'rectangle-icon-layer-add',
                'type': 'symbol',
                'source': 'grille',
                'layout': {
                    // 'icon-image': 'suppr-icon',
                    'icon-image': [
                        'case',
                        ['==', ['get', 'selected'], true], 'suppr-icon',
                        ['==', ['get', 'selected'], false], 'add-icon',
                        'add-icon'
                    ],
                    'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        18, 0.1, // à zoom level 10, l'icône sera à la moitié de sa taille originale
                        24, 1.0  // à zoom level 18, l'icône sera à sa taille originale
                    ],
                    'icon-allow-overlap': true,
                    'icon-anchor': 'center'
                },
                'filter': ['==', ['get', 'panelId'], ''], // Filtre initial, ne sélectionne aucun rectangle
            });

            map.current.on('mouseover', 'rectangle', function (e) {
                map.current.getCanvas().style.cursor = 'pointer';
            });

            map.current.on('mousemove', 'rectangle', function (e) {
                // var rectangleId = e.features[0].properties.panelId;
                var rectangleId = e.features[0].properties.panelId;
                map.current.setFilter('rectangle-icon-layer-add', ['==', ['get', 'panelId'], '']);
                // if(rectangleId && e.features[0].properties.selected === false) {
                map.current.setFilter('rectangle-icon-layer-add', ['==', ['get', 'panelId'], rectangleId]);
                // }
                // map.current.setFilter('rectangle-icon-layer-add', ['==', ['get', 'panelId'], rectangleId]);
            });

            map.current.on('mouseleave', 'rectangle', function () {
                map.current.getCanvas().style.cursor = '';
                map.current.setFilter('rectangle-icon-layer-add', ['==', ['get', 'panelId'], '']);
            });
        }

        // GESTION DES LIGNES

        if (map && map.current && map.current.getSource('lines')) {
            map.current.getSource('lines').setData(turf.featureCollection(rectanglesLines));
        } else {
            map.current.addSource('lines', {
                type: 'geojson',
                data: turf.featureCollection(rectanglesLines)
            });

            map.current.addLayer({
                id: 'lines',
                type: 'line',
                source: 'lines',
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                paint: {
                    'line-color': '#4256FF',
                    'line-width': 0.4,
                }
            });
        }
    }


    // Retourne le point du polygone le plus proche du point spécifié
    function findClosestPointOnPolygonLine(polygon, point) {
        const polygonLines = polygon.geometry.coordinates[0];

        // Variables pour stocker le point le plus proche et la distance minimale
        let closestPoint = null;
        let minDistance = Number.MAX_VALUE;

        // Itérer à travers les segments de ligne du polygone
        for (let i = 0; i < polygonLines.length - 1; i++) {
            const lineStart = polygonLines[i];
            const lineEnd = polygonLines[i + 1];

            // Trouver le point projeté sur le segment de ligne
            const projectedPoint = turf.pointOnLine(turf.lineString([lineStart, lineEnd]), point, { units: 'meters' });

            // Calculer la distance entre le point donné et le point projeté
            const distance = turf.distance(turf.point(point), turf.point(projectedPoint.geometry.coordinates));

            // Mettre à jour le point le plus proche et la distance minimale si nécessaire
            if (distance < minDistance) {
                minDistance = distance;
                closestPoint = projectedPoint.geometry.coordinates;
            }
        }

        return closestPoint;
    }

    // retourne la matrice liée à la grille en cours
    function calculateFilteredMatrix(grid) {
        //console.log(grid);

        var bbox = turf.bbox(grid);
        let rectWidth;
        let rectHeight;

        rectWidth = turf.convertLength(rectangleWidth, "meters", "degrees");
        rectHeight = turf.convertLength(
            calculRealPanelDimension(),
            "meters",
            "degrees"
        );

        const numRows = Math.ceil((bbox[3] - bbox[1]) / (rectHeight + espacement));
        const numColumns = Math.ceil(
            (bbox[2] - bbox[0]) / (rectWidth + espacement)
        );

        const matrix = [];
        for (let i = 0; i < numRows; i++) {
            matrix.push(Array(numColumns).fill(0));
        }

        for (let i = 0; i < grid.features.length; i++) {
            const rectangle = grid.features[i];
            const coords = rectangle.geometry.coordinates[0];
            const row = Math.floor(
                (bbox[3] - coords[1][1]) / (rectHeight + espacement)
            );
            const col = Math.floor(
                (coords[0][0] - bbox[0]) / (rectWidth + espacement)
            );
            const isSelected = rectangle.properties.selected;
            if (isSelected) {
                matrix[row][col] = 1;
            }
        }

        const filteredMatrix = matrix.filter((row) =>
            row.some((value) => value !== 0)
        );

        return filteredMatrix;
    }

    // Pour ajuster la longueur du panneau en fonction de l'angle du toit
    function calculRealPanelDimension() {
        let accAngle: number = angle;
        if(angle >= 80) {
            accAngle = 70;
        }
        const angleInRadians = (accAngle * Math.PI) / 180;
        const sineValue = Math.cos(angleInRadians);
        let new_height = sineValue * rectangleHeight;
        return new_height;
    }

    function createLinesInsideRectangle(rectangle, divsWidth, divsHeight, bearing, centroid) {
        const rotatedRectangle = turf.transformRotate(rectangle, bearing * -1, { pivot: centroid });
        const [minX, minY, maxX, maxY] = turf.bbox(rotatedRectangle);
        const rectWidth = maxX - minX;
        const rectHeight = maxY - minY;
        const stepX = rectWidth / divsWidth;
        const stepY = rectHeight / divsHeight;

        let lines = [];

        // Lignes verticales
        for (let i = 1; i < divsWidth; i++) {
            const x = minX + i * stepX;
            const line = turf.lineString([[x, minY], [x, maxY]]);
            lines.push(line);
        }

        // Lignes horizontales
        for (let i = 1; i < divsHeight; i++) {
            const y = minY + i * stepY;
            const line = turf.lineString([[minX, y], [maxX, y]]);
            lines.push(line);
        }

        // Rotate lines
        for (let i = 0; i < lines.length; i++) {
            lines[i] = turf.transformRotate(lines[i], bearing, { pivot: centroid });
        }

        return lines;
    }


    //   useEffect(() => {
    //     if(recommandationCount < panelCountInCalepinage) {
    //         setCanShowCard(true);
    //     }
    //     setCanShowCard(false);
    //   }, [panelCountInCalepinage, recommandationCount]
    //   );

    const closeCard = () => {
        setCanShowCard(false);
        // canShowCard = false;
    };
    const closeCardTooSmall = () => {
        setCanShowCardTooSmall(false);
    };

    const closeCardTooBig = () => {
        // setCanShowCardTooBig(false);
        setIsTooBig(false);
    };

    const closeCardTooMuch = () => {
        setcanShowCardTooMuch(false);
    }



    return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <StyledMapBox ref={mapContainer} style={{ width: '100%', height: '100%' }} />
            <ContainerCard>
                {canShowCard && currentPolygonInReducer && panelCountInCalepinage && (recommandationCount < panelCountInCalepinage || recommandationCount > panelCountInCalepinage) && !isTooSmall &&
                    <Card style={{
                        backgroundColor: '#FFF1CC',
                        width: '95%',
                        zIndex: 1000,
                        fontSize: '0.85rem',
                    }}>
                        <Card.Body>
                            <div style={{ cursor: 'pointer', position: 'absolute', top: '5px', right: '6px' }} onClick={closeCard}>X</div>
                            <Card.Text>
                                {
                                    t(recommandationCount < panelCountInCalepinage ? 'add_panel' : 'remove_panel')
                                }
                            </Card.Text>
                        </Card.Body>
                    </Card>
                }
                {isTooSmall && canShowCarTooSmall && globalGrid?.features.length < recommandationCount &&
                    <Card style={{
                        backgroundColor: '#FFF1CC',
                        width: '95%',
                        zIndex: 1000,
                        fontSize: '0.85rem',
                    }}>
                        <Card.Body>
                            <div style={{ cursor: 'pointer', position: 'absolute', top: '5px', right: '6px' }} onClick={closeCardTooSmall}>X</div>
                            <Card.Text>
                                {isTooSmall ?
                                    'La surface de votre toiture permet l’installation de ' + globalGrid?.features.length + ' panneaux maximum. La rentabilité et la performance de votre installation seront re-calculés à la prochaine étape.' : null
                                }
                            </Card.Text>
                        </Card.Body>
                    </Card>
                }

                {canShowCardTooMuch && tooMuch && panelCountInCalepinage === MAX_PANEL_COUNT &&
                    <Card style={{
                        backgroundColor: '#FFF1CC',
                        width: '95%',
                        zIndex: 1000,
                        fontSize: '0.85rem',
                    }}>
                        <Card.Body>
                            <div style={{ cursor: 'pointer', position: 'absolute', top: '5px', right: '6px' }} onClick={closeCardTooMuch}>X</div>
                            <Card.Text>
                                {tooMuch ?
                                    'Vous avez atteint le nombre maximum de panneaux pour votre installation.' : null
                                }
                            </Card.Text>
                        </Card.Body>
                    </Card>
                }

                {isTooBig && 
                    <Card style={{
                        backgroundColor: '#FFF1CC',
                        width: '95%',
                        zIndex: 1000,
                        fontSize: '0.85rem',
                    }}>
                        <Card.Body>
                            <div style={{ cursor: 'pointer', position: 'absolute', top: '5px', right: '6px' }} onClick={closeCardTooBig}>X</div>
                            <Card.Text>
                                {isTooBig ?
                                    "La zone sélectionnée est trop grande. Veuillez tracer une surface plus petite." : null
                                }
                            </Card.Text>
                        </Card.Body>
                    </Card>
                }

            {isDrawingStarted &&  
                    <Card style={{
                        backgroundColor: '#FFF1CC',
                        width: '95%',
                        zIndex: 1000,
                        fontSize: '0.85rem',
                    }}>
                        <Card.Body>
                            <Card.Text>
                                { "Pour finaliser votre tracé, veuillez cliquer sur le premier point de votre zone d'installation ou double-cliquez sur le dernier point lorsque vous avez terminé." }
                            </Card.Text>
                        </Card.Body>
                    </Card>
                }
            </ContainerCard>
            {currentPolygonInReducer &&
                <ContainerCardForArea>
                    <span>Surface de la zone  {turf.area(currentPolygonInReducer).toFixed()} m2</span>
                </ContainerCardForArea>
            }
        </div>
    );
}

export default MapMapboxComponent;
