import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// Importing all locales
import enGlobal from "./locales/en-global.json";
import frGlobal from "./locales/fr-global.json";

// Header
import enHeader from "./components/shared/Headers/locales/en.json";
import frHeader from "./components/shared/Headers/locales/fr.json";

// Redirect modal
import frRedirectModal from "./components/shared/redirect-modal/locales/fr.json";
import enRedirectModal from "./components/shared/redirect-modal/locales/en.json";

// Home
import enHome from "./components/Home/locales/en.json";
import frHome from "./components/Home/locales/fr.json";

// Step Localization
import enLocalization from "./components/Steps/Localization/locales/en.json";
import frLocalization from "./components/Steps/Localization/locales/fr.json";

// Step Installation
import enInstallation from "./components/Steps/Installation/locales/en.json";
import frInstallation from "./components/Steps/Installation/locales/fr.json";

// Step Needs
import enNeedsPhase1 from "./components/Steps/Needs/phases/phase1/locales/en.json";
import frNeedsPhase1 from "./components/Steps/Needs/phases/phase1/locales/fr.json";
import enNeedsPhase2 from "./components/Steps/Needs/phases/phase2/locales/en.json";
import frNeedsPhase2 from "./components/Steps/Needs/phases/phase2/locales/fr.json";
import enRecommandationPhase1 from "./components/Steps/Recommandation/phases/phase1/locales/en.json";
import frRecommandationPhase1 from "./components/Steps/Recommandation/phases/phase1/locales/fr.json";
import enRecommandationPhase2 from "./components/Steps/Recommandation/phases/phase2/locales/en.json";
import frRecommandationPhase2 from "./components/Steps/Recommandation/phases/phase2/locales/fr.json";

// Step Design
import enDesign from "./components/Steps/Design/locales/en.json";
import frDesign from "./components/Steps/Design/locales/fr.json";

// Step Order
import enOrder from "./components/Steps/Recommandation/locales/en.json";
import frOrder from "./components/Steps/Recommandation/locales/fr.json";

// Header
import enMapbox from "./components/shared/calepinage/mapbox/locales/en.json";
import frMapbox from "./components/shared/calepinage/mapbox/locales/fr.json";
import { redirect } from "react-router-dom";

const FALLBACK_LANGUAGE = "fr";
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      fr: {
        global: frGlobal,
        header: frHeader,
        home: frHome,
        localization: frLocalization,
        installation: frInstallation,
        needsphase1: frNeedsPhase1,
        needsphase2: frNeedsPhase2,
        recommandationphase1: frRecommandationPhase1,
        recommandationphase2: frRecommandationPhase2,
        design: frDesign,
        order: frOrder,
        mapbox: frMapbox,
        redirectmodal: frRedirectModal
      },
      en: {
        global: enGlobal,
        header: enHeader,
        home: enHome,
        localization: enLocalization,
        installation: enInstallation,
        needsphase1: enNeedsPhase1,
        needsphase2: enNeedsPhase2,
        recommandationphase1: enRecommandationPhase1,
        recommandationphas2: enRecommandationPhase2,
        design: enDesign,
        order: enOrder,
        mapbox: enMapbox,
        redirectmodal: enRedirectModal
      },
    },
    fallbackLng: FALLBACK_LANGUAGE,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
