import styled from "styled-components";

const OBaseTinyButton = styled.button`
    font-style: normal;
    font-weight: 200;
    font-size: 30px;
    line-height: 140%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;

    border-radius: 100%;
    aspect-ratio: 1/1;^
    padding: 0px;
    text-align: center;

    cursor: pointer;
    outline: none;
    border: none;

    position: relative;

    transition: all 0.3s ease-in-out;
    color: ${(props) => props.theme.colors.onyx.onyxPower700}
    background: transparent;
    &:hover {
        background: transparent;
        
    }

    &:active {
        background: transparent;
    }
`;

export const OTinyButton = styled(OBaseTinyButton)<{
  mode?: string;
  background?: string;
  iconSize?: string;
}>`
  ${(props) => {
    switch (props.mode) {
      case "close":
        return `
                &:before {
                    content: "×";
                }
            `;
      case "left":
        return `
                &:before {
                    content: "<";
                }
            `;
      case "right":
        return `
                &:before {
                    content: ">";
                }
            `;
      case "plus":
        return `
                margin-left: 10px;
                &:before {
                    content: "+";
                }
            `;
      case "minus":
        return `
                margin-right: 10px;
                &:before {
                    content: "-";
                }
            `;
      case "+":
        return `
                &:before {
                    content: "+";
                }
            `;
      case "-":
        return `
                &:before {
                    content: "-";
                }
            `;
      case "icon":
        return ``;
      default:
        return `
                
                &:before {
                    content: "×";
                }
            `;
    }
  }}

  > span {
    font-size: ${(props) => { 
        return props.iconSize ? props.iconSize.toString() : "18";
    }}px;

  }

  ${(props) => {
    switch (props.background) {
      case "none":
        return `
                background: transparent;
            `;
      case "clay500":
        return `
                background: ${props.theme.colors.clay.clayPower500};
            `;
      case "clay600":
        return `
                background: ${props.theme.colors.clay.clayPower600};
            `;
      case "blue":
        return `
                background: ${props.theme.colors.primary.bluePower700};
                color: white;
                &:hover {
                  background: ${props.theme.colors.primary.bluePower600};
              }
            `;
      case "blue-alternate":
        return `
                background: ${props.theme.colors.onyx.onyxPower400};
                color: white;
                &:hover {
                  background: ${props.theme.colors.onyx.onyxPower500};
              }
            `;
      case "red":
        return `
                background: ${props.theme.colors.error.cherryPower700};
                color: white;
                &:hover {
                  background: ${props.theme.colors.error.cherryPower700};
              }
            `;
      case "yellow":
        return `
                background: ${props.theme.colors.secondary.yellowPower700};
                color: white;
                &:hover {
                  background: ${props.theme.colors.secondary.yellowPower700};
              }
            `;
      default:
        return "";
    }
  }}
`;

export const OTinyButtonLabelFree = styled(OBaseTinyButton)<{
  label: string;
  background?: string;
}>`
  &:before {
    content: "${(props) => props.label}";
  }

  ${(props) => {
    switch (props.background) {
      case "none":
        return `
                background: transparent;
            `;
      case "clay500":
        return `
                background: ${props.theme.colors.clay.clayPower500};
            `;
      case "clay600":
        return `
                background: ${props.theme.colors.clay.clayPower600};
            `;
      default:
        return "";
    }
  }}
`;


export const OTinyButtonCalepinage = styled(OTinyButton)`
  border-radius: 8px;
  border:1px solid ${ (props) =>  props.theme.colors.clay.clayPower600 };
`;
