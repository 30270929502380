import { ComponentStatus } from "../../components/shared/status/component-status";
import { kitsService } from "../../data/kits/services/kits.service";
import { CURRENT_ENV } from "../../utils/constants";
import { goToShopifyCart } from "../../utils/navigation/navigation-utils";
import { KitItem } from "../configurateur/virtual-cart/use-virtual-cart-items-to-add-hook";
import { KitState } from "./kit-state";

const initialState: KitState = {
  kitData: {
    status: ComponentStatus.idle,
  },
};

enum ActionType {
  createKit = "createKit",
}

export const createKitReducer = (state = initialState, action): KitState => {
  const { status, error, kitCreatedId } = action;

  switch (action.type) {
    case ActionType.createKit:
      return {
        ...state,
        kitData: {
          kitIdCreated: kitCreatedId,
          status: status,
          error: error,
        },
      };
    default:
      return { ...state };
  }
};

export const kitCreate =
  (kit: KitItem[], panelsMatrix?: number[][]) => async (dispatch) => {
    try {
      dispatch({
        type: ActionType.createKit,
        status: ComponentStatus.loading,
      });
      let cartList = "";
      let cartUrl = 'https://oscaro-power.com/cart/';
      let cartOptions = '?attribute[from]=configurateur';
      cartOptions += `&attribute[matrix]=${JSON.stringify(panelsMatrix)}`;
      cartOptions += '&storefront=true';
      // for (let i = 0; i < kit.length; i++) {
      //   cartList += kit[i].id_variant + ":" + kit[i].quantity + ",";
      // }
      for (let i = kit.length; i > 0; i--) {
        cartList += kit[i - 1].id_variant + ":" + kit[i - 1].quantity + ",";
      }
      cartList = cartList.slice(0, -1);
      // console.log('cartList', cartList);
      // // const id = await kitsService.createKit(kit, panelsMatrix);
      // window.top.postMessage({
      //   type: 'cartId',
      //   payload: id
      // }, '*');

      window.top.postMessage({
        type: 'cartList',
        payload: cartList
      }, '*');
      window.top.postMessage({
        type: 'cartUrl',
        payload: cartUrl + cartList + cartOptions
      }, '*');

      // goToShopifyCart(id);
      // dispatch({
      //   type: ActionType.createKit,
      //   status: ComponentStatus.loaded,
      //   kitCreatedId: id,
      // });
    } catch (_) {
      dispatch({
        type: ActionType.createKit,
        status: ComponentStatus.error,
      });
    }
  };
