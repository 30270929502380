import { useEffect } from "react";
import {
  AlimType,
  getPhaseNumberFromAlimType,
} from "../../components/Steps/Design/phases/phase2/sections_content";
import { ConfigurateurProductEntity } from "../../data/configurateur/services/entities/configurateur-product.entity";
import { ConfigurateurInverterEntity } from "../../data/configurateur/services/entities/inverters/configurateur-inverter.entity";
import { ConfigurateurPanelEntity } from "../../data/configurateur/services/entities/panels/configurateur-panel.entity";
import store from "../../store";
import { configurateurAccessoriesLoad } from "../../store/configurateur/accessories/configurateur-accessories-reducer";
import { usePanelCount } from "../configurateur/panels/use-panel-count.hook";
import { useFixationIds } from "../fixations/use-fixations-hook";
import { usePostcode } from "../location/location-hook";

/// Load Accessories on supervision or fixation changed only
/// because it covers all cases
export const useEffectAccessories = (
  alimentation: AlimType,
  panel?: ConfigurateurPanelEntity,
  inverter?: ConfigurateurInverterEntity,
  supervision?: ConfigurateurProductEntity,
  fixationNumber?: number
) => {
  const panelCount = usePanelCount();
  const fixationIds = useFixationIds();
  const postcode = usePostcode();

  useEffect(() => {
    if (
      alimentation &&
      panel &&
      inverter &&
      supervision &&
      fixationIds?.id_legacy &&
      fixationIds?.id_attribute_legacy &&
      fixationNumber
    ) {
      store.dispatch(
        configurateurAccessoriesLoad(
          panel?.legacy_presta_product_id,
          panel?.legacy_presta_product_attribute_id,
          panelCount,
          inverter?.legacy_presta_product_id,
          inverter?.legacy_presta_product_attribute_id,
          inverter?.configurator_quantity,
          fixationIds?.id_legacy,
          fixationIds?.id_attribute_legacy,
          supervision?.legacy_presta_product_id,
          supervision?.legacy_presta_product_attribute_id,
          getPhaseNumberFromAlimType(alimentation),
          inverter?.tracker,
          fixationNumber,
          postcode
        )
      );
    }
  }, [
    supervision?.legacy_presta_product_id,
    fixationIds?.id_legacy,
    fixationIds?.id_attribute_legacy,
    fixationNumber,
    panelCount,
  ]);
};
