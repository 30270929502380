export const PanelsSizeRatio = {
    1 : 8,
    2 : 8,
    3 : 8,
    4 : 8,
    5 : 7,
    6 : 7,
    7 : 7,
    8 : 7,
    9 : 6,
    10 : 6,
    11 : 6,
    12 : 6,
    13 : 6,
    14 : 6,
    15 : 5,
    16 : 5,
    17 : 5,
    18 : 5,
    19 : 4,
    20 : 4,
    21 : 4,
    22 : 4,
    23 : 3.5,
    24 : 3.5,
    25 : 3.5,
}

export function bigNumberPanelsSizeRatio(matrixSize: number) {

    let ratio;

    if (matrixSize > 25){
        ratio = 3;
    }

    if (matrixSize > 50){
        ratio = 2;
    }

    if (matrixSize > 75){
        ratio = 1;
    }

    return ratio;
} 