import { useTranslation } from "react-i18next";
import { Modal } from "../../../../../Styled/modals";
import { STEP_DESIGN } from "../../../../../../store/reducer";
import { VirtualCartBody } from "../../../../../shared/virtual-cart/body/virtual-cart-body";
import { theme } from "../../../../../Styled/GlobalStyle";

export const VirtualCartPopup = (props: { show: boolean; onClose: any }) => {
  const { t } = useTranslation([STEP_DESIGN, "global"]);

  return (
    <Modal
      show={props.show}
      title={t("virtualCart.popup.title")}
      onClose={props.onClose}
    >
      <VirtualCartBody
        itemColor={{
          price: theme.colors.onyx.onyxPower700,
          category: theme.colors.onyx.onyxPower700,
        }}
      />
    </Modal>
  );
};
