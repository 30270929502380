import styled from "styled-components";

export const VirtualCartContainer = styled.div`
  width: 100%;

  .virtualCartSection {
    padding: 24px 0px 0px 0px;
  }

  hr {
    opacity: 1;
    margin: 0px;
    border-top: 1px solid ${(props) => props.theme.colors.clay.clayPower200};
    padding: 24px 0px 0px 24px;
  }
`;
