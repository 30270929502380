import { ConfigurateurProductEntity } from "../data/configurateur/services/entities/configurateur-product.entity";

export const getTotalPrice = (
  product?: ConfigurateurProductEntity,
  quantity?: number
) => {
  if (!product) return 0;
  const qty = quantity || product.configurator_quantity;
  return parseFloat((product.price * qty).toFixed(2));
};

export const getTotalDiscountedPrice = (
  product?: ConfigurateurProductEntity,
  quantity?: number
) => {
  if (!product) return 0;
  const qty = quantity || product.configurator_quantity;
  return parseFloat((product.price_discounted * qty).toFixed(2));
};
