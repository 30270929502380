import styled from "styled-components";
import { motion } from "framer-motion";

export const GeocoderInputContainer = styled(motion.div)`
  position: relative;
  width: 100%;
`;

export const GeolocationIcon = styled.div`
  position: absolute;
  right: 0.8rem;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%) scale(0.8);
  z-index: 15;
  width: 24px;
  height: 24px;
  opacity: 1;
`;

export const SpinnerIcon = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: auto;
  width: 100%;
  z-index: 15;
  img {
    opacity: 0.5;
  }
`;

export const SuggestionsContainer = styled(motion.ul)`
  margin-top: 15px;
  width: 100%;
  position: absolute;
  z-index: 99;
  border-radius: 7px;
  background-color: ${(props) => props.theme.colors.clay.clayPower200};
  max-height: 20rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  list-style: none;
  overflow-y: auto;
`;

export const SuggestionItem = styled.li`
  padding: 8px 8px;
  border-radius: 4px;
  cursor: pointer;
  word-break: break-word;
  color: ${(props) => props.theme.colors.onyx.onyxPower700};

  &:hover {
    opacity: 0.8;
    border-radius: 4px;
  }
`;
