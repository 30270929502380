import React from "react";

import { useTranslation } from "react-i18next";
import { STEP_RECOMMANDATION } from "../../../../../store/reducer";
import {
  OFormGroup,
  OFormLabel,
  OSelect,
  OSelectOption,
  OSelectOptionIcon,
} from "../../../../Styled/Forms";
import { FixationTypeDto } from "../../../../../data/fixations/dtos/fixation-type-dto";
import { ImageUtils } from "../../../../../utils/images/image-utils";
import { SelectableValue } from "../../../../Styled/Forms/models/selectable-value";

const RecommandationFixationSelect: React.FC<{
  fixations: FixationTypeDto[];
  selected: FixationTypeDto;
  setFixation: Function;
}> = ({ fixations, selected, setFixation }) => {
  const { t } = useTranslation([STEP_RECOMMANDATION + "phase1", "global"]);

  const selectableFixations: SelectableValue<FixationTypeDto>[] = fixations.map(
    (value) => ({
      value: value,
      label: value.name,
      icon: ImageUtils.buildFixationImage(value.img),
    })
  );

  const Option = (props) => {
    return (
      <OSelectOption {...props}>
        <img src={props.data.icon} alt="icon" height="20px" />
        {props.data.value.name}
      </OSelectOption>
    );
  };

  return (
    <OFormGroup>
      <OSelectOptionIcon
        src={ImageUtils.buildFixationImage(selected.img)}
        alt="icon"
      />
      <OFormLabel>{t("fixationLabel")}</OFormLabel>
      <OSelect
        styles={{
          indicatorsContainer: (provided) => ({
            ...provided,
            "& svg": {
              fill: "#ABB0DB",
            },
          }),
        }}
        options={selectableFixations}
        value={selectableFixations.find(
          (selectable) => selectable.value.id === selected.id
        )}
        onChange={(selectedOption: SelectableValue<FixationTypeDto>) =>
          setFixation(selectedOption.value)
        }
        components={{ Option }}
        className="react-select-container with-icon"
        classNamePrefix="react-select"
      />
    </OFormGroup>
  );
};

export default RecommandationFixationSelect;
