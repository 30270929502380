import styled from "styled-components";
import { OCol, ORow } from "../../../../Styled/CustomGrid";
import { motion } from "framer-motion";

const BaseCard = styled(motion.div)<{ isexpanded?: string }>`
  user-select: none;
  overflow: hidden;
  width: 100%;
  max-height: ${(props) => (props.isexpanded ? "fit-content" : "25rem")};
  min-height: ${(props) => (props.isexpanded ? "fit-content" : "15rem")};
  height: fit-content;
  border-radius: 8px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .icon-switch {
    display: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    cursor: pointer;
    gap: 0px;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;

    .icon-switch {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: flex-start;
      width: 32px;
      height: 32px;
      aspect-ratio: 1;
      margin-top: 5px;
      border-radius: 50%;
      padding: 0.5rem;

      &:hover {
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
      }

      img {
        transform: ${(props) =>
          props.isexpanded === "true"
            ? "rotateY(180deg) rotateX(180deg)"
            : "rotateY(0deg) rotateX(0deg)"};
        transition: transform 0.5s ease-in-out;
      }
    }
  }
`;

export const PotentialCard = styled(BaseCard)`
  background: ${(props) => props.theme.gradients.yellow};

  .icon-container {
    position: relative;

    .potential-icon {
      position: absolute;
      top: 0;
      right: 2%;
      width: auto;

      @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
        display: none;
      }
    }
  }
`;

export const ProfitCard = styled(BaseCard)`
  z-index: 21;
  * {
    color: ${(props) => props.theme.colors.clay.clayPower200};
  }

  .up-arrow {
    width: 1.5rem;
    height: 1.5rem;
  }

  background: ${(props) => props.theme.gradients.primary};
  margin-top: 25% !important;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    margin-top: 0 !important;

    .profit-card-expanded .recommandation-simulation-rentabilite-selling-ask {
      display: none;
    }
    .profit-card-expanded .recommandation-simulation-rentabilite-eco-ask {
      display: none;
    }
  }
`;

export const BackOCol = styled(OCol)`
  position: absolute;
  width: 100%;
  height: ${(props) => props.theme.sizes.content};
  z-index: -1;

  @media (max-width: 768px) {
    position: relative;
    z-index: 1;
    margin-top: 1rem;
    min-height: 250px;
    height: 100%;
  }
`;

export const Container3D = styled(motion.span)`
  height: 100%;
  margin: 0 auto;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    height: 25rem;
  }
`;

export const BackORow = styled(ORow)`
  display: flex;
  flex-direction: row;
  background-color: transparent;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    .recommandation-simulation-estimation-viewmore {
      padding-bottom: 16px;
    }
  }
`;

export const StatsContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  // margin: 10% 0;
  width: 100%;
  height: auto;
  z-index: 20;
`;

export const StatsContainerMobile = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 15px auto;
  width: 100%;
  height: fit-content;
  gap: 10px;

  .label {
    color: ${(props) => props.theme.colors.clay.clayPower700};
  }
`;

export const StatsDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex;
  margin-left: 0.5rem;
  //padding-top: 30px;
  margin-bottom: 20px;

  p {
    color: ${(props) => props.theme.colors.onyx.onyxPower600};
  }

  h1,
  h2,
  h3 {
    color: ${(props) => props.theme.colors.onyx.onyxPower500};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    margin-left: 0;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
  }

  .recommandation-simulation-estimation-viewmore:hover {
    cursor: pointer;
  }
`;

export const StatsLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  min-height: 10px;
  height: 100%;
  margin-bottom: 1rem;
  position: relative;

  //transform: translateY(30px);

  ::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: ${(props) => props.theme.colors.onyx.onyxPower700};
    right: 0;
  }

  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: ${(props) => props.theme.colors.onyx.onyxPower700};
    border-radius: 50%;
    right: -5px;
    bottom: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    display: none;
  }
`;

export const Oinfo = styled.div`
  background-color: ${(props) => props.theme.colors.secondary.yellowPower400};
  padding: 6px 12px;
  text-align: left;
  border-radius: 8px;
  width:fit-content;
  margin-bottom: 10px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    text-align: center;
    width: 100%;
  }
`;