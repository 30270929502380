import styled from "styled-components";
import { motion } from "framer-motion";

interface OBodyGeneralProps {
  color?: string;
  center?: string;
}

const OBodyGeneral = styled(motion.p)<OBodyGeneralProps>`
  line-height: 150%;
  font-style: inherit;
  margin: 0;
  padding: 0;
  text-align: ${(props) => (props.center ? "center" : "inherit")};
  color: ${(props) => props.color || props.theme.colors.onyx.onyxPower600};

  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: none;
`;

export const OBody1 = styled(OBodyGeneral)`
  font-weight: 700;
  font-size: 20px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    font-size: 18px;
  }
`;

export const OBody2 = styled(OBodyGeneral)`
  font-weight: 400;
  font-size: 20px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    font-size: 18px;
  }
`;

export const OBody3 = styled(OBodyGeneral)`
  font-size: 20px;
  font-weight: 300;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    font-size: 18px;
  }
`;

export const OBody4 = styled(OBodyGeneral)`
  font-weight: 700;
  font-size: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    font-size: 14px;
  }
`;

export const OBody5 = styled(OBodyGeneral)`
  font-weight: 400;
  font-size: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    font-size: 14px;
  }
`;

export const OBody6 = styled(OBodyGeneral)`
  font-weight: 300;
  font-size: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    font-size: 14px;
  }
`;

export const OBody7 = styled(OBodyGeneral)`
  font-weight: 700;
  font-size: 14px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    font-size: 12px;
  }
`;

export const OBody8 = styled(OBodyGeneral)`
  font-weight: 400;
  font-size: 14px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    font-size: 12px;
  }
`;

export const OBody9 = styled(OBodyGeneral)`
  font-weight: 300;
  font-size: 14px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    font-size: 12px;
  }
`;

export const OBody10 = styled(OBodyGeneral)`
  font-weight: 700;
  font-size: 12px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    font-size: 11px;
  }
`;

export const OBody11 = styled(OBodyGeneral)`
  font-weight: 400;
  font-size: 12px;

  /*@media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    font-size: 11px;
  }*/
`;

export const OBody12 = styled(OBodyGeneral)`
  font-weight: 300;
  font-size: 12px;

  /*@media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    font-size: 11px;
  }*/
`;

export const OBodyPotentielAddress = styled(OBodyGeneral)`
  font-weight: 300;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    font-size: 14px;
  }
`;
