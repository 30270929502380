import { useSelector } from "react-redux";
import { RootState } from "../..";
import { usePanelCount } from "../../../hooks/configurateur/panels/use-panel-count.hook";

export const useVirtualCartKitPower = (): number => {
  const panelsData = useSelector(
    (state: RootState) => state?.configurateurPanels?.panelsData?.selected
  );
  const nominalPower = panelsData?.power;
  const panelNumber = usePanelCount();
  if (!nominalPower || !panelNumber) return null;
  // divide by 1000 to have power into kw
  return (panelNumber * nominalPower) / 1000;
};
