import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { OCol, ORow } from "../../../../Styled/CustomGrid";
import {
  STEP_INSTALLATION,
  STEP_RECOMMANDATION,
} from "../../../../../store/reducer";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { OMainButton } from "../../../../Styled/Buttons";
import { RecommandationReduxStepData } from "../../constants";
import {
  Azimut,
  Pente,
  Phase4Props,
  RecommandationPhase1ReduxStepData,
  StructureTypes,
  recommandationPhase1Config,
} from "./constants";
import { OBody2, OBody8 } from "../../../../Styled/Texts";
import { AlertCard } from "./styled";
import { motion } from "framer-motion";
import { fade } from "../../../../Styled/GlobalAnimations";
import {
  InstallationReduxStepData,
  isFlatRoofType,
  isRoofType,
  isStuctureType,
} from "../../../Installation/constants";
import MaterialSelect from "./material_select";
import PenteSelect from "./pente_select";
import AzimutSelect from "./azimut_select";
import { OForm } from "../../../../Styled/Forms";
import RecommandationFixationSelect from "./recommandation_fixation_select";
import { FixationTypeDto } from "../../../../../data/fixations/dtos/fixation-type-dto";
import { FixationRepository } from "../../../../../data/fixations/repositories/fixation-repository";

import RoofPreviewContainer from "../../../../shared/roof_preview";
import { trackDataLayer } from "../../../../../utils/tracking/tracking-utils";

const RecommandationPhase1: React.FC<Phase4Props> = ({
  setData,
  onContinue,
  onChanges,
}) => {
  const { t } = useTranslation([STEP_RECOMMANDATION + "phase1", "global"]);

  const config = recommandationPhase1Config;

  // const needsData: NeedReduxStepData =
  //   useSelector((state: RootState) => state.step.steps[STEP_NEEDS].data) || {};

  const recommandationData: RecommandationReduxStepData =
    useSelector(
      (state: RootState) => state.step.steps[STEP_RECOMMANDATION].data
    ) || {};

  const [material, setMaterial] = React.useState<StructureTypes>(
    recommandationData.phase1?.material || config.defaultValues.material
  );
  const [pente, setPente] = React.useState<Pente>(
    recommandationData.phase1?.pente || config.defaultValues.pente
  );
  const [azimut, setAzimut] = React.useState<Azimut>(
    recommandationData.phase1?.azimut || config.defaultValues.azimut
  );

  const installationData: InstallationReduxStepData = useSelector(
    (state: RootState) => state.step.steps[STEP_INSTALLATION].data || {}
  );

  const fixations = new FixationRepository().getFixationsByEmplacement(
    installationData.installationId
  );
  const [fixation, setFixation] = React.useState<FixationTypeDto>(
    fixations
      .map((fixation) => fixation.id)
      .includes(recommandationData.phase1?.fixation?.id)
      ? recommandationData.phase1?.fixation
      : fixations[0]
  );

  const [seeMaterial, setSeeMaterial] = React.useState<boolean>(true);
  const [seePente, setSeePente] = React.useState<boolean>(true);
  const [seeFixation, setSeeFixation] = React.useState<boolean>(false);

  useEffect(() => {
    const data = {
      material: material,
      pente: pente,
      azimut: azimut,
      fixation: fixation,
    } as RecommandationPhase1ReduxStepData;
    setData(data);
    onChanges(data);
  }, [material, pente, azimut, fixation]);

  const handleContinue = () => {
    trackDataLayer("EmplacementSelected", {
      emplacement: {
        type: seeMaterial ? material : undefined,
        inclinaison: seePente ? pente : undefined,
        orientation: azimut,
        fixation: seeFixation ? fixation : undefined,
      },
    });
    onContinue();
  };

  useEffect(() => {
    if (!installationData.installation) {
      return;
    }

    const isRoof = isRoofType(installationData.installation);
    const isFlatRoof = isFlatRoofType(installationData.installation);
    const isStructure = isStuctureType(installationData.installation);

    if (isRoof) {
      setSeeMaterial(true);
      if (isFlatRoof) {
        setSeePente(false);
        setPente(Pente.PENTE_0_5);
      } else {
        setSeePente(true);
      }
    } else {
      setSeeMaterial(false);
      setSeePente(false);
    }

    if (isStructure) {
      setSeeMaterial(true);
      setSeeFixation(false);
    } else {
      setSeeFixation(true);
      setSeeMaterial(false);
    }
  }, [installationData.installation]);

  return (
    <ORow className="h-100 container-reco">
      <OCol
        sm={config.gridConfig.left[0]}
        md={config.gridConfig.left[1]}
        lg={config.gridConfig.left[2]}
        className="d-flex flex-column justify-content-between align-items-center light-bg gap-3 order-2 order-md-1 p-0 p-md-3 overflow-auto h-auto h-md-100"
      >
        <motion.span className="d-flex flex-column justify-content-start align-items-center w-100 gap-3">
          <OBody2 center="true">
            {isRoofType(installationData.installation)
              ? t("titleToiture")
              : t("titleSupport")}
          </OBody2>
          <motion.div
            className="d-flex flex-column justify-content-center align-items-center w-100"
            {...fade()}
          >
            <OForm className="gap-3">
              {seeMaterial && (
                <MaterialSelect material={material} setMaterial={setMaterial} />
              )}
              {seeFixation && (
                <RecommandationFixationSelect
                  fixations={fixations}
                  selected={fixation}
                  setFixation={setFixation}
                />
              )}
              {seePente && <PenteSelect pente={pente} setPente={setPente} />}
              <AzimutSelect
                azimut={azimut}
                setAzimut={setAzimut}
                supportMode={!isRoofType(installationData.installation)}
              />
            </OForm>
          </motion.div>
          <AlertCard {...fade()}>
            <OBody8>
              <Trans>{t("alert")} </Trans>
            </OBody8>
          </AlertCard>
        </motion.span>
        <motion.span className="d-flex flex-column justify-content-start align-items-center w-100">
          <OMainButton
            className="w-100 recommandation-emplacement-next-btn"
            primary
            onClick={handleContinue}
          >
            {t("global:validate")}
          </OMainButton>
        </motion.span>
      </OCol>
      <OCol
        sm={config.gridConfig.right[0]}
        md={config.gridConfig.right[1]}
        lg={config.gridConfig.right[2]}
        // justify-content-between
        // justify-content-arround
        // justify-content-center align-items-center
        className="d-flex flex-md-column-reverse justify-content-center neutral-bg order-1 order-md-2 p-4 p-md-0 mt-0 position-relative"
      >
        {/* <RecommandationCard starUrl={config.starUrl} /> */}
        <RoofPreviewContainer roofMaterial={material} />

        {/* <PanelsGridContainer canvasMode={true} mapMode={false} /> */}
      </OCol>
    </ORow>
  );
};

export default RecommandationPhase1;
