import axios from "axios";
import { CURRENT_ENV } from "../../../utils/constants";
import { KitDetailDto } from "../dtos/kit-detail.dto";
import { KitDto } from "../dtos/kit.dto";

const routeKitDetails = "/kits/:id/details";
const routeKitConfiguredSolar = "/kits/configured/solar";

export class KitsApi {
  async getKitDetails(id: string): Promise<KitDetailDto> {
    const result = await axios.get<KitDetailDto>(
      CURRENT_ENV.API_OP_BASE_URL + routeKitDetails.replaceAll(":id", id)
    );
    return result.data;
  }

  async createKit(
    products: {
      id_product: string;
      id_variant: string;
      quantity: number;
    }[],
    panelsMatrix?: number[][]
  ): Promise<KitDto> {
    const body = {
      products: products,
      panels_matrix: panelsMatrix,
    };
    const result = await axios.post<KitDto>(
      CURRENT_ENV.API_OP_BASE_URL + routeKitConfiguredSolar,
      body
    );
    return result.data;
  }
}
