import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { STEP_INSTALLATION } from "../../../store/reducer";
import { InstallationType } from "../../Steps/Installation/constants";
import { RoofPreviewContainerProps } from "./constants";
import {RoofPreviewsByMaterialImageLinks, StructurePreviewByInstallatinTypeImageLinks} from "../../Steps/Recommandation/phases/phase1/constants";
import { RoofPreviewPictureContainer } from "./styled";
import RecommandationCard from "../../Steps/Recommandation/phases/phase1/recommandation_card";
import { svgsPath } from "../../../utils/constants";

const RoofPreviewContainer:React.FC<RoofPreviewContainerProps> = ({
    roofMaterial
}) => {

    const installation: InstallationType = useSelector((state: RootState) => state.step.steps[STEP_INSTALLATION].data.installation);

    const pictureLink = installation === "Roof" ? RoofPreviewsByMaterialImageLinks[roofMaterial] : StructurePreviewByInstallatinTypeImageLinks[installation];

    return(
        <RoofPreviewPictureContainer className="w-100">
            <RecommandationCard starUrl={svgsPath + 'star.svg'} />
            <img src={pictureLink} alt="roof preview" style={{marginBottom: "-50px"}} className="mw-100 mb-md-0 pt-sm-3 pt-md-0"/>
        </RoofPreviewPictureContainer>
    )

};

export default RoofPreviewContainer;