import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BudgetHandler } from "./components/shared/budget-handler/budget-handler";
import { EnvironmentHandler } from "./components/shared/env-handler/environment-handler";
import { FullscreenLoader } from "./components/shared/fullscreen-loader/fullscreen-loader";
import { ComponentStatus } from "./components/shared/status/component-status";
import {
  InstallationTypeId,
  getInstallationTypeFromLocation,
} from "./components/Steps/Installation/constants";
import { usePresetConfigurationId } from "./hooks/navigation/use-preset-configuration-hash.hook";
import { useSkipParam } from "./hooks/navigation/use-skip-param.hook";
import store, { RootState } from "./store";
import { setMatriceCalepinageHorsMap } from "./store/calepinage/calepinageHorsMapReducer";
import { setPanelCountInCalepinage } from "./store/calepinage/calepinageReducer";
import { configurateurPresetConfigurationLoad } from "./store/configurateur/preset-configuration/configurateur-preset-configuration-reducer";
import { STEP_INSTALLATION, setStepData } from "./store/reducer";
import { skipToConfiguration } from "./utils/navigation/navigation-utils";
import { GLOBAL_GO_TO_END } from "./components/shared/constant";

export const App = (props) => {
  const { t } = useTranslation("global");
  const dispatch = useDispatch();
  const skipParam = useSkipParam();
  const presetConfigurationId = usePresetConfigurationId();

  const configPresetData = useSelector((state: RootState) => {
    return state.configurateurPresetConfiguration.presetConfigurationData;
  });
  const kitCreationData = useSelector((state: RootState) => {
    return state.createKit.kitData;
  });
  // On App Load, should be called once
  useEffect(() => {
    if (presetConfigurationId) {
      store.dispatch(
        configurateurPresetConfigurationLoad(presetConfigurationId)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (presetConfigurationId && !configPresetData?.details) {
      return;
    }
    // Set default matrix
    const defaultMatrix = configPresetData?.details?.matrix;
    if (configPresetData?.details?.matrix) {
      store.dispatch(setMatriceCalepinageHorsMap(defaultMatrix));
    } else if (configPresetData?.details?.panel_quantity) {
      dispatch(
        setPanelCountInCalepinage(configPresetData?.details?.panel_quantity)
      );
    }

    const location = getInstallationTypeFromLocation(
      configPresetData?.details?.location
    );
    // Set default location
    if (configPresetData?.details?.location) {
      dispatch(
        setStepData({
          step: STEP_INSTALLATION,
          data: {
            installation: location,
            installationId: InstallationTypeId[location],
          },
        })
      );
    }
    if (skipParam || GLOBAL_GO_TO_END) {
      skipToConfiguration(dispatch, location);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    configPresetData?.details?.details.length,
    configPresetData?.details?.location,
  ]);

  if (presetConfigurationId) {
    if (
      configPresetData?.status === ComponentStatus.idle ||
      configPresetData?.status === ComponentStatus.loading
    ) {
      return <FullscreenLoader message={t("preset.loader_message")} />;
    }
  }

  if (kitCreationData.status === ComponentStatus.loading) {
    return <FullscreenLoader message={t("kitCreation.loader_message")} />;
  }
  return (
    <EnvironmentHandler>
      <BudgetHandler>{props.children}</BudgetHandler>
    </EnvironmentHandler>
  );
};
