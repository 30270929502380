import { useSelector } from "react-redux";
import { RootState } from "../../..";
import { InstallHelpEntity } from "../../../../data/prestashop/services/entities/product-entity";

export const useVirtualCartInstallHelps = (): InstallHelpEntity[] => {
  return useSelector((state: RootState) => {
    const installHelpsData = state.configurateurInstallHelps?.installHelpsData;
    if (installHelpsData?.error) {
      return [];
    }
    return installHelpsData?.installHelpsSelected
      ?.filter((element) => element?.checked)
      ?.map((element) => element.installHelp);
  });
};
