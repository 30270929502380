import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  isDev,
  isLocal,
  isProduction,
  setProductionEnv,
} from "../../../utils/constants";

export const EnvironmentHandler = (props) => {
  const [searchParams] = useSearchParams();
  const selectedEnv = searchParams.get("env");
  useEffect(() => {
    if (isProduction) {
      return;
    }
    if (isLocal || isDev) {
      if (selectedEnv === "preprod") {
        return setProductionEnv();
      }
    }
  }, [selectedEnv, searchParams]);
  return props.children;
};
