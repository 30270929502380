import { FixationTypeDto } from "../../../../../data/fixations/dtos/fixation-type-dto";
import { imgsPath, svgsPath } from "../../../../../utils/constants";

export interface Phase4Props {
  setData: React.Dispatch<React.SetStateAction<any>>;
  onContinue: () => void;
  onChanges: (data: RecommandationPhase1ReduxStepData) => void;
}

export interface RecommandationPhase1ReduxStepData {
  azimut: Azimut;
  pente: Pente;
  material: StructureTypes;
  fixation: FixationTypeDto;
}

export enum StructureTypes {
  TUILE_MECANIQUE_GALBEE = "tuileMecaniqueGalbee",
  TUILE_MECANIQUE_PLATE = "tuileMecaniquePlate",
  BAC_ACIER = "bacAcier",
  FIBROCIMENT = "fibrociment",
  TUILE_ECAILLE = "tuileEcaille",
  ARDOISE = "ardoise",
}

export enum FixationType {
  WOOD = "WOOD",
}

export const FixationTypeImagesLinks = {
  WOOD: imgsPath + "fixations/bois.jpg",
};

export const StructureTypesImagesLinks = {
  tuileMecaniqueGalbee: imgsPath + "structures/tuile-meca-galbé.jpg",
  tuileMecaniquePlate: imgsPath + "structures/tuile-meca-plate.jpg",
  bacAcier: imgsPath + "structures/acier.jpg",
  fibrociment: imgsPath + "structures/fibrociment.jpg",
  tuileEcaille: imgsPath + "structures/ecaille.jpg",
  ardoise: imgsPath + "structures/ardoise.jpg",
};

export const RoofPreviewsByMaterialImageLinks = {
  tuileMecaniqueGalbee: imgsPath + "roofPreviews/tuile.png",
  tuileMecaniquePlate: imgsPath + "roofPreviews/tuile-plate.png",
  bacAcier: imgsPath + "roofPreviews/bac-acier.png",
  fibrociment: imgsPath + "roofPreviews/fibrociment.png",
  tuileEcaille: imgsPath + "roofPreviews/tuile-ecaille.png",
  ardoise: imgsPath + "roofPreviews/ardoise.png",
};

export const StructurePreviewByInstallatinTypeImageLinks = {
  FlatRoof: imgsPath + "roofPreviews/flat-roof.png",
  Pergola: imgsPath + "roofPreviews/pergola.png",
  Shelter: imgsPath + "roofPreviews/shelter.png",
  Ground: imgsPath + "roofPreviews/ground.png",
};

export enum StructureTypesId {
  tuileMecaniqueGalbee = 12088,
  tuileMecaniquePlate = 12087,
  bacAcier = 48,
  fibrociment = 49,
  tuileEcaille = 47,
  ardoise = 4060,
}

export enum AzimutId {
  SUD = 0,
  SUD_EST = -45,
  EST = -90,
  NORD_EST = -135,
  NORD = 180,
  NORD_OUEST = 135,
  OUEST = 90,
  SUD_OUEST = 45,
}

export enum Azimut {
  SUD = "SUD",
  SUD_EST = "SUD_EST",
  EST = "EST",
  NORD_EST = "NORD_EST",
  NORD = "NORD",
  NORD_OUEST = "NORD_OUEST",
  OUEST = "OUEST",
  SUD_OUEST = "SUD_OUEST",
}

export enum AzimutRotate {
  SUD = 0,
  SUD_EST = 45,
  EST = 90,
  NORD_EST = 135,
  NORD = 180,
  NORD_OUEST = -135,
  OUEST = -90,
  SUD_OUEST = -45,
}

export const AzimutImagesLinks = {
  SUD: svgsPath + "azimuts/azimut-0.svg",
  SUD_EST: svgsPath + "azimuts/azimut-0.svg",
  EST: svgsPath + "azimuts/azimut-0.svg",
  NORD_EST: svgsPath + "azimuts/azimut-0.svg",
  NORD: svgsPath + "azimuts/azimut-0.svg",
  NORD_OUEST: svgsPath + "azimuts/azimut-0.svg",
  OUEST: svgsPath + "azimuts/azimut-0.svg",
  SUD_OUEST: svgsPath + "azimuts/azimut-0.svg",
};

export enum PenteId {
  PENTE_0_5 = 0,
  PENTE_1524 = 24,
  PENTE_2534 = 34,
  PENTE_3545 = 45,
  PENTE_90 = 90,
}

export enum Pente {
  PENTE_0_5 = "PENTE_0_5",
  PENTE_1524 = "PENTE_1524",
  PENTE_2534 = "PENTE_2534",
  PENTE_3545 = "PENTE_3545",
  PENTE_90 = "PENTE_90",
}

export const PenteImagesLinks = {
  PENTE_0_5: svgsPath + "pentes/pente-0.svg",
  PENTE_1524: svgsPath + "pentes/pente-15-24.svg",
  PENTE_2534: svgsPath + "pentes/pente-25-34.svg",
  PENTE_3545: svgsPath + "pentes/pente-35-45.svg",
  PENTE_90: svgsPath + "pentes/pente-90.svg",
};

export const recommandationPhase1Config = {
  gridConfig: {
    left: [12, 6, 4],
    right: [12, 6, 8],
  },
  defaultValues: {
    azimut: Azimut.SUD,
    pente: Pente.PENTE_2534,
    material: StructureTypes.TUILE_MECANIQUE_GALBEE,
    fixation: undefined,
  },
  starUrl: svgsPath + "star.svg",
};
