import { ConfigurateurProductEntity } from "../../data/configurateur/services/entities/configurateur-product.entity";
import store from "../../store";

type ConfigurationPresetItem<T> = {
  selected?: T;
  quantity?: number;
};
export const getConfigurationPresetItem = <
  T extends ConfigurateurProductEntity
>(
  items: T[]
): ConfigurationPresetItem<T> => {
  const kitDetails =
    store.getState().configurateurPresetConfiguration.presetConfigurationData
      ?.details;
  if (kitDetails?.details.length <= 0) return;
  const selected = items.find((i) => {
    return kitDetails?.details
      .map((d) => d.id_ecommerce_variant)
      .includes(i.id);
  });
  const quantity = kitDetails?.details.find(
    (d) => d.id_ecommerce_variant === selected?.id
  )?.quantity;
  return {
    selected: selected,
    quantity: quantity,
  };
};
