import domtoimage from "dom-to-image";

/**
 * Converts a number or string value to a string format with commas for every 3rd digit.
 * @param {number | string} value - The value to be cleaned.
 * @return {string} - The cleaned value.
 */
export const cleanUnitValue = (value: number | string): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * Converts a number or string value to a string format with spaces for every 3rd digit.
 * If the value is falsy, an empty string is returned.
 * @param {number | string} value - The value to be cleaned.
 * @return {string} - The cleaned value or an empty string.
 */
export const cleanMoneyValue = (value: number | string): string => {
  if (!value) return "";
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

/**
 * Exports the first element with the class 'exportable' as a PNG image using the dom-to-image library.
 * @return {Promise} - A Promise that resolves with the data URL of the image or rejects with an error.
 */
export const exportMap = () => {
  const node = document.getElementsByClassName("exportable")[0];
  return new Promise((resolve, reject) => {
    domtoimage
      .toPng(node)
      .then((dataUrl) => {
        resolve(dataUrl);
      })
      .catch((error) => {
        console.error("oops, something went wrong!", error);
        reject(error);
      });
  });
};

export const redirectToUrl = (url: string) => {
  window.location.href = url;
};

export const convertJsonToXml = (obj): string => {
  let xml = "";
  for (let prop in obj) {
    xml += obj[prop] instanceof Array ? "" : "<" + prop + ">";
    if (obj[prop] instanceof Array) {
      for (let array in obj[prop]) {
        xml += "\n<" + prop + ">\n";
        xml += convertJsonToXml(Object(obj[prop][array]));
        xml += "</" + prop + ">";
      }
    } else if (typeof obj[prop] == "object") {
      xml += convertJsonToXml(Object(obj[prop]));
    } else {
      xml += obj[prop];
    }
    xml += obj[prop] instanceof Array ? "" : "</" + prop + ">\n";
  }
  xml = xml.replace(/<\/?[0-9]{1,}>/g, "");
  return xml;
};
