export function createMinimizedMatrix(n: number): number[][] {

    // Calculer le nombre de lignes et de colonnes nécessaires
    const numRows = (n<=4) ? 1 : Math.floor(Math.sqrt(n));
    const numCols = (n<=4) ? Math.max(Math.ceil(n / numRows), 4) : Math.ceil(n / numRows);
    
    // Initialiser la matrice
    const matrix: number[][] = [];

    // Remplir la matrice avec des "1"
    for (let i = 0; i < numRows - 1; i++) {
        const row: number[] = Array(numCols).fill(1);
        matrix.push(row);
    }

    // Remplir la dernière ligne avec des "0" pour équilibrer la matrice
    const lastRow: number[] = Array(numCols).fill(0);
    const remainingOnes = n - (numRows - 1) * numCols;
    for (let i = 0; i < remainingOnes; i++) {
        lastRow[i] = 1;
    }
    matrix.push(lastRow);

    return matrix;
}