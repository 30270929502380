import React, { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { Sections } from "../constants";
import { STEP_DESIGN } from "../../../../../../store/reducer";
import { sectionVariants } from "../styled";
import { useSelector } from "react-redux";
import store, { RootState } from "../../../../../../store";
import { OBody11, OH3 } from "../../../../../Styled/Texts";
import { OActionButton } from "../../../../../Styled/Buttons/OActionButton";
import { AlimType } from "./constants";
import { SectionAlimentationType } from "./styled";
import { configurateurAlimentationSelect } from "../../../../../../store/configurateur/alimentation/configurateur-alimentation-reducer";
import {
  ConfigurateurPopupContext,
  ConfigurateurPopups,
} from "../configurateur-modals-context";

const AlimentationTypeComponent: React.FC = () => {
  const currentStepName = STEP_DESIGN;
  const currentSectionName = Sections.ALIMENTATION_TYPE;
  const { t } = useTranslation([currentStepName, "global"]);

  const alimentation = useSelector(
    (state: RootState) => state.configurateurAlimentation.alimentationData.type
  );

  const { setPopup } = useContext(ConfigurateurPopupContext);
  return (
    <SectionAlimentationType
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={sectionVariants}
      id={currentSectionName + "_anchor"}
    >
      <OH3 center="true">
        <Trans>{t("phase_2.sections." + currentSectionName + ".title")}</Trans>
      </OH3>

      <motion.div className="section__buttons">
        <OActionButton
          isactive={alimentation === AlimType.MONOPHASE ? "true" : "false"}
          onClick={() => {
            store.dispatch(configurateurAlimentationSelect(AlimType.MONOPHASE));
          }}
        >
          {t("phase_2.sections." + currentSectionName + ".type.monophase")}
        </OActionButton>
        <OActionButton
          isactive={alimentation === AlimType.THREE_PHASE ? "true" : "false"}
          onClick={() => {
            store.dispatch(
              configurateurAlimentationSelect(AlimType.THREE_PHASE)
            );
          }}
        >
          {t("phase_2.sections." + currentSectionName + ".type.three_phase")}
        </OActionButton>
      </motion.div>
      <OBody11>
        <Trans>
          {t("phase_2.sections." + currentSectionName + ".subtitle")}
        </Trans>
      </OBody11>
      <OBody11
        className="alimentation-viewmore conception-configuration-alimentation-viewmore"
        style={{ textDecoration: "underline" }}
        onClick={() => setPopup(ConfigurateurPopups.popupAlimentation)}
      >
        <Trans>
          {" "}
          {t("phase_2.sections." + currentSectionName + ".info.label")}
        </Trans>
      </OBody11>
    </SectionAlimentationType>
  );
};

export default AlimentationTypeComponent;
