import { Trans, useTranslation } from "react-i18next";
import { OBody5, OH3 } from "../../../../../Styled/Texts";
import { Sections } from "../constants";
import { Section } from "../sections_content/styled";
import { sectionVariants } from "../styled";
import {
  STEP_DESIGN,
  STEP_INSTALLATION,
  setStepData,
} from "../../../../../../store/reducer";
import {
  InputSelect,
  SelectOption,
} from "../../../../../shared/inputs/selects/input-select";

import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";
import {
  InstallationType,
  InstallationTypeId,
  InstallationTypeImageLinks,
  isRoofType,
} from "../../../../Installation/constants";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { StructureSelect } from "./structure/structure-select";
import { FixationSelect } from "./fixations/fixation-select";

const Container = styled(Section)`
  .configurateur-input-installation-emplacement {
    width: 100%;
  }
`;

export const SectionEmplacement = () => {
  const step = STEP_DESIGN;
  const section = Sections.EMPLACEMENT;
  const { t } = useTranslation([step, "global"]);
  const tInstallation = useTranslation([STEP_INSTALLATION, "global"]).t;
  const dispatch = useDispatch();

  const emplacement = useSelector(
    (state: RootState) => state.step.steps[STEP_INSTALLATION].data?.installation
  );

  const optionsEmplacement: SelectOption<InstallationType>[] = Object.values(
    InstallationType
  ).map((installation) => {
    return {
      value: installation,
      label: tInstallation(`type.${installation}`),
      icon: InstallationTypeImageLinks[installation],
    };
  });
  return (
    <Container
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={sectionVariants}
      id={section + "_anchor"}
    >
      {/* <OBody5 center="true">
        <Trans>{t("phase_2.sections." + section + ".title")}</Trans>
      </OBody5> */}
      <OH3 center="true">
        <Trans>{t("phase_2.sections." + section + ".title")}</Trans>
      </OH3>
      <InputSelect
        className="configurateur-input-installation-emplacement"
        label={t("phase_2.sections." + section + ".input.title")}
        options={optionsEmplacement}
        selection={emplacement}
        onSelect={(selection) => {
          dispatch(
            setStepData({
              step: STEP_INSTALLATION,
              data: {
                installation: selection.value,
                installationId: InstallationTypeId[selection.value],
              },
            })
          );
        }}
      />
      {isRoofType(emplacement) ? (
        <StructureSelect />
      ) : (
        <FixationSelect emplacementId={InstallationTypeId[emplacement]} />
      )}
    </Container>
  );
};
