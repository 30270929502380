import { RootState } from "../..";
import { ComponentStatus } from "../../../components/shared/status/component-status";
import { configurateurService } from "../../../data/configurateur/services/configurateur-service";
import { ConfigurateurPanelEntity } from "../../../data/configurateur/services/entities/panels/configurateur-panel.entity";
import { getConfigurationPresetItem } from "../../../utils/preset/configuration-preset.utils";

import { ConfigurateurPanelsState } from "./configurateur-panels-state";

const initialState: ConfigurateurPanelsState = {
  panelsData: {
    status: ComponentStatus.idle,
  },
};

enum ActionType {
  loadPanels = "loadPanels",
  selectPanel = "selectPanel",
}

export const configurationPanelsReducer = (
  state = initialState,
  action
): ConfigurateurPanelsState => {
  const { data, status, error, selected } = action;

  switch (action.type) {
    case ActionType.loadPanels:
      return {
        ...state,
        panelsData: {
          data: data || state?.panelsData?.data,
          status: status,
          error: error,
          selected: selected || state?.panelsData?.selected,
        },
      };
    case ActionType.selectPanel:
      return {
        ...state,
        panelsData: {
          ...state.panelsData,
          data: data || state?.panelsData?.data,
          selected: selected,
        },
      };
    default:
      return { ...state };
  }
};

export const configurateurPanelsLoad = () => async (dispatch, getStore) => {
  try {
    dispatch({
      type: ActionType.loadPanels,
      status: ComponentStatus.loading,
    });
    const state = getStore() as RootState;
    const panels = await configurateurService.getPanels();

    const preset = getConfigurationPresetItem<ConfigurateurPanelEntity>(panels);
    const selected =
      state.configurateurPanels?.panelsData?.selected ||
      preset?.selected ||
      panels.find((panel) => panel.is_oscaro_choice) ||
      panels[0];

    const panelsWithPriceDelta = panels.map((panel) => {
      return {
        ...panel,
        price_delta: panel.price_discounted - selected.price_discounted,
      };
    });
    dispatch({
      type: ActionType.loadPanels,
      status: ComponentStatus.loaded,
      data: panelsWithPriceDelta,
      selected: selected,
    });
  } catch (_) {
    dispatch({
      type: ActionType.loadPanels,
      status: ComponentStatus.error,
    });
  }
};

export const configurateurOnPanelSelected =
  (panelId: string) => (dispatch, getState) => {
    const panelsData = getState().configurateurPanels.panelsData;
    const newSelectedPanel = panelsData?.data?.find(
      (panel) => panel.id === panelId
    );
    const panelsWithPriceDelta = panelsData.data.map((panel) => {
      return {
        ...panel,
        price_delta: panel.price_discounted - newSelectedPanel.price_discounted,
      };
    });
    if (newSelectedPanel && panelsData?.selected?.id !== newSelectedPanel?.id) {
      dispatch({
        type: ActionType.selectPanel,
        selected: newSelectedPanel,
        data: panelsWithPriceDelta,
      });
    }
  };
