import styled from "styled-components";
import { theme } from "../GlobalStyle";
import { OBody10 } from "../Texts/OBody";

export const OBadge = styled(OBody10)`
  width: fit-content;
  height: fit-content;
  background: ${() => theme.colors.secondary.yellowPower700};
  text-align: center;
  border-radius: 20px;
  padding: 2px 8px 2px 8px;
  color: ${() => theme.colors.clay.clayPower200};
  opacity: 0.98;
`;
