import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ILocation } from "../../data/location/dtos/location.dto";
import { initialLocalizationStateState } from "./localization-state";
import createDebouncedAsyncThunk from "../../utils/create-debounced-async-thunk";
import { LocationRepository } from "../../data/location/repositories/location-repository";
import { LatLng } from "leaflet";

export const getSuggestionsAsync = createDebouncedAsyncThunk(
  "autocomplete/getSuggestions",
  async (text: string, thunkApi) => {
    if (text) {
      return await new LocationRepository().getLocationFromText(text);
    } else {
      return [];
    }
  },
  200
);

export const getReversePositionAsync = createAsyncThunk<ILocation, LatLng>(
  "autocomplete/getReversePositions",
  async (position, thunkApi) => {
    thunkApi.dispatch(initSuggestions());
    thunkApi.dispatch(initError());
    const response = await new LocationRepository().getLocationFromLatLng(
      position.lat,
      position.lng
    );
    return response;
  }
);

export const getCurrentPositionAsync = createDebouncedAsyncThunk(
  "autocomplete/getCurrentPositions",
  async (_, thunkApi) => {
    thunkApi.dispatch(initSuggestions());
    thunkApi.dispatch(initError());
    const response =
      await new LocationRepository().getLocationFromCurrentPosition();
    return response;
  },
  100
);

// Redux Toolkit slice
export const localizationSlice = createSlice({
  name: "localization",
  initialState: initialLocalizationStateState,
  reducers: {
    initSuggestions: (state) => {
      return {
        ...state,
        suggestions: [],
      };
    },
    initError: (state) => {
      return {
        ...state,
        error: null,
      };
    },
    setError: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        error: action.payload,
      };
    },
    setCenterAndZoom: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        needToCenterAndZoom: action.payload,
      };
    },
    setZoom: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        zoom: action.payload,
      };
    },
    changeText: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        text: action.payload,
      };
    },
    locationSelected: (state, action: PayloadAction<ILocation>) => {
      return {
        ...state,
        location: action.payload,
      };
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getSuggestionsAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSuggestionsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.suggestions = action.payload;
      })
      .addCase(getSuggestionsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = "geocoder_search_error";
      })
      .addCase(getReversePositionAsync.fulfilled, (state, action) => {
        state.needToCenterAndZoom = false;
        state.location = action.payload;
        state.text = action.payload.display_name;
      })
      .addCase(getReversePositionAsync.rejected, (state, action) => {
        state.error = "geocoder_reverse_error";
      })
      .addCase(getCurrentPositionAsync.fulfilled, (state, action) => {
        state.location = action.payload;
        state.needToCenterAndZoom = true;
        state.text = action.payload.display_name;
      })
      .addCase(getCurrentPositionAsync.rejected, (state, action) => {
        if (action.error.message === "geocoder_gps_error") {
          state.error = action.error.message;
        } else {
          state.error = "geocoder_reverse_error";
        }
      });
  },
});

export const selectLocation = (location: ILocation) => async (dispatch) => {
  dispatch(initError());
  dispatch(initSuggestions());
  dispatch(setCenterAndZoom(true));
  dispatch(locationSelected(location));
  dispatch(changeText(location.display_name));
};

export const textChanged = (text: string) => async (dispatch) => {
  dispatch(initError());
  dispatch(initSuggestions());
  dispatch(changeText(text));
};

export const {
  changeText,
  locationSelected,
  initSuggestions,
  initError,
  setCenterAndZoom,
  setZoom,
} = localizationSlice.actions;

export default localizationSlice;
