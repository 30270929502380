import { Variants } from "framer-motion";
import {
  PanelType,
  SectionAdministrationHelpData,
  SectionAlimentationTypeData,
  SectionBatteryData,
  SectionCalepinageHorsToitureTypeData,
  SectionCalepinageToitureMobileTypeData,
  SectionConfirmData,
  SectionInstallationHelpData,
  SectionLightningArresterData,
  SectionSolarKitData,
  SectionSupervisingData,
  SectionWarrantyData,
  SectionWiringData,
} from "./sections_content";

export interface DesignPhase2ReduxStepData extends SectionConfig {
  panelType?: PanelType;
  total?: number;
  panelCount?: number;
}

export enum Sections {
  EMPLACEMENT = "emplacement",
  ALIMENTATION_TYPE = "alimentation_type",
  SOLAR_KIT = "solar_kit",
  WIRING = "wiring",
  SUPERVISING = "supervising",
  LIGHTNING_ARRESTER = "lightning_arrester",
  WARRANTY = "warranty",
  BATTERY = "battery",
  ADMINISTRATION_HELP = "administration_help",
  INSTALLATION_HELP = "installation_help",
  CALEPINAGE_HORS_TOITURE = "calepinage_hors_toiture",
  CALEPINAGE_TOITURE_MOBILE = "calepinage_toiture_mobile",
  CONFIRM = "confirm",
}

export interface SectionConfig {
  [Sections.CALEPINAGE_HORS_TOITURE]?: SectionCalepinageHorsToitureTypeData;
  [Sections.CALEPINAGE_TOITURE_MOBILE]?: SectionCalepinageToitureMobileTypeData;
  [Sections.ALIMENTATION_TYPE]?: SectionAlimentationTypeData;
  [Sections.SOLAR_KIT]?: SectionSolarKitData;
  [Sections.WIRING]?: SectionWiringData;
  [Sections.SUPERVISING]?: SectionSupervisingData;
  [Sections.LIGHTNING_ARRESTER]?: SectionLightningArresterData;
  [Sections.WARRANTY]?: SectionWarrantyData;
  [Sections.BATTERY]?: SectionBatteryData;
  [Sections.ADMINISTRATION_HELP]?: SectionAdministrationHelpData;
  [Sections.INSTALLATION_HELP]?: SectionInstallationHelpData;
  [Sections.CONFIRM]?: SectionConfirmData;
}

export enum MobilePanelType {
  model1 = "model1",
  model2 = "model2",
}
export interface DesignPhase2Props {
  onContinue: () => void;
  onReturn: () => void;
  canReturn: boolean;
}

export const designPhase2Config = {
  defaultValues: {
    panelType: "CLASSIC" as PanelType,
    total: 0,
  },
  sectionGap: "6rem",
  sectionGapFirstChild: "3rem",
  sectionConfig: {
    [Sections.CALEPINAGE_HORS_TOITURE]: {
      display: true,
    },
    [Sections.CALEPINAGE_TOITURE_MOBILE]: {
      display: true,
    },
    [Sections.ALIMENTATION_TYPE]: {
      display: true,
    },
    [Sections.SOLAR_KIT]: {
      display: true,
    },
    [Sections.WIRING]: {
      display: true,
      panel_box: {
        value: [10, 25],
        sup: [-1, 34],
      },
      box_grid: {
        value: [5, 25],
        sup: [-1, 146],
      },
    },
    [Sections.SUPERVISING]: {
      display: true,
      included: false,
    },
    [Sections.LIGHTNING_ARRESTER]: {
      display: true,
      with: false,
      withSup: 149,
    },
    [Sections.WARRANTY]: {
      display: true,
      with: false,
      withSup: 249,
    },
    [Sections.BATTERY]: {
      display: true,
      with: false,
      withSup: 2500,
    },
    [Sections.CONFIRM]: {
      display: true,
      checked: false,
    },
    [Sections.ADMINISTRATION_HELP]: {
      display: true,
    },
    [Sections.INSTALLATION_HELP]: {
      // Hide Dealt
      display: false,
    },
  },
  gridConfig: {
    left: [12, 8, 8],
    right: [12, 4, 4],
  },
};

export const getDesignPhase2DefaultValues = () => {
  const defaultValues = designPhase2Config.defaultValues;
  return {
    ...defaultValues,
  };
};

export const matrixMobileVariants: Variants = {
  // hidden: {
  //   opacity: 0,
  //   y: 100,
  // },
  visible: (index) => ({
    // opacity: 1,
    // y: 0,
    transition: {
      delay: index === 0 ? 0 : index * 0.1, // Délai basé sur l'index de la carte
      ease: "easeIn",
      duration: 0.5,
    },
  }),
  // selected: {
  //   opacity: 1,
  //   scale: 1.05,
  //   y: 0,
  //   border: "solid 1px " + theme.colors.primary.bluePower700,
  //   transition: {
  //     duration: 0.5,
  //     ease: "easeIn",
  //   },
  // },
  // disabled: {
  //   opacity: 0.5,
  //   scale: 1,
  //   y: 0,
  //   transition: {
  //     duration: 0.5,
  //     ease: "easeIn",
  //   },
  // },
};
