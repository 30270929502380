import { Variants } from "framer-motion";
import { localizationConfig } from "../../../Localization/constants";
import {
  Azimut,
  AzimutId,
  Pente,
  PenteId,
  recommandationPhase1Config,
} from "../phase1/constants";
import { needPhase1Config } from "../../../Needs/phases/phase1/constants";

export interface RecommandationPhase2Mapping {
  potential?: PotentialCardData;
  profit?: ProfitCardData;
  stats?: StatsData;
}

export interface recommandationDataPhase2Response {
  apiResponseTab: APITabResponse;
}

export interface RecommandationPhase2ReduxStepData {
  mapping?: RecommandationPhase2Mapping;
  response?: recommandationDataPhase2Response;
  apiPanelsNumber?: number;
}

export const RecommandationPhase2Config = {
  gridConfig: {
    main: [12, 4],
    back: [12],
  },
  lineHeight: "40%",
  lineDuration: 1,
  url: "https://dimensionneur.oscaro-power.com",
  api: {
    budget: needPhase1Config.defaultValues.budget,
    equipment: [],
    lat: localizationConfig.mapConfig.position[0],
    lng: localizationConfig.mapConfig.position[1],
    angle: recommandationPhase1Config.defaultValues.pente,
    orientation: recommandationPhase1Config.defaultValues.azimut,
    country: "fr",
    defaultBudgetWithoutEquipments: 100,
    optimalAngle: PenteId[Pente.PENTE_2534.toString() as unknown as PenteId],
    optimalOrientation: AzimutId[Azimut.SUD.toString() as unknown as AzimutId],
  },
  dynamicPotentialLabelBreakpoints: 80, // in %
  defaultValues: {
    apiPanelsNumber: 0,
    mapping: {
      potential: {},
      profit: {},
      stats: {
        panelsNumber: 0,
        kw_installe: 0,
        prix: 0,
      },
    },
  },
};

export const containerVariants = {
  hidden: { height: "0%", opacity: 0 }, // État initial de l'animation
  visible: {
    // height: RecommandationPhase2Config.lineHeight,
    height: 'auto',
    opacity: 1,
    transition: {
      duration: RecommandationPhase2Config.lineDuration,
      ease: "easeOut",
    },
  },
};

export const needCardLeftVariants: Variants = {
  initial: {
    opacity: 0,
    x: 0,
    y: -100,
  },
  animate: {
    opacity: 1,
    x: 0,
    y: 0,
  },
  transition: { duration: 0.7, ease: "easeOut" } as any,
};

export const needCardRightVariants: Variants = {
  initial: {
    opacity: 0,
    x: 0,
    y: 200,
  },
  animate: {
    opacity: 1,
    y: 0,
    x: 0,
  },
  transition: { duration: 1.2, ease: "easeOut" } as any,
};

export interface PotentialCardData {
  error?: any;
  annualProduction?: number;
  percentageLost?: number;
  sunHour?: number;
  percentage?: number;
}

export interface ProfitCardData {
  error?: any;
  gain?: number;
  resale?: number;
  savings?: number;
}

export interface StatsData {
  error?: any;
  panelsNumber?: number;
  kw_installe?: number;
  prix?: number;
}

export interface APIRequest {
  budget?: number;
  équipment?: string;
  lat: number;
  lng: number;
  angle?: PenteId;
  orientation?: AzimutId;
  country?: string;
}

export interface APITabResponse {
  error?: any;
  consoJ?: number[];
  max?: Max;
  max_soc?: MaxSoc;
  max_sélection?: number;
  max_sélection_PbatJ?: number[];
  max_sélection_PbatJ_AC?: number[];
  power?: Power; // MAIN
  power_sélection?: number;
  prodJ?: ProdJ;
  solar?: Solar;
  solar_sélection?: number;
}

export interface Max {
  [key: string]: number[];
}

export interface MaxSoc {
  [key: string]: number[];
}

export interface Power {
  [key: string]: number[];
}

export interface ProdJ {
  [key: string]: number[];
}
export interface Solar {
  [key: string]: number[];
}

export interface Phase1Props {
  setData: React.Dispatch<React.SetStateAction<any>>;
  onChanges: (data: any) => void;
  onContinue: () => void;
  onReturn: () => void;
}

export interface FetchReturn {
  error?: any;
  potential: PotentialCardData;
  profit: ProfitCardData;
  stats: StatsData;
  apiResponseTab: APITabResponse;
}
