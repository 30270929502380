import { ConfigurateurProductVariantDto } from "../../../api/dtos/configurateur-product-variant.dto";
import { ConfigurateurProductEntity } from "../configurateur-product.entity";

export class ConfigurateurAccessoryEntity extends ConfigurateurProductEntity {
  static fromDto(
    dto: ConfigurateurProductVariantDto
  ): ConfigurateurAccessoryEntity {
    return {
      ...ConfigurateurProductEntity.fromDto(dto),
    };
  }
}

export class ConfigurateurAccessoriesEntity {
  data: ConfigurateurAccessoryEntity[];
  parafoudreRequired: boolean;
}
