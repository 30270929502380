import styled from "styled-components";

export const PanelPictureContainer = styled.img<{ratio: number}>`

    max-width: ${({ ratio }) => ratio}vw;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.md}px) {
        max-width: ${({ ratio }) => ratio * 1.5}vw;
    }

    &.opacity-25 {
        opacity: 0.25;
    }

`;

export const PanelFadedContainer = styled.div`

    position: relative;
    float: right;

    & > #remaining {
        position: absolute;
        font-weight: 550;
        top:0px;
        right:0px;
        left:0px;
        margin: 0 auto;
        display: flex;
        justify-content: center; // Centre les enfants horizontalement
        align-items: center; // Centre les enfants verticalement
        flex: 0;
        color:#00559F;
        width: 36px;
        height: 36px;
        flex-shrink: 0;
        border-radius: 50%;
        border: 2px solid #00559F;
        opacity: 0.8;
        background-color: #fff;
        z-index: 1;
    }

`;