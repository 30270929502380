import styled from "styled-components";
import { theme } from "../../Styled/GlobalStyle";

const color = theme.colors.primary.bluePower700;

export const VerticalDimensionElements = styled.div`

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;

    & .arrow{
        position: relative;
        width: 2px;
        height: 100%;
        display: flex;
        // justify-content: space-between;
        // align-items: center;
    }

    & .body{
        height: 20px;
        padding: 0 5px;
        margin-top: -10px;
        background: ${color};
        text-align: center;
        color: white;
        font-size: 14px;
        font-weight: 100;
        font-family: "Poppins", sans-serif;
    }

    & .line{
        width: 2px;
        height: 100%;
        background: ${color};
    }

    & .arrow .top,
    .arrow .bottom {
        color: ${color};
        font-size: 18px;
        position: absolute;
    }

    & .arrow .top {
        top:0px;
        left: 0px;
        transform: translate(-45%, -40%);

    }

    & .arrow .bottom {
        bottom: 0px;
        left: 0px;
        transform: translate(-44%, 40%);
    }

`;