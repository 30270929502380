import {
  STEP_LOCALIZATION,
  STEP_INSTALLATION,
  STEP_NEEDS,
  STEP_RECOMMANDATION,
  STEP_DESIGN,
} from "../../store/reducer";

export const trackPageStarted = (title: string, page: string) => {
  const dataLayer = (window as any).dataLayer || [];
  dataLayer.push({
    event: "PageStarted",
    data: {
      title: title,
      path: page,
      date: new Date().toUTCString(),
    },
  });
};

export const trackPageExited = (title: string, page: string) => {
  const dataLayer = (window as any).dataLayer || [];
  dataLayer.push({
    event: "PageExited",
    data: {
      title: title,
      path: page,
      date: new Date().toUTCString(),
    },
  });
};

export const trackDataLayer = (layerName: string, data: any) => {
  const dataLayer = (window as any).dataLayer || [];
  dataLayer.push({
    event: layerName,
    data: data,
  });
};

/// Return the page name following [step] and [phase]
/// I did this to avoid a refactoring on navigation
export const getPageInfoFromStep = (
  step: string,
  phase: number
): { title: string; page: string } => {
  switch (step + phase) {
    case STEP_LOCALIZATION + "1":
      return {
        title: "Localisation",
        page: "/localisation",
      };
    case STEP_INSTALLATION + "1":
      return {
        title: "Installation",
        page: "/installation",
      };
    case STEP_NEEDS + "1":
      return {
        title: "Budget",
        page: "/besoins/budget",
      };
    case STEP_NEEDS + "2":
      return {
        title: "Equipements",
        page: "/besoins/equipements",
      };
    case STEP_RECOMMANDATION + "1":
      return {
        title: "Emplacement",
        page: "/recommandation/emplacement",
      };
    case STEP_RECOMMANDATION + "2":
      return {
        title: "Simulation",
        page: "/recommandation/simulation",
      };
    case STEP_DESIGN + "1":
      return {
        title: "Calepinage",
        page: "/conception/calepinage",
      };
    case STEP_DESIGN + "2":
      return {
        title: "Configuration",
        page: "/conception/configuration",
      };
    default:
      return {
        title: "Default",
        page: step + "/" + phase,
      };
  }
};

export const getTrackingPageClassPrefix = (step: string, phase: number) => {
  const pageInfo = getPageInfoFromStep(step, phase);
  return pageInfo.page.replaceAll("/", "-");
};
