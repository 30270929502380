import { t } from "i18next";
import { ConfigurateurInverterEntity } from "../../../../../../data/configurateur/services/entities/inverters/configurateur-inverter.entity";
import store from "../../../../../../store";
import { configurateurOnOnduleurSelected } from "../../../../../../store/configurateur/onduleurs/configurateur-onduleurs-reducer";
import { svgsPath } from "../../../../../../utils/constants";
import { formatNumber } from "../../../../../../utils/convert-number-locale";
import { OBadge } from "../../../../../Styled/badges/OBadge";
import { OSecondaryButton } from "../../../../../Styled/Buttons";
import { theme } from "../../../../../Styled/GlobalStyle";
import { OBody12, OBody7, OBody9 } from "../../../../../Styled/Texts";
import { OnduleurItemContainer } from "./onduleur-styled";

export const OnduleurItem: React.FC<{
  onduleur: ConfigurateurInverterEntity;
  showItems: boolean;
  setShowItems;
}> = ({ onduleur, showItems, setShowItems }) => {
  return (
    <OnduleurItemContainer
      onClick={() => {
        setShowItems(!showItems);
        store.dispatch(configurateurOnOnduleurSelected(onduleur.id));
      }}
    >
      <img
        className="onduleur-item-img"
        src={onduleur.parent.default_image?.url}
        alt={onduleur.parent.title}
      />
      <OBody12>{onduleur.configurator_name || onduleur.parent.title}</OBody12>
      {onduleur.price_delta !== 0 && (
        <OBody7
          color={theme.colors.onyx.onyxPower500}
          className="onduleurTrailing"
        >
          {onduleur.price_delta > 0 && "+"}
          {formatNumber(onduleur.price_delta)}
        </OBody7>
      )}
      <img
        className="onduleur-item-arrow-right"
        src={svgsPath + "arrow-right.svg"}
        alt=""
      />
    </OnduleurItemContainer>
  );
};

export const OnduleurSelectedItem: React.FC<{
  onduleur: ConfigurateurInverterEntity;
  showItems: boolean;
  setShowItems;
}> = ({ onduleur, showItems, setShowItems }) => {
  return (
    <OnduleurItemContainer className="selectedOnduleur">
      <img
        className="onduleur-item-img"
        src={onduleur?.parent.default_image?.url}
        alt={onduleur?.parent.title}
      />

      <OBody9>{onduleur?.parent.title}</OBody9>
      <OSecondaryButton
        className="onduleurTrailing"
        onClick={() => setShowItems(!showItems)}
      >
        {!showItems ? t("global:update") : t("global:close")}
      </OSecondaryButton>

      {onduleur?.discount > 0 && (
        <OBadge className="onduleur-item-discount">
          {t("global:discount", { discount: onduleur?.discount })}
        </OBadge>
      )}
    </OnduleurItemContainer>
  );
};
