import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

export const OContainer = styled(Container)`
  height: 100%;
  padding: 0px;
  @media (max-width: 768px) {
     padding: 1.5rem;
  }
`;

export const ORow = styled(Row)`
  padding: 0px;
  margin: 0;
`;

export const OCol = styled(Col)`
  //padding: 2rem;
  margin: 0px;
  box-sizing: border-box;
  display: inline-block;

  &.no-padding {
    padding: 0px;
  }

  // @media (max-width: 768px) {
  //   padding: 0.5rem;
  // }

  &.configContainer {
    padding: 1.5rem;
    @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      max-width: inherit;
      width: auto;
    }
  }
`;

export const ColCalepinage = styled(Col)<{orientation: number}>`
    display: flex;
    position: relative;
    justify-content: center; // Centre les enfants horizontalement
    align-items: center; // Centre les enfants verticalement
    flex: 0;
    width: auto;
    padding-right: 0;
    padding: 0;
    &:first-child {
      img {
        // margin-left: 10px; // Ajustez cette valeur en fonction de l'espace que vous voulez
      }
    }
    & > img {
      max-width: 25px;
      max-height: 25px;
      padding-right: ${(props) => props.orientation === 0 ? "0" : "2px"};
    }
    & > span {
      position: absolute;
      font-weight: 550;
      top:-6px;
      left:-9px;
      display: flex;
      justify-content: center; // Centre les enfants horizontalement
      align-items: center; // Centre les enfants verticalement
      flex: 0;
      color:#00559F;
      width: 36px;
      height: 36px;
      flex-shrink: 0;
      border-radius: 50%;
      border: 2px solid #00559F;
      opacity: 0.8;
      background-color: #fff;
      z-index: 1;
    }
`;

export const RowCalepinage = styled(Row)`
    display: flex;
    justify-content: center; // Centre les enfants horizontalement
    align-items: center; // Centre les enfants verticalement
    padding-bottom: 3px;
`;

export const OColContainerCalepinage = styled(Col)<{
  isSelected?: boolean
}>`
  border: 1px solid  ${(props) => props.isSelected? props.theme.colors.primary.bluePower700 : props.theme.colors.clay.clayPower600};
  border-radius: 8px;
  width: auto;
  height: auto;
  max-width: 40%;
  max-height: 40%;
  padding-top: 10px;
  margin-left: 10px;
  padding-bottom: 10px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    border-color: ${(props) => props.theme.colors.primary.bluePower700};
  }
  min-width: 175px;
  min-height: 150px;
`;

export const ORowContainerCalepinage = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  gap:1rem;
`;



