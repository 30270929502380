import styled from "styled-components";
import { OBody4 } from "../../../../Styled/Texts";

interface ProgressBarProps {
  progress: string;
}

export const ProgressBarContainer = styled.div`
  position: relative;
  width: 100%;
  height: 20px;
  background-color: transparent;
  border-radius: 7px;
  border: 1px solid ${(props) => props.theme.colors.secondary.yellowPower700};
`;

export const ProgressBar = styled.div<ProgressBarProps>`
  background-color: ${(props) => props.theme.colors.secondary.yellowPower700};
  height: 100%;
  border-radius: 8px;
  transition: all 0.2s ease-in;
  width: ${(props) =>
    parseFloat(props.progress) >= 100 ? 100 : props.progress}%;
`;

export const ProgressLabel = styled(OBody4)<ProgressBarProps>`
  transition: all 0.2s ease-in;
  display: "block";
  width: 50px;
  position: absolute;
  left: ${(props) =>
    Math.min(
      Number(props.progress),
      80
    )}%; // ne pas dépasser 90% pour s'assurer que le label reste à l'intérieur de la barre
  color: ${(props) => props.theme.colors.onyx.onyxPower500};
  font-size: 14px;
  top: 0;
`;
