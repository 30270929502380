import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  STEP_RECOMMANDATION,
  setGlobalCurrentPhase,
  setStepAccessible,
  setStepData,
} from "../../../store/reducer";
import { OContainer } from "../../Styled/CustomGrid";
import CustomTransition from "../../Styled/CustomTransitions";
import { StepProps } from "../../interface";
import { RecommandationPhase1ReduxStepData } from "./phases/phase1/constants";
import RecommandationPhase1 from "./phases/phase1/recommandation_phase_1";
import { RecommandationPhase2ReduxStepData } from "./phases/phase2/constants";
import RecommandationPhase2 from "./phases/phase2/recommandation_phase_2";
import { RecommandationContainer } from "./styled";

const Recommandation: React.FC<StepProps> = ({ onContinue }: any) => {
  const currentStepName = STEP_RECOMMANDATION;
  const stepData = useSelector(
    (state: RootState) => state.step.steps[currentStepName].data
  );

  const dispatch = useDispatch();

  const [dataPhase1, setDataPhase1] =
    useState<RecommandationPhase1ReduxStepData>(stepData?.phase1 || null);
  const [dataPhase2, setDataPhase2] =
    useState<RecommandationPhase2ReduxStepData>(stepData?.phase2 || null);

  const [phase, setPhase] = useState(stepData.phase || 1);

  useEffect(() => {
    // Handle changes on this phase to refresh simulation if needed
    // store.subscribe(simulationHandleChange);
    dispatch(setStepAccessible({ step: currentStepName, accessible: true }));
  }, []);

  const handlePhase1Change = (data: RecommandationPhase1ReduxStepData) => {
    setDataPhase1(data);
    dispatch(setStepData({ step: currentStepName, data: { phase1: data } }));
  };

  const handlePhase2Change = (data: any) => {
    setDataPhase2(data);
    dispatch(setStepData({ step: currentStepName, data: { phase2: data } }));
  };

  useEffect(() => {
    dispatch(setGlobalCurrentPhase(phase));
  }, [phase]);

  const handleContinue = (
    dataKey: string,
    dataValue: any,
    nextPhase?: number
  ) => {
    if (!nextPhase) {
      onContinue();
      return;
    }
    dispatch(
      setStepData({
        step: currentStepName,
        data: { [dataKey]: dataValue, phase: nextPhase },
      })
    );
    setPhase(nextPhase);
  };

  return (
    <RecommandationContainer className="h-100">
      <OContainer fluid className="h-100 position-relative">
        <CustomTransition mode="fade" key={phase}>
          {phase === 1 ? (
            <RecommandationPhase1
              setData={setDataPhase1}
              onChanges={handlePhase1Change}
              onContinue={() => handleContinue("phase1", dataPhase1, null)}
            />
          ) : null}

          {phase === 2 ? (
            <RecommandationPhase2
              onChanges={handlePhase2Change}
              setData={setDataPhase2}
              onContinue={onContinue}
              onReturn={() => handleContinue("phase2", dataPhase2, phase - 1)}
            />
          ) : null}
        </CustomTransition>
      </OContainer>
    </RecommandationContainer>
  );
};

export default Recommandation;
