import React, { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { STEP_RECOMMANDATION, devMode } from "../../../../../store/reducer";
import { ProfitCard } from "./styled";
import {
  OBody10,
  OBody2,
  OBody3,
  OBody4,
  OBody5,
  OBody8,
  OBody9,
  OH2,
  OH3,
} from "../../../../Styled/Texts";
import { needCardLeftVariants } from "./constants";
import { motion } from "framer-motion";
import useIsMobile from "../../../../../utils/useIsMobile";
import { cleanMoneyValue } from "../../../../../utils";

import { ComponentStatus } from "../../../../shared/status/component-status";
import { SimulationState } from "../../../../../store/simulation/simulation-state";
import { svgsPath } from "../../../../../utils/constants";
import { AskIcon } from "../../../../Styled/Buttons/AskButton/styled";
import { Tooltip } from "react-tooltip";
import {
  SimulationTooltip,
  SimulationTooltipContext,
} from "./recommandation_phase_2";
import { formatNumber } from "../../../../../utils/convert-number-locale";

export const ProfitCardComponent: React.FC<{
  simulationState: SimulationState;
}> = ({ simulationState }) => {
  const { t } = useTranslation([STEP_RECOMMANDATION + "phase2", "global"]);
  const defaultValue = "...";
  const simulation = simulationState.simulation;
  const isMobile = useIsMobile();
  const [isExpanded, setIsExpanded] = useState(!isMobile);

  const handleCardClick = () => {
    if (isMobile) {
      setIsExpanded(!isExpanded);
    }
  };

  useEffect(() => {
    if (isMobile) {
      setIsExpanded(false);
    } else {
      setIsExpanded(true);
    }
  }, [isMobile]);

  // Used for mobile mode only
  const { tooltip, setTooltip } = useContext(SimulationTooltipContext);

  if (simulation.error && simulation.status === ComponentStatus.error) {
    return <></>;
  }

  const gain = simulation?.data?.rentabilite.gain;
  const surplus = simulation?.data?.rentabilite.surplus;
  const savings = simulation?.data?.rentabilite.savings;

  const isLoading = simulation?.status !== ComponentStatus.loaded;

  return (
    !isLoading && (
      <ProfitCard
        onClick={handleCardClick}
        {...needCardLeftVariants}
        isexpanded={isExpanded.toString()}
      >
        <motion.div
          className="icon-switch"
          style={{ backgroundColor: "white" }}
        >
          <img src={svgsPath + "swip-up-black.svg"} alt="" />
        </motion.div>

        {isExpanded ? (
          <motion.div className="profit-card-expanded d-flex flex-column gap-3">
            {!isMobile && (
              <img src={svgsPath + "up.svg"} alt="" className="up-arrow" />
            )}
            <OH2>
              <Trans>{t("profitCard.title")}</Trans>
            </OH2>

            <motion.div className="d-flex flex-row justify-content-between align-items-center gap-2">
              <motion.div className="d-flex flex-column justify-content-center align-items-start">
                <div className="align-items-center d-flex">
                  <OBody9 className="no-margin">
                    {t("profitCard.estimatedGain")}
                  </OBody9>
                  <div className="o-tooltip-container">
                    <AskIcon
                      className="recommandation-simulation-rentabilite-eco-ask"
                      data-tooltip-id="recommandation-simulation-rentabilite-eco-tooltip"
                    >
                      ?
                    </AskIcon>
                    <Tooltip
                      id="recommandation-simulation-rentabilite-eco-tooltip"
                      className="o-tooltip"
                      noArrow={true}
                    >
                      <OBody8>{t("profitCard.economy.tooltip")}</OBody8>
                    </Tooltip>
                  </div>
                </div>

                <OH3 className="no-margin">
                  {formatNumber(savings, 0) ?? defaultValue}
                </OH3>
                <OBody10 className="no-margin">
                  <strong>{t("profitCard.perunit")}</strong>
                </OBody10>
              </motion.div>
              <motion.div className="d-flex flex-column justify-content-center align-items-start">
                <OH3>+</OH3>
              </motion.div>
              <motion.div className="d-flex flex-column justify-content-center">
                <div className="d-flex align-items-center">
                  <OBody9 className="no-margin">
                    {t("profitCard.estimatedResale")}
                  </OBody9>
                  <div className="o-tooltip-container">
                    <AskIcon
                      className="recommandation-simulation-rentabilite-selling-ask"
                      data-tooltip-id="recommandation-simulation-rentabilite-selling-tooltip"
                    >
                      ?
                    </AskIcon>
                    <Tooltip
                      id="recommandation-simulation-rentabilite-selling-tooltip"
                      className="o-tooltip"
                      noArrow={true}
                    >
                      <OBody8>{t("profitCard.selling.tooltip")}</OBody8>
                    </Tooltip>
                  </div>
                </div>

                <OH3 className="no-margin">
                  {formatNumber(surplus, 0) ?? defaultValue}
                </OH3>
                <OBody10 className="no-margin">
                  <strong>{t("profitCard.perunit")}</strong>
                </OBody10>
              </motion.div>
            </motion.div>

            <motion.span className="d-flex flex-row justify-content-start align-items-baseline gap-1">
              <OBody5>{t("profitCard.savings")}</OBody5>
              <OH2>
                {formatNumber(gain, 0) ?? defaultValue}
              </OH2>
            </motion.span>
          </motion.div>
        ) : (
          // Collapsed view
          <motion.div className="d-flex flex-column align-self-center">
            <div className="d-flex align-items-center">
              <OBody5>
                <Trans>
                  {t("profitCard.gainDetailsCollasped", {
                    value: cleanMoneyValue(savings ?? defaultValue),
                  })}
                </Trans>
              </OBody5>
              <div className="o-tooltip-container">
                <AskIcon
                  className="recommandation-simulation-rentabilite-eco-ask"
                  data-tooltip-id="recommandation-simulation-rentabilite-eco-tooltip"
                  onClick={(e) => {
                    e.stopPropagation();
                    setTooltip(SimulationTooltip.economy);
                  }}
                >
                  ?
                </AskIcon>
                <Tooltip
                  id="recommandation-simulation-rentabilite-eco-tooltip"
                  className="o-tooltip"
                  noArrow={true}
                  isOpen={tooltip === SimulationTooltip.economy}
                >
                  <OBody8>{t("profitCard.economy.tooltip")}</OBody8>
                </Tooltip>
              </div>
            </div>

            <div className="d-flex align-items-center">
              <OBody5>
                <Trans>
                  {t("profitCard.resaleDetailsCollasped", {
                    resale: cleanMoneyValue(surplus ?? defaultValue),
                  })}
                </Trans>
              </OBody5>
              <div className="o-tooltip-container">
                <AskIcon
                  className="recommandation-simulation-rentabilite-selling-ask"
                  data-tooltip-id="recommandation-simulation-rentabilite-selling-tooltip"
                  onClick={(e) => {
                    e.stopPropagation();
                    setTooltip(SimulationTooltip.selling);
                  }}
                >
                  ?
                </AskIcon>
                <Tooltip
                  id="recommandation-simulation-rentabilite-selling-tooltip"
                  className="o-tooltip"
                  noArrow={true}
                  isOpen={tooltip === SimulationTooltip.selling}
                >
                  <OBody8>{t("profitCard.selling.tooltip")}</OBody8>
                </Tooltip>
              </div>
            </div>
          </motion.div>
        )}
      </ProfitCard>
    )
  );
};

export default ProfitCardComponent;
