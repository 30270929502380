import { useCallback, useEffect, useRef } from "react";

const pixelTolerance = 30;
// Display hotjar feedback once
export const useHotjarFeedback = () => {
  const displayed = useRef(false);
  const showFeedback = () => {
    const _ = window as any;
    if (_.hj) {
      _.hj("event", "open_survey_configurator");
    }
  };
  const handleScroll = useCallback(
    (e) => {
      const { scrollHeight, scrollTop, clientHeight } = e.target;
      // https://stackoverflow.com/a/34550171
      const isScrolledToBottom =
        Math.abs(scrollHeight - scrollTop - clientHeight) < pixelTolerance;

      if (isScrolledToBottom && !displayed.current) {
        displayed.current = true;
        window.removeEventListener("scroll", handleScroll);
        showFeedback();
      }
    },
    [displayed]
  );

  // Register listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
};
