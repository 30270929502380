import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import {
  STEP_DESIGN,
  STEP_INSTALLATION,
  STEP_LOCALIZATION,
  STEP_NEEDS,
  STEP_RECOMMANDATION,
  setStepAccessible,
} from "../../../../../store/reducer";
import { skipToConfiguration } from "../../../../../utils/navigation/navigation-utils";
import { trackDataLayer } from "../../../../../utils/tracking/tracking-utils";
import { OMainButton, OReturnButton } from "../../../../Styled/Buttons";
import { OLinkButton } from "../../../../Styled/Buttons/Link/o-link-button";
import { OCol, ORow } from "../../../../Styled/CustomGrid";
import { fade } from "../../../../Styled/GlobalAnimations";
import { theme } from "../../../../Styled/GlobalStyle";
import {
  NeedPhase1Props,
  NeedPhase1ReduxStepData,
  needPhase1Config,
} from "./constants";
import {
  BudgetBody,
  BudgetSubtitle,
  BudgetTitle,
  LabelAmount,
  OInputAmount,
} from "./styled";

const NeedPhase1: React.FC<NeedPhase1Props> = ({
  data,
  setData,
  onContinue,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation([STEP_NEEDS + "phase1", "global"]);
  const config = needPhase1Config;

  const [inputData, setInputData] = React.useState<number | string>(
    data?.budget !== undefined && data?.budget !== "" && data?.budget !== null
      ? data.budget
      : ""
  );

  const [needContinue, setNeedContinue] = React.useState(false);

  const [initialBudget] = React.useState<number>(data.budget);

  const [needToDisableSteps, setNeedToDisableSteps] = React.useState(false);
  const installationType = useSelector(
    (state: RootState) => state.step.steps[STEP_INSTALLATION].data?.installation
  );

  useEffect(() => {
    if (needContinue) {
      onContinue();
    }
  }, [data.budget, needContinue]);

  const handleInputChange = (value: any) => {
    const numValue = Number(value);
    if (initialBudget && numValue !== initialBudget) {
      setNeedToDisableSteps(true);
    } else {
      setNeedToDisableSteps(false);
    }
    setInputData(numValue || "");
  };

  const handleContinue = () => {
    if (inputData && inputData !== -1 && inputData !== "") {
      setNeedContinue(true);
      setData({
        budget: inputData,
      } as NeedPhase1ReduxStepData);
      trackDataLayer("BudgetSelected", { budget: inputData });
      if (inputData) {
        if (needToDisableSteps) {
          dispatch(
            setStepAccessible({ step: STEP_RECOMMANDATION, accessible: false })
          );
          dispatch(setStepAccessible({ step: STEP_DESIGN, accessible: false }));
        }
      }
    }
  };

  const handleClickIDK = () => {
    setNeedContinue(true);
    setData({
      budget: null,
    } as NeedPhase1ReduxStepData);
    trackDataLayer("BudgetSelected", { budget: undefined });
    if (initialBudget) {
      dispatch(
        setStepAccessible({ step: STEP_RECOMMANDATION, accessible: false })
      );
      dispatch(setStepAccessible({ step: STEP_DESIGN, accessible: false }));
    }
  };

  return (
    <ORow className="h-100">
      <OCol
        sm={config.gridConfig.left[0]}
        md={config.gridConfig.left[1]}
        lg={config.gridConfig.left[2]}
        className="d-flex flex-column justify-content-start justify-content-md-center align-items-center contentsized gap-3 p-0"
      >
        <BudgetTitle center="true" {...fade(0.1)}>
          <Trans> {t("title")}</Trans>
        </BudgetTitle>
        <BudgetSubtitle center="true" {...fade(0.1)}>
          <Trans> {t("subtitle")}</Trans>
        </BudgetSubtitle>
        <BudgetBody center="true" {...fade(0.2)}>
          <Trans> {t("description")}</Trans>
        </BudgetBody>
        <motion.div
          {...fade(0.3)}
          className="d-flex flex-column position-relative"
        >
          <OInputAmount
            placeholder=" "
            value={inputData}
            onChange={(e: any) => handleInputChange(e.target.value)}
            type="number"
            id="monthlyAmount"
            min={config.min}
            max={config.max}
            withicon="true"
            withfloatinglabel="true"
            {...fade(0.4)}
          />
          <span className="suffix">€</span>
          <LabelAmount htmlFor="monthlyAmount">{t("placeholder")}</LabelAmount>

          <OMainButton
            primary
            onClick={handleContinue}
            {...fade(0.6)}
            className="w-100 besoins-budget-next-btn mt-3"
            disabled={!inputData}
          >
            {t("global:validate")}
          </OMainButton>
          <OReturnButton
            className="besoins-budget-dont-know-btn"
            writed
            onClick={handleClickIDK}
          >
            {" "}
            {t("idk")}{" "}
          </OReturnButton>

          <OLinkButton
            className="skip-btn text-center"
            color={theme.colors.onyx.onyxPower700}
            onClick={() =>
              skipToConfiguration(dispatch, installationType, [
                STEP_LOCALIZATION,
                STEP_RECOMMANDATION,
              ])
            }
          >
            {t("global:goToConfiguration")}
          </OLinkButton>
        </motion.div>
      </OCol>
    </ORow>
  );
};

export default NeedPhase1;
