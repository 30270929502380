import { useEffect } from "react";
import {
  getPageInfoFromStep,
  trackPageExited,
  trackPageStarted,
} from "./tracking-utils";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

/// Hook to handle navigation changed
/// Used to track navigation into GA and Mixpanel
export const useTrackingPageView = () => {
  const currentStep = useSelector((state: RootState) => state.step.currentStep);
  const currentPhase = useSelector(
    (state: RootState) => state.step.currentPhase
  );
  const previousStep = useSelector(
    (state: RootState) => state.step.previousStep
  );
  const previousPhase = useSelector(
    (state: RootState) => state.step.previousPhase
  );

  useEffect(() => {
    if (previousStep && previousPhase) {
      const previousPageInfo = getPageInfoFromStep(previousStep, previousPhase);
      trackPageExited(previousPageInfo.title, previousPageInfo.page);
    }
    const pageInfo = getPageInfoFromStep(currentStep, currentPhase);
    trackPageStarted(pageInfo.title, pageInfo.page);
  }, [currentStep, currentPhase]);
};
