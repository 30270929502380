import { createGlobalStyle } from "styled-components";

export const theme = {
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1440,
  },
  heights: {
    input: "56px",
  },
  colors: {
    neutralBackground: "#F4F2F3",
    neutralBackground2: "#F5F5F5",
    primary: {
      bluePower700: "#143FF5",
      bluePower600: "#4256FF",
      bluePower500: "#5C6DFF",
      bluePower400: "#DBDFFF",
      bluePower300: "#EDEFFF",
      bluePower200: "#FAFAFF",
    },
    secondary: {
      yellowPower700: "#FFBA00",
      yellowPower600: "#FFC11A",
      yellowPower500: "#FFC833",
      yellowPower400: "#FFF1CC",
      yellowPower300: "#FFF8E5",
      yellowPower200: "#FFFEFA",
    },
    onyx: {
      onyxPower700: "#000000",
      onyxPower600: "#1B1B1E",
      onyxPower500: "#3F415C",
      onyxPower400: "#ABB0DB",
      onyxPower300: "#D7DAF5",
      onyxPower200: "#F5F6FF",
    },
    tertiary: {
      leafPower700: "#6DD900",
      leafPower600: "#83D92B",
      leafPower500: "#98D957",
      leafPower400: "#9AFF3A",
      leafPower300: "#B4FF6E",
      leafPower200: "#CFFFA1",
      leafPower666: "#9AFF3A",
    },
    error: {
      cherryPower700: "#F54336",
      cherryPower600: "#F6564A",
      cherryPower500: "#F87B72",
      cherryPower400: "#FAA19B",
      cherryPower300: "#FCC7C3",
      cherryPower200: "#FFF6F5",
    },
    clay: {
      clayPower700: "#8B8C94",
      clayPower600: "#CECECE",
      clayPower500: "#EBEBED",
      clayPower400: "#F5F5F7",
      clayPower300: "#FAFBFB",
      clayPower200: "#FFFFFF",
      clayPower666: "#FEFEFE",
    },
  },
  gradients: {
    primary:
      "linear-gradient(239.24deg, #00559F 67.54%, rgba(255, 255, 255, 0) 299.77%)",
    yellow:
      "linear-gradient(115.46deg,rgba(245, 172, 42, 0.8) 0%,rgba(255, 255, 255, 0) 273.49%)",
    tertiary:
      "linear-gradient(115.46deg,rgba(109, 217, 0, 0.8) 0%,rgba(255, 255, 255, 0) 273.49%)",
  },
  sizes: {
    header: "80px",
    // content: "calc(100vh - 50px - 96px - 80px)", //  remove height for: blue banner - global nav - configurateur nav
    content: "100%", //  remove height for: blue banner - global nav - configurateur nav
    // mobileContent: "calc(100vh - 50px - 77px - 80px)", //  remove height for: blue banner - global nav - configurateur nav
    mobileContent: "100%", //  remove height for: blue banner - global nav - configurateur nav
  },
};

export const GlobalStyle = createGlobalStyle`

  body {
    box-sizing: border-box;
    // background-color:   ${theme.colors.neutralBackground};
  }

  .max-h-15 {
    max-height: 15vh;
  }

  .no-margin {
    margin: 0px;
  }

  .neutral-bg {
    background-color: ${theme.colors.neutralBackground};
  }

  .light-bg {
    background-color: ${theme.colors.clay.clayPower200};

    @media (max-width: ${theme.breakpoints.md}px) {
      background-color: ${theme.colors.neutralBackground};
    }
  }

  .contentsized {
    min-height: ${theme.sizes.content};
    max-height: ${theme.sizes.content};

    &.locked {
      overflow: hidden;
      max-height: ${theme.sizes.content};
    }

    @media (max-width: ${theme.breakpoints.md}px) {
      min-height: inherit;
      max-height: inherit;
    }
  }

  #root-configurateur {
    max-height: 100%;
    background-color: ${theme.colors.neutralBackground2};
  }
  

  strong {
    font-weight: bold !important;
  }

  .primary {
    color: ${theme.colors.primary.bluePower700} !important;
  }
  

  .light {
    color: ${theme.colors.clay.clayPower200};
  }

  .center {
    text-align: center;
    }

    * {
    scrollbar-width: thin;
  }
;
  }
      
      /* Style pour Firefox */
      @-moz-document url-prefix() {
        html {
          scrollbar-width: thin;
          scrollbar-color: ${theme.colors.primary.bluePower700} transparent;
        }
      }

  /**
   * Radio button
   * */
  input[type="radio"] {
    appearance: none;
    background-color: ${(props) => theme.colors.clay.clayPower200};
    color: ${(props) => theme.colors.clay.clayPower600};
    width: 1em;
    height: 1em;
    border: 4px solid;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }
  input[type="radio"]:checked {
    color: ${(props) => theme.colors.primary.bluePower700};
  }

  /**
   * Checkbox button
   * */
  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    border-radius: 10px;
  }

  input[type="checkbox"]:checked {
    accent-color: ${(props) => theme.colors.primary.bluePower700};
  }

  /**
   * FlexBox
   * **/

  .flex-shrink-0 {
    flex-shrink: 1;
  }

  .flex-shrink-1 {
    flex-shrink: 1;
  }

  .overflow-auto {
    overflow: auto;
    }

  .flex-auto {
    flex: auto;
  }
  .disabled-button {
    -webkit-filter: opacity(.3) drop-shadow(0 0 0 #FFF);
    filter: opacity(.3) drop-shadow(0 0 0 #FFF);
    cursor: default !important;
  }

  .container-reco {
    overflow:hidden;
    @media (max-width: ${theme.breakpoints.md}px) {
      overflow-y: auto;
    }
  }

  .o-tooltip-container .o-tooltip {
    z-index: 25;
    padding: 16px 24px 16px 24px;
    border-radius: 16px;
    background-color: ${(props) => theme.colors.onyx.onyxPower600};
    opacity: 1;
    max-width: 20vw;
    text-align: center;
    color: ${(props) => theme.colors.clay.clayPower200};
  }

  @media (max-width: ${theme.breakpoints.md}px) {
    .o-tooltip-container .o-tooltip {
      max-width: 80vw;
    }
  }

  .h-md-100 {
    @media (min-width: ${theme.breakpoints.md}px) {
      height: 100% !important;
    }
  }

  .mw-md-75 {
    @media (min-width: ${theme.breakpoints.md}px) {
      width: 75% !important;
    }
  }

  .material-symbols-rounded {
    font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 24;
  }

  .rotate90 {
    transform: rotate(90deg);
  }

  .float-right {
    float: right;
  }

  .float-left {
    float: left;
  }
`;
