import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  STEP_DESIGN,
  STEP_INSTALLATION,
  setGlobalCurrentPhase,
  setStepAccessible,
  setStepData,
} from "../../../store/reducer";
import { OContainer } from "../../Styled/CustomGrid";
import { DesignContainer } from "./styled";

import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { setMatriceCalepinage } from "../../../store/calepinage/calepinageReducer";
import { createMinimizedMatrix } from "../../../utils/create-minimized-matrix";
import useIsMobile from "../../../utils/useIsMobile";
import CustomTransition from "../../Styled/CustomTransitions";
import { StepProps } from "../../interface";
import {
  InstallationReduxStepData,
  isRoofType,
} from "../Installation/constants";
import DesignPhase1 from "./phases/phase1/design_phase_1";
import DesignPhase2 from "./phases/phase2/design_phase_2";

const Design: React.FC<StepProps> = ({ onContinue }: any) => {
  const currentStepName = STEP_DESIGN;

  const hasSkipped = useSelector((state: RootState) => state.step.skip);
  const installationData: InstallationReduxStepData = useSelector(
    (state: RootState) => state.step.steps[STEP_INSTALLATION].data || {}
  );

  const shouldDisplayCalepinage = useSelector(
    (state: RootState) => state.step.steps[STEP_DESIGN].data.phase1.display
  );

  const panelCountInCalepinage = useSelector(
    (state: RootState) => state.calepinageReducer.panelCountInCalepinage
  );

  const recommandationCount = useSelector(
    (state: RootState) => state.calepinageReducer.recommandationCount
  );

  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const [phase, setPhase] = useState(
    isRoofType(installationData?.installation) && !hasSkipped ? 1 : 2
  );

  useEffect(() => {
    if (
      installationData.installation &&
      !isRoofType(installationData.installation) &&
      isMobile
    ) {
      dispatch(
        setMatriceCalepinage(
          createMinimizedMatrix(panelCountInCalepinage || recommandationCount)
        )
      );
    }
  }, [installationData.installation]);

  useEffect(() => {
    dispatch(setStepAccessible({ step: currentStepName, accessible: true }));
  }, []);

  useEffect(() => {
    dispatch(setGlobalCurrentPhase(phase));
    dispatch(setStepData({ step: currentStepName, data: { phase } }));
  }, [phase]);

  const handleContinue = (nextPhase: number) => {
    if (nextPhase === 0) {
      onContinue();
      return;
    }
    setPhase(nextPhase);
  };

  return (
    <DesignContainer className="h-100">
      <OContainer fluid className="h-100">
        <CustomTransition mode="fade" key={phase}>
          {phase === 1 && !isMobile && shouldDisplayCalepinage ? (
            <DesignPhase1 onContinue={() => handleContinue(phase + 1)} />
          ) : (
            <DesignPhase2
              onContinue={() => handleContinue(0)}
              onReturn={() => handleContinue(phase - 1)}
              canReturn={isRoofType(installationData.installation)}
            />
          )}
        </CustomTransition>
      </OContainer>
    </DesignContainer>
  );
};

export default Design;
