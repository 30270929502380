import { ComponentStatus } from "../../../components/shared/status/component-status";
import { configurateurService } from "../../../data/configurateur/services/configurateur-service";
import { ConfigurateurSupervisionState } from "./configurateur-supervision-state";

const initialState: ConfigurateurSupervisionState = {
  supervisionData: {
    status: ComponentStatus.idle,
  },
};

enum ActionType {
  loadSupervision = "loadSupervision",
  selectSupervision = "selectSupervision",
}

export const configurationSupervisionReducer = (
  state = initialState,
  action
): ConfigurateurSupervisionState => {
  const { data, status, error, selected } = action;

  switch (action.type) {
    case ActionType.loadSupervision:
      return {
        ...state,
        supervisionData: {
          data: data || state?.supervisionData?.data,
          status: status,
          error: error,
          selected: selected || state?.supervisionData?.selected,
        },
      };
    case ActionType.selectSupervision:
      return {
        ...state,
        supervisionData: {
          ...state.supervisionData,
          data: data || state?.supervisionData?.data,
          selected: selected,
        },
      };
    default:
      return { ...state };
  }
};

export const configurateurSupervisionLoad =
  (inverterId: string, inverterIdAttribute: string) => async (dispatch) => {
    try {
      dispatch({
        type: ActionType.loadSupervision,
        status: ComponentStatus.loading,
      });
      const supervisions = await configurateurService.getSupervisions(
        inverterId,
        inverterIdAttribute
      );

      const selected =
        supervisions.find((supervision) => supervision.is_oscaro_choice) ||
        supervisions[0];

      dispatch({
        type: ActionType.loadSupervision,
        status: ComponentStatus.loaded,
        data: supervisions,
        selected: selected,
      });
    } catch (_) {
      dispatch({
        type: ActionType.loadSupervision,
        status: ComponentStatus.error,
      });
    }
  };
