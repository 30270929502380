import styled from "styled-components";

import { motion } from "framer-motion";

const OBaseTertiaryButton = styled(({ writed, primary, ...rest }) => (
  <motion.button {...rest} />
))`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    min-height: ${(props) => props.theme.heights.input};

    border-radius: 8px;
    padding: 12px 35px;
    text-align: center;

    cursor: pointer;
    outline: none;

    position: relative;

    transition: all 0.3s ease-in-out;
    color: ${(props) => props.theme.colors.onyx.onyxPower700}
    border: 1px solid ${(props) => props.theme.colors.onyx.onyxPower300};
    background: transparent;
    &:hover {
        background: transparent;
    }

    &:active {
        background: transparent;
    }
`;

export const OTertiaryButton = styled(
  ({ underline, writed, color, ...rest }) => <OBaseTertiaryButton {...rest} />
)<{
  underline?: boolean;
  writed?: boolean;
  color?: "secondary";
}>`
  ${(props) =>
    props?.underline &&
    `
    text-decoration: underline;
    border: none;
`}

  ${(props) =>
    props.color === "secondary" &&
    `
  color: ${props.theme.colors.secondary.yellowPower700};
  `}
  
  ${(props) =>
    props?.writed &&
    `
    border: none;
    background: transparent;

    &::after {
        content: "";
        display: block;
        width:  90%;
        height: 20px;
        background-image: url(svgsPath + 'trait.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-color: transparent;
        position: absolute;
        bottom: -5px;
    }
    `}
`;
