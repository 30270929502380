import { motion } from "framer-motion";
import styled from "styled-components";

interface OHeadingGeneralProps {
  center?: string;
}

const OHeadingGeneral = styled(motion.h1)<OHeadingGeneralProps>`
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: ${(props) => props.theme.colors.onyx.onyxPower600};
  text-align: ${(props) => (props.center ? "center" : "inherit")};
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: none;
  margin-bottom: 0px;
`;

export const OH1 = styled(OHeadingGeneral).attrs({ as: "h1" })`
  font-size: 48px;
  font-weight: 400;
  @media (max-width: ${(props) => props.theme.breakpoints.xl}px) {
    font-size: 48px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    font-size: 32px;
  }
`;

export const OH2 = styled(OHeadingGeneral).attrs({ as: "h2" })`
  font-size: 32px;
  font-weight: 400;
  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    font-size: 24px;
  }
`;

export const OH3 = styled(OHeadingGeneral).attrs({ as: "h3" })`
  font-size: 24px;
  font-weight: 400;
  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    font-size: 20px;
  }
`;

export const OH4 = styled(OHeadingGeneral).attrs({ as: "h4" })`
  font-size: 24px;
  font-weight: 400;
  @media (max-width: ${(props) => props.theme.breakpoints.xl}px) {
    font-size: 20px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    font-size: 18px;
  }
`;

export const OH5 = styled(OHeadingGeneral).attrs({ as: "h5" })`
  font-size: 18px;
  font-weight: 400;
  @media (max-width: ${(props) => props.theme.breakpoints.xl}px) {
    font-size: 16px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    font-size: 16px;
  }
`;
