import { Trans, useTranslation } from "react-i18next";
import { OBody5 } from "../../../../../Styled/Texts";
import { Modal } from "../../../../../Styled/modals";
import { STEP_DESIGN } from "../../../../../../store/reducer";
import {
  ConfigurateurPopupContext,
  ConfigurateurPopups,
} from "../configurateur-modals-context";
import { useContext } from "react";
import { theme } from "../../../../../Styled/GlobalStyle";

export const AdministrativeHelpModal = (props: { show: boolean }) => {
  const { t } = useTranslation([STEP_DESIGN, "global"]);
  const { setPopup } = useContext(ConfigurateurPopupContext);
  return (
    <Modal
      show={props.show}
      title={t("phase_2.sections.administration_help.modal.title")}
      onClose={() => setPopup(ConfigurateurPopups.none)}
    >
      <OBody5>
        {t("phase_2.sections.administration_help.modal.description")}
      </OBody5>
      <br />
      <div className="modal-content-list">
        <OBody5 className="modal-content-subtitle">
          {t("phase_2.sections.administration_help.modal.section1.title")}
        </OBody5>
        <OBody5>
          {t("phase_2.sections.administration_help.modal.section1.description")}
        </OBody5>
        <ul>
          <li>
            <OBody5>
              {t("phase_2.sections.administration_help.modal.section1.item1")}
            </OBody5>
          </li>
          <li>
            <OBody5>
              <Trans>
                {t("phase_2.sections.administration_help.modal.section1.item2")}
              </Trans>
            </OBody5>
          </li>
          <li>
            <OBody5>
              <Trans>
                {t("phase_2.sections.administration_help.modal.section1.item3")}
              </Trans>
            </OBody5>
          </li>
        </ul>
      </div>
      <div className="modal-content-list">
        <OBody5 className="modal-content-subtitle">
          {t("phase_2.sections.administration_help.modal.section2.title")}
        </OBody5>
        <OBody5>
          {t("phase_2.sections.administration_help.modal.section2.description")}
        </OBody5>
      </div>
    </Modal>
  );
};
