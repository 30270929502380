import styled from "styled-components";

export const VirtualCartItemContainer = styled.div`
  padding: 16px 0px 0px 0px;
  display: flex;
  align-items: center;
  width: 100%;

  .virtualCartItemLeading {
    flex: 1 1 auto;
    padding: 0px 16px 0px 16px;
  }

  .virtualCartItemLabel {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .virtualCartItemTrailing {
    text-align: end;
  }

  img {
    background-color: #fff;
    width: 40px;
    height: 40px;
  }

  a {
    font-weight: 400;
    font-size: 14px;
    color: ${(props) => props.theme.colors.clay.clayPower600};
  }
`;
