import styled from "styled-components";
import { OSecondaryButton } from "../../Styled/Buttons";
import { OInput } from "../../Styled/Forms";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RootState } from "../../../store";
import { useState } from "react";
import { OBody12 } from "../../Styled/Texts";
import { theme } from "../../Styled/GlobalStyle";

export const InputPostCodeChecker = (props: { onCheck }) => {
  const { t } = useTranslation("global");

  const installHelpsData = useSelector(
    (state: RootState) => state.configurateurInstallHelps.installHelpsData
  );
  const [postcode, setPostcode] = useState(installHelpsData?.postcode);
  const [error, setError] = useState<string | undefined>();

  return (
    <Container>
      <InputContainer>
        <OInputPostCode
          id="input-postcode-checker"
          placeholder={t("inputs.postcode")}
          value={postcode}
          onChange={(e) => setPostcode(e.target.value)}
        />
        <ButtonCheck
          onClick={() => {
            if (!postcode || postcode?.length < 2) {
              setError(t("inputs.errors.invalidFormat"));
              return;
            }
            props.onCheck(postcode);
          }}
        >
          {t("check")}
        </ButtonCheck>
      </InputContainer>
      {error && (
        <OBody12 className="error" color={theme.colors.error.cherryPower600}>
          {error}
        </OBody12>
      )}
    </Container>
  );
};

const Container = styled.div`
  .error {
    padding-top: 8px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid ${(props) => props.theme.colors.clay.clayPower600};
  border-radius: 8px;
  width: 100%;
`;

const OInputPostCode = styled(OInput)`
  min-width: 50%;
  border: none;
`;

const ButtonCheck = styled(OSecondaryButton)`
  height: fit-content;
  margin-right: 8px;
`;
