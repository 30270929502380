import { FixationEntity } from "../../data/fixations/repositories/entities/fixation-entity";

export const SET_RECOMMANDATION_COUNT = "SET_RECOMMANDATION_COUNT";
export const SELECT_FIXATION_FOR_CALEPINAGE_HORS_TOITURE =
  "SELECT_FIXATION_FOR_CALEPINAGE_HORS_TOITURE";
export const SET_PANEL_COUNT_IN_CALEPINAGE = "SET_PANEL_COUNT_IN_CALEPINAGE";
export const RESET_PANEL_COUNT_IN_CALEPINAGE =
  "RESET_PANEL_COUNT_IN_CALEPINAGE";
export const SET_MATRICE_CALEPINAGE = "SET_MATRICE_CALEPINAGE";
export const NEED_COMPUTE_MATRICE_CALEPINAGE =
  "NEED_COMPUTE_MATRICE_CALEPINAGE";
export const SET_DISPOSITION_PANNEAUX = "SET_DISPOSITION_PANNEAUX";

interface SetMatriceCalepinageAction {
  type: typeof SET_MATRICE_CALEPINAGE;
  payload: number[][];
}
interface SetRecommandationCountAction {
  type: typeof SET_RECOMMANDATION_COUNT;
  payload: number;
}

interface SetPanelCountInCalepinageAction {
  type: typeof SET_PANEL_COUNT_IN_CALEPINAGE;
  payload: number;
}

interface SelectFixationForCalepinageHorsToitureAction {
  type: typeof SELECT_FIXATION_FOR_CALEPINAGE_HORS_TOITURE;
  payload: FixationEntity;
}

interface resetPanelCountInCalepinageAction {
  type: typeof RESET_PANEL_COUNT_IN_CALEPINAGE;
}

interface needComputeMatriceCalepinageAction {
  type: typeof NEED_COMPUTE_MATRICE_CALEPINAGE;
  payload: boolean;
}

interface setDispositionPanneaux {
  type: typeof SET_DISPOSITION_PANNEAUX;
  payload: number;
}
export type CalepinageActionTypes =
  | SetRecommandationCountAction
  | SelectFixationForCalepinageHorsToitureAction
  | SetPanelCountInCalepinageAction
  | resetPanelCountInCalepinageAction
  | SetMatriceCalepinageAction
  | needComputeMatriceCalepinageAction
  | setDispositionPanneaux;

export interface CalepinageState {
  recommandationCount: number;
  panelCountInCalepinage: number;
  fixationSelected: FixationEntity;
  matriceCalepinage: number[][];
  needComputeMatriceCalepinage: boolean;
  dispositionPanneaux: number;
}

const initialState: CalepinageState = {
  recommandationCount: 8,
  panelCountInCalepinage: 8,
  fixationSelected: null,
  matriceCalepinage: [
    [1, 1, 1, 1],
    [1, 1, 1, 1],
  ],
  needComputeMatriceCalepinage: false,
  dispositionPanneaux: 0,
};

export function calepinageReducer(
  state = initialState,
  action: CalepinageActionTypes
): CalepinageState {
  switch (action.type) {
    case SET_RECOMMANDATION_COUNT:
      return {
        ...state,
        recommandationCount: action.payload,
      };
    case SELECT_FIXATION_FOR_CALEPINAGE_HORS_TOITURE:
      return {
        ...state,
        fixationSelected: action.payload,
      };
    case SET_PANEL_COUNT_IN_CALEPINAGE:
      return {
        ...state,
        panelCountInCalepinage: action.payload,
      };
    case RESET_PANEL_COUNT_IN_CALEPINAGE:
      return {
        ...state,
        panelCountInCalepinage: null,
      };
    case SET_MATRICE_CALEPINAGE:
      return {
        ...state,
        matriceCalepinage: action.payload,
      };
    case NEED_COMPUTE_MATRICE_CALEPINAGE:
      return {
        ...state,
        needComputeMatriceCalepinage: action.payload,
      };
    case SET_DISPOSITION_PANNEAUX:
      return {
        ...state,
        dispositionPanneaux: action.payload,
      };
    default:
      return state;
  }
}

export function setRecommandationCount(count: number): CalepinageActionTypes {
  return {
    type: SET_RECOMMANDATION_COUNT,
    payload: count,
  };
}

export function selectFixationForCalepinageHorsToiture(
  fixation: FixationEntity
): CalepinageActionTypes {
  return {
    type: SELECT_FIXATION_FOR_CALEPINAGE_HORS_TOITURE,
    payload: fixation,
  };
}

export function setPanelCountInCalepinage(
  count: number
): CalepinageActionTypes {
  return {
    type: SET_PANEL_COUNT_IN_CALEPINAGE,
    payload: count,
  };
}

export function resetPanelCountInCalepinageAction(): CalepinageActionTypes {
  return {
    type: RESET_PANEL_COUNT_IN_CALEPINAGE,
  };
}

export function setMatriceCalepinage(
  matrice: number[][]
): CalepinageActionTypes {
  return {
    type: SET_MATRICE_CALEPINAGE,
    payload: matrice,
  };
}

export function setNeedComputeMatriceCalepinage(
  need: boolean
): CalepinageActionTypes {
  return {
    type: NEED_COMPUTE_MATRICE_CALEPINAGE,
    payload: need,
  };
}

export function setDispositionPanneaux(
  disposition: number
): CalepinageActionTypes {
  return {
    type: SET_DISPOSITION_PANNEAUX,
    payload: disposition,
  };
}
