import { ConfigurateurProductVariantDto } from "../../../api/dtos/configurateur-product-variant.dto";
import { ConfigurateurProductEntity } from "../configurateur-product.entity";

export class ConfigurateurInverterEntity extends ConfigurateurProductEntity {
  tracker?: number;
  type?: string;

  static fromDto(
    dto: ConfigurateurProductVariantDto
  ): ConfigurateurInverterEntity {
    const type = dto.class_fields.fields.find(
      (f) => f.key === "class.inverter_type"
    )?.value;
    return {
      ...ConfigurateurProductEntity.fromDto(dto),
      tracker: dto.tracker,
      type: type,
    };
  }
}
