import { motion } from "framer-motion";
import styled from "styled-components";
import { OTinyButton } from "..";

export const StyledCloseDialogButton = styled(OTinyButton)`
  float: right;
  width: 20px;
  height: 20px;

  font-size: auto;
  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    float: none;
  }
`;

export const StyledDialog = styled.dialog`
  user-select: none;
  outline: none;
  position: absolute;
  top: 0 !important;
  transform: translateY(-150%);
  z-index: 999 !important;

  * {
    color: ${(props) => props.theme.colors.onyx.onyxPower700} !important;
  }

  max-width: 80vw;
  max-height: 80vh;
  min-width: 600px;
  min-height: 300px;
  overflow: auto;
  border: none;
  border-radius: 8px;
  padding: 2rem;
  background-color: white;
  z-index: 999 !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

export const AskIcon = styled(motion.div)<{
  absolute?: boolean;
  outline?: boolean;
}>`
  color: ${(props) => props.theme.colors.clay.clayPower700} !important;
  position: ${(props) => (props.absolute ? "absolute" : "relative")};
  transform: ${(props) =>
    props.absolute ? "translate(-50%, -50%) scale(0.8)" : "scale(0.8)"};
  cursor: pointer;
  right: ${(props) => (props.absolute ? "2px" : "none")};
  z-index: 15;
  width: 26px;
  height: 26px;
  margin-left:5px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.outline ? "transparent" : props.theme.colors.clay.clayPower500};
  border: solid 2px
    ${(props) =>
      props.outline ? props.theme.colors.onyx.onyxPower700 : "transparent"};
  transition: all 0.2s ease-in-out;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
    border: solid 2px
      ${(props) =>
        props.outline
          ? props.theme.colors.onyx.onyxPower700
          : props.theme.colors.clay.clayPower500};
  }
`;
