import { useSelector } from "react-redux";
import { RootState } from "../../store";

export const usePostcode = (): string => {
  const postcode = useSelector((state: RootState) => {
    if (!state.step.skip) {
      return state.localization?.location?.address?.postcode;
    }
    return state.configurateurInstallHelps.installHelpsData?.postcode;
  });
  return postcode;
};
