import styled from "styled-components";

export const OSelectableCard = styled.div<{
  backgroundColor?: string;
  checked: boolean;
}>`
  width: 100%;
  border-radius: 8px;
  border: solid 1px;
  min-height: 56px;
  display: flex;
  align-items: center;
  
  border-color: ${(props) =>
    props.checked
      ? props.theme.colors.primary.bluePower700
      : props.theme.colors.clay.clayPower600};
  background-color: ${(props) =>
    props.backgroundColor || props.theme.colors.clay.clayPower200};
  padding: 8px 16px 8px 16px;

  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  :hover {
    cursor: pointer;
  }
  .leftItems {
    display: flex;
  }
  .label {
    flex: 1 1 0%;
    padding-left: 16px;
  }

  .trailing {
    align-self: ;
  }

  .sublabel {
    padding: 8px 0px 0px 0px;
  }
`;
