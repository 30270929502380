import { motion } from "framer-motion";
import styled from "styled-components";

// !important used to override Prestashop theme
export const OLinkButton = styled(motion.a)<{ color?: string }>`
  color: ${(props) =>
    props.color || props.theme.colors.primary.bluePower700} !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  text-decoration: underline !important;

  :hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.primary.bluePower700} !important;
  }
`;
