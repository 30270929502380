export interface NeedPhase1ReduxStepData {
  budget: number;
}

export const needPhase1Config = {
  defaultValues: {
    budget: undefined,
  },
  min: 0,
  max: 10000000,
  gridConfig: {
    left: [12, 12, 12],
  },
};

export interface NeedPhase1Props {
  data: any;
  setData: React.Dispatch<React.SetStateAction<any>>;
  onContinue: () => void;
}
