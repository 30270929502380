import { ConfigurateurProductVariantDto } from "../../../api/dtos/configurateur-product-variant.dto";
import { ConfigurateurProductEntity } from "../configurateur-product.entity";

export class ConfigurateurBatteryEntity extends ConfigurateurProductEntity {
  static fromDto(
    dto: ConfigurateurProductVariantDto
  ): ConfigurateurBatteryEntity {
    return {
      ...ConfigurateurProductEntity.fromDto(dto),
    };
  }
}
