import { Orientation2D } from "../../../components/shared/panels_grid/constants";
import { FixationApi } from "../api/fixation-api";
import { FixationTypeDto } from "../dtos/fixation-type-dto";
import { FixationEntity } from "./entities/fixation-entity";
export class FixationRepository {
  private api = new FixationApi();

  public getFixationsByEmplacement(emplacementId: string): FixationTypeDto[] {
    const fixations = this.api.getFixations();
    const fixationsType = this.api.getFixationsType();

    const fixationsForEmplacementIds = fixations
      .filter((fixation) => fixation.placement.includes(emplacementId))
      .map((fixation) => fixation.id)
      .filter((id, index, self) => self.indexOf(id) === index);

    return fixationsType
      .filter((type) => fixationsForEmplacementIds.includes(type.id))
      .sort((a, _b) => {
        // Set fixation métallique on top if emplacement is "Toit plat"
        if (emplacementId === "45" && a.id === 864) {
          return -1;
        }
        return 0;
      });
  }

  public getFixationsByFixationIdAndPanelCount(
    fixationId: number,
    panelCount: number
  ): FixationEntity[] {
    const fixations = this.api.getFixations();

    const fixationsFiltered = fixations
      .filter((fixation) => fixation.id === fixationId)
      .filter((fixation) => fixation.l * fixation.c === panelCount)
      .map((fixation) => {
        const orientation = this.api
          .getFixationsOnLandscapeMode()
          .includes(fixation.id)
          ? Orientation2D.LANDSCAPE
          : Orientation2D.PORTRAIT;
        return {
          ...fixation,
          orientation: orientation,
        };
      });

    return fixationsFiltered;
  }
}

export const fixationService = new FixationRepository();
