import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { PotentialCard } from "./styled";
import {
  OBody5,
  OBody6,
  OBody9,
  OBodyPotentielAddress,
  OH2,
} from "../../../../Styled/Texts";
import { STEP_RECOMMANDATION, devMode } from "../../../../../store/reducer";
import { needCardRightVariants, RecommandationPhase2Config } from "./constants";
// motion
import { motion } from "framer-motion";
import {
  ProgressBar,
  ProgressBarContainer,
  ProgressLabel,
} from "./progress_bar";
import { fade } from "../../../../Styled/GlobalAnimations";
import useIsMobile from "../../../../../utils/useIsMobile";

import { ComponentStatus } from "../../../../shared/status/component-status";
import { SimulationState } from "../../../../../store/simulation/simulation-state";
import { svgsPath } from "../../../../../utils/constants";
import Skeleton from "react-loading-skeleton";

export const PotentialCardComponent: React.FC<{
  simulationState: SimulationState;
  adresse: string;
}> = ({ simulationState, adresse }) => {
  const { t } = useTranslation([STEP_RECOMMANDATION + "phase2", "global"]);
  const defaultValue = "...";
  const config = RecommandationPhase2Config;

  const isMobile = useIsMobile();
  const [isExpanded, setIsExpanded] = useState(!isMobile);
  const simulation = simulationState.simulation;

  const handleCardClick = () => {
    if (isMobile) {
      setIsExpanded(!isExpanded);
    }
  };

  useEffect(() => {
    if (isMobile) {
      setIsExpanded(false);
    } else {
      setIsExpanded(true);
    }
  }, [isMobile]);

  if (simulation.error && simulation.status === ComponentStatus.error) {
    return <></>;
  }

  const heureEnsoleillementFormatted =
    simulation?.data?.potentielSolaire?.heureEnsoleillement?.toFixed(0);

  const percentageOpti =
    simulation?.data?.potentielSolaire?.pourcentageOptimisation;
  const percentageOptiFormatted =
    percentageOpti >= 100 ? "100" : percentageOpti?.toFixed(0);
  const lostRendementFormatted =
    simulation?.data?.potentielSolaire?.lostRendementPercent?.toFixed(0);

  const puissanceFormatted =
    simulation?.data?.potentielSolaire?.productionAnnuelle;

  const isLoading = simulation?.status !== ComponentStatus.loaded;
  return (
    !isLoading && (
      <>
        <PotentialCard
          onClick={handleCardClick}
          {...needCardRightVariants}
          isexpanded={isExpanded.toString()}
        >
          <motion.div
            className="icon-switch"
            style={{ backgroundColor: "#E7A800" }}
          >
            <img src={svgsPath + "swip-up.svg"} alt="" />
          </motion.div>

          {isExpanded ? (
            // Code for Expanded view
            <motion.div className="d-flex flex-column gap-3 overflow-hidden">
              <motion.span
                className="position-relative icon-container"
                {...fade(0.2)}
              >
                <Trans>
                  <OH2>{t("potentialCard.title")}</OH2>
                </Trans>
              </motion.span>

              <OBodyPotentielAddress>
                {adresse && adresse.length > 0 && adresse}
              </OBodyPotentielAddress>

              <OBody9>
                <Trans>{t("potentialCard.description")}</Trans>
              </OBody9>

              <>
                <motion.div
                  className="d-flex flex-column  justify-content-center"
                  {...fade(0.2)}
                >
                  <motion.div className="d-flex  justify-content-start gap-4">
                    <OBody6>
                      <Trans>
                        {t("potentialCard.unitOne", {
                          unit: heureEnsoleillementFormatted ?? defaultValue,
                        })}
                      </Trans>
                    </OBody6>
                    <OBody6>
                      <Trans>
                        {t("potentialCard.unitTwo", {
                          unit: puissanceFormatted ?? defaultValue,
                        })}
                      </Trans>
                    </OBody6>
                  </motion.div>

                  <ProgressBarContainer>
                    <ProgressBar progress={percentageOptiFormatted ?? "0"} />
                    <ProgressLabel progress={percentageOptiFormatted ?? "0"}>
                      {percentageOptiFormatted ?? "..."}{" "}
                      {t("potentialCard.unitThree")}
                    </ProgressLabel>
                  </ProgressBarContainer>
                </motion.div>
                {percentageOpti > config.dynamicPotentialLabelBreakpoints ? (
                  <OBody9 {...fade(0.5)}>
                    <Trans>{t("potentialCard.optimalCondition")}</Trans>
                  </OBody9>
                ) : (
                  <OBody9 {...fade(0.5)}>
                    <Trans>
                      {t("potentialCard.unOptimalCondition", {
                        percentageLost: lostRendementFormatted ?? defaultValue,
                      })}
                    </Trans>
                  </OBody9>
                )}
              </>
            </motion.div>
          ) : (
            // Code for Collapsed view
            <>
              <motion.div className="d-flex flex-column justify-content-center">
                <OBody5>
                  <Trans>
                    {t("potentialCard.unitOne", {
                      unit: heureEnsoleillementFormatted ?? defaultValue,
                    })}
                  </Trans>
                </OBody5>
                <OBody5>
                  <Trans>
                    {t("potentialCard.unitTwo", {
                      unit: puissanceFormatted ?? defaultValue,
                    })}
                  </Trans>
                </OBody5>
                <OBody5>
                  <Trans>
                    {t("potentialCard.collaspedPercentageUnit", {
                      percentage: percentageOptiFormatted,
                    })}
                  </Trans>
                </OBody5>
              </motion.div>
            </>
          )}
        </PotentialCard>{" "}
      </>
    )
  );
};

export default PotentialCardComponent;
