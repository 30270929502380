import styled from "styled-components";
import { motion } from "framer-motion";
import { designPhase2Config } from "./constants";

export const sectionVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 },
};

export const SectionManagerStyled = styled(motion.section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: ${designPhase2Config.sectionGap};
`;

export const SectionContainer = styled(motion.div)<{isroofanddesktop: boolean}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  > *:not(:first-child) {
    gap: ${designPhase2Config.sectionGap};
  }

  > *:first-child {
    margin-bottom: ${(props) => props.isroofanddesktop? "2rem" : "5rem"};
  }

  &.scrollableY {
    // overflow-x: hidden;
    // overflow-y: scroll !important;
    padding:1.5rem;
  }
  background-color:${(props) => props.theme.colors.clay.clayPower666};
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}px) {
    background-color:inherit;
    &.scrollableY { padding:0;}
    > *:first-child {
      margin-bottom:1rem;
    }
  }

`;

export const MobileMatrixContainer = styled(motion.div)`
  // height: 33vh;
`;