import { ConfigurateurProductVariantDto } from "../../../api/dtos/configurateur-product-variant.dto";
import { ConfigurateurProductEntity } from "../configurateur-product.entity";

export class ConfigurateurPanelFixationEntity extends ConfigurateurProductEntity {
  static fromDto(
    dto: ConfigurateurProductVariantDto
  ): ConfigurateurPanelFixationEntity {
    return {
      ...ConfigurateurProductEntity.fromDto(dto),
    };
  }
}

export class ConfigurateurPanelFixationsEntity {
  data: ConfigurateurPanelFixationEntity[];
  fixationsNb: number;
}
