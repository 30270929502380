import React, { useEffect, useState } from "react";
import { STEP_DESIGN, STEP_RECOMMANDATION } from "../../../store/reducer";

import { motion } from "framer-motion";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import store, { RootState } from "../../../store";
import { useConfigurationisLoading } from "../../../store/configurateur/use-configuration-is-loading";
import { useVirtualCartEconomy } from "../../../store/configurateur/virtual-cart/use-virtual-cart-economy";
import { useVirtualCartKitPower } from "../../../store/configurateur/virtual-cart/use-virtual-cart-kit-power";
import { useVirtualCartKitProfitability } from "../../../store/configurateur/virtual-cart/use-virtual-cart-kit-profitability";
import { useVirtualCartTotal } from "../../../store/configurateur/virtual-cart/use-virtual-cart-total-hook";
import { SimulationState } from "../../../store/simulation/simulation-state";
import { assetsPath, svgsPath } from "../../../utils/constants";
import { formatNumber } from "../../../utils/convert-number-locale";
import useIsMobile from "../../../utils/useIsMobile";
import { RecommandationReduxStepData } from "../../Steps/Recommandation/constants";
import { OMiniLoader } from "../../Styled/loader";
import { OBody11, OBody9, OH4 } from "../../Styled/Texts";
import { ComponentStatus } from "../status/component-status";
import { VirtualCartBody } from "./body/virtual-cart-body";
import {
  KitPreviewBar,
  KitPreviewContainer,
  KitPreviewMain,
  KitPreviewSection,
  VirtualCartTotal,
  containerVariants,
  mainVariants,
} from "./styled";
import { GLOBAL_USE_NEW_FORMULA_ECONOMY } from "../constant";
import { dispatch } from "d3";
import { validateMatriceCalepinage } from "../../../store/calepinage/calepinageHorsMapReducer";

/**
 * The component displays total price, power, profitability, and savings of the kit.
 * It allows users to expand or collapse the information shown based on their preferences.
 * This component is designed to not render on mobile devices.
 */
const KitPreview: React.FC<{
  full?: boolean;
  isExpanded?: boolean;
  setIsExpanded;
}> = ({ full = false, isExpanded = false, setIsExpanded }) => {
  const currentStepName = STEP_DESIGN;
  const { t } = useTranslation([currentStepName, "global"]);

  const stepData = useSelector(
    (state: RootState) => state.step.steps[STEP_RECOMMANDATION].data
  ) as RecommandationReduxStepData;
  const isMobile = useIsMobile();
  const defaultValues = "...";

  const handleCardClick = () => {
    setIsExpanded(!isExpanded);
  };
  const virtualCartTotal = useVirtualCartTotal();
  const simulationState = useSelector(
    (state: RootState) =>
      (state.simulationReducer as SimulationState).simulation
  );
  const isSimulationLoading =
    simulationState?.status === ComponentStatus.loading;
  const [displayData] = useState({
    totalPrice: stepData.phase2.mapping.stats.prix,
    panelsPower: stepData.phase2.mapping.potential.annualProduction,
    rentability: stepData.phase2.mapping.profit.resale,
    savings: stepData.phase2.mapping.profit.savings,
    panelsNumber: stepData.phase2.mapping.stats.panelsNumber,
  });
  const kitPower = useVirtualCartKitPower();
  const economy = useVirtualCartEconomy(kitPower).max;
  const savings = useVirtualCartKitProfitability(
    virtualCartTotal.discountedPrice,
    economy,
    false
  );
  const profitability = useVirtualCartKitProfitability(
    virtualCartTotal.discountedPrice,
    economy,
    GLOBAL_USE_NEW_FORMULA_ECONOMY
  );
  
  const isConfigurationLoading = useConfigurationisLoading();
  const [wasLoading, setWasLoading] = useState(false);
  
  useEffect(() => {
    if(isConfigurationLoading && !wasLoading) {
      setWasLoading(true);
    }
    if (wasLoading && !isConfigurationLoading) {
      store.dispatch(validateMatriceCalepinage());
    }
  }, [isConfigurationLoading, wasLoading]);

  if (isMobile) {
    return null;
  }

  return (
    <KitPreviewContainer
      className={isExpanded ? "expanded" : ""}
      variants={containerVariants}
      initial="initial"
      animate={isExpanded ? "expanded" : "collapsed"}
    >
      {isExpanded ? (
        <KitPreviewMain
          variants={mainVariants}
          initial="collapsed"
          animate={isExpanded ? "expanded" : "collapsed"}
        >
          <VirtualCartBody />
        </KitPreviewMain>
      ) : (
        <motion.span></motion.span>
      )}
      <KitPreviewBar>
        <KitPreviewSection
          className={full ? "main" : "full"}
          isexpanded={isExpanded.toString()}
        >
          {full && (
            <motion.div
              className="icon-switch"
              style={{ backgroundColor: "white" }}
              onClick={handleCardClick}
            >
              <img src={svgsPath + "swip-up-black.svg"} alt="" />
            </motion.div>
          )}
          <div>
            <VirtualCartTotal>
              {virtualCartTotal.price > virtualCartTotal.discountedPrice &&
                !isConfigurationLoading && (
                  <OBody11>{formatNumber(virtualCartTotal.price)}</OBody11>
                )}
              <OH4
                className={`${
                  isConfigurationLoading ? "virtual-cart-title" : ""
                }`}
              >
                <Trans>
                  {formatNumber(virtualCartTotal.discountedPrice) || "0"}
                </Trans>
              </OH4>
            </VirtualCartTotal>

            {isConfigurationLoading && (
              <OMiniLoader className="virtual-cart-loader" />
            )}
          </div>
        </KitPreviewSection>
        <KitPreviewSection>
          {full && (
            <>
              <motion.div>
                <OBody9 className="text-center">
                  <Trans>
                    {t("preview.solar_kit.title", {
                      value: displayData.panelsNumber ?? defaultValues,
                    })}
                  </Trans>
                </OBody9>
              </motion.div>
              <OH4>
                <Trans>
                  {t("preview.solar_kit.unit", {
                    value: kitPower ? formatNumber(kitPower, 1, false, {}, false, true) : "-",
                  })}
                </Trans>
              </OH4>
            </>
          )}
        </KitPreviewSection>
        {/* <KitPreviewSection>
          {full && (savings || isSimulationLoading) && (
            <>
              <motion.div>
                <OBody9 className="text-center">
                  <Trans>{t("preview.rentability.title")}</Trans>
                </OBody9>
              </motion.div>
              <OH4>
                <Trans>
                  {isSimulationLoading ? (
                    <LoaderSimulation />
                  ) : (
                    t("preview.rentability.unit", {
                      value: savings,
                    })
                  )}
                </Trans>
              </OH4>
            </>
          )}
        </KitPreviewSection> */}
        <KitPreviewSection>
          {full && (economy || isSimulationLoading) && (
            <>
              <motion.div>
                <OBody9 className="text-center">
                  <Trans>{t("preview.economy.title")}</Trans>
                </OBody9>
              </motion.div>
              <OH4>
                <Trans>
                  {(!isSimulationLoading && !isConfigurationLoading)? (
                    t("preview.economy.unit", {
                      value: economy ? formatNumber(economy, 0) : defaultValues,
                    })
                  ) : (
                    <LoaderSimulation />
                  )}
                </Trans>
              </OH4>
            </>
          )}
        </KitPreviewSection>
        <KitPreviewSection>
          {full && (profitability || isSimulationLoading) && (
            <>
              <motion.div>
                <OBody9 className="text-center">
                  <Trans>{t("preview.profitability.title")}</Trans>
                </OBody9>
              </motion.div>
              <OH4>
                <Trans>
                  {(!isSimulationLoading && !isConfigurationLoading) ? (
                    t("preview.profitability.unit", {
                      value: profitability ? profitability : defaultValues,
                    })
                  ) : (
                    <LoaderSimulation />
                  )}
                </Trans>
              </OH4>
            </>
          )}
        </KitPreviewSection>
      </KitPreviewBar>
    </KitPreviewContainer>
  );
};

const LoaderSimulation = styled.img.attrs((props) => ({
  src: assetsPath + "loader/loader.gif",
}))`
  width: 20px;
  height: 20px;
`;

export default KitPreview;
