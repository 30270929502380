import { useSelector } from "react-redux";
import { RootState } from "..";
import { ComponentStatus } from "../../components/shared/status/component-status";

export const useConfigurationisLoading = (): boolean => {
  const isLoading = useSelector(
    (state: RootState) =>
      state.configurateurPanels?.panelsData?.status ===
        ComponentStatus.loading ||
      state.configurateurOnduleurs?.onduleursData?.status ===
        ComponentStatus.loading ||
      state.configurateurFixations?.fixationsData?.status ===
        ComponentStatus.loading ||
      state.configurateurSupervisions?.supervisionData?.status ===
        ComponentStatus.loading ||
      state.configurateurGaranties?.garantiesData?.status ===
        ComponentStatus.loading ||
      state.configurateurBatteries?.batteriesData?.status ===
        ComponentStatus.loading ||
      state.configurateurHelps?.helpsData?.status === ComponentStatus.loading ||
      state.configurateurAccessories?.accessoriesData?.status ===
        ComponentStatus.loading ||
      state.configurateurInstallHelps?.installHelpsData?.status ===
        ComponentStatus.loading
  );
  return isLoading;
};
