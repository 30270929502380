import { ComponentStatus } from "../../../components/shared/status/component-status";
import { kitsService } from "../../../data/kits/services/kits.service";

import { ConfigurateurPresetConfigurationState } from "./configurateur-preset-configuration-state";

const initialState: ConfigurateurPresetConfigurationState = {
  presetConfigurationData: {
    status: ComponentStatus.idle,
  },
};

enum ActionType {
  loadPresetConfiguration = "loadPresetConfiguration",
}

type Action = {
  type: ActionType;
  newState?: ConfigurateurPresetConfigurationState;
};

export const configurationPresetConfigurationReducer = (
  state = initialState,
  action: Action
): ConfigurateurPresetConfigurationState => {
  const { type, newState } = action;

  switch (type) {
    case ActionType.loadPresetConfiguration:
      return {
        ...state,
        presetConfigurationData: newState?.presetConfigurationData,
      };
    default:
      return { ...state };
  }
};

export const configurateurPresetConfigurationLoad =
  (presetConfigurationId: string) => async (dispatch, getStore) => {
    try {
      dispatch({
        type: ActionType.loadPresetConfiguration,
        newState: {
          presetConfigurationData: {
            status: ComponentStatus.loading,
          },
        },
      });
      const details = await kitsService.getKitDetails(presetConfigurationId);
      if (!details) {
        dispatch({
          type: ActionType.loadPresetConfiguration,
          newState: {
            presetConfigurationData: {
              status: ComponentStatus.loaded,
            },
          },
        });
      }
      const action: Action = {
        type: ActionType.loadPresetConfiguration,
        newState: {
          presetConfigurationData: {
            status: ComponentStatus.loaded,
            details: details,
          },
        },
      };
      dispatch(action);
    } catch (_) {
      dispatch({
        type: ActionType.loadPresetConfiguration,
        newState: {
          presetConfigurationData: {
            status: ComponentStatus.error,
          },
        },
      });
    }
  };
