import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { Sections } from "../constants";
import { STEP_DESIGN } from "../../../../../../store/reducer";
import { sectionVariants } from "../styled";
import { useSelector } from "react-redux";
import store, { RootState } from "../../../../../../store";
import { OBody11, OBody6, OBody8, OH3 } from "../../../../../Styled/Texts";
import { OActionButton } from "../../../../../Styled/Buttons/OActionButton";
import { SectionLightningArresterData } from "./constants";
import { SectionLightningArrester } from "./styled";
import { AskIcon } from "../../../../../Styled/Buttons/AskButton/styled";
import { ConfigurateurAccessoriesCombination } from "../../../../../../store/configurateur/accessories/configurateur-accessories-state";
import { configurateurAccessoriesOnCombinationChanged } from "../../../../../../store/configurateur/accessories/configurateur-accessories-reducer";
import { LightningArresterTooltip } from "../lightning-arrester/lightning-arrester-tooltip";
import { formatNumber } from "../../../../../../utils/convert-number-locale";
import { OInfoCard } from "../../../../../Styled/info-card/o-info-card";

const LightningArresterComponent: React.FC<{
  initial: SectionLightningArresterData;
}> = ({ initial }) => {
  const currentStepName = STEP_DESIGN;
  const currentSectionName = Sections.LIGHTNING_ARRESTER;
  const { t } = useTranslation([currentStepName, "global"]);

  const accessoriesCombination = useSelector(
    (state: RootState) =>
      state.configurateurAccessories?.accessoriesData?.selectedCombination
  ) as ConfigurateurAccessoriesCombination;

  const priceDelta = useSelector((state: RootState) => {
    const withParafoudre =
      state.configurateurAccessories?.accessoriesData?.combinations.find(
        (combination) =>
          combination.entryA === accessoriesCombination.entryA &&
          combination.entryB === accessoriesCombination.entryB &&
          combination.withParafoudre === true
      );
    const withoutParafoudre =
      state.configurateurAccessories?.accessoriesData?.combinations.find(
        (combination) =>
          combination.entryA === accessoriesCombination.entryA &&
          combination.entryB === accessoriesCombination.entryB &&
          combination.withParafoudre === false
      );
    return withParafoudre?.totalPrice - withoutParafoudre?.totalPrice;
  }) as number;

  return (
    <SectionLightningArrester
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={sectionVariants}
      id={currentSectionName + "_anchor"}
    >
      <OH3 center="true">
          <Trans>
            {t("phase_2.sections." + currentSectionName + ".title")}
          </Trans>
        </OH3>
        <OBody6 center="true" className="mt-3">
          {t("phase_2.sections." + currentSectionName + ".description")}
        </OBody6>
      <motion.div className="section__title">
        {/* <OH3 center="true">
          <Trans>
            {t("phase_2.sections." + currentSectionName + ".title")}
          </Trans>
        </OH3>
        <AskIcon className="lightning-arrester-ask">
          ?<LightningArresterTooltip />
        </AskIcon> */}
      </motion.div>
      {accessoriesCombination?.parafoudreRequired && (
        <OInfoCard width="100%">
          <OBody11>
            {t(
              "phase_2.sections." + currentSectionName + ".parafoudreRequired"
            )}
          </OBody11>
        </OInfoCard>
      )}
      <motion.div className="section__buttons">
        <OActionButton
          className="design"
          isactive={
            accessoriesCombination?.withParafoudre === false ? "true" : "false"
          }
          onClick={() => {
            store.dispatch(
              configurateurAccessoriesOnCombinationChanged({
                withParafoudre: false,
              })
            );
          }}
        >
          {t("without")}
        </OActionButton>
        <OActionButton
          className="design"
          isactive={
            accessoriesCombination?.withParafoudre === true ? "true" : "false"
          }
          onClick={() => {
            store.dispatch(
              configurateurAccessoriesOnCombinationChanged({
                withParafoudre: true,
              })
            );
          }}
          sublabel={priceDelta > 0 && `+${formatNumber(priceDelta, 0)}`}
        >
          {t("with")}
        </OActionButton>
      </motion.div>
    </SectionLightningArrester>
  );
};

export default LightningArresterComponent;
