import { useTranslation } from "react-i18next";
import { STEP_RECOMMANDATION } from "../../../../../../store/reducer";
import { OBody5 } from "../../../../../Styled/Texts";
import { Modal } from "../../../../../Styled/modals";

export const RecommandationEstimationModal = (props: {
  show: boolean;
  onClose;
}) => {
  const { t } = useTranslation([STEP_RECOMMANDATION + "phase2", "global"]);

  return (
    <Modal
      show={props.show}
      title={t("stats.modal.title")}
      onClose={props.onClose}
    >
      <div className="modal-content-list">
        <OBody5>{t("stats.modal.description1")}</OBody5>
      </div>
      <br />
      <OBody5>{t("stats.modal.description2")}</OBody5>
    </Modal>
  );
};
