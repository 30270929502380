import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ConfigurateurPanelEntity } from "../../../../../../data/configurateur/services/entities/panels/configurateur-panel.entity";
import { STEP_DESIGN } from "../../../../../../store/reducer";
import { svgsPath } from "../../../../../../utils/constants";
import { formatNumber } from "../../../../../../utils/convert-number-locale";
import { OBody10, OBody11, OBody12, OBody8 } from "../../../../../Styled/Texts";

export const PanelDetailItem = (props: { panel: ConfigurateurPanelEntity }) => {
  const { t } = useTranslation([STEP_DESIGN, "global"]);
  const panel = props.panel;

  return (
    <div className="flex">
      {panel?.configurator_name ? (
        <PanelDetailItemLabelContainer>
          <OBody11>{panel?.configurator_name}</OBody11>
        </PanelDetailItemLabelContainer>
      ) : (
        <PanelDetailItemLabelContainer backgroundColor="none">
          <OBody11>&nbsp;</OBody11>
        </PanelDetailItemLabelContainer>
      )}
      <PanelDetailItemContainer>
        <img
          className="panel-detail-img"
          src={panel?.parent.default_image?.url}
          alt={panel?.parent.title}
        />
        <OBody8>{panel?.configurator_name}</OBody8>
        <OBody12>{panel?.parent.vendor}</OBody12>
        <OBody10>{formatNumber(panel?.price_discounted, 0)}</OBody10>
        {panel?.power && (
          <>
            <PanelDetailFeature>
              <img
                src={`${svgsPath}shield-elec.svg`}
                alt={panel?.configurator_name}
              />
              <OBody10>{panel?.power} Wc</OBody10>
              <OBody12>Puissance</OBody12>
            </PanelDetailFeature>
          </>
        )}
        {panel?.guranteeYear && (
          <>
            <PanelDetailFeature>
              <img
                src={`${svgsPath}warranty.svg`}
                alt={panel?.configurator_name}
              />
              <OBody10>{panel?.guranteeYear} ans</OBody10>
              <OBody12>Garantie produit</OBody12>
            </PanelDetailFeature>
          </>
        )}
        {panel?.dimension?.height &&
          panel?.dimension?.length &&
          panel?.dimension?.width && (
            <>
              <PanelDetailFeature>
                <img
                  src={`${svgsPath}dimension.svg`}
                  alt={panel?.configurator_name}
                />
                <OBody10>
                  {t(
                    "phase_2.sections.solar_kit.sections.modal.dimension.value",
                    {
                      width: panel.dimension.width,
                      length: panel.dimension.length,
                      height: panel.dimension.height,
                    }
                  )}
                </OBody10>
                <OBody12>
                  {t(
                    "phase_2.sections.solar_kit.sections.modal.dimension.title"
                  )}
                </OBody12>
              </PanelDetailFeature>
            </>
          )}
      </PanelDetailItemContainer>
    </div>
  );
};

const PanelDetailItemLabelContainer = styled.div<{ backgroundColor?: string }>`
  text-align: center;
  margin-right: 16px;
  background-color: ${(props) =>
    props.backgroundColor || props.theme.colors.tertiary.leafPower700};
  border-radius: 4px;
  p {
    opacity: 0.7;
    color: ${(props) => props.theme.colors.clay.clayPower200};
  }
`;

const PanelDetailItemContainer = styled.div`
  border: 2px solid ${(props) => props.theme.colors.clay.clayPower500};
  border-radius: 12px;
  padding: 16px;
  margin-top: 8px;
  margin-right: 16px;
  margin-bottom: 8px;
  text-align: center;

  .panel-detail-img {
    height: 120px;
    padding-bottom: 8px;
    max-width: fit-content;
  }
`;

const PanelDetailFeature = styled.div`
  margin-top: 24px;
`;
