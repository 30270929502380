import styled from "styled-components";

import { OTertiaryButton } from "./OTertiaryButton";

export const OReturnButton = styled(OTertiaryButton)`
  border: none;
  background: transparent;
  min-height: ${(props) => props.theme.heights.input};
  text-decoration: underline;

  &:hover {
  }
`;
