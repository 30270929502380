import { Orientation2D } from "../panels_grid/constants";

export type Position = [number, number];

export const MAX_PANEL_COUNT = 25;
export const PANEL_WIDTH_PORTRAIT = 1.096;
export const PANEL_WIDTH_PAYSAGE = 1.812;
export const PANEL_HEIGHT_PORTRAIT = 1.812;
export const PANEL_HEIGHT_PAYSAGE = 1.096;
export const ZOOM_MAX_OUT = 18;
export const MAX_AREA = 500;
export const URL_TUTO_CALEPINAGE =
  "https://doc.oscaro-power.com/tuto-calepinage.mp4";

export interface CalepinageToitureProps {
  position?: Position;
  mapMode?: boolean;
  canvasMode?: boolean;
  orientation?: Orientation2D;
  mainButtonClicked?: number;
  deleteAll?: boolean;
  setDeleteAll?: (deleteAll: boolean) => void;
}
