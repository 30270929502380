import React, { useCallback } from 'react';
import store from '../../../store';
import { OMaterialButton } from '../../Styled/Buttons/OMaterialButton';
import { HorizontalDimensionElements } from './styled-horizontal-dimension';

interface HorizontalDimensionProps {
    value: string;
    // id?: string;
    // index: number;
    // disposition: number;
    // className?: string;
}

// const HorizontalDimension: React.FC<HorizontalDimensionProps> = ({ index, disposition }) => {
const HorizontalDimension: React.FC<HorizontalDimensionProps> = ({value}) => {

    // const addPanelInRowEvent = () => {
    //     store.dispatch(addPanelInRow({ rowIndex: index }));
    // }

    return(
        <HorizontalDimensionElements>

            <span className="body">
                {value}
            </span>

            <div className="arrow">
                <span className="material-symbols-rounded back">keyboard_arrow_left</span>
                <span className="line"></span>
                <span className="material-symbols-rounded forward">keyboard_arrow_right</span>
            </div>

        </HorizontalDimensionElements>
    );
};

export default HorizontalDimension;