import { createSelector } from "@reduxjs/toolkit";
import { RootState } from ".";
import { isRoofType } from "../components/Steps/Installation/constants";
import { getPanelCount } from "./calepinage/calepinageHorsMapReducer";
import { STEP_INSTALLATION } from "./reducer";

// The function takes the entire Redux state as a parameter, and extracts just the part of the state it needs.
export const selectCurrentStep = (state) => state.step.currentStep;

// This selector takes an array of selectors (or an array of input-selectors), and a transformer function as its parameters.
export const isCurrentStep = (key) =>
  createSelector([selectCurrentStep], (currentStep) => currentStep === key);

export const getCurrentPanelCount = (state: RootState) => {
  const installationData = state.step.steps[STEP_INSTALLATION].data;
  const hasSkipped = state.step.skip;
  if (hasSkipped && isRoofType(installationData?.installation)) {
    return getPanelCount(
      state.calepinageHMReducer?.matriceCalepinageApiValidated
    );
  }
  return state.calepinageReducer?.panelCountInCalepinage;
};
