import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  STEP_DESIGN,
  STEP_INSTALLATION,
  STEP_RECOMMANDATION,
} from "../../../../../store/reducer";
import { SectionConfig, Sections } from "./constants";
import AlimentationTypeComponent from "./sections_content/1_alimentation_type";
import SolarKitComponent from "./sections_content/2_solar_kit";
import WiringComponent from "./sections_content/3_wiring";
import SupervisingComponent from "./sections_content/4_supervising";
import LightningArresterComponent from "./sections_content/5_lightning_arrester";
import WarrantyComponent from "./sections_content/6_warranty";
import BatteryComponent from "./sections_content/7_battery";
import AdministrationHelpComponent from "./sections_content/8_administration_help";
import InstallationHelpComponent from "./sections_content/9_installation_help";
import { SectionManagerStyled } from "./styled";

import store, { RootState } from "../../../../../store";
import { configurateurPanelsLoad } from "../../../../../store/configurateur/panels/configurateur-panels-reducer";

import { useSelector } from "react-redux";
import { useEffectAccessories } from "../../../../../hooks/accessories/use-effect-accessories";
import { useIsCalepinageHmValidated } from "../../../../../hooks/configurateur/calepinage/use-calepinage-hm-validated";
import { usePanelCount } from "../../../../../hooks/configurateur/panels/use-panel-count.hook";
import {
  useEffectPanneauFixations,
  useFixationIds,
} from "../../../../../hooks/fixations/use-fixations-hook";
import { usePostcode } from "../../../../../hooks/location/location-hook";
import { configurateurBatteriesLoad } from "../../../../../store/configurateur/batteries/configurateur-batteries-reducer";
import { configurateurGarantiesLoad } from "../../../../../store/configurateur/garanties/configurateur-garanties-reducer";
import { configurateurHelpsLoad } from "../../../../../store/configurateur/helps/configurateur-helps-reducer";
import { configurateurInstallHelpsLoad } from "../../../../../store/configurateur/install-helps/configurateur-install-helps-reducer";
import { configurateurOnduleursLoad } from "../../../../../store/configurateur/onduleurs/configurateur-onduleurs-reducer";
import { configurateurSupervisionLoad } from "../../../../../store/configurateur/supervision/configurateur-supervision-reducer";
import { useConfigurationisLoading } from "../../../../../store/configurateur/use-configuration-is-loading";
import { useVirtualCartItems } from "../../../../../store/configurateur/virtual-cart/use-virtual-cart-items-hook";
import {
  useVirtualCartInstallHelpToAdd,
  useVirtualCartItemsToAdd,
} from "../../../../../store/configurateur/virtual-cart/use-virtual-cart-items-to-add-hook";
import { useVirtualCartKitPower } from "../../../../../store/configurateur/virtual-cart/use-virtual-cart-kit-power";
import { useVirtualCartTotal } from "../../../../../store/configurateur/virtual-cart/use-virtual-cart-total-hook";
import { kitCreate } from "../../../../../store/kit/kit-reducer";
import { formatNumber } from "../../../../../utils/convert-number-locale";
import { trackDataLayer } from "../../../../../utils/tracking/tracking-utils";
import useIsMobile from "../../../../../utils/useIsMobile";
import { OMainButton } from "../../../../Styled/Buttons";
import { OLinkButton } from "../../../../Styled/Buttons/Link/o-link-button";
import { theme } from "../../../../Styled/GlobalStyle";
import { OMiniLoader } from "../../../../Styled/loader";
import { OBody11, OBody4, OBody5 } from "../../../../Styled/Texts";
import { InstallationType } from "../../../Installation/constants";
import { StructureTypesId } from "../../../Recommandation/phases/phase1/constants";
import { SectionEmplacement } from "./emplacement";
import { InstallHelpSummary } from "./install-help/summary/install-help-summary";
import { getPhaseNumberFromAlimType } from "./sections_content";
import CalepinageHorsToitureTypeComponent from "./sections_content/0_calepinageHorsToiture_type";
import CalepinageToitureMobileComponent from "./sections_content/0_calepinageToiture_mobile_type";
import {
  TotalContainer,
  TotalContent,
  TotalWithDiscount,
} from "./sections_content/styled";

interface SectionManagerProps {
  initialConfig: SectionConfig;
  isCartExpanded: boolean;
  onViewCartDetailClicked: any;
}

const SectionManager: React.FC<SectionManagerProps> = ({
  initialConfig,
  isCartExpanded,
  onViewCartDetailClicked,
}) => {
  const [config] = useState<SectionConfig>(initialConfig);
  const currentStepName = STEP_DESIGN;
  const { t } = useTranslation([currentStepName, "global"]);

  const hasSkipped = useSelector((state: RootState) => state.step.skip);
  const alimentation = useSelector(
    (state: RootState) => state.configurateurAlimentation.alimentationData.type
  );
  const emplacement = useSelector(
    (state: RootState) =>
      state.step.steps[STEP_RECOMMANDATION].data.phase1?.material
  );

  const panelCount = usePanelCount();
  const panelsData = useSelector(
    (state: RootState) => state.configurateurPanels?.panelsData
  );

  const onduleursData = useSelector(
    (state: RootState) => state.configurateurOnduleurs?.onduleursData
  );
  const supervisionData = useSelector(
    (state: RootState) => state.configurateurSupervisions?.supervisionData
  );

  const installationData = useSelector(
    (state: RootState) => state.step.steps[STEP_INSTALLATION].data
  );

  const [batteryId, batteryAttribute] = useSelector((state: RootState) => [
    state.configurateurBatteries?.batteriesData?.selected
      ?.legacy_presta_product_id,
    state.configurateurBatteries?.batteriesData?.selected
      ?.legacy_presta_product_attribute_id,
  ]);

  const isRoof = installationData?.installation === InstallationType.Roof;

  const fixationNumber = useSelector(
    (state: RootState) =>
      state.configurateurFixations?.fixationsData?.panneauFixation?.fixationsNb
  );

  const postcode = usePostcode();

  // Refresh panels
  useEffect(() => {
    store.dispatch(configurateurPanelsLoad());
  }, []);

  // Fixations
  const matrice = useSelector((state: RootState) => {
    if (state.step.skip) {
      return state.calepinageHMReducer?.matriceCalepinageApiValidated;
    }
    return state.calepinageReducer?.matriceCalepinage;
  });

  const dispositionPanneaux = useSelector((state: RootState) => {
    if (state.step.skip) {
      return state.calepinageHMReducer?.dispositionPanneaux ? 0 : 1; // FUCK
    }
    return state.calepinageReducer?.dispositionPanneaux;
  });

  const fixationIds = useFixationIds();
  useEffectPanneauFixations(
    installationData?.installation,
    matrice,
    StructureTypesId[emplacement] as number,
    dispositionPanneaux,
    panelsData?.selected?.legacy_presta_product_id
  );

  // Load garanties
  useEffect(() => {
    store.dispatch(configurateurGarantiesLoad());
  }, []);

  // Refresh onduleurs on panels loaded
  useEffect(() => {
    if (panelsData?.data?.length > 0) {
      const emplacementId = StructureTypesId[emplacement] as number;

      const panelId = panelsData?.selected?.legacy_presta_product_id;
      const panelIdAttribute =
        panelsData?.selected?.legacy_presta_product_attribute_id;
      const phaseNumber = getPhaseNumberFromAlimType(alimentation);
      if (emplacementId && fixationIds?.id_legacy && panelId && phaseNumber) {
        store.dispatch(
          configurateurOnduleursLoad(
            emplacementId,
            fixationIds?.id_legacy,
            panelId,
            panelIdAttribute,
            panelCount,
            phaseNumber
          )
        );
      }
    }
  }, [
    emplacement,
    panelsData?.selected?.legacy_presta_product_id,
    alimentation,
    panelCount,
    fixationIds?.id_legacy,
  ]);

  // Refresh supervisions on onduleurs load
  useEffect(() => {
    const [id, attribute] = [
      onduleursData?.selected?.legacy_presta_product_id,
      onduleursData?.selected?.legacy_presta_product_attribute_id,
    ];
    if (id && attribute) {
      store.dispatch(configurateurSupervisionLoad(id, attribute));
    }
  }, [
    onduleursData?.selected?.legacy_presta_product_id,
    onduleursData?.selected?.legacy_presta_product_attribute_id,
  ]);

  // Refresh accessories combinations
  useEffectAccessories(
    alimentation,
    panelsData?.selected,
    onduleursData?.selected,
    supervisionData?.selected,
    fixationNumber
  );

  // Refresh batteries on onduleurs changed
  useEffect(() => {
    const onduleur = onduleursData?.selected;
    if (onduleur && alimentation) {
      store.dispatch(
        configurateurBatteriesLoad(
          onduleur?.legacy_presta_product_id,
          onduleur?.legacy_presta_product_attribute_id,
          getPhaseNumberFromAlimType(alimentation)
        )
      );
    }
  }, [onduleursData?.selected?.id]);

  // Refresh helps on panel changed
  useEffect(() => {
    const panelId = panelsData?.selected?.legacy_presta_product_id;
    const panelIdAttribute =
      panelsData?.selected?.legacy_presta_product_attribute_id;

    if (panelIdAttribute !== undefined && panelCount) {
      store.dispatch(
        configurateurHelpsLoad(
          panelId,
          panelIdAttribute,
          panelCount,
          batteryId,
          batteryAttribute
        )
      );
    }
  }, [
    panelsData?.selected?.legacy_presta_product_id,
    panelsData?.selected?.legacy_presta_product_attribute_id,
    panelCount,
    batteryId,
    batteryAttribute,
  ]);

  // Refresh install helps (dealt) on onduleur, alim or localization changed
  useEffect(() => {
    const onduleur = onduleursData?.selected;
    if (onduleur && alimentation) {
      store.dispatch(
        configurateurInstallHelpsLoad(postcode, onduleur, alimentation)
      );
    }
  }, [onduleursData?.selected?.id, alimentation, postcode]);

  const itemsToAdd = useVirtualCartItemsToAdd();
  const installHelpToSave = useVirtualCartInstallHelpToAdd();
  const isMobile = useIsMobile();

  const items = useVirtualCartItems();
  const kitPower = useVirtualCartKitPower();
  const isConfigurationLoading = useConfigurationisLoading();
  const isCalepinageHmValidated = useIsCalepinageHmValidated();
  const total = useVirtualCartTotal();

  // Format cart data we want to track
  const getDataToTrack = useCallback(() => {
    return {
      cartPreview: {
        kitPower: kitPower,
        panelsNumber: panelCount,
        categories: items.map((cartItem) => {
          return {
            category: cartItem.category,
            items: cartItem.items.map((item) => item.details),
          };
        }),
      },
    };
  }, [items, kitPower, panelCount]);

  return (
    <SectionManagerStyled>
      {hasSkipped && <SectionEmplacement />}
      {config[Sections.CALEPINAGE_HORS_TOITURE].display && !isRoof && (
        <CalepinageHorsToitureTypeComponent />
      )}
      {config[Sections.CALEPINAGE_TOITURE_MOBILE].display &&
        isRoof &&
        isMobile && <CalepinageToitureMobileComponent />}
      {config[Sections.ALIMENTATION_TYPE].display && (
        <AlimentationTypeComponent />
      )}
      {config[Sections.SOLAR_KIT].display && (
        <SolarKitComponent initial={config[Sections.SOLAR_KIT]} />
      )}
      {config[Sections.WIRING].display && (
        <WiringComponent initial={config[Sections.WIRING]} />
      )}
      {config[Sections.SUPERVISING].display && (
        <SupervisingComponent initial={config[Sections.SUPERVISING]} />
      )}
      {config[Sections.LIGHTNING_ARRESTER].display && (
        <LightningArresterComponent
          initial={config[Sections.LIGHTNING_ARRESTER]}
        />
      )}
      {config[Sections.WARRANTY].display && (
        <WarrantyComponent initial={config[Sections.WARRANTY]} />
      )}
      {config[Sections.BATTERY].display && (
        <BatteryComponent initial={config[Sections.BATTERY]} />
      )}
      {config[Sections.ADMINISTRATION_HELP].display && (
        <AdministrationHelpComponent
          initial={config[Sections.ADMINISTRATION_HELP]}
        />
      )}
      {config[Sections.INSTALLATION_HELP].display && (
        <InstallationHelpComponent
          initial={config[Sections.INSTALLATION_HELP]}
        />
      )}

      <TotalContainer>
        <TotalContent>
          <OBody5>{t("total")}</OBody5>
          {!isConfigurationLoading ? (
            <TotalWithDiscount>
              {total.price > total.discountedPrice && (
                <OBody11 className="discount">
                  {total ? formatNumber(total.price) : "-"}
                </OBody11>
              )}
              <OBody4>
                {total.discountedPrice
                  ? formatNumber(total.discountedPrice)
                  : "-"}
              </OBody4>
            </TotalWithDiscount>
          ) : (
            <OMiniLoader width={"24px"} />
          )}
        </TotalContent>
        <OLinkButton
          color={theme.colors.clay.clayPower700}
          onClick={onViewCartDetailClicked}
        >
          {isCartExpanded ? t("hideCartDetails") : t("viewCartDetails")}
        </OLinkButton>
        {installHelpToSave && (
          <InstallHelpSummary item={installHelpToSave}></InstallHelpSummary>
        )}
        <OMainButton
          className="w-100 conception-configuration-next-btn"
          center
          primary
          disabled={isConfigurationLoading || !isCalepinageHmValidated}
          onClick={
            !isConfigurationLoading && isCalepinageHmValidated
              ? () => {
                  trackDataLayer("CartSelected", getDataToTrack());
                  store.dispatch(kitCreate(itemsToAdd, matrice));
                }
              : null
          }
        >
          {t("phase_2.continue")}
        </OMainButton>
      </TotalContainer>
    </SectionManagerStyled>
  );
};

export default SectionManager;
