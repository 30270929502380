import _ from 'lodash';

export const transposeMatrix = (matrix: number[][]): number[][] => {


    // const transposedMatrix = matrix[0].map((_, colIndex) => matrix.map((row) => row[colIndex]));
    const transposedMatrix = _.zip(...matrix);

    // replace undefined values by 0
    transposedMatrix.forEach((row, rowIndex) => {
        row.forEach((col, colIndex) => {
            if (transposedMatrix[rowIndex][colIndex] === undefined) {
                transposedMatrix[rowIndex][colIndex] = 0;
            }
        })
    })

    // for each row, while the last value is 0, remove it
    transposedMatrix.forEach((row, rowIndex) => {
        while (transposedMatrix[rowIndex][transposedMatrix[rowIndex].length - 1] === 0) {
            transposedMatrix[rowIndex].pop();
        }
    })
    
    return transposedMatrix;
  }