import styled from "styled-components";
import { InstallHelpEntity } from "../../../../../../../data/prestashop/services/entities/product-entity";
import { OBody4, OBody7, OBody8 } from "../../../../../../Styled/Texts";
import { useTranslation } from "react-i18next";
import { STEP_DESIGN } from "../../../../../../../store/reducer";
import { formatNumber } from "../../../../../../../utils/convert-number-locale";

export const InstallHelpSummary = (props: { item: InstallHelpEntity }) => {
  const { t } = useTranslation([STEP_DESIGN, "global"]);
  return (
    <InstallHelpContainer>
      <div className="install-help-summary-leading">
        <img src={props.item.thumbnail} alt={props.item.title} />
        <div>
          <OBody7>{props.item.title}</OBody7>
          <OBody8>
            {t("virtualCart.category.installHelp.paymentDescription")}
          </OBody8>
        </div>
      </div>
      <OBody4>{formatNumber(props.item.kitPrice)}</OBody4>
    </InstallHelpContainer>
  );
};

const InstallHelpContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.colors.clay.clayPower700};
  padding: 16px 0px 16px 0px;
  
  hr {
    opacity: 1;
    margin: 0px;
    border-top: 1px solid ${(props) => props.theme.colors.clay.clayPower200};
    padding: 24px 0px 0px 24px;
  }

  img {
    width: 40px;
    height: 40px;
  }

  .install-help-summary-leading {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    p {
      padding 0px 0px 0px 8px;
    }
  }
`;
