import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Sections } from "../constants";
import { STEP_DESIGN } from "../../../../../../store/reducer";
import { useSelector } from "react-redux";
import store, { RootState } from "../../../../../../store";
import { OBody6, OBody9, OH3 } from "../../../../../Styled/Texts";
import { OActionButton } from "../../../../../Styled/Buttons/OActionButton";
import { SectionWiringData } from "./constants";
import { SectionWiring } from "./styled";
import { sectionVariants } from "../styled";
import { motion } from "framer-motion";
import { ConfigurateurAccessoriesCombination } from "../../../../../../store/configurateur/accessories/configurateur-accessories-state";
import { configurateurAccessoriesOnCombinationChanged } from "../../../../../../store/configurateur/accessories/configurateur-accessories-reducer";
import { formatNumber } from "../../../../../../utils/convert-number-locale";

const WiringComponent: React.FC<{
  initial: SectionWiringData;
}> = ({ initial }) => {
  const currentStepName = STEP_DESIGN;
  const currentSectionName = Sections.WIRING;
  const { t } = useTranslation([currentStepName, "global"]);
  const defaultValues = "...";

  const accessoriesCombination = useSelector(
    (state: RootState) =>
      state.configurateurAccessories?.accessoriesData?.selectedCombination
  ) as ConfigurateurAccessoriesCombination;

  const entryACombination = useSelector((state: RootState) =>
    state.configurateurAccessories?.accessoriesData?.combinations.find(
      (combination) => combination.entryA === 25
    )
  ) as ConfigurateurAccessoriesCombination;

  const entryBCombination = useSelector((state: RootState) =>
    state.configurateurAccessories?.accessoriesData?.combinations.find(
      (combination) => combination.entryB === 25
    )
  ) as ConfigurateurAccessoriesCombination;

  const sectionData = useSelector(
    (state: RootState) =>
      state.step.steps[currentStepName].data.phase2?.[currentSectionName] ??
      initial
  ) as SectionWiringData;

  const priceDelta =
    entryBCombination?.totalPrice - entryACombination?.totalPrice;
  return (
    <SectionWiring
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={sectionVariants}
      id={currentSectionName + "_anchor"}
    >
      <OH3 center="true">
        {t("phase_2.sections." + currentSectionName + ".title")}
      </OH3>
      <OBody6 center="true" className="mt-3">
        <Trans>
          {t(
            "phase_2.sections." +
              currentSectionName +
              ".types.panel_box.subtitle"
          )}
        </Trans>
      </OBody6>
      <motion.div className="section__buttons panel_box">
        <OActionButton
          className="design"
          onClick={() => {
            store.dispatch(
              configurateurAccessoriesOnCombinationChanged({
                entyrA: 10,
              })
            );
          }}
          isactive={accessoriesCombination?.entryA === 10 ? "true" : "false"}
          sublabel={t("include")}
        >
          {t("phase_2.sections." + currentSectionName + ".value", {
            value: sectionData.panel_box?.value?.[0] ?? defaultValues,
          })}
        </OActionButton>
        <OActionButton
          className="design"
          onClick={() => {
            store.dispatch(
              configurateurAccessoriesOnCombinationChanged({
                entyrA: 25,
              })
            );
          }}
          isactive={accessoriesCombination?.entryA === 25 ? "true" : "false"}
          sublabel={
            entryACombination?.priceDelta > 0 &&
            `+${formatNumber(entryACombination?.priceDelta, 0)}`
          }
        >
          {t("phase_2.sections." + currentSectionName + ".value", {
            value: sectionData.panel_box?.value?.[1] ?? defaultValues,
          })}
        </OActionButton>
      </motion.div>

      <OBody9 center="true" className="mt-2">
        <Trans>
          {t(
            "phase_2.sections." +
              currentSectionName +
              ".types.box_grid.subtitle"
          )}
        </Trans>
      </OBody9>
      <motion.div className="section__buttons box_grid">
        <OActionButton
          className="design"
          onClick={() => {
            store.dispatch(
              configurateurAccessoriesOnCombinationChanged({
                entryB: 5,
              })
            );
          }}
          isactive={accessoriesCombination?.entryB === 5 ? "true" : "false"}
          sublabel={t("include")}
        >
          {t("phase_2.sections." + currentSectionName + ".value", {
            value: sectionData?.box_grid?.value?.[0] ?? defaultValues,
          })}
        </OActionButton>
        <OActionButton
          className="design"
          onClick={() => {
            store.dispatch(
              configurateurAccessoriesOnCombinationChanged({
                entryB: 25,
              })
            );
          }}
          isactive={accessoriesCombination?.entryB === 25 ? "true" : "false"}
          sublabel={priceDelta > 0 && `+${formatNumber(priceDelta)}`}
        >
          {t("phase_2.sections." + currentSectionName + ".value", {
            value: sectionData?.box_grid?.value?.[1] ?? defaultValues,
          })}
        </OActionButton>
      </motion.div>
    </SectionWiring>
  );
};

export default WiringComponent;
