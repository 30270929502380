import { svgsPath } from "../../../../../utils/constants";

export interface NeedPhase2ReduxStepData {
  equipements: string[];
  sum: number;
}

export interface NeedPhase2Props {
  onContinue: () => void;
  onReturn: () => void;
}

const needPhase2MainPictureUrl = svgsPath + "equipments/maison_needs.png";

export const needPhase2Config = {
  seeEstimation: false,
  defaultValues: {
    equipements: ['ElectricWaterHeater'],
    sum: 2955,
  },
  gridConfig: {
    left: [12, 6, 4],
    right: [12, 6, 8],
  },
  simulation: {
    SUN_WIDTH: 400, // taille plus grande
    SUN_HEIGHT: 400, // taille plus grande
    CARD_WIDTH: 120, // taille plus petite
    CARD_HEIGHT: 140, // taille plus petite
    SIMULATION_COLLIDE_RADIUS: 100,
    CENTER_X_RATIO: 2, // placer au centre de la largeur
    CENTER_Y_RATIO: 2, // placer au centre de la hauteur
    NODE_DISTANCE: 230, // distance from center to nodes
    NODE_SPREAD: 340, // total degrees around the center
    SIMULATION_GRAVITY_STRENGTH: 0.004, // force de gravité
    SIMULATION_FORCE: 50,
    SIMULATION_LINK_FORCE: 0.1,
    SIMULATION_ALPHA_TARGET_START: 0.3, // vitesse de déplacement plus faible au début du drag
    SIMULATION_ALPHA_TARGET_END: 0.3, // vitesse de déplacement plus faible à la fin du drag
    SIMULATION_LINK_DISTANCE: 50, // liens plus longs
  },
  cardTemplate: `
  <div class="svgCard"><img class="svgCard_img" src="{{link}}" alt="{{key}}"/>
    <p class="svgCard_label">{{label}}</p>
    <p class="svgCard_power">{{power}} {{unit}}</p></div>`,
  sunTemplate: `<img src="${needPhase2MainPictureUrl}" style="width: 100%; height: 100%;"/>`,
};

export enum NeedType {
  ElectricWaterHeater = "ElectricWaterHeater",
  ElectricHeater = "ElectricHeater",
  HeatPump = "HeatPump",
  Pool = "Pool",
  ElectricCar = "ElectricCar",
}

export interface EquipementsData {
  name?: NeedType;
  power?: number;
  link?: string;
  label?: string;
  unit?: string;
}

export const NeedTypeImageLinks = {
  [NeedType.ElectricWaterHeater]: svgsPath + "equipments/chauffe-eau.svg",
  [NeedType.ElectricHeater]: svgsPath + "equipments/radiateur-electrique.svg",
  [NeedType.HeatPump]: svgsPath + "equipments/pompe-a-chaleur.svg",
  [NeedType.Pool]: svgsPath + "equipments/piscine.svg",
  [NeedType.ElectricCar]: svgsPath + "equipments/voiture-electrique.svg",
};

export const NeedTypePower = {
  [NeedType.ElectricWaterHeater]: 2190,
  [NeedType.ElectricHeater]: 7230,
  [NeedType.HeatPump]: 5784,
  [NeedType.Pool]: 683.2,
  [NeedType.ElectricCar]: 5037,
};

export const NeedTypeApiKeys = {
  [NeedType.ElectricHeater]: "Convecteur",
  [NeedType.ElectricWaterHeater]: "Chauffe-eau",
  [NeedType.HeatPump]: "Pompe à chaleur",
  [NeedType.Pool]: "Filtration piscine",
  [NeedType.ElectricCar]: "Voiture électrique",
};
