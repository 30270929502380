// NON UTILISE - REDUCER EXEMPLE

import { BesoinsState } from "./besoins-state";

const initialState: BesoinsState = {
  besoinsData: {
    },
};

enum ActionType {
  setBudget = "setBudget",
  resetBudget = "resetBudget",
}

export const besoinsReducer = (
  state = initialState,
  action
): BesoinsState => {
  const { data } = action;

  switch (action.type) {
    case ActionType.setBudget:
      return {
        besoinsData: {
          budget: data
        },
      };
    case ActionType.resetBudget:
      return {
        besoinsData: {
          budget: null
        },
      };
    default:
      return { ...state };
  }
};

export const besoinsSetBudget = (budget: number) => async (dispatch) => {
  dispatch({
    type: ActionType.setBudget,
    data: budget,
  });
};

export const resetBudget = () => async (dispatch) => {
  dispatch({
    type: ActionType.resetBudget,
  });
} 

