import axios from "axios";
import {
  API_GEOCODING_COUNTRY,
  API_GEOCODING_KEY,
  API_MAPBOX_GEOCODING,
} from "../../../utils/constants";
import { MapboxLocationDto } from "../dtos/mapbox-location.dto";
export class GeocodingApi {
  async reverse(lat: number, lon: number): Promise<MapboxLocationDto> {
    const response = await axios.get(
      `${API_MAPBOX_GEOCODING}/mapbox.places/${lon},${lat}.json?types=address&country=${API_GEOCODING_COUNTRY}&access_token=${API_GEOCODING_KEY}`
    );
    return response.data;
  }

  async getLocation(text: string): Promise<MapboxLocationDto> {
    const response = await axios.get(
      `${API_MAPBOX_GEOCODING}/mapbox.places/${text}.json?types=address&country=${API_GEOCODING_COUNTRY}&access_token=${API_GEOCODING_KEY}&proximity=ip`
    );
    return response.data;
  }
}
