import { useTranslation } from "react-i18next";
import { Modal } from "../../../../../Styled/modals";
import { STEP_DESIGN } from "../../../../../../store/reducer";
import {
  ConfigurateurPopupContext,
  ConfigurateurPopups,
} from "../configurateur-modals-context";
import { useContext } from "react";
import { PanelDetailItem } from "./panel-detail-item";
import styled from "styled-components";
import { ConfigurateurPanelEntity } from "../../../../../../data/configurateur/services/entities/panels/configurateur-panel.entity";

export const PanelsModal = (props: {
  show: boolean;
  panels: ConfigurateurPanelEntity[];
}) => {
  const { t } = useTranslation([STEP_DESIGN, "global"]);
  const { setPopup } = useContext(ConfigurateurPopupContext);
  return (
    <Modal
      show={props.show}
      title={t("phase_2.sections.solar_kit.sections.modal.title")}
      onClose={() => setPopup(ConfigurateurPopups.none)}
      maxContentWidth="70vw"
      maxContentHeight="95vh"
    >
      <PanelsContainer>
        {props.panels?.map((panel) => (
          <PanelDetailItem panel={panel} />
        ))}
      </PanelsContainer>
    </Modal>
  );
};

const PanelsContainer = styled.div`
  overflow-x: scroll;
  display: flex;
`;
