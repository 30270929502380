import React from "react";

import { OBody7, OBody9 } from "../Texts";
import { theme } from "../GlobalStyle";
import { OSelectableCard } from "../selectable-cards/styled";

export const SelectableRadioCard: React.FC<{
  checked: boolean;
  label: string;
  trailingLabel?: string;
  onClick: Function;
}> = ({ checked, label, trailingLabel, onClick }) => {
  return (
    <OSelectableCard
      checked={checked}
      onClick={() => {
        onClick();
      }}
    >
      <div>
        <input type="radio" checked={checked} />
        <OBody9 className="label" color={theme.colors.onyx.onyxPower700}>
          {label}
        </OBody9>

        {trailingLabel && <OBody7 className="trailing">{trailingLabel}</OBody7>}
      </div>
    </OSelectableCard>
  );
};
