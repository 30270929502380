import styled from "styled-components";
import Lottie from "react-lottie-player";
import loader from "../../../assets/loaders/opower-loader.json";
import { OBody5 } from "../../Styled/Texts";
import { theme } from "../../Styled/GlobalStyle";
import { loaderOpUrl } from "../../../utils/constants";

type Props = {
  message: string;
};

export const FullscreenLoader = (props: Props) => {
  return (
    <FullscreenLoaderContainer>
      <OBody5 color={theme.colors.onyx.onyxPower500}>{props.message}</OBody5>
      <Lottie
        className="fullscreen-loader"
        play={true}
        loop={true}
        path={loaderOpUrl}
        segments={[
          [0, 42],
          [43, 110],
        ]}
      />
    </FullscreenLoaderContainer>
  );
};

export const FullscreenLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  p {
    text-align: center;
  }

  .fullscreen-loader {
    width: 10vw;
    height: 10vh;
  }
`;
