import { useTranslation } from "react-i18next";
import { OBody9 } from "../../../../../../Styled/Texts";
import { OInfoCard } from "../../../../../../Styled/info-card/o-info-card";
import { STEP_DESIGN } from "../../../../../../../store/reducer";
import { Sections } from "../../constants";
import styled from "styled-components";

import { InputPostCodeChecker } from "../../../../../../shared/inputs/input-postcode-checker";

export const InstallHelpNoAddress = (props) => {
  const { t } = useTranslation([STEP_DESIGN, "global"]);
  const currentSectionName = Sections.INSTALLATION_HELP;
  return (
    <InstallHelpNoAddressContainer>
      <OInfoCard className="install-help-no-address-info" width="100%">
        <OBody9>
          {t("phase_2.sections." + currentSectionName + ".noAddress.title")}
        </OBody9>
      </OInfoCard>
      <InputPostCodeChecker onCheck={props.onCheck} />
    </InstallHelpNoAddressContainer>
  );
};

const InstallHelpNoAddressContainer = styled.div`
  width: 100%;
  .install-help-no-address-info {
    margin-bottom: 16px;
  }
`;
