import React from "react";
import { motion } from "framer-motion";

interface CustomTransitionProps {
  mode: "fade" | "modern" | "translate" | "bubble" | "none";
  children: React.ReactNode;
}

const CustomTransition: React.FC<CustomTransitionProps> = ({
  mode,
  children,
}) => {
  const transitionProps = {
    fade: {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      exit: { opacity: 0 },
      transition: { duration: 0.3 },
    },
    modern: {
      initial: { opacity: 0, y: 30 },
      animate: { opacity: 1, y: 0 },
      exit: { opacity: 0, y: -30 },
      transition: { duration: 0.5 },
    },
    translate: {
      initial: { opacity: 0, x: 50 },
      animate: { opacity: 1, x: 0 },
      exit: { opacity: 0, x: -50 },
      transition: { duration: 0.5 },
    },
    none: {
      initial: { opacity: 1 },
      animate: { opacity: 1 },
      exit: { opacity: 1 },
      transition: { duration: 0.5 },
    },
    bubble: {
      initial: { opacity: 0, scale: 0.8 },
      animate: { opacity: 1, scale: 1 },
      exit: { opacity: 0, scale: 1.2 },
      transition: { duration: 0.4 },
    },
  };

  return <motion.div {...transitionProps[mode]} className="h-100 position-relative">{children}</motion.div>;
};

export default CustomTransition;
