import React, { useCallback, useContext, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Sections } from "../constants";
import { STEP_DESIGN } from "../../../../../../store/reducer";
import { sectionVariants } from "../styled";
import { useSelector } from "react-redux";
import store, { RootState } from "../../../../../../store";
import { OBody6, OBody9, OH3 } from "../../../../../Styled/Texts";
import { SectionInstallationHelpData } from "./constants";
import { SectionInstallationHelp } from "./styled";
import { OActionButton } from "../../../../../Styled/Buttons";

import { SelectableCheckCard } from "../../../../../Styled/selectable-cards/selectable-check-card/selectable-check-card";
import {
  configurateurInstallHelpCheckEligibility,
  configurateurInstallHelpSelect,
} from "../../../../../../store/configurateur/install-helps/configurateur-install-helps-reducer";
import { ConfigurateurInstallHelpErrors } from "../../../../../../store/configurateur/install-helps/configurateur-install-helps-state";
import {
  ConfigurateurPopupContext,
  ConfigurateurPopups,
} from "../configurateur-modals-context";
import { motion } from "framer-motion";
import { formatNumber } from "../../../../../../utils/convert-number-locale";
import { InstallHelpNoAddress } from "../install-help/no-address/install-help-no-address";
import { usePostcode } from "../../../../../../hooks/location/location-hook";
import { OLinkButton } from "../../../../../Styled/Buttons/Link/o-link-button";
import { theme } from "../../../../../Styled/GlobalStyle";

const InstallationHelpComponent: React.FC<{
  initial: SectionInstallationHelpData;
}> = () => {
  const currentStepName = STEP_DESIGN;
  const currentSectionName = Sections.INSTALLATION_HELP;
  const { t } = useTranslation([currentStepName, "global"]);

  const selectables = useSelector(
    (state: RootState) =>
      state.configurateurInstallHelps?.installHelpsData?.installHelpsSelected
  );
  const error = useSelector(
    (state: RootState) =>
      state.configurateurInstallHelps?.installHelpsData?.error
  );
  const getError = useCallback(() => {
    if (error === undefined) return undefined;
    switch (error) {
      case ConfigurateurInstallHelpErrors.department:
        return t("installHelp.error.department");
      default:
        return t("installHelp.error.configuration");
    }
  }, [error, t]);

  const { setPopup } = useContext(ConfigurateurPopupContext);
  const postcode = usePostcode();
  const location = useSelector((state: RootState) =>
    !state.step.skip ? state.localization?.location : undefined
  );
  const [postcodeCheckerVisible, setPostcodeCheckerVisible] = useState(
    !postcode
  );

  const getHelps = useCallback(() => {
    if (selectables?.length > 0 && !getError()) {
      return selectables?.map((selectable) => {
        return (
          <SelectableCheckCard
            checked={selectable?.checked}
            label={selectable?.installHelp?.title}
            trailingLabel={formatNumber(selectable?.installHelp?.kitPrice)}
            sublabel={t("installHelp.paimentWarning")}
            onClick={() => {
              store.dispatch(
                configurateurInstallHelpSelect(selectable?.installHelp?.id)
              );
            }}
          ></SelectableCheckCard>
        );
      });
    }
    return <OActionButton disabled={true}>{getError()}</OActionButton>;
  }, [getError, selectables, t]);

  const getChild = useCallback(() => {
    if (location) {
      return getHelps();
    }
    if (!postcodeCheckerVisible) {
      return (
        <>
          {getHelps()}
          <OLinkButton
            color={theme.colors.onyx.onyxPower700}
            onClick={() => {
              setPostcodeCheckerVisible(true);
            }}
          >
            {t(
              "phase_2.sections." +
                currentSectionName +
                ".noAddress.changePostcode"
            )}
          </OLinkButton>
        </>
      );
    } else {
      return (
        <InstallHelpNoAddress
          onCheck={(value) => {
            setPostcodeCheckerVisible(false);
            store.dispatch(configurateurInstallHelpCheckEligibility(value));
          }}
        />
      );
    }
  }, [location, postcodeCheckerVisible, getHelps, t, currentSectionName]);
  return (
    <SectionInstallationHelp
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={sectionVariants}
      id={currentSectionName + "_anchor"}
    >
      <OH3 center="true">
        <Trans>{t("phase_2.sections." + currentSectionName + ".title")}</Trans>
      </OH3>
      <OBody9 center="true">
        <Trans>
          {t("phase_2.sections." + currentSectionName + ".subtitle")}
        </Trans>
      </OBody9>
      <motion.div className="section__title__col">
        <OBody6
          className="conception-configuration-aide-installation-viewmore"
          center="true"
          onClick={() => setPopup(ConfigurateurPopups.popupInstallHelp)}
        >
          {t("phase_2.sections." + currentSectionName + ".viewMore")}
        </OBody6>
      </motion.div>
      {getChild()}
    </SectionInstallationHelp>
  );
};

export default InstallationHelpComponent;
