import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { imgsPath } from "../../../utils/constants";
import { theme } from "../../Styled/GlobalStyle";
import { OBody10, OBody12 } from "../../Styled/Texts";

export const CustomerServiceCard = (props) => {
  const { t } = useTranslation("global");
  return (
    <Container className={props.class}>
      <Img
        src={imgsPath + "customer-service/customerservice.png"}
        alt="customerservice"
      />
      <Body>
        <OBody10>{t("customerService.title")}</OBody10>
        <OBody12>{t("customerService.description")}</OBody12>
        <OBody10>
        {t("customerService.emojiPhone")}&nbsp;&nbsp;
          <a href="tel:+0175850888">
            {t("customerService.phone")}
          </a>
          </OBody10>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: row;
  gap: 12px;
  background-color: ${theme.colors.tertiary.leafPower666};
  border-radius: 12px;
  padding: 8px;
  align-items: center;
`;

const Img = styled.img`
  width: 64px;
  height: 64px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;

  gap: 4px;
  align-items: start;
  text-align: start;

  max-width: 200px;
`;
