import { useTranslation } from "react-i18next";
import { OBody5 } from "../../../../../Styled/Texts";
import { Modal } from "../../../../../Styled/modals";
import { STEP_DESIGN } from "../../../../../../store/reducer";
import {
  ConfigurateurPopupContext,
  ConfigurateurPopups,
} from "../configurateur-modals-context";
import { useContext } from "react";
import { theme } from "../../../../../Styled/GlobalStyle";

export const WarrantyModal = (props: { show: boolean }) => {
  const { t } = useTranslation([STEP_DESIGN, "global"]);
  const { setPopup } = useContext(ConfigurateurPopupContext);
  return (
    <Modal
      show={props.show}
      title={t("phase_2.sections.warranty.modal.title")}
      onClose={() => setPopup(ConfigurateurPopups.none)}
    >
      <OBody5 color={theme.colors.primary.bluePower700}>
        {t("phase_2.sections.warranty.modal.subtitle")}
      </OBody5>
      <br />
      <OBody5>{t("phase_2.sections.warranty.modal.description")}</OBody5>
    </Modal>
  );
};
