import { motion } from "framer-motion";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import store, { RootState } from "../../../../../store";
import {
  STEP_LOCALIZATION,
  STEP_NEEDS,
  STEP_RECOMMANDATION,
} from "../../../../../store/reducer";
import { cleanMoneyValue } from "../../../../../utils";
import { OMainButton, OTertiaryButton } from "../../../../Styled/Buttons";
import { OCol } from "../../../../Styled/CustomGrid";
import { fade } from "../../../../Styled/GlobalAnimations";
import { OBody11, OBody12, OBody3, OH2 } from "../../../../Styled/Texts";
import { LocalizationReduxStepData } from "../../../Localization/constants";
import { RecommandationReduxStepData } from "../../constants";
import {
  Phase1Props,
  RecommandationPhase2Config,
  containerVariants,
} from "./constants";
import PotentialCardComponent from "./potential_card";
import ProfitCardComponent from "./profit_card.tsx";
import {
  BackORow,
  Oinfo,
  StatsContainer,
  StatsContainerMobile,
  StatsDataContainer,
  StatsLine,
} from "./styled";

import {
  simulationLoad,
  simulationShouldLoad,
} from "../../../../../store/simulation/simulation-slice";
import { SimulationState } from "../../../../../store/simulation/simulation-state";
import { NeedReduxStepData } from "../../../Needs/constants";
import { AzimutId, PenteId } from "../phase1/constants";
// import { setRecommandationCount } from "../../../../../store/calepinage/calepinageReducer";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch } from "react-redux";
import { fixationService } from "../../../../../data/fixations/repositories/fixation-repository";
import {
  setPanelCountInCalepinage,
  setRecommandationCount,
} from "../../../../../store/calepinage/calepinageReducer";
import { formatNumber } from "../../../../../utils/convert-number-locale";
import { createMinimizedMatrix } from "../../../../../utils/create-minimized-matrix";
import { trackDataLayer } from "../../../../../utils/tracking/tracking-utils";
import { CommonError } from "../../../../Styled/errors/common-error";
import { OLoader } from "../../../../Styled/loader";
import MatrixViewer from "../../../../shared/matrix_viewer";
import { ComponentStatus } from "../../../../shared/status/component-status";
import { RecommandationEstimationModal } from "./estimation/recommandation-estimation-modal";

export const SimulationTooltipContext = createContext<{
  tooltip?: SimulationTooltip;
  setTooltip?: any;
}>({});

export enum SimulationTooltip {
  economy,
  selling,
  none,
}
const RecommandationPhase2: React.FC<Phase1Props> = ({
  setData,
  onContinue,
  onChanges,
  onReturn,
}) => {
  const { t } = useTranslation([STEP_RECOMMANDATION + "phase2", "global"]);
  const config = RecommandationPhase2Config;
  //const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const needData: NeedReduxStepData =
    useSelector((state: RootState) => state.step.steps[STEP_NEEDS].data) || {};

  const recommandationData: RecommandationReduxStepData =
    useSelector(
      (state: RootState) => state.step.steps[STEP_RECOMMANDATION].data
    ) || {};

  const simulationState: SimulationState = useSelector(
    (state: RootState) => state.simulationReducer
  );

  const localizationData: LocalizationReduxStepData = useSelector(
    (state: RootState) => state.step.steps[STEP_LOCALIZATION].data || {}
  );

  // const recommandationCount = useSelector(
  //   (state: RootState) => state.calepinageReducer.recommandationCount
  // );

  const address = useMemo(() => localizationData.search, [localizationData]);

  const simulationParams = {
    budget: needData.phase1?.budget,
    equipements: needData?.phase2?.equipements,
    lat: localizationData.position[0],
    lng: localizationData.position[1],
    angle: PenteId[recommandationData?.phase1?.pente],
    orientation: AzimutId[recommandationData?.phase1?.azimut],
    pays: "fr",
  };

  const [matriceCalepinage, setMatriceCalepinage] = useState([[]]);

  const panelCountInCalepinage = useSelector(
    (state: RootState) => state.calepinageReducer.panelCountInCalepinage
  );

  useEffect(() => {
    if (simulationShouldLoad(simulationState)) {
      store.dispatch(simulationLoad(simulationParams));
    }
  }, []);

  const kitPanels = simulationState.simulation?.data?.estimation?.kitPanels;
  const kitPowerKw = simulationState.simulation?.data?.estimation?.kitPowerKw;
  const kitPrice = simulationState.simulation?.data?.estimation?.kitPrice;
  const kitEachPanelWc = (kitPowerKw * 1000) / kitPanels;

  useEffect(() => {
    if (kitPanels) {
      dispatch(setRecommandationCount(kitPanels));

      // Set PanelCountInCalepinage (initial hors toiture)
      if (panelCountInCalepinage !== kitPanels) {
        dispatch(setPanelCountInCalepinage(kitPanels));
      }

      // Set PanelCountInCalepinage only if not already set (ie. not on return from design phase)
      // if( panelCountInCalepinage <= 1){
      // dispatch(
      //   setPanelCountInCalepinage(
      //     simulationState?.simulation?.data?.estimation?.kitPanels
      //   )
      // );
      // }

      setMatriceCalepinage(createMinimizedMatrix(kitPanels));
    }
  }, [dispatch, kitPanels]);

  const canValidate =
    simulationState.simulation?.status === ComponentStatus.loaded;

  const handleContinue = () => {
    if (!canValidate) return;
    trackDataLayer("SimulationSelected", {
      simulation: {
        panelsNumber: simulationState.simulation.data?.estimation?.kitPanels,
        kitPower: simulationState.simulation.data?.estimation?.kitPowerKw,
        kitPrice: simulationState.simulation.data?.estimation?.kitPrice,
      },
    });
    onContinue();
  };

  const handleRetry = () => {
    store.dispatch(simulationLoad(simulationParams));
  };

  const isLoading =
    simulationState.simulation?.status !== ComponentStatus.loaded &&
    simulationState.simulation?.status !== ComponentStatus.error;
  const hasError = simulationState.simulation?.status === ComponentStatus.error;

  const getOrientation = useCallback(() => {
    return fixationService.getFixationsByFixationIdAndPanelCount(
      recommandationData?.phase1?.fixation?.id,
      kitPanels
    )[0]?.orientation;
  }, [recommandationData?.phase1?.fixation?.id, kitPanels]);

  const [popupVisible, setPopupVisible] = useState(false);
  const [tooltip, setTooltip] = useState(SimulationTooltip.none);
  // return (
  //   <div className="position-absolute h-100 w-100 d-flex justify-content-center align-items-center">
  //     <MatrixViewer matrix={matriceCalepinage} orientation={orientation}></MatrixViewer>
  //   </div>
  // );

  return (
    <div onClick={() => setTooltip(SimulationTooltip.none)}>
      <SimulationTooltipContext.Provider
        value={{
          tooltip: tooltip,
          setTooltip: setTooltip,
        }}
      >
        <RecommandationEstimationModal
          show={popupVisible}
          onClose={() => {
            setPopupVisible(false);
          }}
        />
        {isLoading && <OLoader content={t("loader.content")} />}
        {hasError && <CommonError content={t("error.common")} />}
        {/* central container - in absolute on desktop*/}
        {!isLoading && !hasError && (
          <motion.div
            className="position-absolute h-100 w-100 justify-content-center align-items-center d-none d-md-flex flex-column"
            animate={kitPanels > 0 ? "visible" : "hidden"}
          >
            <StatsContainer
              variants={containerVariants}
              initial="hidden"
              animate={kitPanels > 0 ? "visible" : "hidden"}
              className=""
            >
              <StatsLine></StatsLine>
              <StatsDataContainer className="">
                <OBody3 {...fade}>{t("stats.kitTitle")}</OBody3>
                <OBody3 {...fade}>
                  {t("stats.kit")} :
                  {" " + kitPanels ??
                    config.defaultValues.mapping.stats.panelsNumber}
                  {t("stats.panels", {
                    count:
                      kitPanels ??
                      config.defaultValues.mapping.stats.panelsNumber,
                  }) +
                    ` (${t("global:withUnit.wc", {
                      value: formatNumber(kitEachPanelWc, 0, false),
                    })}) `}
                </OBody3>
                <OH2 {...fade}>
                  {
                    // cleanUnitValue(
                    formatNumber(
                      kitPowerKw ??
                        config.defaultValues.mapping.stats.kw_installe,
                      2,
                      false
                    )
                  }
                  {t("stats.unitOne")} -{" "}
                  {formatNumber(
                    kitPrice ?? config.defaultValues.mapping.stats.prix,
                    0
                  )}
                </OH2>

                <Oinfo>
                  <Trans>
                    <OBody12 {...fade}>{t("stats.nextstep")}</OBody12>
                  </Trans>
                </Oinfo>

                <OBody12
                  className="recommandation-simulation-estimation-viewmore"
                  style={{ textDecoration: "underline" }}
                  onClick={() => {
                    setPopupVisible(true);
                  }}
                >
                  {t("stats.viewmore")}
                </OBody12>
              </StatsDataContainer>
            </StatsContainer>

            <MatrixViewer
              {...fade}
              matrix={matriceCalepinage}
              orientation={getOrientation()}
            ></MatrixViewer>
          </motion.div>
        )}

        {!isLoading && (
          <BackORow className="position-absolute h-100 flex-nowrap flex-column flex-md-row w-100 w-md-auto align-items-start overflow-auto">
            {/* <BackOCol
        sm={config.gridConfig.back[0]}
        className="d-flex flex-column justify-content-between align-items-center order-2 order-md-1 contentsized no-padding"
      >
        <Container3D>
          <PanelsGridContainer mapMode={false} canvasMode={true} />
        </Container3D>
      </BackOCol> */}

            {/* Matrice de calepinage en mode recommandation-mobile */}
            <OCol
              sm={config.gridConfig.main[0]}
              md={config.gridConfig.main[1]}
              className="d-flex flex-column justify-content-between align-items-center order-1 d-md-none p-0"
            >
              <MatrixViewer
                matrix={matriceCalepinage}
                orientation={getOrientation()}
              ></MatrixViewer>
            </OCol>

            {/* Stats en mobile */}
            <OCol
              sm={config.gridConfig.main[0]}
              md={config.gridConfig.main[1]}
              className="d-flex flex-column justify-content-between align-items-center order-2 d-md-none p-0"
            >
              <StatsContainerMobile
                variants={containerVariants}
                initial="hidden"
                animate={kitPanels > 0 ? "visible" : "hidden"}
              >
                <motion.div {...fade()}>
                  <OBody11 className="label">{t("stats.kit")}</OBody11>
                  <OH2>
                    {
                      // cleanUnitValue(
                      formatNumber(
                        kitPowerKw ??
                          config.defaultValues.mapping.stats.kw_installe,
                        2,
                        false
                      )
                    }{" "}
                    {t("stats.unitOne")}
                  </OH2>
                  <OBody11>
                    {" "}
                    {kitPanels ??
                      config.defaultValues.mapping.stats.panelsNumber}
                    {t("stats.panels", {
                      count:
                        kitPanels ??
                        config.defaultValues.mapping.stats.panelsNumber,
                    })}
                  </OBody11>
                </motion.div>

                <motion.div {...fade()}>
                  <OBody11 className="label">{t("stats.kit")}</OBody11>
                  <OH2>
                    {cleanMoneyValue(
                      kitPrice ?? config.defaultValues.mapping.stats.prix
                    )}{" "}
                    {t("stats.unitTwo")}
                  </OH2>
                  <OBody11>{t("stats.payMethodLabel")}</OBody11>
                </motion.div>
              </StatsContainerMobile>
              {!hasError && (
                <div>
                  <Oinfo>
                    <Trans>
                      <OBody12 {...fade}>{t("stats.nextstep")}</OBody12>
                    </Trans>
                  </Oinfo>
                  <OBody12
                    className="recommandation-simulation-estimation-viewmore"
                    style={{ textDecoration: "underline" }}
                    onClick={() => {
                      setPopupVisible(true);
                    }}
                  >
                    {t("stats.viewmore")}
                  </OBody12>
                </div>
              )}
            </OCol>

            {/* Yellow card */}
            <OCol
              sm={config.gridConfig.main[0]}
              md={config.gridConfig.main[1]}
              className="d-flex flex-column justify-content-between align-items-center order-4 order-md-1 p-0 p-md-3 mb-3"
            >
              <PotentialCardComponent
                simulationState={simulationState}
                adresse={address}
              />
            </OCol>

            {/* Buttons */}
            {!isLoading && (
              <OCol
                sm={config.gridConfig.main[0]}
                md={config.gridConfig.main[1]}
                className="d-flex flex-column justify-content-between align-items-center p-0 order-5 order-md-2 mt-auto"
              >
                <motion.div className="d-flex flex-column gap-1 w-100">
                  {hasError ? (
                    <OMainButton
                      className="w-100 recommandation-simulation-retry-btn"
                      primary
                      onClick={handleRetry}
                    >
                      {t("global:retry")}
                    </OMainButton>
                  ) : (
                    <OMainButton
                      className="w-100 recommandation-simulation-next-btn"
                      primary
                      disabled={!canValidate}
                      onClick={handleContinue}
                    >
                      {t("global:continue")}
                    </OMainButton>
                  )}
                  <OTertiaryButton
                    className="w-100 recommandation-simulation-prev-btn"
                    underline
                    onClick={onReturn}
                  >
                    {t("global:return")}
                  </OTertiaryButton>
                </motion.div>
              </OCol>
            )}

            {/* Blue Card */}
            <OCol
              sm={config.gridConfig.main[0]}
              md={config.gridConfig.main[1]}
              className="d-flex flex-column justify-content-between align-items-center order-3 order-md-3 p-0 p-md-3 mb-3"
            >
              <ProfitCardComponent simulationState={simulationState} />
            </OCol>
          </BackORow>
        )}
      </SimulationTooltipContext.Provider>
    </div>
  );
};

export default RecommandationPhase2;
