import { motion } from "framer-motion";
import styled from "styled-components";
import { StepWrapperContainer } from "../../Styled/StepWrapperContainer";
import { installationConfig } from "./constants";

export const InstallationContainer = styled(StepWrapperContainer)``;

export const CardContainer = styled(motion.div)`
    width: 100%;
    justify-content: center;
    
    @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
      //width: 95%;
      margin: 0 auto;
    }
}`;

export const CheckCard = styled(motion.img)`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 24px;
  height: 24px;
  padding: 7px;
  z-index: 1;
  background: ${(props) => props.theme.colors.clay.clayPower200};
  border-radius: 0px ${installationConfig.cards.borderRadius} 0px 5px;
`;

export const ImageCardContainer = styled(motion.div)`
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
`;

export const Card = styled(motion.div)`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.2s ease-in-out;

  background: ${(props) => props.theme.colors.clay.clayPower200};
  border-radius: ${installationConfig.cards.borderRadius};
  outline: solid 1px ${(props) => props.theme.colors.clay.clayPower600};
  width: ${installationConfig.cards.width.lg};
  height: ${installationConfig.cards.height.lg};
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    width: ${installationConfig.cards.width.md};
    height: ${installationConfig.cards.height.md};
  }

  p {
    padding: 8px 0px;
  }

  div {
    border-radius: 8px 8px 0px 0px;

    img {
      padding: 0px;
      margin: 0px;
      width: 100%;
      height: 100%;
      aspect-ratio: 2/1;
      object-fit: cover;
      transition: all 0.2s ease-in-out !important;
      transform-origin: center;
      overflow: hidden !important;
      filter: opacity(90%);
    }
    img:hover {
      filter: opacity(100%);
    }
  }

  &.disabled {
    cursor: default;

    &:hover div img {
      transform: scale(1);
    }
  }

  &:hover {
    div img {
      transform: scale(${installationConfig.cards.hoverScale});
    }
  }
`;
