import { VirtualCartContainer } from "./styled";
import { useVirtualCartItems } from "../../../../store/configurateur/virtual-cart/use-virtual-cart-items-hook";
import { OBody9 } from "../../../Styled/Texts";
import { theme } from "../../../Styled/GlobalStyle";
import { VirtualCartItem } from "../item/virtual-cart-item";

export const VirtualCartBody = (props: {
  itemColor?: {
    category?: string;
    link?: string;
    price?: string;
  };
}) => {
  const cartCategories = useVirtualCartItems();
  return (
    <VirtualCartContainer>
      {cartCategories?.map((cartCategory) => {
        return (
          <div key={cartCategory.category} className="virtualCartSection">
            {cartCategory.splitted && <hr />}
            <OBody9 color={theme.colors.clay.clayPower600}>
              {cartCategory.category}
            </OBody9>
            {cartCategory.items?.map((item) => {
              return (
                <VirtualCartItem
                  item={item}
                  itemColor={props.itemColor}
                ></VirtualCartItem>
              );
            })}
          </div>
        );
      })}
    </VirtualCartContainer>
  );
};
