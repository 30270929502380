import { ConfigurateurProductVariantDto } from "../../../api/dtos/configurateur-product-variant.dto";
import { ConfigurateurProductEntity } from "../configurateur-product.entity";

export class ConfigurateurPanelEntity extends ConfigurateurProductEntity {
  power?: number;

  static fromDto(
    dto: ConfigurateurProductVariantDto
  ): ConfigurateurPanelEntity {
    const power =
      dto.class_fields.fields.find((f) => f.key === "class.panel_power")
        ?.value || "0";
    return {
      ...ConfigurateurProductEntity.fromDto(dto),
      power: parseInt(power),
    };
  }
}
