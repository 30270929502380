import { motion } from "framer-motion";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import Select, { components } from "react-select";
import { OBody11 } from "../Texts";

export const OInput = styled(motion.input)<{
  withicon?: string;
  type?: string;
  withfloatinglabel?: string;
}>`
  width: ${(props) => (props.type === "checkbox" ? "auto" : "100%")};
  padding: 10px 10px;
  border-radius: 4px;
  min-width: ${(props) => (props.type === "checkbox" ? "auto" : "20rem")};
  font-weight: 400;
  font-size: 16px;
  background: ${(props) => props.theme.colors.clay.clayPower200};
  border: 1px solid ${(props) => props.theme.colors.clay.clayPower600};
  border-radius: 8px;
  min-height: ${(props) =>
    props.type === "checkbox" ? "auto" : props.theme.heights.input};
  z-index: 10;
  text-overflow: ellipsis;
  outline: none;
  padding-left: ${(props) =>
    props.type === "checkbox" ? "0px" : "1.2rem"} !important;

  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    color: ${(props) => props.theme.colors.clay.clayPower700};
    opacity: 1;
  }
  &:active,
  &:focus {
    outline: none;
  }

  padding-right: ${(props) =>
    props.withicon && props.type !== "checkbox" ? "40px" : "10px"};

  ${(props) =>
    props.withfloatinglabel &&
    `
    padding-top:1rem;
    padding-bottom:0;
    & ~ label {
      transition-duration:200ms;
      cursor: text;
    }
    &:placeholder-shown ~ label {
      color: ${props.theme.colors.clay.clayPower700};
      transform: translateY(0) scale(1);
      font-size: 14px;
    }

    &:focus ~ label {
      color: ${props.theme.colors.onyx.onyxPower500};
      transform: translateY(-0.6rem) scale(0.75);
      font-size: 14px;
    }
  `}
`;

export const InputWithIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const OForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const OFormLabel = styled(OBody11)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  transform: translateX(15px) translateY(5px);

  color: ${(props) => props.theme.colors.onyx.onyxPower500} !important;
`;

export const OFormGroup = styled(Form.Group)`
  margin: 0px;
  padding: 0px;
  position: relative;
`;

export const OFormControl = styled(Form.Control)`
  width: 100%;
  padding: 17px 10px;
  padding-top: 26px;
  padding-bottom: 10px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  background: transparent;
  border: 1px solid ${(props) => props.theme.colors.clay.clayPower600};
  border-radius: 8px;
  text-overflow: ellipsis;
  outline: none;
  transition: all 0.2s ease-in-out;
  align-items: center;
`;

export const OSelectOption = styled(components.Option)``;

export const OSelectOptionIcon = styled.img`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 45%;
  left: 15px;
  z-index: 10;

  transition: all 0.2s ease-in-out;
`;

// React select styling
export const OSelect = styled(Select)`
  width: 100%;

  &.with-icon {
    .react-select__control {
      padding-left: 35px;
      padding-top: 12px;
    }
  }

  .react-select__control {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 140%;
    min-height: ${(props) => props.theme.heights.input};

    width: 100%;
    padding: 8px 4px;
    padding-bottom: 2px;
    border-radius: 8px;

    background: ${(props) => props.theme.colors.clay.clayPower200};
    border: 1px solid ${(props) => props.theme.colors.clay.clayPower600};
    text-overflow: ellipsis;
    outline: none;

    &:active,
    &:focus {
      outline: none;
    }
  }

  .react-select__value-container {
    padding-top: 8px;
  }

  .react-select__indicators {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-5px);
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__menu {
    z-index: 100;
  }

  .react-select__menu-list {
    background-color: ${(props) => props.theme.colors.clay.clayPower200};
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.clay.clayPower600};
  }

  .react-select__option {
    color: inherit;
    cursor: pointer;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      border-radius: 50%;
    }
  }

  .react-select__option--is-focused {
    background-color: ${(props) => props.theme.colors.clay.clayPower400};
  }

  .react-select__option--is-selected {
    background-color: ${(props) => props.theme.colors.clay.clayPower500};
  }
`;
