import { applyMiddleware, configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducer";

const composedEnhancer = applyMiddleware(thunkMiddleware);

const initialState = window.initialReduxState || {};

const store = configureStore({
  reducer: rootReducer,
  enhancers: [composedEnhancer],
  preloadedState: initialState.reduxState,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
