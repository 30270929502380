import { Variants } from "framer-motion";
import { theme } from "../../Styled/GlobalStyle";

export const equipCardVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: () => ({
    opacity: 1,
  }),
  selected: {
    opacity: 1,
    scale: 1.05,
    y: 0,
    border: "solid 1px " + theme.colors.primary.bluePower700,
    transition: {
      duration: 0.5,
      ease: "easeIn",
    },
  },
  disabled: {
    opacity: 0.5,
    scale: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: "easeIn",
    },
  },
};
