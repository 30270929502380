import styled from "styled-components";
import { theme } from "../GlobalStyle";

export const OInfoCard = styled.div<{
  backgroundColor?: string;
  width?: string;
  height?: string;
}>`
  background-color: ${(props) =>
    props.backgroundColor || theme.colors.secondary.yellowPower400};
  padding: 16px 32px;
  text-align: center;
  border-radius: 8px;

  width: ${(props) => props.width || "fit-content"};
  height: ${(props) => props.height || "fit-content"};
`;
