import {
  getDealtOffers,
  getOscaroPowerProductsById,
} from "../../prestashop/api/prestashop-api";
import {
  AccessoriesEntity,
  BatteryEntity,
  GarantieEntity,
  HelpEntity,
  InstallHelpEntity,
  PanneauFixationEntity,
} from "../../prestashop/services/entities/product-entity";
import { getProductEntityFromDto } from "../../prestashop/services/products-service";
import { ConfigurateurApi } from "../api/configurateur-api";
import {
  ConfigurateurAccessoriesEntity,
  ConfigurateurAccessoryEntity,
} from "./entities/accessories/configurateur-accessories.entity";
import { ConfigurateurBatteryEntity } from "./entities/batteries/configurateur-battery.entity";
import { ConfigurateurProductEntity } from "./entities/configurateur-product.entity";
import {
  ConfigurateurPanelFixationEntity,
  ConfigurateurPanelFixationsEntity,
} from "./entities/fixations-panel/configurateur-fixations-panel.entity";
import { ConfigurateurInverterEntity } from "./entities/inverters/configurateur-inverter.entity";
import { ConfigurateurPanelEntity } from "./entities/panels/configurateur-panel.entity";

class ConfigurateurService {
  configApi = new ConfigurateurApi();

  async getPanels(): Promise<ConfigurateurPanelEntity[]> {
    const data = await this.configApi.getPanels();
    return data.liste.map((p) => ConfigurateurPanelEntity.fromDto(p));
  }

  async getOnduleurs(
    emplacementId: number,
    fixationId: string,
    panelId: string,
    panelIdAttribute: string,
    panelsNumber: number,
    phaseNumber: number
  ): Promise<ConfigurateurInverterEntity[]> {
    try {
      const data = await this.configApi.getOnduleurs(
        emplacementId,
        fixationId,
        panelId,
        panelIdAttribute,
        panelsNumber,
        phaseNumber
      );
      return data.liste.map((p) => ConfigurateurInverterEntity.fromDto(p));
    } catch (error) {
      return [];
    }
  }

  async getSupervisions(
    inverterId: string,
    inverterIdAttribute: string
  ): Promise<ConfigurateurProductEntity[]> {
    try {
      const data = await this.configApi.getSupervision(
        inverterId,
        inverterIdAttribute
      );
      return data.liste.map((p) => ConfigurateurProductEntity.fromDto(p));
    } catch (error) {
      return [];
    }
  }

  async getAccessories(
    panelId: string,
    panelAttribute: string,
    panelsNumber: number,
    onduleurId: string,
    onduleurAttribute: string,
    onduleursNumber: number,
    fixationId: string,
    fixationAttribute: string,
    fixationNumber: number,
    withParafoudre: boolean,
    entryA: number,
    entryB: number,
    supervisionId: string,
    supervisionAttribute: string,
    phaseNumber: number,
    tracker: number,
    postcode?: string
  ): Promise<ConfigurateurAccessoriesEntity> {
    try {
      const data = await this.configApi.getAccessories(
        panelId,
        panelAttribute,
        panelsNumber,
        onduleurId,
        onduleurAttribute,
        onduleursNumber,
        fixationId,
        fixationAttribute,
        fixationNumber,
        withParafoudre,
        entryA,
        entryB,
        supervisionId,
        supervisionAttribute,
        phaseNumber,
        tracker,
        postcode
      );

      return {
        parafoudreRequired: data.parafoudre_necessaire,
        data: data.liste.map((item) =>
          ConfigurateurAccessoryEntity.fromDto(item)
        ),
      };
    } catch (error) {
      return;
    }
  }

  async getBatteries(
    onduleurId: string,
    onduleurAttribute: string,
    phaseNumber: number
  ): Promise<ConfigurateurBatteryEntity[]> {
    try {
      const data = await this.configApi.getBatteries(
        onduleurId,
        onduleurAttribute,
        phaseNumber
      );
      return data.liste.map((p) => ConfigurateurBatteryEntity.fromDto(p));
    } catch (error) {
      return [];
    }
  }

  async getHelps(
    panelId: string,
    panelIdAttribute: string,
    panelsNumber: number,
    batteryId?: string,
    batteryIdAttribute?: string
  ): Promise<ConfigurateurProductEntity[]> {
    try {
      const data = await this.configApi.getSoutienAdmin(
        panelId,
        panelIdAttribute,
        panelsNumber,
        batteryId,
        batteryIdAttribute
      );
      return data.liste.map((p) => ConfigurateurProductEntity.fromDto(p));
    } catch (error) {
      return [];
    }
  }

  async getGaranties(): Promise<ConfigurateurProductEntity[]> {
    try {
      const data = await this.configApi.getGaranties();
      return data.liste.map((p) => ConfigurateurProductEntity.fromDto(p));
    } catch (error) {
      return [];
    }
  }

  async getDealtOffers(): Promise<InstallHelpEntity[]> {
    try {
      const offers = await getDealtOffers();
      return offers["dealt-op-offers"]?.map((offer) => {
        const price = Number.parseFloat(offer?.price);
        return {
          ...offer,
          kitPrice: price,
        };
      });
    } catch (e) {
      return [];
    }
  }

  async getPanneauFixations(
    matrice: number[][],
    disposition: number,
    emplacementId: number,
    panelId: string
  ): Promise<ConfigurateurPanelFixationsEntity> {
    const data = await this.configApi.getPanneauFixations(
      matrice,
      disposition,
      emplacementId,
      panelId
    );

    return {
      fixationsNb: data.nbxFixation,
      data: data.liste.map((item) =>
        ConfigurateurPanelFixationEntity.fromDto(item)
      ),
    };
  }

  async getPanneauFixationById(
    fixationIdAttribute: string
  ): Promise<ConfigurateurProductEntity> {
    const data = await this.configApi.getProductsByLegacyIds([
      fixationIdAttribute,
    ]);
    const product = data[0];
    return ConfigurateurProductEntity.fromDto(product);
  }
}

export const configurateurService = new ConfigurateurService();
