import { ConfigurateurProductVariantDimensionEntity } from "../data/configurateur/services/entities/configurateur-product.entity";

interface matrixRealDimensions {
  horizontalDimension: number;
  verticalDimension: number;
  areaDimension: number;
}

export const getMatrixRealDimensions = (
  matrix,
  disposition,
  dimension: ConfigurateurProductVariantDimensionEntity
): matrixRealDimensions => {
  const [panelHeight, panelWidth, panelLgth] = [
    dimension.height / 1000,
    dimension.width / 1000,
    dimension.length / 1000,
  ];

  if (disposition === 1) {
    const horizontalMatrixSize = Math.max(
      ...matrix.map((row: []) => row.length)
    );
    const verticalMatrixSize = matrix.length;

    const horizontalDimension =
      horizontalMatrixSize * panelWidth +
      0.01 +
      0.002 * (horizontalMatrixSize - 1); // 10 cm bords de rails + 2 cm bords de panneaux
    const verticalDimension = verticalMatrixSize * panelLgth;
    const areaDimension = horizontalDimension * verticalDimension;

    return {
      horizontalDimension: horizontalDimension,
      verticalDimension: verticalDimension,
      areaDimension: areaDimension,
    };
  }

  const horizontalMatrixSize = matrix.length;
  const verticalMatrixSize = Math.max(...matrix.map((row: []) => row.length));

  const horizontalDimension = horizontalMatrixSize * panelLgth;
  const verticalDimension =
    verticalMatrixSize * panelWidth + 0.01 + 0.002 * (verticalMatrixSize - 1); // 10 cm bords de rails + 2 cm bords de panneaux
  const areaDimension = horizontalDimension * verticalDimension;

  return {
    horizontalDimension: horizontalDimension,
    verticalDimension: verticalDimension,
    areaDimension: areaDimension,
  };
};
