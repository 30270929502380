import { useDispatch, useSelector } from "react-redux";
import store, { RootState } from "../../../../../store";
import {
  getCurrentPositionAsync,
  selectLocation,
  textChanged,
  getSuggestionsAsync,
} from "../../../../../store/localization/localization-reducer";
import {
  AutocompleteGeoIcon,
  AutocompleteInputContainer,
  AutocompleteSuggestionItem,
  AutocompleteSuggestionsContainer,
} from "./styled";
import { InputWithIcon, OInput } from "../../../../Styled/Forms";
import { localizationConfig } from "../../constants";
import { OBody6 } from "../../../../Styled/Texts";
import { theme } from "../../../../Styled/GlobalStyle";
import { useTranslation } from "react-i18next";
import { STEP_LOCALIZATION } from "../../../../../store/reducer";
import { SpinnerIcon } from "../../../../shared/geocoder/styled";
import { motion } from "framer-motion";
import { LabelAmount } from "../../../Needs/phases/phase1/styled";
import _ from "lodash";
import { useCallback, useState } from "react";

export function Autocomplete() {
  const { t } = useTranslation([STEP_LOCALIZATION, "global"]);
  const dispatch = useDispatch();
  const autocomplete = useSelector((state: RootState) => state.localization);
  const [localInputValue, setLocalInputValue] = useState(autocomplete.text);

  const config = localizationConfig.geocoderConfig;

  const handleTextChange = useCallback(
    _.debounce((value) => {
      if (value && value.length > 2) {
        store.dispatch(textChanged(value));
        dispatch<any>(getSuggestionsAsync(value));
      }
    }, 600),
    [dispatch]
  );

  const handleInputChange = (e) => {
    store.dispatch(textChanged(e.currentTarget.value));
    setLocalInputValue(e.currentTarget.value);
    handleTextChange(e.currentTarget.value);
  };

  return (
    <>
      <AutocompleteInputContainer>
        <InputWithIcon>
          <OInput
            id="localization_input"
            type="text"
            placeholder=" "
            value={autocomplete.text}
            withfloatinglabel="true"
            withicon="true"
            onChange={handleInputChange}
          />
          <LabelAmount htmlFor="localization_input">
            {t("geocoder_placeholder")}
          </LabelAmount>
          <AutocompleteGeoIcon
            onClick={() => dispatch<any>(getCurrentPositionAsync())}
          >
            <img src={config.gpsIcon} alt="+" />
          </AutocompleteGeoIcon>
        </InputWithIcon>
        <motion.div>
          {autocomplete.loading && (
            <SpinnerIcon>
              <img src={config.spinnerIcon} alt="+" />
            </SpinnerIcon>
          )}
          {autocomplete.suggestions.length > 0 && (
            <AutocompleteSuggestionsContainer
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.3 }}
              variants={config.containerVariants}
            >
              {autocomplete.suggestions.map((suggestion) => (
                <AutocompleteSuggestionItem
                  key={suggestion.place_id + Math.random()}
                  onClick={() => store.dispatch(selectLocation(suggestion))}
                >
                  {suggestion.display_name}
                </AutocompleteSuggestionItem>
              ))}
            </AutocompleteSuggestionsContainer>
          )}
          {autocomplete.error && (
            <OBody6 style={{ color: theme.colors.error.cherryPower700 }}>
              {t(autocomplete.error)}
            </OBody6>
          )}
        </motion.div>
      </AutocompleteInputContainer>
    </>
  );
}
