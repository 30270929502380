// ENV
// TODO detect from hostname
export const isProd = false;

const location = window.location;
const hostName = location.hostname;
//------------------
let webUrl = location.origin;
let prestaApiUrl = "https://dev.oscaro-power.com"; // DEV API by Default
let buildRootPath = "/"; // root by default

// export let STORE_URL = "https://beta.dev.oscaro-power.com";
//------------------

const API_GEOCODING_KEY_DEV =
  "pk.eyJ1Ijoiby1wb3dlciIsImEiOiJjbGx5eHJ4MmoycmsyM2NwODMzaWp4ODFxIn0.jW4d1ap8A2n9lqWKX-rJyg"; // dev@oscaro-power.com
const API_GEOCODING_KEY_PROD =
  "pk.eyJ1Ijoiby1wb3dlciIsImEiOiJjbGt0Z3VvOWEwOWdsM2Zxb3Rnb3E0ZWZ0In0.N28W4Govyb24ddlQrvjTxA"; // petru.magdeleine@oscaro-power.com

export let API_GEOCODING_KEY = API_GEOCODING_KEY_PROD;

//------------------

export const environments = {
  dev: {
    prestaApiUrl: "https://n-1.oscaro-power.com",
    buildRootPath: webUrl + "/",
    API_CONFIGURATEUR_URL:
      "https://beta.dev.api-configurateur.oscaro-power.com",
    API_DIMENSIONNEUR_URL: "https://beta.api-dimensionneur.oscaro-power.com",
    API_OP_BASE_URL: "https://beta.dev.api.oscaro-power.com",
    STORE_URL: "https://beta.dev.oscaro-power.com",
    API_GEOCODING_KEY: API_GEOCODING_KEY_DEV,
  },
  prod: {
    prestaApiUrl: "https://n-1.oscaro-power.com",
    buildRootPath: webUrl + "/",
    API_CONFIGURATEUR_URL: "https://beta.api-configurateur.oscaro-power.com",
    API_DIMENSIONNEUR_URL: "https://beta.api-dimensionneur.oscaro-power.com",
    API_OP_BASE_URL: "https://beta.api.oscaro-power.com",
    STORE_URL: "https://oscaro-power.com/",
    API_GEOCODING_KEY: API_GEOCODING_KEY_PROD,
  },
  staging: {
    prestaApiUrl: "https://n-1.oscaro-power.com",
    buildRootPath: webUrl + "/",
    API_CONFIGURATEUR_URL: "https://beta.api-configurateur.oscaro-power.com",
    API_DIMENSIONNEUR_URL: "https://beta.api-dimensionneur.oscaro-power.com",
    API_OP_BASE_URL: "https://beta.api.oscaro-power.com",
    STORE_URL: "https://oscaro-power.com/",
    API_GEOCODING_KEY: API_GEOCODING_KEY_PROD,
  },
};

export let CURRENT_ENV = environments.dev;

export const setLocalEnv = () => {
  setCustomEnv({
    ...environments.dev,
    API_CONFIGURATEUR_URL: "http://127.0.0.1:5000",
    API_OP_BASE_URL: "http://localhost:8080",
  });
};

export const setDevEnv = () => {
  CURRENT_ENV = environments.dev;
};

export const setProductionEnv = () => {
  CURRENT_ENV = environments.prod;
};

export const setStagingEnv = () => {
  CURRENT_ENV = environments.staging;
}


export const setCustomEnv = (customEnv: {
  prestaApiUrl: string;
  buildRootPath: string;
  API_CONFIGURATEUR_URL: string;
  API_DIMENSIONNEUR_URL: string;
  API_OP_BASE_URL: string;
  STORE_URL: string;
  API_GEOCODING_KEY: string;
}) => {
  CURRENT_ENV = customEnv;
};

//------------------

export const isDev =
  hostName.indexOf("beta.dev.configurateur.oscaro-power.com") !== -1 ||
  hostName.indexOf("dev.configurateur.oscaro-power.com") !== -1 ||
  hostName.indexOf("dev.configuration.oscaro-power.com") !== -1 ||
  hostName.indexOf("dev-configurateur-dot-opower-data.ew.r.appspot.com") !== -1;
export const isLocal = hostName.indexOf("localhost") !== -1;
export const isProduction =
  hostName.indexOf("beta.configurateur.oscaro-power.com") !== -1 ||
  hostName.indexOf("configurateur.oscaro-power.com") !== -1 ||
  hostName.indexOf("configuration.oscaro-power.com") !== -1 ||
  hostName.indexOf("configurateur-dot-opower-data.ew.r.appspot.com") !== -1;

export const isStaging =
  hostName.indexOf("staging-configurateur-dot-opower-data.ew.r.appspot.com") !== -1;

if (isLocal) {
  setProductionEnv();
  // setLocalEnv();
  // setDevEnv();
} else if (isDev) {
  buildRootPath = webUrl + "/";
  setDevEnv();
} else if (isProduction) {
  buildRootPath = webUrl + "/";
  setProductionEnv();
}
else if (isStaging) {
  setStagingEnv();
}

export const assetsPath = buildRootPath + "assets/";
export const fontsPath = buildRootPath + "fonts/";
export const imgsPath = buildRootPath + "imgs/";
export const svgsPath = buildRootPath + "svgs/";

//------------------

// API
const urlParams = "ws_key=AUQPDAXNNL67N18H4HWVS8AKK2EQ53NG&output_format=JSON";

export const API_DEV_TOKEN = "ZGV2OlF1YXJhbnRhaW5l";
export const API_PRODUCTS = prestaApiUrl + "/api/products/?" + urlParams;
export const API_OSCAROPOWER = prestaApiUrl + "/api/oscaropower/?" + urlParams;
export const API_SETTINGS = API_OSCAROPOWER + "&display=settings";

// DEALT
const dealtUrlParams =
  "ws_key=6RE26JSYHD2NMXDGSB4GXPD8SMZYZU1E&output_format=JSON";
export const API_DEALT_OFFERS =
  prestaApiUrl + "/api/dealtlight-op-offers/?" + dealtUrlParams;
export const API_CART = (cartId: number, data: string) =>
  prestaApiUrl +
  `/api/carts/${cartId}?${urlParams}&ps_method=PUT&json_to_xml=${data}`;

export const API_CART_DEALT =
  prestaApiUrl +
  "/api/dealtlight-cart-offer-references?ws_key=6RE26JSYHD2NMXDGSB4GXPD8SMZYZU1E&output_format=JSON";

//Localization
export const ZOOM_DEFAULT = 5;
// export const API_GEOCODING_KEY = "pk.ebf8b8698c7db3d284490dfe85dc834c"; // dev@oscaro-power.com
// export const API_GEOCODING_KEY_PROD = "pk.8a35fe281bf9afee31b1b821d921bfff"; // petru.magdeleine@oscaro-power.com
export const API_GEOCODING_LIMIT = 10;
export const API_GEOCODING_COUNTRY = "fr,be";
export const API_GEOCODING_LANG = "fr";

//MapBox
export const PUBLIC_KEY_MAPBOX =
  "pk.eyJ1Ijoiby1wb3dlciIsImEiOiJjbGt0Z3VvOWEwOWdsM2Zxb3Rnb3E0ZWZ0In0.N28W4Govyb24ddlQrvjTxA";
export const API_MAPBOX_GEOCODING = "https://api.mapbox.com/geocoding/v5";

export const loaderOpUrl =
  "https://lottie.host/545c5bd0-ae1e-4ac1-b867-fb42dc4ddb20/o7vvfyXyw0.json";
