import { StepData } from "../../../store/reducer";
import { imgsPath } from "../../../utils/constants";

export interface InstallationReduxStepData extends StepData {
  installation?: InstallationType;
  installationId?: string;
}

export enum InstallationType {
  Roof = "Roof",
  FlatRoof = "FlatRoof",
  Pergola = "Pergola",
  Shelter = "Shelter",
  Ground = "Ground",
}

export const InstallationTypeId = {
  [InstallationType.Roof]: "roof_001",
  [InstallationType.FlatRoof]: "45",
  [InstallationType.Pergola]: "544",
  [InstallationType.Shelter]: "543",
  [InstallationType.Ground]: "46",
};

export const InstallationTypeImageLinks = {
  [InstallationType.Roof]: imgsPath + "installations/modeToiture.png",
  [InstallationType.FlatRoof]: imgsPath + "installations/modeToitPlat.png",
  [InstallationType.Pergola]: imgsPath + "installations/modePergola.png",
  [InstallationType.Shelter]: imgsPath + "installations/modeAbri.png",
  [InstallationType.Ground]: imgsPath + "installations/modeAuSol.png",
};

export const installationConfig = {
  defaultValues: {
    installation: undefined,
    installationId: undefined,
  },
  disabled: [],
  gridConfig: {
    left: [12, 12, 12],
  },
  cards: {
    hoverScale: 1.1,
    borderRadius: "8px",
    width: {
      lg: "12.5rem",
      md: "9rem",
    },
    height: {
      lg: "9.5rem",
      md: "9rem",
    },
  },
};

// Define the installation types that are considered as roof types (for the design phase)
export const isRoofType = (type: string) => {
  if (!type) return true;
  return type === InstallationType.Roof;
};

export const isStuctureType = (installation: string) => {
  if (!installation) return false;
  return installation === InstallationType.Roof;
};

// Define if is a flat roof type
export const isFlatRoofType = (type: string) => {
  if (!type) return false;
  return type === InstallationType.FlatRoof;
};

export const getInstallationTypeFromLocation = (
  location?: string
): InstallationType => {
  if (!location) return;
  switch (location.toUpperCase()) {
    case "TOIT_PLAT":
      return InstallationType.FlatRoof;
    case "PERGOLA":
      return InstallationType.Pergola;
    case "ABRI":
      return InstallationType.Shelter;
    case "AU_SOL":
      return InstallationType.Ground;
    default:
      return InstallationType.Roof;
  }
};
