import React, { useCallback, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Sections } from "../constants";
import {
  STEP_DESIGN,
  STEP_RECOMMANDATION,
} from "../../../../../../store/reducer";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";
import { OBody9, OH3 } from "../../../../../Styled/Texts";
import { SectionCalepinageHorsToitureType } from "./styled";
import { FixationDto } from "../../../../../../data/fixations/dtos/fixation-dto";
import { fixationService } from "../../../../../../data/fixations/repositories/fixation-repository";
import { OTinyButtonCalepinage } from "../../../../../Styled/Buttons";
import {
  ORow,
  OCol,
  ORowContainerCalepinage,
  RowCalepinage,
  ColCalepinage,
  OColContainerCalepinage,
} from "../../../../../Styled/CustomGrid";
import {
  selectFixationForCalepinageHorsToiture,
  setPanelCountInCalepinage,
} from "../../../../../../store/calepinage/calepinageReducer";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import { svgsPath } from "../../../../../../utils/constants";
import { FixationEntity } from "../../../../../../data/fixations/repositories/entities/fixation-entity";
import { Orientation2D } from "../../../../../shared/panels_grid/constants";

const CalepinageHorsToitureTypeComponent: React.FC = () => {
  const dispatch = useDispatch();
  const currentStepName = STEP_DESIGN;
  const currentSectionName = Sections.CALEPINAGE_HORS_TOITURE;
  const { t } = useTranslation([currentStepName, "global"]);

  const recommandationCount = useSelector(
    (state: RootState) => state.calepinageReducer.recommandationCount
  );
  const fixationSelected = useSelector(
    (state: RootState) => state.calepinageReducer.fixationSelected
  );
  const panelCountInCalepinage = useSelector(
    (state: RootState) => state.calepinageReducer.panelCountInCalepinage
  );
  const dispatchPanelCountInCalepinage = useCallback(
    (count) => dispatch(setPanelCountInCalepinage(count)),
    [dispatch]
  );

  const supportId = useSelector((state: RootState) => {
    return state.step.steps[STEP_RECOMMANDATION].data?.phase1?.fixation?.id;
  });

  const [fixationList, setFixationList] = useState<FixationEntity[]>([]);

  const [count, setCount] = useState(panelCountInCalepinage);

  const dispatchNewPanelCount = useRef(
    debounce((e) => {
      dispatchPanelCount(e);
    }, 600)
  ).current;

  const decrement = useCallback(
    (newCount) => {
      if (isFixationAvailableForNewCount(newCount, supportId)) {
        setCount(newCount);
        dispatchNewPanelCount(newCount);
      } else {
        if (newCount > 1) {
          decrement(newCount - 1);
        }
      }
    },
    [supportId]
  );

  const increment = useCallback(
    (newCount) => {
      if (isFixationAvailableForNewCount(newCount, supportId)) {
        setCount(newCount);
        dispatchNewPanelCount(newCount);
      } else {
        if (newCount < 25) {
          increment(newCount + 1);
        }
      }
    },
    [supportId]
  );

  const dispatchPanelCount = (count: number) => {
    dispatchPanelCountInCalepinage(count);
  };

  useEffect(() => {
    if (!isFixationAvailableForNewCount(panelCountInCalepinage, supportId)) {
      increment(panelCountInCalepinage + 1);
    }
  }, [increment, panelCountInCalepinage, supportId]);

  useEffect(() => {
    if (panelCountInCalepinage == null) {
      setCount(recommandationCount);
      dispatchPanelCountInCalepinage(recommandationCount);
    }
  }, [
    dispatchPanelCountInCalepinage,
    panelCountInCalepinage,
    recommandationCount,
  ]);

  const selectCalepinage = (c, l) => {
    // 1 - On récupère l'ID + ID product de la fixation associée
    const fixationFound = fixationList.filter(
      (fixation) => fixation.c === c && fixation.l === l
    );
    if (fixationFound.length === 1) {
      // 2 - On met à jour le store
      // Si la fixation étaient déjà séléctionnée , ne rien faire
      // Sinon , mettre à jour le store

      dispatch(selectFixationForCalepinageHorsToiture(fixationFound[0]));
    }
  };

  const checkIfFixationIsSelected = (c, l) => {
    return fixationSelected?.c === c && fixationSelected?.l === l;
  };

  const isFixationAvailableForNewCount = useCallback((newCount, supportId) => {
    const fixations = fixationService.getFixationsByFixationIdAndPanelCount(
      supportId,
      newCount
    );
    return fixations?.length > 0;
  }, []);

  // Select first item by default
  useEffect(() => {
    const fixationDefault = fixationList[0];
    if (fixationDefault) {
      selectCalepinage(fixationDefault.c, fixationDefault.l);
    }
  }, [fixationList]);

  useEffect(() => {
    const tempFixationList =
      fixationService.getFixationsByFixationIdAndPanelCount(supportId, count);

    setFixationList(tempFixationList);
  }, [count, supportId]);

  return (
    <SectionCalepinageHorsToitureType
      initial="hidden"
      animate="visible"
      exit="exit"
      id={currentSectionName + "_anchor"}
    >
      <OH3 center="true" className="mt-1">
        <Trans>{t("phase_2.sections." + currentSectionName + ".title")}</Trans>
      </OH3>
      <ORow className="align-items-center">
        <OCol className="d-flex align-items-center justify-content-end">
          <OBody9>
            <Trans>
              {t("phase_2.sections." + currentSectionName + ".quantity")}
            </Trans>
          </OBody9>
        </OCol>
        <OCol className="d-flex align-items-center justify-content-start">
          <OTinyButtonCalepinage
            mode="minus"
            background="none"
            onClick={() => {
              decrement(count - 1);
            }}
            disabled={count <= 1}
          />
          <OH3>{count}</OH3>
          <OTinyButtonCalepinage
            mode="plus"
            background="none"
            onClick={() => {
              increment(count + 1);
            }}
            disabled={count >= 25}
          />
        </OCol>
      </ORow>
      <ORowContainerCalepinage>
        {fixationList.map((fixation, index) => {
          const lines = [];
          const columns = [];
          let restant = 1;
          // if (fixation.l === 1) {
          restant = fixation.c - 5;
          // }
          for (let i = 0; i < fixation.l; i++) {
            lines.push(<ORow>Lines</ORow>);
          }

          for (let i = 0; i < lines.length; i++) {
            let currentLine = <ORow key={`line-curr-${i}`}></ORow>;
            for (let j = 0; j < fixation.c; j++) {
              const img =
                svgsPath +
                (fixation.orientation === Orientation2D.LANDSCAPE
                  ? "matrice-panneau-paysage.svg"
                  : "matrice-panneau-portrait.svg");
              const children =
                j < 5 || restant <= 1 ? (
                  <ColCalepinage orientation key={`line-${i}-col-${j}`}>
                    <img src={img} alt="Panneau SVG" />
                  </ColCalepinage>
                ) : j - 6 == 0 ? (
                  <ColCalepinage orientation>
                    <img src={img} alt="Panneau SVG" /> <span>+ {restant}</span>{" "}
                  </ColCalepinage>
                ) : null;
              currentLine = (
                <RowCalepinage key={`line-${i}`}>
                  {currentLine.props.children}
                  {children}
                </RowCalepinage>
              );
            }
            columns.push(currentLine);
          }

          return (
            <OColContainerCalepinage
              isSelected={checkIfFixationIsSelected(fixation.c, fixation.l)}
              onClick={() => selectCalepinage(fixation.c, fixation.l)}
            >
              {columns}
            </OColContainerCalepinage>
          );
        })}
      </ORowContainerCalepinage>
    </SectionCalepinageHorsToitureType>
  );
};

export default CalepinageHorsToitureTypeComponent;
