import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { OH2, OBody9, OBody2 } from "../../../../Styled/Texts";
import { StyledRecommandationCard } from "./styled";
import { slide } from "../../../../Styled/GlobalAnimations";
import {
  STEP_INSTALLATION,
  STEP_RECOMMANDATION,
} from "../../../../../store/reducer";
import { motion } from "framer-motion";
import useIsMobile from "../../../../../utils/useIsMobile";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { isFlatRoofType } from "../../../Installation/constants";

const RecommandationCard: React.FC<{ starUrl: string }> = ({ starUrl }) => {
  const { t } = useTranslation([STEP_RECOMMANDATION + "phase1", "global"]);
  const isMobile = useIsMobile();

  const installation = useSelector(
    (state: RootState) => state.step.steps[STEP_INSTALLATION].data.installation
  );

  return (
    <StyledRecommandationCard
      {...slide("left", 1)}
      className=" d-flex flex-column gap-3"
    >
      {!isMobile && (
        <motion.span>
          <img id="star" src={starUrl} alt="star" />
          <OH2>{t("recommandation_title")}</OH2>
          <OBody2>{t("recommandation_subtitle")}</OBody2>
        </motion.span>
      )}
      <OBody9>
        <Trans>{t("recommandation_description")}</Trans>
      </OBody9>
      {isFlatRoofType(installation) && (
        <OBody9>
          <Trans>{t("recommandation_description_toit_plat")}</Trans>
        </OBody9>
      )}
    </StyledRecommandationCard>
  );
};

export default RecommandationCard;
