import { useContext } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { STEP_DESIGN } from "../../../../../store/reducer";
import { URL_TUTO_CALEPINAGE } from "../../../../shared/calepinage/constant";
import { Modal } from "../../../../Styled/modals";
import {
  ConfigurateurPopupContext,
  ConfigurateurPopups,
} from "../phase2/configurateur-modals-context";

export const CalepinageVideoModal = (props: { show: boolean }) => {
  const { t } = useTranslation([STEP_DESIGN, "global"]);
  const { setPopup } = useContext(ConfigurateurPopupContext);
  return (
    <Modal
      show={props.show}
      title={t("phase_1.how_to_draw_install")}
      onClose={() => setPopup(ConfigurateurPopups.none)}
    >
      <ReactPlayer
        url={URL_TUTO_CALEPINAGE}
        loop={true}
        muted={true}
        playing={true}
        width="100%"
        height="100%"
        controls={true}
      />
    </Modal>
  );
};
