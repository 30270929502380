import styled from "styled-components";

export const OrientationGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;
