import { Trans } from "react-i18next";
import styled from "styled-components";
import { assetsPath } from "../../../utils/constants";
import { theme } from "../GlobalStyle";
import { OBody5 } from "../Texts";

export const OLoader = (props: { content?: string; maxWidth?: number }) => {
  return (
    <OLoaderContainer maxWidth={props.maxWidth}>
      <img src={assetsPath + "loader/loader.gif"} alt={props.content} />
      {props.content && (
        <OBody5 color={theme.colors.onyx.onyxPower700}>
          <Trans>{props.content}</Trans>
        </OBody5>
      )}
    </OLoaderContainer>
  );
};
export const OLoaderContainer = styled.div<{ maxWidth?: number }>`
  background-color: ${(props) => props.theme.colors.clay.clayPower200};
  border-radius: 16px;
  padding: 24px;
  text-align: center;

  max-width: fit-content;
  max-height: fit-content;
  height: -moz-max-content;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  img {
    height: 60px;
    width: 60px;
  }

  p {
    padding-top: 8px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}px) {
    img {
      height: 40px;
      width: 40px;
    }
  }
`;

export const OMiniLoader = (props: {
  className?: string;
  width?: string | number;
  height?: string | number;
}) => (
  <img
    className={props?.className}
    src={assetsPath + "loader/loader.gif"}
    width={props?.width}
    height={props?.height}
    alt=""
  />
);
