import { ComponentStatus } from "../../../components/shared/status/component-status";
import { InstallHelpEntity } from "../../../data/prestashop/services/entities/product-entity";

export interface ConfigurateurInstallHelpsState {
  installHelpsData: {
    status: ComponentStatus;
    data?: InstallHelpEntity[];
    error?: ConfigurateurInstallHelpErrors;
    installHelpsSelected?: {
      checked: boolean;
      installHelp: InstallHelpEntity;
    }[];
    postcode?: string;
  };
}

export enum ConfigurateurInstallHelpErrors {
  department,
  configuration,
}
