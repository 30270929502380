import {
  InstallationType,
  InstallationTypeId,
} from "../../components/Steps/Installation/constants";
import {
  STEP_DESIGN,
  STEP_INSTALLATION,
  setCurrentStep,
  setSkip,
  setStepAccessible,
  setStepData,
} from "../../store/reducer";
import { CURRENT_ENV, isLocal } from "../constants";

export const skipToConfiguration = (
  dispatch,
  installationType,
  inaccessibleSteps: string[] = []
) => {
  dispatch(setSkip(true));
  dispatch(
    setStepData({
      step: STEP_DESIGN,
      data: {
        phase: 2,
      },
    })
  );
  dispatch(
    setStepAccessible({ step: STEP_DESIGN, accessible: false, data: {} })
  );
  dispatch(setCurrentStep("design"));

  if (!installationType) {
    dispatch(
      setStepData({
        step: STEP_INSTALLATION,
        data: {
          installation: InstallationType.Roof,
          installationId: InstallationTypeId[InstallationType.Roof],
        },
      })
    );
  }

  inaccessibleSteps.forEach((s) => {
    dispatch(setStepAccessible({ step: s, accessible: false, data: {} }));
  });
};

export const goToShopifyCart = (kitId: string) => {
  const cartUrl = CURRENT_ENV.STORE_URL + `/cart/${kitId}:1`;
  if (isLocal) {
    window.location.href = cartUrl;
  } else {
    window.location.replace(cartUrl);
  }
};
