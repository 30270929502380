import React from "react";
import { useTranslation } from "react-i18next";
import { StructureTypes, StructureTypesImagesLinks } from "./constants";
import { STEP_RECOMMANDATION } from "../../../../../store/reducer";
import {
  OFormGroup,
  OFormLabel,
  OSelect,
  OSelectOption,
  OSelectOptionIcon,
} from "../../../../Styled/Forms";

const MaterialSelect: React.FC<{
  material: StructureTypes;
  setMaterial: Function;
}> = ({ material, setMaterial }) => {
  const { t } = useTranslation([STEP_RECOMMANDATION + "phase1", "global"]);

  const StructureTypesArray = Object.values(StructureTypes)
    .filter((value) => isNaN(Number(value)))
    .map((value) => ({
      value,
      label: t(`type.${value}`),
      icon: StructureTypesImagesLinks[value],
    }));

  const Option = (props) => {
    return (
      <OSelectOption {...props}>
        <img src={props.data.icon} alt="icon" height="20px" />
        {props.data.label}
      </OSelectOption>
    );
  };

  return (
    <OFormGroup>
      <OSelectOptionIcon src={StructureTypesImagesLinks[material]} alt="icon" />
      <OFormLabel>{t("typeLabel")}</OFormLabel>
      <OSelect
        styles={{
          indicatorsContainer: (provided) => ({
            ...provided,
            "& svg": {
              fill: "#ABB0DB",
            },
          }),
        }}
        options={StructureTypesArray}
        value={StructureTypesArray.find((option) => option.value === material)}
        onChange={(selectedOption: any) => setMaterial(selectedOption.value)}
        components={{ Option }}
        className="react-select-container with-icon"
        classNamePrefix="react-select"
      />
    </OFormGroup>
  );
};

export default MaterialSelect;
