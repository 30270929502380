import { useEffect, useState } from 'react';
import { theme } from '../components/Styled/GlobalStyle';

// Définir le breakpoint pour mobile (par exemple, 768px)
const MOBILE_SCREEN_SIZE = theme.breakpoints.md;

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_SCREEN_SIZE);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_SCREEN_SIZE);
    };

    window.addEventListener('resize', handleResize);

    // Faire le ménage après exécution en supprimant l'event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};

export default useIsMobile;
