import { motion } from "framer-motion";
import styled from "styled-components";
import { theme } from "../GlobalStyle";
import { OSpinner } from "./OSpinner";
import { OSuccess } from "./OSuccess";

const OBaseMainButton = styled(({ primary, ...rest }) => (
  <motion.button {...rest} />
))`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  min-height: ${(props) => props.theme.heights.input};

  border-radius: 8px;
  padding: 12px 50px;
  text-align: center;

  cursor: pointer;
  border: none;
  outline: none;

  position: relative;

  &:hover {
    background: ${(props) => props.theme.colors.onyx.onyxPower600};
  }
  &:active {
    background: ${(props) => props.theme.colors.onyx.onyxPower700};
  }

  &:disabled {
    cursor: not-allowed;

    &:hover {
      background-color: ${(props) => props.theme.colors.onyx.onyxPower700};
    }
  }

  transition: all 0.3s ease-in-out;
  color: ${(props) => props.theme.colors.clay.clayPower200};
  background: ${(props) => props.theme.colors.onyx.onyxPower700};
`;

export const OMainButton = styled(
  ({ disabled, loading, success, primary, ...rest }) => (
    <OBaseMainButton {...rest} />
  )
)<{
  disabled?: boolean;
  loading?: boolean;
  success?: boolean;
  primary?: boolean;
}>`
  ${(props) =>
    props.primary &&
    `
        background: ${props.theme.colors.primary.bluePower700};
        color: ${props.theme.colors.clay.clayPower200};
        &:hover {
            background: ${props.theme.colors.primary.bluePower600};
        }
        &:active {
            background: ${props.theme.colors.primary.bluePower700};
        }
        &.conception-configuration-next-btn {
        margin-bottom: 20px;
       }

        
    `}

  ${(props) =>
    props.disabled &&
    `
        cursor: not-allowed !important;
        background: ${props.theme.colors.clay.clayPower500};
        color: ${props.theme.colors.onyx.onyxPower700};
        
        &:hover {
            background: ${props.theme.colors.clay.clayPower500};
            opacity: 0.8;
        }
    `}

    ${(props) => props.loading && OSpinner}

    ${(props) => props.success && OSuccess}
`;

export const OInformationButton = styled(OMainButton)`
  font-size: 14px;
  min-height: 32px;
  line-height: 100%;
  width: fit-content;
  background: ${theme.colors.primary.bluePower700};
`;
