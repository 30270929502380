import styled from "styled-components";
import { theme } from "../../Styled/GlobalStyle";

/* Variables */

// const panelWidth = 78;
// const panelHeight = 110;

// const scrollbar = 12;

// // PORTRAIT VARIABLES
// const panelCountRow = 3; // ROWS
// const panelCountColumn = 7; // COLUMNS

// const panelGridWidth = panelWidth * panelCountColumn;
// const panelGridHeight = panelHeight * panelCountRow;

// const editorWidth = panelWidth + panelGridWidth;
// const editorHeight = panelHeight + panelGridHeight;

// // --- PAYSAGE VARIABLES

// const panelCountRowPaysage = 6; // ROWS
// const panelCountColumnPaysage = 6; // COLUMNS

// const panelGridWidthPaysage = panelHeight * panelCountColumnPaysage;
// const panelGridHeightPaysage = panelWidth * panelCountRowPaysage;

// const editorWidthPaysage = panelHeight + panelGridWidthPaysage;
// const editorHeightPaysage = panelWidth + panelGridHeightPaysage;

/* END Variables */

export const MatrixEditorContainer = styled.div`
  margin-top: ${(props) => props.theme.panelWidth}px;
  overflow: auto;
  // background-color: rgba(0, 0, 255, 0.1);
  width: 100%;
  height: 100%;
  //max-height: 468px;
  display: flex;

  &#matrix-editor-portrait {
    padding-right: ${(props) => props.theme.panelWidth}px;
    max-height: ${(props) => props.theme.editorHeight}px; // 448px;
  }

  &#matrix-editor-paysage {
    padding-right: 0.5rem;
    // max-width: ${(props) =>
      props.theme.editorWidthPaysage + props.theme.panelWidth}px;
    max-height: ${(props) =>
      props.theme.editorHeightPaysage + props.theme.scrollbar}px;
  }
`;

export const AreaDimensionContainer = styled.div`
  width: 0px;
  height: 0px;
  position: relative;
`;

export const AreaDimensionElements = styled.div`
  position: relative;
  // top: -${(props) => props.theme.panelWidth}px;
  // left: -${(props) => props.theme.panelWidth}px;
  top: -7vw;
  right: -2vw;
  min-width: ${(props) => props.theme.panelWidth2}px;
  height: ${(props) => props.theme.panelWidth}px;
  display: flex;
  align-items: center;
  justify-content: center;
  
`;

export const AreaDimension = styled.p`
  margin: 0;
  background-color: ${theme.colors.primary.bluePower700};
  color: white;
  font-size: 14px;
  padding: 0 4px;
  font-family: "Poppins", sans-serif;
  text-align: center;
`;

export const HorizontalDimensionContainer = styled.div`
  width: 0px;
  height: 0px;
  position: relative;
`;

export const HorizontalDimensionElements = styled.div<{
  rows?: number;
  columns?: number;
}>`
   position: absolute;
   top: -${(props) => props.theme.panelWidth - 20}px;
   left: ${(props) => props.theme.panelWidth}px};
   height: ${(props) => props.theme.panelWidth}px;

   display: flex;
   align-items: end;
   justify-content: center;
   margin-left: ${(props) => props.theme.panelWidth}px;

   &#horizontal-dimension-portrait {
      width: ${(props) => props.columns * props.theme.panelWidth}px;
      max-width: ${(props) =>
        props.theme.panelGridWidth + props.theme.scrollbar}px;
   }

   &#horizontal-dimension-paysage {
      width: ${(props) => props.rows * props.theme.panelHeight}px;
      max-width: ${(props) =>
        props.theme.panelGridWidthPaysage + props.theme.scrollbar}px;
   }

`;

export const VerticalDimensionContainer = styled.div`
  width: 0px;
  height: 0px;
  position: relative;
`;

export const VerticalDimensionElements = styled.div<{
  rows?: number;
  columns?: number;
}>`
  width: ${(props) => props.theme.panelWidth}px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: calc(2 * ${(props) => props.theme.panelWidth}px);

  &#vertical-dimension-portrait {
    height: ${(props) => props.rows * props.theme.panelHeight}px;
    max-height: ${(props) =>
      props.theme.panelGridHeight + props.theme.scrollbar}px;
    // margin-right: ${(props) => props.theme.panelWidth}px;
  }

  &#vertical-dimension-paysage {
    height: ${(props) => props.columns * props.theme.panelWidth}px;
    max-height: ${(props) =>
      props.theme.panelGridHeightPaysage + props.theme.scrollbar}px;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex: 1;

  &#main-container-portrait {
    overflow: hidden auto;
    // min-height: ${(props) => props.theme.panelGridHeight}px;
    max-height: ${(props) => props.theme.panelGridHeight + props.theme.scrollbar}px; // ? scrollbar

    // min-height: 336px;
    // min-height: 336px;
    //max-height: 348px; // 12px scrollbar

    margin-left: ${(props) => props.theme.panelWidth}px; //78px;
  }

  &#main-container-paysage {
    overflow: auto hidden;
   // min-width: ${(props) => props.theme.panelGridWidthPaysage}px; //351px;
    max-width: ${(props) =>
      props.theme.panelGridWidthPaysage +
      props.theme.scrollbar}px; // ? scrollbar
    max-height: ${(props) =>
      props.theme.panelGridHeightPaysage +
      props.theme.panelWidth +
      props.theme.scrollbar}px; //336px;
    flex-direction: column;
  }
`;

export const TopRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-width: ${(props) => props.theme.panelGridWidth}px; //351px;
  max-width: ${(props) => props.theme.panelGridWidth}px; //351px;

  min-height: ${(props) => props.theme.panelWidth}px; //112px;
  max-height: ${(props) => props.theme.panelWidth}px; //112px;
`;

export const ScrollingContainer = styled.div`
  display: flex;
  overflow: auto;
  white-space: nowrap;

  &#scrolling-container-portrait {
    min-width: ${(props) => props.theme.panelGridWidth}px; //351px;
    max-width: ${(props) => props.theme.panelGridWidth}px; //351px;
    overflow-x: auto;
    flex-wrap: nowrap;
    flex-direction: column;
  }

  &#scrolling-container-paysage {
    min-height: ${(props) => props.theme.panelGridHeightPaysage}px; //336px;
    max-height: ${(props) => props.theme.panelGridHeightPaysage}px; //336px;
    overflow-y: auto;
    flex-wrap: unset;
    width: fit-content;
    flex-direction: row;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  min-width: ${(props) => props.theme.panelWidth}px; //78px;
  max-width: ${(props) => props.theme.panelWidth}px; //78px;
  // min-height: ${(props) => props.theme.panelHeight}px; //112px;
  min-height: ${(props) => props.theme.panelWidth}px; //78px;
  max-height: ${(props) => props.theme.panelWidth}px; //78px;

  margin-left: ${(props) => props.theme.panelWidth}px; //78px;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${(props) => props.theme.panelWidth}px; //78px;
  max-width: ${(props) => props.theme.panelWidth}px; //78px;
`;

export const RowControllers = styled.div`
  // border: 1px solid black;

  // width: ${(props) => props.theme.panelWidth}px; //78px;
  // min-width: ${(props) => props.theme.panelWidth}px; //78px;
  // height: ${(props) => props.theme.panelHeight}px; //112px;
  // min-height: ${(props) => props.theme.panelHeight}px; //112px;

  &#controllers-portrait {
    width: ${(props) => props.theme.panelWidth}px; //78px;
    min-width: ${(props) => props.theme.panelWidth}px; //78px;
    height: ${(props) => props.theme.panelHeight}px; //112px;
    min-height: ${(props) => props.theme.panelHeight}px; //112px;
  }

  &#controllers-paysage {
    width: ${(props) => props.theme.panelHeight}px; //112px;
    min-width: ${(props) => props.theme.panelHeight}px; //112px;
    height: ${(props) => props.theme.panelWidth}px; //78px;
    min-height: ${(props) => props.theme.panelWidth}px; //78px;
  }
`;

export const CenterContainer = styled.div`
  flex: 1;
  flex-grow: 1;
`;

export const RightContainer = styled.div`
  right: 0;
  width: ${(props) => props.theme.panelWidth}px; //78px;
  margin-top: ${(props) => props.theme.panelWidth * 2}px; //78px * 2
  text-align: center;
  max-height: ${(props) => props.theme.panelGridHeightPaysage}px; //336px;
`;

export const ColumnRightController = styled.div`
  border-radius: 4px;
  border: 1px dashed ${theme.colors.secondary.yellowPower600};
  transition: border 0.3s ease-out;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  // width: ${(props) => props.theme.panelHeight}px; //112px;
  // max-width: ${(props) => props.theme.panelHeight}px; //112px;
  width : 78px;
  max-width : 78px;
  flex-direction: column;

  cursor: pointer;

  &:hover {
    border: 1px dashed ${theme.colors.secondary.yellowPower700};
  }

  &:hover span {
    color: ${theme.colors.secondary.yellowPower700};
  }
`;

export const RowBottomControllers = styled.div`
  width: ${(props) => props.theme.panelGridWidth}px;
  margin-left: ${(props) => props.theme.panelWidth * 2}px;
`;

export const RowBottomController = styled.div`
  // height: ${(props) => props.theme.panelHeight / 2}px; // 48
  height: 48px;
  max-height: 48px;
  margin-top: 2vw;

  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px dashed ${theme.colors.secondary.yellowPower600};
  transition: border 0.3s ease-out;

  cursor: pointer;

  &:hover {
    border: 1px dashed ${theme.colors.secondary.yellowPower700};
  }

  &:hover span {
    color: ${theme.colors.secondary.yellowPower700};
  }
`;

export const PanelsContainer = styled.div`
  min-width: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap !important;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}px) {
        max-width: 2vw;
    }
`;

export const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;           /* Allow items to wrap into the next line */
  overflow: hidden;          /* Hide overflowing content */
  margin: 0 auto;
  box-sizing: border-box;
    width: fit-content;        /* Fit the content */
  height: fit-content;       /* Fit the content */
`;

export const PanelItem = styled.div`
  // background-color: ${theme.colors.neutralBackground};
  //padding: 5px;
  min-width: ${(props) => props.theme.panelWidth}px; //78px;
  width: ${(props) => props.theme.panelWidth}px; //78px;
  min-height: ${(props) => props.theme.panelHeight}px; //112px;
  height: ${(props) => props.theme.panelHeight}px; //112px;

  &.panel-portrait {
    min-width: ${(props) => props.theme.newPanelWidth}px; //78px;
    width: ${(props) => props.theme.newPanelWidth}px; //78px;
    min-height: ${(props) => props.theme.newPanelHeight}px; //112px;
    height: ${(props) => props.theme.newPanelHeight}px; //112px;
      //    min-width: 150px;
      // width: 150px;
      // min-height: 210px;
      // height: 210px;
  }

  &.panel-paysage {
    min-width: ${(props) => props.theme.panelHeight}px; //78px;
    width: ${(props) => props.theme.panelHeight}px; //78px;
    min-height: ${(props) => props.theme.panelWidth}px; //112px;
    height: ${(props) => props.theme.panelWidth}px; //112px;
  }
`;

export const PanelCore = styled.div<{
  panelSrc?: string;
}>`
  width: 100%;
  height: 100%;
  border-radius: 4px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  //background-image: url("/svgs/matrice-panneau-portrait.svg");
  background-image: url(${(props) => props.panelSrc});
  background-position: center;
  // background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;

  //background-color: ${theme.colors.clay.clayPower700};

  position: relative;
`;

export const PanelNumber = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: ${theme.colors.primary.bluePower700};
  color: white;
  font-size: 11px;
  padding: 0 4px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  border-radius: 4px;
  // margin: 4px;
  width: 50px;
`;

export const PanelVirtual = styled.div`
  background-color: ${theme.colors.neutralBackground};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border: 1px dashed #143ff5;
  border-radius: 4px;

  p {
    width: 100%;
    font-size: 11px;
    color: #143ff5;
    font-weight: 500;
    font-family: "Poppins";
    line-height: 100%;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
    text-align: center;
    margin: 0;
  }
`;

export const DeleteHover = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.35);
  border-radius: 4px;
  border: 1px dashed ${theme.colors.onyx.onyxPower500};

  p {
    width: 100%;
    font-size: 11px;
    color: white;
    font-weight: 500;
    font-family: "Poppins";
    line-height: 100%;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
    text-align: center;
    margin: 0;
  }
`;

export const Obstacle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background-color: ${theme.colors.neutralBackground};
  border-radius: 4px;
  border: 1px dashed ${theme.colors.clay.clayPower700};

  p {
    width: 100%;
    font-size: 11px;
    color: ${theme.colors.clay.clayPower700};
    font-weight: 500;
    font-family: "Poppins";
    line-height: 100%;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
    text-align: center;
    margin: 0;
  }

  &:hover {
    border: 1px dashed ${theme.colors.onyx.onyxPower500};
    cursor: pointer;
  }

  &:hover p,
  &:hover span {
    color: ${theme.colors.onyx.onyxPower500};
  }
`;

export const ObstacleHover = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.85);
  // opacity: 0.5;
  border-radius: 4px;
  border: 1px dashed ${theme.colors.error.cherryPower700};
  transition: all 0.2s ease-out;

  p {
    width: 100%;
    font-size: 11px;
    color: grey;
    font-weight: 500;
    font-family: "Poppins";
    line-height: 100%;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
    text-align: center;
    margin: 0;
    color: ${theme.colors.error.cherryPower700};
  }
`;

export const RotateButton = styled.div`
  background-color: ${theme.colors.clay.clayPower200};
  width: 50px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: solid 1px ${theme.colors.clay.clayPower600};
  cursor: pointer;
`;

export const InformationContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  gap: 32px;
  position: absolute;
  bottom: 80px;
  text-align: center;
  display: flex;

  & span {
    display: inline-block;
    vertical-align: middle;
  }
`;

export const InfoMessage = styled.span`
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 600;
`;

export const InfoText = styled.span`
  font-size: 12px;
  font-family: "Poppins";
  & strong {
    font-weight: 600;
  }
`;

export const WarningContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 14px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 100;
  opacity: 0.8;
`;

export const WarningBox = styled.div`
  background-color: ${theme.colors.error.cherryPower200};
  padding: 5px 8px;
  border-radius: 8px;
`;

export const WarningMessage = styled.p`
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  color: ${theme.colors.error.cherryPower700};
  margin: 0;
`;
