import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../..";
import { ConfigurateurAccessoryEntity } from "../../../data/configurateur/services/entities/accessories/configurateur-accessories.entity";
import { ConfigurateurProductEntity } from "../../../data/configurateur/services/entities/configurateur-product.entity";
import { ConfigurateurPanelFixationEntity } from "../../../data/configurateur/services/entities/fixations-panel/configurateur-fixations-panel.entity";
import { ConfigurateurAdminHelpEntity } from "../../../data/configurateur/services/entities/helps/configurateur-admin-help.entity";
import { ConfigurateurInverterEntity } from "../../../data/configurateur/services/entities/inverters/configurateur-inverter.entity";
import { ConfigurateurPanelEntity } from "../../../data/configurateur/services/entities/panels/configurateur-panel.entity";
import { InstallHelpEntity } from "../../../data/prestashop/services/entities/product-entity";
import { usePanelCount } from "../../../hooks/configurateur/panels/use-panel-count.hook";
import { STEP_DESIGN } from "../../reducer";
import { ConfigurateurAccessoriesCombination } from "../accessories/configurateur-accessories-state";
import { useVirtualCartInstallHelps } from "./install-helps/use-virtual-cart-install-helps";

export interface VirtualCartCategory {
  category: string;
  splitted: boolean;
  items: VirtualCartCategoryItem[];
}

export interface VirtualCartCategoryItem {
  details?: ConfigurateurProductEntity;
  img: string;
  label: string;
  link?: string;
  linkLabel?: string;
  trailingLabel?: string;
}

interface ProductsByCategory {
  name: string;
  products: ConfigurateurProductEntity[];
}

export const useVirtualCartItems = (): VirtualCartCategory[] => {
  const { t } = useTranslation([STEP_DESIGN, "global"]);

  const panel = useSelector(
    (state: RootState) => state.configurateurPanels?.panelsData?.selected
  );
  const panelNumber = usePanelCount();
  const onduleur = useSelector(
    (state: RootState) => state.configurateurOnduleurs?.onduleursData?.selected
  );
  const panneauFixations = useSelector(
    (state: RootState) =>
      state.configurateurFixations?.fixationsData?.panneauFixation?.data
  );

  const supervision = useSelector(
    (state: RootState) =>
      state.configurateurSupervisions?.supervisionData?.selected
  );
  const garantie = useSelector(
    (state: RootState) => state.configurateurGaranties?.garantiesData?.selected
  );
  const battery = useSelector(
    (state: RootState) => state.configurateurBatteries?.batteriesData?.selected
  );
  const help = useSelector(
    (state: RootState) => state.configurateurHelps?.helpsData?.selected
  );
  const installHelps = useVirtualCartInstallHelps();

  const accessoireCombination = useSelector(
    (state: RootState) =>
      state.configurateurAccessories?.accessoriesData?.selectedCombination
  );

  const categoryMain = t("virtualCart.category.main");
  const categoryServices = t("virtualCart.category.services");

  const accessoriesCategories =
    accessoireCombination && getAccessoriesCartItems(accessoireCombination);

  const coffrets = getCoffrets(accessoireCombination?.data);

  const mainCategory = getMainCartCategory(
    categoryMain,
    panelNumber,
    panel,
    panneauFixations,
    onduleur,
    coffrets
  );

  const supervisionCategories =
    supervision &&
    getCategoriesFromProducts(
      [supervision],
      {},
      t("virtualCart.category.supervision")
    );
  const batteryCategories =
    battery &&
    getCategoriesFromProducts([battery], {
      labelBuilder: (_category, product) => {
        return product?.configurator_name || product?.parent.title;
      },
    });

  return [
    mainCategory,
    ...(supervisionCategories?.length > 0 ? supervisionCategories : []),
    ...(batteryCategories?.length > 0 ? batteryCategories : []),
    ...(accessoriesCategories?.length > 0 ? accessoriesCategories : []),
    ...(help || garantie
      ? [getServicesCartItems(categoryServices, help, garantie)]
      : []),
    // ...(installHelps?.length > 0
    //   ? [
    //       getInstallHelpCartItems(
    //         categoryInstallHelp,
    //         installHelps,
    //         t("virtualCart.category.installHelp.paymentDescription")
    //       ),
    //     ]
    //   : []),
  ];
};

const getCategoriesFromProducts = (
  products: ConfigurateurProductEntity[],
  itemsBuilders?: {
    imgBuilder?: {
      (category: string, product: ConfigurateurProductEntity): string;
    };
    labelBuilder?: {
      (category: string, product: ConfigurateurProductEntity): string;
    };
    linkLabelBuilder?: {
      (category: string, product: ConfigurateurProductEntity): string;
    };
    priceBuilder?: {
      (category: string, product: ConfigurateurProductEntity): number;
    };
  },
  categoryName?: string
): VirtualCartCategory[] => {
  const group = _.groupBy(
    products,
    (product: ConfigurateurProductEntity) =>
      categoryName || product?.parent.category
  );
  const productsByCategory: ProductsByCategory[] = [];
  Object.keys(group).forEach((key) => {
    productsByCategory.push({
      name: key,
      products: group[key],
    });
  });
  return productsByCategory.map((category) => {
    return {
      category: category.name,
      splitted: false,
      items: category.products.map((product) =>
        getItemFromProduct(category.name, product, itemsBuilders)
      ),
    };
  });
};

const getItemFromProduct = (
  category: string,
  product: ConfigurateurProductEntity,
  itemsBuilders?: {
    imgBuilder?: {
      (category: string, product: ConfigurateurProductEntity): string;
    };
    labelBuilder?: {
      (category: string, product: ConfigurateurProductEntity): string;
    };
    linkLabelBuilder?: {
      (category: string, product: ConfigurateurProductEntity): string;
    };
  }
) => {
  const prefixLabel =
    product?.configurator_quantity > 1
      ? `${product?.configurator_quantity}x `
      : "";
  return {
    details: product,
    img: itemsBuilders?.imgBuilder
      ? itemsBuilders?.imgBuilder(category, product)
      : product?.parent.default_image?.url,
    label: itemsBuilders?.labelBuilder
      ? itemsBuilders?.labelBuilder(category, product)
      : `${prefixLabel}${product?.parent.title}`,
    link: product?.parent.store_url,
    linkLabel: itemsBuilders?.linkLabelBuilder
      ? itemsBuilders?.linkLabelBuilder(category, product)
      : "Accéder à la fiche produit",
  };
};

const getMainCartCategory = (
  category: string,
  panelNumber: number,
  panel?: ConfigurateurPanelEntity,
  panelFixations?: ConfigurateurPanelFixationEntity[],
  onduleur?: ConfigurateurInverterEntity,
  coffrets?: ConfigurateurAccessoryEntity[]
): VirtualCartCategory => {
  return {
    category: category,
    splitted: false,
    items: [
      ...(panel
        ? [
            getItemFromProduct(category, panel, {
              labelBuilder: (_category, product) => {
                if (product! instanceof ConfigurateurPanelEntity) {
                  return "";
                }
                const currentPanel = product as ConfigurateurPanelEntity;
                const suffix = product?.tag ? ` ( ${product?.tag} )` : "";
                return `${panelNumber}x ${currentPanel?.parent?.category} - ${currentPanel?.configurator_name} ${currentPanel?.power} Wc${suffix}`;
              },
              imgBuilder: (_category, product) => {
                return product?.parent.default_image?.url;
              },
            }),
          ]
        : []),
      ...(panelFixations?.length > 0
        ? panelFixations?.map((fixation) =>
            getItemFromProduct(category, fixation)
          )
        : []),
      ...(onduleur ? [getItemFromProduct(category, onduleur)] : []),
      ...(coffrets?.length > 0
        ? coffrets?.map((coffret) => getItemFromProduct(category, coffret))
        : []),
    ],
  };
};

const getAccessoriesCartItems = (
  accessoireCombination: ConfigurateurAccessoriesCombination
): VirtualCartCategory[] => {
  let accessoriesCategories = getCategoriesFromProducts(
    accessoireCombination?.data
  );
  // Remove "Coffret de protection" from category
  const coffrets = getCoffrets(accessoireCombination?.data);
  if (coffrets?.length > 0) {
    accessoriesCategories = accessoriesCategories.filter(
      (item) => item.category !== coffrets[0].parent.category
    );
  }

  return accessoriesCategories?.sort((a, b) => {
    // if there is a fixation category into accessories
    // force to be in first position
    if (a?.category?.toLowerCase()?.includes("fixation")) {
      return -1;
    }
    return 0;
  });
};

const getCoffrets = (
  accessories: ConfigurateurAccessoryEntity[]
): ConfigurateurAccessoryEntity[] => {
  return accessories?.filter((item) =>
    // No other way to check the Coffrets category...
    // legacy: item.parent?.category === "103"
    item.parent?.category?.includes("Coffret")
  );
};

const getServicesCartItems = (
  category: string,
  help?: ConfigurateurAdminHelpEntity,
  garantie?: ConfigurateurProductEntity
): VirtualCartCategory => {
  return {
    category: category,
    splitted: false,
    items: [
      ...(help ? [getItemFromProduct(category, help)] : []),
      ...(garantie ? [getItemFromProduct(category, garantie)] : []),
    ],
  };
};

const getInstallHelpCartItems = (
  category: string,
  installHelps: InstallHelpEntity[],
  trailingLabel: string
): VirtualCartCategory => {
  return {
    category: category,
    splitted: true,
    items: installHelps?.map((installHelp) => {
      return {
        img: installHelp?.thumbnail,
        label: installHelp?.title,
        price: Number.parseFloat(installHelp?.price),
        trailingLabel: trailingLabel,
      };
    }),
  };
};
