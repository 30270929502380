import React from "react";

import { OBody11, OBody7, OBody9 } from "../../Texts";
import { theme } from "../../GlobalStyle";
import { OSelectableCard } from "../styled";

export const SelectableCheckCard: React.FC<{
  checked: boolean;
  label: string;
  trailingLabel?: string;
  sublabel?: string;
  onClick: Function;
}> = ({ checked, label, trailingLabel, onClick, sublabel }) => {
  return (
    <OSelectableCard className="flex-column"
      checked={checked}
      onClick={() => {
        onClick();
      }}
    >
      <div>
        <input type="checkbox" checked={checked} />
        <OBody9 className="label" color={theme.colors.onyx.onyxPower700}>
          {label}
        </OBody9>
        {trailingLabel && <OBody7 className="trailing">{trailingLabel}</OBody7>}
      </div>

      {sublabel && (
        <OBody11
          className="sublabel"
          color={theme.colors.tertiary.leafPower700}
        >
          {sublabel}
        </OBody11>
      )}
    </OSelectableCard>
  );
};
