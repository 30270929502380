import { useEffect } from "react";
import { useSelector } from "react-redux";
import { NeedReduxStepData } from "../../components/Steps/Needs/constants";
import {
  AzimutId,
  PenteId,
} from "../../components/Steps/Recommandation/phases/phase1/constants";
import store, { RootState } from "../../store";
import {
  STEP_LOCALIZATION,
  STEP_NEEDS,
  STEP_RECOMMANDATION,
} from "../../store/reducer";
import { simulationLoad } from "../../store/simulation/simulation-slice";
import { SimulationState } from "../../store/simulation/simulation-state";
import { GLOBAL_LAUNCH_SIMULATION, GLOBAL_SIMULATION_DATAS } from "../../components/shared/constant";

export const useSimulation = () => {
  const simulationState: SimulationState = useSelector(
    (state: RootState) => state.simulationReducer
  );
  const hasSkipped = useSelector((state: RootState) => state.step.skip);
  const needData: NeedReduxStepData =
    useSelector((state: RootState) => state.step.steps[STEP_NEEDS].data) || {};
  const localizationData = useSelector(
    (state: RootState) => state.step.steps[STEP_LOCALIZATION].data || {}
  );

  const recommandationData =
    useSelector(
      (state: RootState) => state.step.steps[STEP_RECOMMANDATION].data
    ) || {};

  useEffect(() => {
    if (!hasSkipped || GLOBAL_LAUNCH_SIMULATION) {
      let simulationParams = GLOBAL_SIMULATION_DATAS;
      if(!GLOBAL_LAUNCH_SIMULATION) {
        simulationParams = {
          budget: needData.phase1?.budget,
          equipements: needData?.phase2?.equipements,
          lat: localizationData.position[0],
          lng: localizationData.position[1],
          angle: PenteId[recommandationData?.phase1?.pente],
          orientation: AzimutId[recommandationData?.phase1?.azimut],
          pays: "fr",
        };
      }
      // console.log('simulationParams ', simulationParams)
      store.dispatch(simulationLoad(simulationParams));
    }
  }, []);
};
