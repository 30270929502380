import {
  STEP_RECOMMANDATION,
  setStepData,
} from "../../../../../../../store/reducer";
import {
  InputSelect,
  SelectOption,
} from "../../../../../../shared/inputs/selects/input-select";
import {
  StructureTypes,
  StructureTypesImagesLinks,
} from "../../../../../Recommandation/phases/phase1/constants";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useEffect, useState } from "react";

const Container = styled.div`
  width: 100%;
`;

export const StructureSelect = () => {
  const tRecommandation = useTranslation([
    STEP_RECOMMANDATION + "phase1",
    "global",
  ]).t;

  const dispatch = useDispatch();

  const [structure, setStructure] = useState(
    StructureTypes.TUILE_MECANIQUE_GALBEE
  );

  const optionsStructures: SelectOption<StructureTypes>[] = Object.values(
    StructureTypes
  ).map((structure) => {
    return {
      value: structure,
      label: tRecommandation(`type.${structure}`),
      icon: StructureTypesImagesLinks[structure],
    };
  });

  useEffect(() => {
    dispatch(
      setStepData({
        step: STEP_RECOMMANDATION,
        data: {
          phase1: {
            material: structure,
          },
        },
      })
    );
  }, [dispatch, structure]);

  return (
    <Container>
      <InputSelect
        className="configurateur-input-installation-type"
        label={tRecommandation(`typeLabel`)}
        options={optionsStructures}
        selection={structure}
        onSelect={(selection) => {
          setStructure(selection.value);
        }}
      />
    </Container>
  );
};
