import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store, { RootState } from "../../../store";
import PanelContainer from "./panel-container";
import {
  AreaDimension,
  AreaDimensionContainer,
  AreaDimensionElements,
  CenterContainer,
  ColumnRightController,
  HorizontalDimensionContainer,
  HorizontalDimensionElements,
  InfoMessage,
  InfoText,
  InformationContainer,
  LeftContainer,
  MainContainer,
  MatrixEditorContainer,
  RightContainer,
  RotateButton,
  RowBottomController,
  RowBottomControllers,
  RowControllers,
  ScrollingContainer,
  TopContainer,
  TopRowContainer,
  VerticalDimensionContainer,
  VerticalDimensionElements,
  WarningBox,
  WarningContainer,
  WarningMessage,
} from "./styled";

import {
  addNewRow,
  rotateMatrix,
  validateMatriceCalepinage,
} from "../../../store/calepinage/calepinageHorsMapReducer";
import { svgsPath } from "../../../utils/constants";
import { formatNumber } from "../../../utils/convert-number-locale";
import { getMatrixRealDimensions } from "../../../utils/matrix-to-dimensions";
import { OMaterialButton } from "../../Styled/Buttons/OMaterialButton";
import { OBody8 } from "../../Styled/Texts";
import HorizontalDimension from "./horizontal-dimension";
import RowButtonsContainer from "./row-buttons-container";
import VerticalDimension from "./vertical-dimension";

import { ThemeProvider } from "styled-components";
import useDimension from "../../../utils/useDimension";
import { bigSize, defaultSize, smallSize } from "./variables-theme";

export interface MatrixEditorProps {}

const MatrixEditor: React.FC<MatrixEditorProps> = ({}) => {
  const dimension = useDimension();

  // Themes by dimension.height
  let sizeTheme = defaultSize;
  switch (true) {
    case dimension.height < 850:
      sizeTheme = smallSize;
      break;
    case dimension.height >= 850 && dimension.height < 960:
      sizeTheme = defaultSize;
      break;
    case dimension.height >= 960:
      sizeTheme = bigSize;
      break;
    default:
      break;
  }

  const [maxRow, setMaxRow] = useState(0);
  const [matrixWithObstacle, setMatrixWithObstacle] = useState(false);

  const [closedWarningMessage1, setClosedWarningMessage1] = useState(false);
  const [closedWarningMessage2, setClosedWarningMessage2] = useState(false);
  const isDesactivateWaringMessage = true;

  const [matrixDimensions, setMatrixDimensions] = useState({
    horizontalDimension: 0,
    verticalDimension: 0,
    areaDimension: 0,
  });

  const matrixFromReducer = useSelector(
    (state: RootState) => state?.calepinageHMReducer?.matriceCalepinage
  );

  const rowWithVirtualPanelToShow = useSelector(
    (state: RootState) => state?.calepinageHMReducer?.rowWithVirtualPanelToShow
  );

  const rowWithDeletePanelToShow = useSelector(
    (state: RootState) => state?.calepinageHMReducer?.rowWithDeletePanelToShow
  );

  const dispositionPanneaux = useSelector(
    (state: RootState) => state?.calepinageHMReducer?.dispositionPanneaux
  );

  const panelsSelected = useSelector(
    (state: RootState) => state.configurateurPanels.panelsData.selected
  );

  const panelsCount = useSelector(
    (state: RootState) => state?.calepinageHMReducer?.count
  );

  const maxPanelCount = useSelector(
    (state: RootState) => state?.calepinageHMReducer?.maxPanelCount
  );

  useEffect(() => {
    setMaxRow(getMaxLength(matrixFromReducer));
    if (matrixFromReducer.some((row) => row.some((item) => item === 0))) {
      setMatrixWithObstacle(true);
      setClosedWarningMessage1(false);
    } else {
      setMatrixWithObstacle(false);
    }
    if (panelsSelected) {
      setMatrixDimensions(
        getMatrixRealDimensions(
          matrixFromReducer,
          dispositionPanneaux,
          panelsSelected.dimension
        )
      );
    }
  }, [matrixFromReducer]);

  useEffect(() => {
    if (panelsSelected) {
      setMatrixDimensions(
        getMatrixRealDimensions(
          matrixFromReducer,
          dispositionPanneaux,
          panelsSelected.dimension
        )
      );
    }
  }, [panelsSelected]);

  useEffect(() => {
    // console.log("rowWithVirtualPanelToShow - ", rowWithVirtualPanelToShow);
  }, [rowWithVirtualPanelToShow]);

  useEffect(() => {
    // console.log("rowWithDeletePanelToShow - ", rowWithDeletePanelToShow);
  }, [rowWithDeletePanelToShow]);

  useEffect(() => {
    // console.log("dispositionPanneaux - ", dispositionPanneaux);
  }, [dispositionPanneaux]);

  useEffect(() => {
    // console.log("dispositionPanneaux - ", dispositionPanneaux);
  }, [panelsCount]);

  const getMaxLength = (matrix) => {
    return _.maxBy(matrix, function (o) {
      return o.length;
    }).length;
  };

  const debounceAddRow = useCallback(() => {
    if (maxPanelCount === panelsCount) {
      setClosedWarningMessage2(false);
      return;
    }
    store.dispatch(addNewRow());
    store.dispatch(validateMatriceCalepinage());
  }, []);

  const addRow = useCallback(() => {
    debounceAddRow();
  }, []);

  const rotate = () => {
    store.dispatch(rotateMatrix());
  };

  const getPanelsCount = (c,l) => {
    let count = 0;
    let result = [];
    for (let i = 0 ; i < matrixFromReducer.length ; i++) {
      for(let j = 0 ; j < matrixFromReducer[i].length ; j++) {
        if(matrixFromReducer[i][j] === 1) {
          count++;
        }
        if(l === i) {
          result.push(count);
        }
      }
    }
    return result;
  }

  return (
    <ThemeProvider theme={sizeTheme}>
      <div
        className={`${dispositionPanneaux ? "d-flex flex-column" : "d-flex"}`}
      >
        {/* UTILITY BUTTONS */}
        <div className="d-none">
          {/* <div className="">
                    <img src={svgsPath + "plus-circle.svg"} alt="" />
                </div> */}

          {/* <span className="material-symbols-rounded">add_circle</span> */}

          <OMaterialButton
            className="material-symbols-rounded"
            icon="+"
            size="28"
            color="primary"
          ></OMaterialButton>
          <OMaterialButton
            className="material-symbols-rounded"
            icon="-"
            size="28"
            color="grey-blue"
          ></OMaterialButton>

          {/* <OTinyButton mode="icon" background="blue" onClick={rotate}>
                    <span className="material-symbols-rounded">add</span>
                </OTinyButton> */}

          {/* <OTinyButton mode="icon" background="blue-alternate" onClick={rotate}>
                    <span className="material-symbols-rounded">pivot_table_chart</span>
                </OTinyButton> */}

          {/* <OTinyButton mode="+" background="red" onClick={rotate} /> */}

          {/* <OTinyButton mode="icon" background="red" iconSize="38" onClick={rotate}>
                    <span className="material-symbols-rounded">add</span>
                </OTinyButton> */}

          <div className="d-flex align-items-center gap-2 pt-3">
            {/* <OTinyButton className="" mode="icon" background="yellow" onClick={rotate}>
                        <span className="material-symbols-rounded">add</span>
                    </OTinyButton> */}
            <OMaterialButton
              className="material-symbols-rounded"
              icon="+"
              size="22"
              color="yellow"
            ></OMaterialButton>
            <OBody8>Ajouter une ligne</OBody8>
          </div>

          <OMaterialButton
            className="material-symbols-rounded"
            icon="+"
            size="22"
            color="red"
          ></OMaterialButton>
          <OMaterialButton
            className="material-symbols-rounded"
            icon="+"
            size="22"
            color="yellow"
          ></OMaterialButton>
          <OMaterialButton
            className="material-symbols-rounded"
            icon="-"
            size="22"
            color="grey-light"
          ></OMaterialButton>
          <OMaterialButton
            className="material-symbols-rounded"
            icon="-"
            size="22"
            color="grey"
          ></OMaterialButton>
          <OMaterialButton
            className="material-symbols-rounded"
            icon="-"
            size="22"
            color="dark"
          ></OMaterialButton>
        </div>

        <WarningContainer>
          {!isDesactivateWaringMessage && matrixWithObstacle && !closedWarningMessage1 && (
            <WarningBox>
              <OMaterialButton
                className="material-symbols-rounded float-right"
                icon="x"
                size="18"
                color="red"
                onClick={() => {
                  setClosedWarningMessage1(true);
                }}
              ></OMaterialButton>

              <WarningMessage>
                Du fait de la présence d'obstacle(s), votre kit solaire pourrait
                manquer de rallonge(s).
                <br />
                Nous vous conseillons de contacter un de nos experts au 01 75 85
                08 88 pour valider votre configuration.
                {/* Votre configuration requiert une validation par un de nos experts, mais vous pouvez tout de même finaliser votre commande ou alors contactez notre service client au 01 75 85 08 88 */}
              </WarningMessage>
            </WarningBox>
          )}

          {panelsCount == maxPanelCount && !closedWarningMessage2 && (
            <WarningBox>
              <OMaterialButton
                className="material-symbols-rounded float-right"
                icon="x"
                size="18"
                color="red"
                onClick={() => {
                  setClosedWarningMessage2(true);
                }}
              ></OMaterialButton>

              <WarningMessage>
                Vous avez atteint le nombre maximum de panneaux pour votre
                installation.
              </WarningMessage>
            </WarningBox>
          )}
        </WarningContainer>

        {panelsSelected && (
          <VerticalDimensionContainer>
            <VerticalDimensionElements
              id={`${
                dispositionPanneaux
                  ? "vertical-dimension-portrait"
                  : "vertical-dimension-paysage"
              }`}
              rows={matrixFromReducer.length}
              columns={maxRow}
            >
              <VerticalDimension
                value={formatNumber(
                  matrixDimensions.verticalDimension,
                  2,
                  false,
                  { style: "unit", unit: "meter" }
                )}
              />
            </VerticalDimensionElements>
          </VerticalDimensionContainer>
        )}

        <MatrixEditorContainer
          id={`${
            dispositionPanneaux
              ? "matrix-editor-portrait"
              : "matrix-editor-paysage"
          }`}
          className={`${dispositionPanneaux ? "flex-column" : "flex-row"}`}
        >
          {panelsSelected && (
            <AreaDimensionContainer>
              <AreaDimensionElements>
                <AreaDimension>
                  {panelsCount} Panneaux ({dispositionPanneaux ? "portrait" : "paysage"}) : {formatNumber(matrixDimensions.areaDimension, 0, false, {}, true)} m²
                </AreaDimension>
              </AreaDimensionElements>
            </AreaDimensionContainer>
          )}

          {panelsSelected && (
            <HorizontalDimensionContainer>
              <HorizontalDimensionElements
                id={`${
                  dispositionPanneaux
                    ? "horizontal-dimension-portrait"
                    : "horizontal-dimension-paysage"
                }`}
                rows={matrixFromReducer.length}
                columns={maxRow}
              >
                <HorizontalDimension
                  value={formatNumber(
                    matrixDimensions.horizontalDimension,
                    2,
                    false,
                    { style: "unit", unit: "meter" }
                  )}
                />
              </HorizontalDimensionElements>
            </HorizontalDimensionContainer>
          )}

          <TopContainer className="" id="top-container">
            <RotateButton onClick={rotate}>
              <img
                src={svgsPath + "swap-orientation.svg"}
                alt="portrait/paysage"
              />
            </RotateButton>
          </TopContainer>

          <MainContainer
            id={`${
              dispositionPanneaux
                ? "main-container-portrait"
                : "main-container-paysage"
            }`}
            className={`${dispositionPanneaux ? "" : ""}`}
          >
            <LeftContainer
              id="left-container"
              className={`${dispositionPanneaux ? "" : "d-none"}`}
            >
              {matrixFromReducer.map((row, i) => (
                <RowControllers
                  id={`${dispositionPanneaux ? "controllers-portrait" : ""}`}
                >
                  <div className="h-100 d-flex justify-content-center">
                    <RowButtonsContainer
                      index={i}
                      disposition={dispositionPanneaux}
                      displayMessage={setClosedWarningMessage2}
                    />
                  </div>
                </RowControllers>
              ))}
            </LeftContainer>

            <TopRowContainer
              id="top-row-container"
              className={`${dispositionPanneaux ? "d-none" : ""}`}
            >
              {matrixFromReducer.map((row, i) => (
                <RowControllers
                  id={`${dispositionPanneaux ? "" : "controllers-paysage"}`}
                  className={`${dispositionPanneaux ? "d-none" : ""}`}
                >
                  <div className="h-100 d-flex justify-content-center">
                    <RowButtonsContainer
                      index={i}
                      disposition={dispositionPanneaux}
                      displayMessage={setClosedWarningMessage2}
                    />
                  </div>
                </RowControllers>
              ))}
            </TopRowContainer>

            <CenterContainer id="center-container">
              <ScrollingContainer
                id={`${
                  dispositionPanneaux
                    ? "scrolling-container-portrait"
                    : "scrolling-container-paysage"
                }`}
              >
                {matrixFromReducer.map((row, index) => (
                  <PanelContainer
                    dispositionPanneaux={dispositionPanneaux}
                    row={row}
                    index={index}
                    rowWithVirtualPanelToShow={rowWithVirtualPanelToShow}
                    rowWithDeletePanelToShow={rowWithDeletePanelToShow}
                    rowNumbers={getPanelsCount(row.length, index)}
                  ></PanelContainer>
                ))}
              </ScrollingContainer>
            </CenterContainer>
          </MainContainer>
        </MatrixEditorContainer>

        <RightContainer className={`${dispositionPanneaux ? "d-none" : ""}`}>
          <ColumnRightController onClick={() => addRow()}>
            <OMaterialButton
              className="material-symbols-rounded"
              icon="+"
              size="22"
              color="yellow"
            ></OMaterialButton>
            Ajouter une colonne
          </ColumnRightController>
        </RightContainer>

        <RowBottomControllers
          className={`${dispositionPanneaux ? "mt-2" : "d-none"}`}
        >
          <RowBottomController onClick={addRow}>
            <OMaterialButton
              className="material-symbols-rounded"
              icon="+"
              size="22"
              color="yellow"
            ></OMaterialButton>
            Ajouter une ligne
          </RowBottomController>
        </RowBottomControllers>
      </div>

      {/* <InformationContainer>
        <div>
          <OMaterialButton
            id="icon"
            className="material-symbols-rounded"
            icon="!"
            size="20"
            color="red"
          ></OMaterialButton>

          <InfoMessage>
            Cliquez sur un panneau pour déclarer un obstacle ou le supprimer
          </InfoMessage>

          <br />

          {panelsSelected && (
            <InfoText>
              Nombre de panneaux du kit :{" "}
              <strong>
                {panelsCount} panneaux (
                {dispositionPanneaux ? "Portrait" : "Paysage"})
              </strong>{" "}
              <br />
              Le modèle choisi est :{" "}
              <strong>
                {panelsSelected.configurator_name} -{" "}
                {panelsSelected.parent.vendor} - {panelsSelected.power} Wc -{" "}
                {panelsSelected.dimension.length}x
                {panelsSelected.dimension.width} mm{" "}
              </strong>{" "}
              <br />
              Veuillez noter que le visuel du panneau de démonstration est
              générique et ne représente pas le modèle que vous avez
              sélectionné.
            </InfoText>
          )}
        </div>
      </InformationContainer> */}
    </ThemeProvider>
  );
};

export default MatrixEditor;
