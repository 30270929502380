import mapboxgl from "mapbox-gl";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { getReversePositionAsync } from "../../../../../store/localization/localization-reducer";
import { API_GEOCODING_KEY } from "../../../../../utils/constants";
import { localizationConfig } from "../../constants";
import { addMarkerLayerToMap } from "./use-jump-to-location";
import { useMapboxMarkerChanged } from "./use-mapbox-marker";

export const useInitMap = (map, mapContainer, zoom) => {
  const dispatch = useDispatch();
  const location = useSelector(
    (state: RootState) => state.localization.location
  );

  const markerIcon = document.createElement("div");
  markerIcon.className = "mapbox-marker-icon";

  const [marker] = useState(
    new mapboxgl.Marker(markerIcon, {
      draggable: true,
    })
  );

  useEffect(() => {
    if (map.current) return; // initialize map only once
    mapboxgl.accessToken = API_GEOCODING_KEY;

    const center = [
      location?.lon || localizationConfig.mapConfig.position[1],
      location?.lat || localizationConfig.mapConfig.position[0],
    ];

    map.current = new mapboxgl.Map({
      container: mapContainer?.current,
      style: localizationConfig.mapConfig.satelliteStyle,
      center: center,
      zoom: zoom,
    });

    // controls
    const zoomControl = new mapboxgl.NavigationControl({
      visualizePitch: true,
    });
    map.current.addControl(zoomControl, "top-left");

    // listen to double click event
    map.current.on("dblclick", (e) => {
      dispatch<any>(getReversePositionAsync(e.lngLat));
    });

    map.current.on("load", () => {
      addMarkerLayerToMap(map.current, location);
    });

    // listen to marker dragend
    marker.on("dragend", () => {
      const newPosition = marker.getLngLat();
      dispatch<any>(getReversePositionAsync(newPosition));
    });
  });
  useMapboxMarkerChanged(map, location, marker);
};
